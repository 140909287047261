.membersRewardsPageWrap {
    .mainContent {
        .pageInternalScroll {
            padding-top: 0px;

            .tab-menu-wrapper {
                .premium-members-invites {
                    padding: 22px 40px 10px;

                    .leftContent {

                        .container {
                            padding: 0px 0px;
                            margin: 0px 0px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {

    .membersRewardsPageWrap {
        .mainContent {
            padding-top: 55px;
            min-height: 100vh;

            .premium-members-invites {
                padding: 22px 10px 10px;

                .leftContent {
                    .container {
                        padding: 0px 0px;
                    }
                }
            }
        }


    }
}

@media (max-width: 480px) {
    .membersCheckoutPageWrap {
        .sharingWrapper {
            .sharingIconsList {
                ul {
                    justify-content: center;
                    padding: 14px 15px 0px;

                    li {
                        max-width: 25%;
                        flex: 0 0 25%;
          }
          }
          }
          }

          }
          }