  .detailPageWrap {
      @include background("../img/hotel-detail-bg.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow-x: hidden;

      .rbt .rbt-aux .btn-close {
          top: -2px;
          z-index: 999;
          height: 35px;
          -webkit-appearance: none;
      }

      .not-found-container {
          height: 100%;

          .not-found-content {
              height: 95%;
          }

          .expire-icon {
              max-width: 80px;
              margin: 0 auto 30px;
          }
      }

      .filterCnt2 {
          .Date-picker-range {
              .DateRangePicker_picker {
                  top: 41px !important;
              }
          }
      }

      .filterCnt {
          position: static;

          .locationStartEndWrap {
              max-width: 420px;

              @media (max-width: 1024px) {
                  max-width: 100%;
              }

              .inputDropdown {
                  .icon-location-on {
                      transform: none;
                      top: 0;
                  }
              }
          }

          .dateAndBtnWrap {
              .Date-picker-range {
                  margin-left: 0px;
              }

              .startEndDate,
              .dropdownWrap {
                  width: 280px;
              }



              @media (max-width: 991px) {

                  .startEndDate,
                  .dropdownWrap {
                      width: 220px;
                  }
              }

          }

          button {
              cursor: pointer;
          }

          .cabin-class-wrapper {
              min-width: 330px;
              width: 100%;

              @media (max-width: 767px) {
                  min-width: 100%;
              }

              .cabinClassWrapper {
                  display: none;
              }

              .addMinusWrapTitle {
                  font-size: 17px;
              }
          }
      }

      .progress {
          display: -ms-flexbox;
          display: flex;
          height: 4px;
          overflow: hidden;
          line-height: 0;
          font-size: 0.75rem;
          background-color: rgba(126, 148, 168, 0.4);
          border-radius: 5px;
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          margin-top: 4px;

          .progress-bar {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-direction: column;
              flex-direction: column;
              -ms-flex-pack: center;
              justify-content: center;
              overflow: hidden;
              color: #fff;
              text-align: center;
              white-space: nowrap;
              background-color: #00b7a6;
              transition: width 0.6s ease;
          }
      }

      .sectionTitle {
          padding: 10px 0;

          h2 {
              color: $white;
              font-size: 21px;
              font-weight: 400;
          }
      }

      .pageWrap {
          .mainContent {
              overflow-x: unset;
              padding-top: 0;
              //   min-height: calc(100vh - 118px);

              >.container {
                  padding-top: 0;
              }
          }
      }

      .bannerSlider {
          margin-top: 10px;
          background-color: rgb(212, 237, 245);
          border-radius: 10px;

          .slick-track {
              min-width: 100%;
          }

          .fullImages {
              max-width: 69.8%;
              flex: 0 0 69.8%;
              padding: 5px;
              border-radius: 10px;

              .imagesGroup {
                  border: none;
                  box-shadow: none;
                  background-size: cover;
                  background-position: center center;
                  background-repeat: no-repeat;
                  height: auto !important;
                  padding: 0px;
                  background-color: transparent;
                  min-height: 417px;
                  //   .imagesGroup {
                  //     background: none !important;
                  //   }

                  @media (max-width: 991px) {
                      min-height: 340px;
                  }

                  .slick-list {
                      border-radius: 10px;
                  }
              }
          }

          .content {
              border-radius: 10px;
              padding: 0 5px 0 0;
              margin: 5px 0;

              .mapWrap {
                  border: none;
                  height: 161px;

                  img {
                      height: 142px;
                  }
              }

              .infoBox {
                  padding: 0;
                  margin-top: 5px;
                  min-height: 250px;

                  &.box-info {
                      justify-content: flex-start;
                  }

                  .infoBox {
                      padding: 15px 0;
                      width: 100%;
                      margin: 0;
                      background-color: transparent;
                  }

                  .buttonsGroup {
                      padding-top: 14px;
                      padding-bottom: 0;
                  }

                  .btn {
                      border-radius: 22.5px;
                      min-width: 160px;
                      padding: 12px;
                      color: $white;

                      @media (max-width:767px) {
                          min-width: 106px;
                          padding: 8.5px 7px;
                          margin-right: 12px;
                      }
                  }

                  .price-box-col {
                      padding: 0;
                  }
              }
          }
      }

      .infoBox {
          padding: 15px 0;
          width: 100%;
          margin-top: 5px;
          @extend .customShadow;


          .title {
              border-bottom: 1px solid #435b75;

              button {
                  font-weight: normal;
                  color: rgba(67, 91, 117, 0.6);
                  font-size: 14px;
                  padding: 0 34px 5px;
                  position: relative;
                  border: none;
                  background-color: transparent;

                  &.active {
                      color: #1d6ec9;

                      &:after {
                          content: "";
                          display: block;
                          position: absolute;
                          left: 0;
                          bottom: -1px;
                          border-bottom: 2px solid #1d6ec9;
                          width: 100%;
                      }
                  }
              }
          }

          .infoBoxWrapper {
              padding: 0 12px;

              .buttonSpace {
                  padding-top: 14px;

                  .btn {
                      min-width: 160px;
                      padding: 12px;
                  }
              }
          }

          .publicPrice {
              margin-top: 10px;

              span {
                  font-size: 17px;
                  color: rgba(67, 91, 117, 0.6);

                  &:nth-child(2) {
                      color: #435b75;
                      font-weight: 500;
                  }
              }
          }

          .fromPrice {
              margin-top: 4px;

              &.singlePrice {
                  //   padding-top: 15px;
                  //   align-items: center;
                  //   flex-direction: column;
                  //   justify-content: center;
                  //   text-align: center;
                  min-height: 67px;
                  align-items: flex-end;

                  @media(max-width:767px) {
                      min-height: 55px;
                  }
              }

              .fromPriceWrap {
                  .lightText.btmSpace {
                      font-size: 10px;
                  }
              }

              .from-details-price {
                  text-align: right;

                  >span {
                      color: #435b75;
                      font-weight: 500;
                  }
              }

              span {
                  font-size: 21px;
                  color: #435b75;
                  font-weight: 500;

                  &:nth-child(2) {
                      color: #435b75;
                      font-weight: 500;
                  }
              }
          }

          p {
              color: #435b75;
              font-size: 14px;
          }

          .priceDetails {
              margin-bottom: 4px;


              .fa {
                  font-size: 18px;
                  margin-left: 5px;
                  margin-top: -2px;
              }
          }

          .savings {
              margin-top: 3px;
              margin-bottom: 6px;

              span {
                  color: #00b7a6;
                  font-size: 14px;
                  font-weight: 400;

                  &:nth-child(2) {
                      font-weight: 500;
                  }
              }
          }
      }

      .thumbnail {
          margin: 0;
          position: relative;
          padding: 0 2.5px;

          .images {
              padding: 0 2.5px;

              @media (max-width: 991px) {

                  div {
                      height: 100%;
                  }
              }

              img {
                  border-radius: 10px;
                  max-height: 117px;
                  height: 117px;
                  overflow: hidden;
                  object-fit: cover;
                  padding: 0px 1px;

                  @media (max-width: 1024px) {
                      max-height: 90px;
                      height: 90px;
                  }
              }
          }

          .thumbnailCount {
              position: absolute;
              bottom: 10px;
              right: 10px;
              z-index: 1;
              background-color: rgba(67, 91, 117, 0.6);
              border-radius: 6px;
              max-width: 64px;
              padding: 6px;

              img {
                  max-width: 20px;
              }

              h3 {
                  color: $white;
                  margin-left: 4px;
                  font-weight: 400;
              }
          }
      }

      .SliderContent {
          padding: 19px 10px 25px;

          .cartTitle {
              font-size: 25px;
              color: #1d6ec9;
              font-weight: 400;
              margin-bottom: 8px;
          }

          .rating {
              margin-bottom: 23px;
          }

          .location {
              font-size: 14px;
              color: #435b75;
              margin-bottom: 10px;

              img {
                  margin-right: 7px;
              }
          }

          .phone {
              font-size: 14px;
              color: #435b75;

              .icon-phone {
                  margin-right: 7px;
              }
          }

          .timeWrapper {
              margin-bottom: 19px;

              h4 {
                  font-size: 14px;
                  color: rgba(67, 91, 117, 0.6);
                  font-weight: 400;
                  margin-bottom: 3px;
              }

              h3 {
                  font-size: 17px;
                  color: #435b75;
                  font-weight: 500;
                  display: flex;
                  align-items: center;

                  img {
                      margin-right: 3px;
                  }
              }
          }

          .themeWrapper {
              .col {
                  display: flex;
                  align-items: center;
              }

              h4 {
                  font-size: 14px;
                  color: rgba(67, 91, 117, 0.6);
                  font-weight: 400;
                  margin-bottom: 9px;
              }

              h3 {
                  height: 38px;
                  width: 38px;
                  display: flex;
                  align-items: center;
                  background-color: rgba(255, 255, 255, 0.35);
                  border: 1px solid #d2dae6;
                  border-radius: 100%;
                  box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.6), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                  justify-content: center;

                  img {
                      max-width: 20px;
                  }
              }

              h5 {
                  color: #435b75;
                  font-size: 14px;
                  margin-left: 10px;
                  font-weight: 400;
              }
          }

          .leftContent {
              max-width: 56%;
              flex: 0 0 56%;
          }
      }

      .tabsWrapper {
          padding: 25px 0 29px;

          ul {
              margin: 0 -25px;
              flex-wrap: wrap;
          }

          li {
              padding: 0 25px;

              a {
                  font-size: 21px;
                  color: $white;
                  cursor: pointer;
              }

              &.active {
                  a {
                      border-bottom: 1px solid $white;
                      padding-bottom: 3px;
                  }
              }


          }
      }

      .amenitiesBox {
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 10px;
          padding: 0 15px;

          li {
              list-style: none;
          }

          .showMoreLink {
              button {
                  font-size: 14px;
                  color: #1d6ec9;
                  font-weight: 400;
                  background-color: transparent;
                  border: none;
              }
          }

          .show-all-amenities {
              padding-top: 13px;
          }

          .leftContent {
              border-right: 1px solid rgba(67, 91, 117, 0.2);
              padding: 20px 15px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              h3 {
                  font-size: 17px;
                  color: #435b75;
                  font-weight: 500;
              }

              ul {
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 23px;

                  li {
                      max-width: 33.33%;
                      flex: 0 0 33.33%;
                      display: flex;
                      align-items: center;
                      padding-bottom: 16px;
                  }

                  .images {
                      height: 38px;
                      width: 38px;
                      flex: 0 0 38px;
                      align-items: center;
                      background-color: hsla(0, 0%, 100%, .35);
                      border: 1px solid #d2dae6;
                      border-radius: 100%;
                      box-shadow: -2px -2px 4px 0 hsl(0deg 0% 100% / 60%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                      display: flex;
                      height: 38px;
                      justify-content: center;
                      color: #7E94A8;

                      svg {
                          font-size: 18px;
                      }

                      svg * {
                          color: #7E94A8;
                      }
                  }

                  h4 {
                      font-size: 14px;
                      color: #435b75;
                      font-weight: 400;
                      margin-left: 12px;
                  }
              }
          }

          .rightContent {
              padding: 21px 15px;

              .heading {
                  display: flex;
                  align-items: center;

                  h4 {
                      margin-left: 10px;
                      color: rgba(67, 91, 117, 0.6);
                      font-weight: 400;
                      font-size: 14px;
                  }
              }

              .ratingComment {
                  font-size: 19px;
              }

              .commentCount {
                  font-size: 17px;
                  padding: 6px 7.1px;
              }

              ul {
                  display: flex;
                  flex-wrap: wrap;
                  list-style-type: none;
                  padding-top: 34px;
                  margin: 0 0 0 -11px;
              }

              li {
                  max-width: 44%;
                  flex: 0 0 44%;
                  padding: 0 3% 21px;

                  h4 {
                      color: #435b75;
                      font-weight: 400;
                      font-size: 14px;
                      text-transform: capitalize;
                  }

                  h3 {
                      font-size: 17px;
                      color: #00b7a6;
                      font-weight: 400;
                  }
              }

              .showMoreLink {
                  padding-left: 4px;
              }
          }
      }

      .selectRoomTabs {
          padding: 14px 0 20px;

          .sectionTitleWrap {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .sectionTitle {
                  padding-bottom: 0;
              }

              .sortWrapper {
                  position: relative;
                  //  cursor: pointer;
                  margin-left: auto;
                  // padding: 8px 7px 9.2px 15px;
                  padding: 0;
                  min-height: 53px;
                  align-items: flex-start;
                  border-radius: 12px;
                  background-color: rgba(255, 255, 255, 0.4);
                  min-width: 235px;

                  .sort-by-title {
                      padding: 9px 14px 0;
                      color: #ffffff;
                      font-size: 14px;
                      line-height: normal;
                      font-weight: 400;
                  }

                  .ant-select {
                      display: block;

                      .ant-select-selector {
                          background-color: transparent;
                          border: none;
                          border-radius: 0;
                          color: #1d6ec9;
                          font-weight: 400;
                          padding: 0;
                          box-shadow: none;
                          min-height: auto;
                      }

                      .ant-select-selection-item {
                          padding: 0 14px;
                          color: #1d6ec9;
                      }

                      .ant-select-arrow {
                          font-size: 17px;
                          transform: translateY(-50%);

                          &:after {
                              display: inline-block;
                              font: normal normal normal 14px / 1 FontAwesome;
                              font-size: inherit;
                              text-rendering: auto;
                              -webkit-font-smoothing: antialiased;
                              content: "\f107";
                              color: #435b75 !important;
                          }

                          svg {
                              display: none;
                          }
                      }
                  }

                  .content {
                      >div {
                          width: 100%;
                      }

                      >.whiteText {
                          padding: 9px 14px 0 0;

                          @media (min-width:768px) and (max-width:1023px) {
                              //   padding: 4px 14px 0 0;
                          }
                      }

                      [class*=control] {
                          >div {
                              padding: 0px 0px;
                              margin: 10px 0px 0px 0px;
                          }

                      }

                      .sort-by-dropdown,
                      .css-1s2u09g-control,
                      .css-1pahdxg-control {
                          width: 100%;
                          background: transparent;
                          border: none;
                          min-height: 0px;
                          box-shadow: none;
                          cursor: pointer;

                          .css-319lph-ValueContainer,
                          .css-qc6sy-singleValue,
                          .css-6j8wv5-Input {
                              padding: 0px 0px;
                              color: #1d6ec9;
                              margin: 0px;
                          }

                          .css-tlfecz-indicatorContainer,
                          .css-1gtu0rj-indicatorContainer {
                              display: none;
                              padding: 0px;
                              color: #1d6ec9;
                              width: 15px;
                          }

                          .css-1okebmr-indicatorSeparator {
                              display: none;
                          }

                          [class*=menu] {
                              box-sizing: border-box;
                              width: 100%;
                              min-width: 211px;
                              padding: 5px;
                              // margin-left: -15px;
                              margin-top: 5px;
                              border-radius: 10px;
                              box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
                              border: solid 1px #d2d9e7;
                              background-color: #e6e7ee;
                              z-index: 1;

                              [class*=MenuList] {
                                  [class*=option] {
                                      padding: 5px 8px;
                                      font-size: 15px;
                                      border-radius: 6px;
                                      line-height: 17px;
                                      color: #435b75 !important;
                                      cursor: pointer;
                                  }

                              }

                              [class*=option] {
                                  border-color: #d2dae6 !important;
                                  color: #435b75 !important;
                                  padding: 5px 8px;
                                  border-radius: 6px;
                              }

                              .css-1n7v3ny-option,
                              .custom-select__option--is-selected,
                              .css-9gakcf-option,
                              [class*=option] {
                                  background-color: rgb(230, 231, 238) !important;
                                  color: #435b75 !important;
                              }

                              [class*=option]:hover {
                                  box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                                      inset -2px -2px 6px 0 #ffffff !important;
                                  border-color: #d2dae6 !important;
                                  color: #435b75 !important;
                                  padding: 5px 8px;
                              }
                          }
                      }

                      [class*=fa] {
                          position: absolute;
                          right: 10px;
                          top: 19px;
                          pointer-events: none;
                      }

                      .sort-by-dropdown {
                          margin-top: 0px;
                          position: absolute;
                          top: 0px;
                          left: 0;
                          height: 100%;
                          width: 100%;

                          .css-1hb7zxy-IndicatorsContainer {
                              position: absolute;
                              top: -11px;
                              right: 0;
                          }

                          .css-26l3qy-menu {
                              [class*=option] {
                                  font-size: 12px
                              }
                          }

                          [class*=control] {
                              padding: 18px 14px 10px;
                              background: transparent;
                              border: none;
                              min-height: 0px;
                              box-shadow: none;
                              cursor: pointer;
                          }

                          [class*=ValueContainer] {
                              padding-top: 10px;
                          }

                          [class*=indicatorSeparator],
                          [class*=indicatorContainer] {
                              display: none;
                          }

                          [class*=singleValue] {
                              position: absolute;
                              top: 0;
                              left: 0;
                              height: 100%;
                              width: 100%;
                              padding: 0px 0px;
                              color: #1d6ec9;
                              margin: 0px;
                          }
                      }
                  }
              }

              .mobileSortFilter.showPopup {
                  display: block;
                  padding: 20px 15px 80px;
                  z-index: 999;

                  .clearFilter {
                      display: none;
                  }

                  .mobilePopTitle {
                      padding-bottom: 12px;
                  }

                  .filterTitle {
                      padding-bottom: 13px;
                      order: 2;
                  }

                  .closePopup {
                      top: 0px;
                  }

                  .sortFilter {
                      display: block;
                      align-items: flex-start;
                      padding-top: 8px;
                      flex-direction: column;

                      .filterDropdownWrap {
                          width: 100%;
                          position: relative;
                          flex-grow: 1;
                          max-width: 100%;
                          min-width: 100%;

                          +.filterDropdownWrap {
                              margin-left: 0px;
                              margin-bottom: 0px;
                          }

                          &.sortByOption {
                              max-width: 100%;
                              width: 100%;
                              margin-left: 0px;
                              margin-bottom: 25px;

                              .content {
                                  padding: 0px 0px;
                              }

                              .filterDropdown {
                                  top: 63px;
                                  left: auto;
                                  right: 0;
                                  padding: 0;
                                  width: 100%;
                                  min-width: 100%;
                              }

                              .flterOption {
                                  padding: 0px 0px;
                                  font-size: 17px;
                                  box-shadow: -2px -2px 4px 0 #ffffff, 2px 2px 3px 0 rgb(0 0 0 / 15%);
                                  border: solid 1px #cfd1e1;
                                  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                                  width: 100%;

                                  >div {
                                      width: 100%;
                                  }

                                  [class*=control] {
                                      background-color: transparent;
                                      background: none;
                                      border: none;
                                      width: 100%;
                                      box-shadow: none;
                                      border: none;
                                      padding: 9px 10px;
                                      padding-right: 30px;
                                      cursor: pointer;

                                      >div {
                                          padding: 0px;
                                          //display: none;
                                      }

                                      [class*=singleValue] {
                                          color: #1d6ec9;
                                          font-size: 17px;
                                          font-weight: 400;
                                      }

                                      [class*=ValueContainer] {
                                          padding: 0px 3px;
                                          display: flex;
                                      }

                                      [class*=Input] {
                                          margin: 0px;
                                      }
                                  }

                                  [class*=control]:nth-child(2) {
                                      display: none;

                                  }



                                  .css-26l3qy-menu {
                                      border-radius: 10px;
                                      box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                                      background-color: #eff0f5;
                                      padding: 5px;
                                      display: block;
                                      margin-top: 15px;
                                      height: auto;
                                      min-height: 250px;
                                      box-sizing: border-box;
                                      z-index: 999;

                                      .css-4ljt47-MenuList div {
                                          display: block;
                                          color: #435b75;
                                          padding: 10px 9px;
                                          font-size: 16px;
                                          font-weight: normal;
                                          border: solid 1px transparent;
                                          border-radius: 10px;
                                          white-space: nowrap;
                                          margin-bottom: 2px;
                                      }



                                      [class*=option]:hover {
                                          box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                                          border-color: #d2dae6;
                                          background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                                      }
                                  }

                                  [class*=control]:after {
                                      content: "\f107";
                                      display: inline-block;
                                      font: normal bold normal 18px/1 FontAwesome;
                                      -webkit-font-smoothing: antialiased;
                                      color: #1d6ec9;
                                      transform: rotate(0deg);
                                      font: normal normal normal 14px/1 FontAwesome;
                                      font-size: inherit;
                                      text-rendering: auto;
                                      right: 10px;
                                      top: 50%;
                                      position: absolute;
                                      transform: translateY(-50%);
                                  }
                              }
                          }

                          &.rightAuto {
                              margin-right: auto;
                          }

                          [class*=IndicatorsContainer],
                          [class*=indicatorContainer],
                          [class*=indicatorSeparator] {
                              display: none !important;
                              padding: 0px;
                          }

                          .btnsCnt {
                              .borderBtn {
                                  border-radius: 15px;
                                  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1),
                                      -1px -1px 4px 1px rgba(255, 255, 255, 0.25);
                                  background-color: #1d6ec9;
                                  font-size: 14px;
                                  font-weight: normal;
                                  color: $white;
                              }
                          }
                      }

                      .whiteLabel {
                          display: none;
                      }

                      .flterOption {
                          border-radius: 10px;
                          font-size: 17px;
                          box-shadow: none;
                          background-color: transparent;
                          font-weight: 400;
                          color: var(--text-color);
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          padding: 0px 0px 10px 0px;
                          margin-bottom: 0px;
                          cursor: pointer;
                          white-space: nowrap;

                          .col {
                              padding: 0px 0px;
                          }

                          .fa-angle-down {
                              display: none;
                          }
                      }

                      .filterDropdown {
                          border-radius: 10px;
                          box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.25);
                          background-color: #eff0f5;
                          padding: 5px 15px 20px;
                          box-sizing: border-box;
                          position: absolute;
                          top: 50px;
                          min-width: 200px;
                          display: none;
                          z-index: 2;

                          .multiCol3 {
                              @media (min-width: 768px) {

                                  .customCheckbox {
                                      width: 33.33%;
                                      float: left;
                                      margin-top: 0;
                                  }

                                  .customCheckbox {
                                      width: 33.33%;
                                      float: left;
                                      margin-top: 10px;
                                      line-height: 7px;

                                      >span:before {
                                          top: -6px;
                                      }
                                  }
                              }
                          }

                          .multiColBtn {
                              clear: both;
                              text-align: right;
                              display: block;

                              .blueBtn {
                                  margin-right: 5px;
                              }
                          }

                          &.priceFilter {
                              width: 280px;
                              padding-top: 17px;
                          }

                          ul {
                              list-style: none;
                              padding: 6px;

                              li {
                                  margin-bottom: 2px;
                              }

                              a {
                                  display: block;
                                  color: var(--text-color3);
                                  padding: 10px 9px;
                                  border: solid 1px transparent;
                                  border-radius: 6px;
                                  white-space: nowrap;

                                  &:hover {
                                      box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                                          inset -2px -2px 6px 0 #ffffff;
                                      border-color: #d2dae6;
                                      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                                  }
                              }
                          }
                      }

                      .collayout-3-dropdown {
                          @media (min-width: 768px) {
                              .multiCol3 .customCheckbox>span:before {
                                  top: -3px;
                              }

                              .customCheckbox input:checked+span:after {
                                  top: -1px;
                              }
                          }
                      }

                      .openedOption {
                          .flterOption {
                              background-color: rgba(255, 255, 255, 0.6);
                          }

                          .filterDropdown {
                              display: block;
                          }
                      }

                      .btnsCnt {
                          display: flex;
                          align-items: center;
                          font-size: 14px;
                          padding-top: 26px;
                      }

                      .borderBtn {
                          color: #1d6ec9;
                          background: var(--white);
                          width: 100px;
                          border: 1px solid #1d6ec9;
                          border-radius: 15px;
                          margin-right: 28px;
                          padding: 6px;
                          cursor: pointer;

                          &.checked,
                          &:hover {
                              background: #fff;
                              color: #1d6ec9;
                          }
                      }

                      .filterby-input {
                          order: 2;
                          margin-bottom: 26px;

                          .showDropdown {
                              padding: 2px 0px;

                              .inputDropdown {
                                  position: static;
                                  min-width: 100%;
                                  min-height: auto;
                                  min-height: 45px;
                                  box-sizing: border-box;
                                  color: #1d6ec9;
                                  margin: 0px 0px;
                                  font-size: 17px;
                                  padding: 12px 15px 12px 15px;
                                  border-radius: 10px;
                                  box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                                  -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                                  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                                  border-color: transparent;

                                  @include placeholder-text {
                                      color: #1d6ec9;
                                  }
                              }

                              .rbt-input-hint {
                                  height: auto !important;
                                  padding: 12px 15px 10px 15px !important;
                                  color: #1d6ec9 !important;
                              }

                              #hotel_search {
                                  border-radius: 10px;
                                  box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                                  background-color: #eff0f5;
                                  padding: 5px;
                                  box-sizing: border-box;
                                  min-width: 200px;
                                  display: none;
                                  z-index: 2;
                                  margin-top: 10px;

                                  a {
                                      display: block;
                                      color: var(--text-color3);
                                      border: solid 1px transparent;
                                      border-radius: 6px;
                                      white-space: nowrap;
                                      margin-bottom: 2px;
                                      padding: 10px 15px;
                                  }

                                  a:hover {
                                      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                                      border-color: #d2dae6;
                                      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                                  }
                              }

                          }
                      }

                      .filterDropdownWrap {

                          .d-none {
                              display: block !important;
                          }

                          .star-rating {
                              display: flex !important;
                              max-width: 130px;
                          }

                          .checkbox-card {
                              display: block;
                          }

                          .priceBtn {
                              display: none;
                          }

                          .priceRange {
                              padding: 17px 0px 0px;
                              display: flex !important;
                              justify-content: space-between;

                              .inputRange {
                                  margin-left: 10px;
                              }

                              h4 {
                                  margin-bottom: 10px;
                                  margin-right: 10px;
                                  font-weight: normal;
                                  color: var(--text-color);
                                  font-size: 17px;
                              }
                          }

                          .cardBody {
                              padding-bottom: 20px;

                              .inputRange {
                                  .inputRange {
                                      left: 10px;
                                  }

                                  .input-range__track--active {
                                      background: #0075ff;
                                  }

                                  .input-range__slider {
                                      background: #0075ff;
                                      border-color: #0075ff;
                                  }
                              }
                          }
                      }

                      .cancelBtn {
                          color: var(--text-color3);

                          &:hover {
                              color: #000;
                          }
                      }

                      .priceVal {
                          color: var(--text-color);
                          font-size: 17px;
                      }

                      .rangeStyle {
                          padding: 10px 0 0;

                          input {
                              width: 100%;
                          }
                      }

                      .customCheckbox {
                          display: block;
                          white-space: nowrap;

                          span {
                              font-size: 17px;
                              padding-left: 32px;
                          }

                          +.customCheckbox {
                              margin-top: 12px;
                          }

                          >span:before {
                              width: 21px;
                              height: 21px;
                          }

                      }
                  }
              }

              .mobileSortFilter {
                  .closePopup {
                      display: block;
                  }
              }
          }

          .sectionTitle {
              padding: 10px 0 30px;
              display: flex;
              align-items: center;
          }

          .rate-timer-wrap {
              padding-left: 12px;
          }

          ul {
              margin: 10px -5px;
              align-items: stretch;

              li {
                  color: $white;
                  font-size: 14px;
                  font-weight: 500;
                  padding: 0 5px;
                  display: flex;

                  a,
                  button {
                      color: $white;
                      padding: 7px 19px;
                      transition: 0.3s;
                      border: 1px solid $white;
                      border-radius: 10px;
                      background: transparent;

                      span {
                          display: block;
                      }

                      &:hover,
                      &.active {
                          color: #1d6ec9;
                          border: solid 1px transparent;
                          background-color: rgba(255, 255, 255, 0.4);
                      }
                  }
              }
          }

          .refundToggle {
              margin: 10px 0;

              ul {
                  width: 100%;
                  margin: 0 auto;
                  max-width: 550px;

                  li {
                      max-width: 50%;
                      flex: 0 0 50%;
                      padding: 0 10px;

                      a,
                      button {
                          display: block;
                          text-align: center;
                          font-size: 18px;
                          width: 100%;
                          font-weight: 500;

                          span {
                              font-size: 14px;
                              font-weight: 400;
                          }

                          @media (max-width: 767px) {
                              font-size: 15px;
                              padding: 7px;
                              border-radius: 4px;

                              span {
                                  font-size: 10px;
                                  margin-top: 1px;
                              }

                              &.active {
                                  background-color: rgba(255, 255, 255, 0.8);
                              }
                          }

                      }
                  }
              }
          }

          .expireTimer {
              background-color: rgba(255, 255, 255, 0.4);
              border-radius: 10px;
              padding: 6px 9px;

              .d-flex {
                  align-items: center;
              }

              img {
                  margin-right: 7px;
              }

              h3 {
                  font-size: 14px;
                  color: #1d6ec9;
                  font-weight: 400;
              }
          }
      }

      .selectRoomContent {
          .leftContent {
              background-color: rgba(255, 255, 255, 0.8);
              padding: 10px 10px 18px;
              margin-bottom: auto;
              border-radius: 10px;
              max-width: 40.99%;
              flex: 0 0 40.99%;

              h3 {
                  color: #435b75;
                  font-weight: 500;
                  font-size: 17px;
                  margin-bottom: 8px;
                  margin-top: 15px;
              }

              button {
                  font-size: 14px;
                  font-weight: 400;
                  color: #1d6ec9;
                  border: none;
                  background-color: transparent;
              }

              .imageContent {
                  .image {
                      max-width: 59.78%;
                      flex: 0 0 59.78%;
                  }

                  .customShadow {
                      padding-bottom: 6px;
                  }

                  .content {
                      padding-left: 16px;

                      h3 {
                          color: #435b75;
                          font-weight: 500;
                          margin-bottom: 14px;
                          margin-top: 5px;
                      }

                      h4 {
                          max-width: 130px;
                          color: #435b75;
                          font-weight: 400;
                          font-size: 14px;
                          display: flex;
                          margin-bottom: 12px;

                          &:last-child {
                              margin-bottom: 5px;
                          }

                          img {
                              margin-right: 8px;
                              margin-bottom: auto;
                          }
                      }

                      h5 {
                          font-size: 14px;
                          font-weight: 400;
                          color: rgba(67, 91, 117, 0.6);
                          margin-bottom: 12px;
                      }
                  }
              }
          }

          .rightContent {
              padding-left: 15px;

              .showMoreLinks {
                  padding-bottom: 28px;

                  button {
                      font-size: 17px;
                      color: $white;
                      font-weight: 400;
                  }
              }

              .card2 {
                  background-color: rgba(255, 255, 255, 0.8);
                  padding: 15px 10px 12px 15px;
              }

              .imgWrap {
                  border: none;
                  background-color: inherit;
                  box-shadow: none;
                  padding-bottom: 2px;

                  .savings {
                      font-size: 14px;
                      background-color: #00b7a6;
                      border-radius: 6px;
                      text-align: center;
                      color: $white;
                      padding: 4.5px 3.5px;
                      max-width: 110px;
                  }

                  .links {
                      .btnBorder {
                          border-color: #00b7a6;
                          color: #00b7a6;
                          border-radius: 6px;
                          min-width: 109px;
                          padding: 4px 10px;
                          margin-bottom: 7px;
                      }

                      button {
                          color: #1d6ec9;
                          font-size: 12px;
                      }
                  }
              }

              .infoBox {
                  max-width: 70.74%;
                  flex: 0 0 70.74%;
                  margin-top: 0;
                  padding: 0;

                  .title {
                      padding: 10px 0 0;
                      border-color: rgba(67, 91, 117, 0.2);

                      button {
                          padding: 0 15px 8px;
                      }
                  }

                  .btn {
                      margin-bottom: 15px;
                      min-width: 160px;
                      padding: 9.5px;
                      border-radius: 20px;
                  }

                  .btnBorder2 {
                      border-radius: 20px;
                      padding: 6.5px 7px;
                      margin-bottom: 7px;
                  }

                  .availableRoomsWrap {
                      border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                      margin-bottom: 22px;
                      padding-bottom: 5px;
                      padding-top: 9px;
                  }

                  .availableRooms {
                      background-color: rgba(67, 91, 117, 0.6);
                      border-radius: 6px;
                      font-size: 12px;
                      color: $white;
                      padding: 3.5px;
                      max-width: 110px;
                      text-align: center;
                      font-weight: 400;
                      margin: 0 auto;
                  }

                  .infoBoxWrapper {
                      padding: 0 12px;

                      .buttonSpace {
                          padding-top: 14px;

                          .btn {
                              min-width: 136px;
                              padding: 12px;
                          }
                      }
                  }

                  .publicPrice {
                      margin-top: 8px;

                      span {
                          font-size: 14px;
                      }
                  }

                  .fromPrice {
                      margin-top: 4px;

                      span {
                          font-size: 17px;
                      }
                  }

                  .priceDetails {
                      margin-bottom: 4px;
                      color: #1d6ec9;
                      position: relative;
                      display: inline-flex;
                      cursor: pointer;

                      &:hover,
                      &:focus {
                          .pricedetail-dropdown {
                              display: block;
                          }
                      }

                      h4 {
                          font-size: 12px;
                          font-weight: 400;

                      }
                  }

                  p {
                      font-size: 12px;
                  }

                  .savings {
                      margin-top: 5px;
                      margin-bottom: 6px;

                      p {
                          font-size: 14px;
                          color: rgba(255, 255, 255, 0.8);
                      }
                  }
              }


              .pricedetail-dropdown {
                  .dropdown-info h4 {
                      font-size: 12px;
                      font-weight: 400;
                      color: rgba(67, 91, 117, 0.6);
                  }

                  .total-amount {
                      h4 {
                          font-size: 17px;
                      }
                  }

                  .saving-box {
                      h4 {
                          color: #00b7a6 !important;
                      }
                  }

                  .note {
                      font-size: 12px;
                      @include ubuntu(400);
                      line-height: normal;
                      letter-spacing: normal;
                      color: #00b7a6;
                      max-width: 147px;
                  }


              }
          }
      }

      .selectRoomContentMain {
          h4 {
              color: #ffffff;
              font-size: 21px;
              font-weight: 400;
              margin-bottom: 10px;
          }

          .selectRoomContent {
              &:last-child {
                  .rightContent {
                      .showMoreLinks {
                          padding-bottom: 18px;
                      }
                  }
              }
          }

          .refundable-empty-box {
              min-height: 130px;
              background-color: rgba(255, 255, 255, 0.8);
              border-radius: 10px;
              margin-bottom: 16px;
              display: flex;
              align-items: center;
              justify-content: center;

              h4 {
                  color: #435b75;
                  margin-bottom: 0;
              }
          }
      }

      // No reviews found
      .review-empty-box {
          min-height: 130px;
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 10px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          h4 {
              color: #435b75;
              margin-bottom: 0;
              font-size: 21px;
          }
      }

      .amenitiesWrapper {
          padding: 0 0 18px;

          .sectionTitle {
              padding-top: 0;
          }

          .amenitiesMainInfo {
              background-color: rgba(255, 255, 255, 0.8);
              border-radius: 10px;
              margin-top: 3px;
          }

          .amenitiesInfo {
              padding: 16px 15px;

              h3 {
                  color: #435b75;
                  font-size: 17px;
                  line-height: 1.2;
                  font-weight: 500;
                  display: flex;
                  margin-bottom: 10px;

                  img,
                  svg {
                      margin-right: 6px;
                      height: 20px;
                      width: 20px;
                      flex: 0 0 20px;
                      color: #7E94A8 !important;

                      * {
                          color: #7E94A8 !important;
                          stroke: #7E94A8 !important;

                      }
                  }
              }

              li {
                  font-size: 14px;
                  color: #435b75;
                  line-height: 1.55;
              }

              .row {
                  .col {
                      flex: 0 0 25%;
                      max-width: 100%;
                      margin-bottom: 15px;
                  }
              }
          }

          .showAllLinks {
              border-top: 1px solid rgba(67, 91, 117, 0.2);
              padding: 10px 0;

              button {
                  font-size: 14px;
                  font-weight: 400;
                  color: #1d6ec9;
                  border: none;
                  background-color: transparent;
              }
          }
      }

      .propertyDetails {
          margin-bottom: 16px;

          .propertyDetailsTab {
              border-radius: 10px;
              background-color: rgba(255, 255, 255, 0.8);
              margin-top: 6px;

              .content {
                  padding: 21px 10px 17px 15px;
                  max-width: 1030px;
                  color: #435b75;
                  font-size: 14px;
                  line-height: 1.5;
                  margin-bottom: 21px;
                  display: flex;

                  &:last-child {
                      margin-bottom: 0;
                  }

              }

              ul {
                  border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                  padding: 12px 16px;

                  li {
                      display: flex;
                      align-items: center;
                      padding: 0 13px;

                      img,[class*=icon]  {
                          margin-right: 4px;
                      }

                      a,
                      button {
                          font-size: 14px;
                          color: rgba(67, 91, 117, 0.6);
                          font-weight: 400;
                          position: relative;
                          border: none;
                          background-color: transparent;
                      }

                      &.active {
                        img,[class*=icon]:before  {
                            color: #1d6ec9;
                        }
                          a,
                          button {
                              color: #1d6ec9;

                              &::after {
                                  content: "";
                                  display: block;
                                  width: 100%;
                                  left: -30px;
                                  bottom: -12px;
                                  height: 2px;
                                  background-color: #1d6ec9;
                                  position: absolute;
                                  min-width: 173px;
                              }
                          }
                      }
                  }
              }

          }

          .content {
              flex-wrap: wrap;

              .nearby-wrap {
                  display: flex;
                  flex: 0 0 50%;
                  padding: 0px 20px;
                  flex-direction: column;

                  div {
                      display: flex;
                      justify-content: space-between;
                  }
              }
          }
      }

      .rewievsWrapper {
          .sectionTitle {
              padding: 21px 0 13px;
          }

          .rewievsInfo {
              background-color: rgba(255, 255, 255, 0.8);
              padding: 30px 10px 20px 29px;
              border-radius: 10px;
              margin-bottom: 40px;

              @media(max-width:991px) {
                  padding: 20px 10px;
              }
          }

          .leftContent {
              max-width: 27.7%;
              flex: 0 0 27.7%;

              .contentWrap {
                  padding-right: 62px;
              }

              .ratingComment {
                  display: flex;

                  .commentCount {
                      margin-bottom: auto;
                      font-size: 30px;
                      padding: 6.3px 8.5px;
                      margin-right: 9px;
                      font-weight: 400;
                      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
                  }

                  h4 {
                      font-size: 25px;
                      font-weight: 400;
                  }

                  p {
                      color: rgba(67, 91, 117, 0.6);
                      font-weight: 400;
                      font-size: 14px;
                  }
              }

              .progressWrapper {
                  padding-top: 19px;

                  .progressList {
                      margin-bottom: 13px;
                  }

                  h4 {
                      color: #435b75;
                      font-weight: 400;
                      font-size: 14px;
                  }

                  h3 {
                      color: #00b7a6;
                      font-weight: 400;
                      font-size: 17px;
                  }
              }

              .typesofTravelers {
                  padding-top: 21px;
                  padding-right: 30px;

                  .progressListRow {
                      display: flex;
                      flex-wrap: wrap;
                  }

                  .heading {
                      padding-bottom: 11px;

                      h3 {
                          font-size: 14px;
                          color: #435b75;
                          font-weight: 400;
                      }
                  }

                  .progressList {
                      max-width: 50%;
                      flex: 0 0 50%;
                      text-align: center;
                      padding-bottom: 16px;

                      .image {
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-45%, -45%);
                      }

                      h4 {
                          color: #435b75;
                          font-weight: 400;
                          font-size: 14px;
                          margin-top: 5px;

                          @media(min-width:768px) and (max-width:991px) {
                              font-size: 12px;
                          }
                      }

                      .percent1 {
                          width: 80px;
                          height: 80px;
                          position: relative;
                          margin: 0 auto;

                          svg {
                              height: inherit;
                          }
                      }

                      circle {
                          position: absolute;
                          fill: none;
                          stroke-width: 4;
                          transform: translate(10px, 10px);
                          stroke-dasharray: 440;
                          stroke-linecap: round;

                          &:nth-child(1) {
                              stroke-dashoffset: 0;
                              stroke: #c1cbd6;
                          }

                          &:nth-child(2) {
                              stroke-dashoffset: calc(440 - (440 * 15) / 100);
                              stroke: #00b7a6;
                              animation: percent 1.5s linear;
                              animation-delay: 1s;
                          }

                          span {
                              color: #00b7a6;
                          }
                      }

                      .number {
                          position: relative;
                          bottom: 190px;
                          right: -50px;
                          color: #fff;
                      }

                      @keyframes percent {
                          0% {
                              stroke-dashoffset: 0;
                              stroke-width: 0;
                          }
                      }
                  }
              }
          }

          .rightContent {
              padding-right: 0;

              .reviewTabs {
                  flex-wrap: wrap;
                  margin: 0 -3px 22px;

                  li {
                      padding: 0 3px;

                      button {
                          background-color: #eff0f5;
                          border-radius: 8px;
                          font-size: 14px;
                          color: #1d6ec9;
                          font-weight: 400;
                          border: 1px solid #dae2ee;
                          padding: 3.5px 12px;
                          box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
                          display: block;
                          text-align: center;
                          min-width: 120px;
                          text-transform: capitalize;
                      }

                      &:first-child,
                      &:last-child {
                          button {
                              min-width: 100px;
                          }
                      }

                      &.active {
                          button {
                              border-radius: 8px;
                              box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
                              border: solid 1px #dae2ee;
                              background-color: #eff0f5;
                          }
                      }
                  }
              }

              .card2 {
                  padding: 10px 10px 14px;
                  margin-bottom: 10px;
              }

              .ratingComment {
                  font-size: 17px;
                  margin-bottom: 10px;
              }

              .commentCount {
                  font-size: 14px;
                  font-weight: 500;
                  padding: 3px 2px;
              }

              .heading {
                  h4 {
                      color: rgba(67, 91, 117, 0.6);
                      font-weight: 400;
                      font-size: 14px;
                  }
              }

              h3 {
                  font-size: 17px;
                  color: #435b75;
                  font-weight: 500;
                  margin-bottom: 6px;
              }

              p {
                  font-size: 14px;
                  color: #435b75;
                  font-weight: 400;
                  margin-bottom: 8px;
                  line-height: normal;
              }

              h5 {
                  font-size: 14px;
                  color: #435b75;
                  font-weight: 500;
                  display: flex;
                  align-items: center;

                  img {
                      margin-right: 8px;
                  }
              }

              .show-more-reviews-popup {
                  font-size: 14px;
                  font-weight: 400;
                  color: #1d6ec9;
                  border: none;
                  background-color: transparent;
              }
          }

          .showMoreLink {

              a,
              button {
                  font-size: 14px;
                  font-weight: 400;
                  color: #1d6ec9;
                  background-color: transparent;
                  border: none;
              }
          }
      }

      .review-popup {
          position: fixed;
          height: 100%;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 999;
          -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
          background-color: rgba(67, 91, 117, 0.5);
          align-items: center;
          justify-content: center;
          flex-direction: column;
          display: none;

          &.review-popup-open {
              display: block;
          }

          .popup-content-wrap {
              max-width: 1000px;
              margin: 0 auto;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
          }

          .popup-content {
              position: relative;
              border-radius: 10px;
              box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
              border: solid 1px #cfd1e1;
              background-color: #eff0f5;
              max-height: 64vh;
              overflow: hidden;
              min-height: 300px;
              overflow-y: auto;
              max-width: 980px;
          }

          .close {
              margin-top: -4px;
              padding: 4px;
              font-weight: 400;
              font-size: 18px;
              position: absolute;
              top: 10px;
              right: 8px;
              line-height: 1;
              min-width: auto;
              box-shadow: none;
              background: none;
              border-radius: 0;

              &:before {
                  display: none;
              }
          }

          .rewievsWrapper {
              .leftContent {
                  max-width: 32.05%;
                  flex: 0 0 32.05%;
              }

              .card2 {
                  padding: 12px 9px 11px 13px;
                  border-radius: 8px;
                  box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #fff;
                  border: solid 1px #dae2ee;
                  background-color: rgba(255, 255, 255, 0.35);
              }
          }
      }

      .new-card-section {
          display: none;

          &.activeTab {
              display: block;
          }

          .new-card-box {
              margin-bottom: 16px;
          }

          .pricedetail-dropdown {
              padding: 0;
              width: 250px;

              .resort-fee {
                  margin-bottom: 0;
              }

              .note {
                  border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                  max-width: 100%;
                  padding: 8px 15px;

                  p {
                      max-width: 147px;
                      font-size: 12px;
                      font-family: "Ubuntu", sans-serif;
                      font-weight: 400;
                      line-height: normal;
                      letter-spacing: normal;
                      color: #00b7a6;
                  }
              }

              .dropdown-content {
                  padding: 0;
                  margin: 0;
                  border: none;

                  .resortFeessection {
                      background-color: #d4d6e2;
                      border-radius: 0 0 8px 8px;
                      padding: 10px;
                      margin: 20px 0 0;

                      h3 {
                          margin-top: 0;

                      }

                      .circle {
                          padding: 3px;
                          border: 1px solid #435b75;
                          border-radius: 100px;
                          line-height: 0;
                          height: 22px;
                          width: 22px;
                          display: inline-flex;
                          align-items: center;
                          justify-content: center;
                      }

                      img {
                          width: 12px;
                      }

                      h3 {
                          font-size: 12px;
                          margin-bottom: 5px;
                          display: flex;
                          align-items: center;

                          strong {
                              margin-left: 5px;
                          }
                      }

                      p {
                          font-size: 10px;
                      }
                  }
              }

              .total-text {
                  font-family: "Ubuntu", sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #435b75;
              }

              .dropdown-info {
                  padding: 0 15px;

                  h3 {
                      font-size: 14px;
                  }

                  h4 {
                      color: rgba(67, 91, 117, 0.6) !important;

                      span {
                          min-width: 100px;
                      }
                  }
              }

              .not-included-site {
                  font-size: 9px;
                  margin-top: 2px;
                  font-weight: 600;
                  color: rgba(67, 91, 117, 0.8);
              }
          }

          .leftContent {
              max-width: 100%;
              flex: 0 0 100%;
              padding: 10px 10px 5px;

              .smoking-status {
                  border: 1px solid rgba(67, 91, 117, 0.2);
                  margin-left: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 5px;
                  border-radius: 2px;
                  height: 35px;
                  width: 35px;
                  max-width: 35px;
                  flex: 0 0 35px;
                  background-color: #ffffff;
                  //   min-width: 140px;

                  span {
                      color: #435b75;
                      font-size: 14px;
                      font-weight: 500;
                  }
              }

              .heading-group {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  @media (max-width:767px) {
                      padding: 5px 8px 0;
                  }
              }

              .room-types {
                  h5 {
                      margin-left: 10px;
                      margin-right: 10px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      padding: 9px 12px;
                      border-radius: 10px;
                      min-width: 140px;
                      font-size: 14px;
                  }

                  .btn-non-refundable {
                      border: 1px solid red;
                      color: red;
                  }

                  .btn-refundable {
                      border: 1px solid #00b7a6;
                      color: #00b7a6;
                  }
              }

              .heading-group2 {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  max-width: 30%;
                  flex: 0 0 30%;

                  @media(max-width:991px) and (min-width:768px) {
                      flex-direction: column;
                      max-width: 26%;
                      flex: 0 0 26%;

                      .availableRoomsWrap {
                          margin-bottom: 8px;
                      }
                  }

                  .availableRoomsWrap {
                      margin-right: 10px;
                  }
              }

              .room-status-type {
                  display: flex;
                  margin-bottom: auto;
              }

              .heading-wrap {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 12px;

                  h3 {
                      margin-top: 0;
                      margin-bottom: 2px;
                  }

                  .availableRooms {
                      background-color: rgba(67, 91, 117, 0.6);
                      border-radius: 6px;
                      font-size: 14px;
                      color: #ffffff;
                      padding: 5px 4px;
                      min-width: 130px;
                      text-align: center;
                      font-weight: 400;
                      line-height: 1;
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      margin-bottom: 0;
                  }
              }

              .savings-heading {
                  p {
                      font-size: 14px;
                      background-color: #00b7a6;
                      border-radius: 6px;
                      text-align: center;
                      color: $white;
                      padding: 5px 4px;
                      max-width: 110px;
                      min-width: 110px;
                      line-height: 1;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                  }
              }

              .imageContent {
                  .image {
                      max-width: 28%;
                      flex: 0 0 28%;

                      @media (max-width:767px) {
                          margin-bottom: 12px;
                      }

                      .customShadow {
                          position: relative;
                          height: 207px;

                          .room-details-show-popup {
                              position: absolute;
                              bottom: 15px;
                              left: 15px;
                              right: 0;
                              text-align: center;
                              background: #fff;
                              display: inline-block;
                              max-width: 98px;
                              padding: 6px;
                              border-radius: 6px;
                          }

                          img {
                              width: 100%;
                              max-height: 210px;
                              overflow: hidden;
                              object-fit: cover;
                              border-radius: 6px;
                              height: 100%;
                          }
                      }
                  }

                  .content {
                      padding: 0 20px;
                      max-width: 30%;
                      flex: 0 0 30%;

                      h3 {
                          margin: 0;
                      }

                      .heading {
                          display: flex;
                          align-items: center;
                          padding-bottom: 10px;
                      }
                  }
              }

              .content-wrap {
                  .customRadio {
                      margin-bottom: 12px;
                      display: block;

                      >span {
                          margin-top: 0;
                      }
                  }

                  .room-left {
                      margin-left: 10px;
                      font-size: 12px;
                  }

                  .disabled {
                      opacity: 0.5;
                      cursor: not-allowed;
                  }

                  .refundable-box {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      &.non-refundable-ui .checkStyle {
                          color: red;
                      }

                      &.refundable-ui .checkStyle {
                          color: #00b7a6;
                      }
                  }

                  .extra-data-wrap {
                      .refundable-box {
                          border: 1px solid #D1DAE6;
                          border-radius: 4px;
                          padding: 10.5px 16px;
                      }

                      .checked {
                          border-color: #1d6ec9;
                          color: #1d6ec9;
                      }
                  }

                  .new-extra-wrap {
                      border-top: 1px solid rgba(67, 91, 117, 0.2);
                      padding-top: 15px;
                  }

                  .content-title {
                      color: #435B75 !important;
                  }
              }

              .refundable-title {
                  padding-left: 16px;
              }

              .hotelFacilities {
                  margin: 10px -5px 0;

                  .sq-ft-wrap {
                      display: flex;
                      flex-wrap: wrap;
                  }

                  .sq-ft-content {
                      //   font-weight: 600;
                  }

                  img {
                      margin-right: 6px;
                  }

                  h4 {
                      color: #435b75;
                      font-weight: 400;
                      font-size: 14px;
                      display: flex;
                      align-items: center;
                      margin-bottom: 9px;
                      // max-width: 50%;
                      // flex: 0 0 50%;
                      padding: 0 8px;
                  }
              }

              .toggle-link {
                  display: flex;
                  flex-wrap: wrap;
                  margin: 0 0 10px;
                  padding: 10px 0 5px;
                  border-bottom: 1px solid rgba(67, 91, 117, 0.2);

                  .smoking-type {
                      display: flex;
                      align-items: center;
                      padding: 0 12px;
                      color: rgba(67, 91, 117, 0.6);
                      position: relative;
                      max-width: 50%;
                      flex: 0 0 50%;
                      justify-content: center;

                      &.active {
                          color: #1d6ec9;

                          &:after {
                              content: '';
                              display: block;
                              background-color: #1d6ec9;
                              height: 1px;
                              width: 100%;
                              position: absolute;
                              bottom: -5px;
                              left: 0;
                          }
                      }
                  }
              }

              .extras {
                  @media (max-width: 767px) {
                      display: flex;
                      flex-wrap: wrap;
                      padding: 5px 5px 10px 12px;

                      &.activeTab {
                          display: flex;
                      }

                      .extra-title {
                          max-width: 100%;
                          flex: 0 0 100%;
                          margin: 4px 0;
                      }

                      .custom-radio label {
                          display: flex;
                          align-items: center;

                          input {
                              margin-right: 3px;
                          }
                      }

                      .row {
                          flex-direction: column;
                          margin: 0 0 15px;
                          max-width: 50%;
                          flex: 0 0 50%;
                          justify-content: center;
                          text-align: center;
                      }

                      .extras-left-ontent,
                      .extras-right-ontent {
                          max-width: 158px;
                          margin: 0 auto;
                          flex: 0 0 auto;
                          text-align: left;
                          width: 100%;
                          padding-left: 20px;
                      }
                  }

                  .extra-title {
                      margin-bottom: 10px;

                      h4 {
                          max-width: 100%;
                          font-weight: 500;
                      }
                  }

                  &.activeTab {
                      display: block;
                  }

                  .row {
                      margin-bottom: 16px;
                  }

                  h6 {
                      color: #435b75;
                      font-weight: 500;
                      font-size: 14px;
                  }
              }

          }

          .rightContent {
              .card2 {
                  padding: 0;
              }

              .infoBox {
                  max-width: 100%;
                  flex: 0 0 100%;

                  .price-type {
                      margin: 25px 0 15px;
                      display: flex;

                      .custom-radio {
                          min-width: 100px;
                      }
                  }

                  .buttonsGroup {
                      margin: 10px 0;
                  }

                  .row {
                      flex-direction: column;
                  }

                  .price-container {
                      display: none;

                      &.activeTab {
                          display: block;
                      }
                  }
              }

              .showMoreLinks {
                  text-align: center
              }
          }

          .price-box-col {
              .infoBox {
                  margin-top: 0;
                  padding: 0;
                  justify-content: flex-start;
                  min-height: 158px;
                  position: relative;

                  .custom-loader {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      z-index: 1;
                      transform: translate(-50%, -50%);
                      height: 100%;
                      width: 100%;
                      background-color: rgba(255, 255, 255, 0.8);
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      svg {
                          color: #1d6ec9;
                          height: inherit;
                          width: inherit;

                      }

                      .ant-spin-dot {
                          height: 28px;
                          width: 28px;
                          font-size: 28px;
                      }
                  }

                  .buttonsGroup {
                      padding-bottom: 10px;

                      .centerAlign {
                          margin: 5px 0 8px;
                      }

                      .upgradeBtn {
                          text-align: right;

                          @media (max-width:767px) {
                              display: flex;
                              align-items: center;
                          }

                          .btn {
                              margin-right: 8px;
                              min-width: 120px;
                          }

                          .btn-cancel {
                              margin-right: 5px;
                          }
                      }

                      .cancellation-policy {
                          margin-bottom: 5px;

                          +.cancellation-policy {
                              margin-top: 5px;
                          }

                          a {
                              font-size: 14px;
                              font-weight: 400;
                              color: #1d6ec9;
                          }
                      }
                  }

                  .fromPriceWrap {
                      p {
                          margin-top: 3px;
                      }
                  }

                  .price-container {
                      display: none;

                      &.activeTab {
                          display: block;
                      }
                  }

                  .title {
                      padding: 10px 15px 0;
                      border-color: rgba(67, 91, 117, 0.2);

                      button {
                          padding: 0 20px 8px;
                          cursor: pointer;

                          @media (max-width: 1040px) {
                              padding: 0 12px 8px;
                          }
                      }
                  }

                  .btn {
                      margin: 12px 0;
                      min-width: 160px;
                      padding: 9.5px;
                      border-radius: 10px;
                      color: #fff;
                  }

                  .btnBorder2 {
                      border-radius: 10px;
                      padding: 6.5px 7px;
                      margin-bottom: 12px;
                      color: #00c5c7;
                  }

                  .availableRoomsWrap {
                      border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                      margin-bottom: 15px;
                      padding-bottom: 5px;
                      padding-top: 9px;
                      text-align: center;
                  }

                  .availableRooms {
                      background-color: rgba(67, 91, 117, 0.6);
                      border-radius: 6px;
                      font-size: 12px;
                      color: $white;
                      padding: 4px;
                      max-width: 108px;
                      text-align: center;
                      font-weight: 400;
                      margin: 0 auto;
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 1;
                  }

                  .infoBoxWrapper {
                      padding: 0 12px;

                      .buttonSpace {
                          padding-top: 14px;

                          .btn {
                              min-width: 136px;
                              padding: 12px;
                          }
                      }
                  }

                  .publicPrice {
                      margin-top: 8px;

                      span {
                          font-size: 14px;
                      }
                  }

                  .fromPrice {
                      margin: 10px 0;
                      align-items: flex-start;

                      &.singlePrice {
                          //   align-items: center;
                      }

                      span {
                          font-size: 17px;
                          font-weight: 500;
                      }
                  }

                  .priceDetails {
                      margin-bottom: 4px;
                      color: #1d6ec9;
                      position: relative;
                      display: inline-flex;
                      cursor: pointer;
                      display: flex;
                      align-items: flex-start;

                      &:hover,
                      &:focus {
                          .pricedetail-dropdown {
                              display: block;
                          }
                      }

                      h4 {
                          font-size: 12px;
                          font-weight: 400;
                          color: #1d6ec9;
                      }

                      .saving-box {
                          h4 {
                              color: #00b7a6 !important;
                          }
                      }
                  }

                  p {
                      font-size: 11px;
                  }

                  .savings {
                      margin: 25px 0 10px;
                      min-height: 11px;

                      p {
                          font-size: 14px;
                          color: rgba(255, 255, 255, 0.8);
                      }
                  }
              }
          }

          .sold-out-wrap {
              .infoBox {
                  min-height: 200px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  h2 {
                      background: rgba(255, 86, 48, 0.05);
                      border: 1px dashed #FF5630;
                      border-radius: 4px;
                      width: 130px;
                      height: 46px;
                      margin: 0 auto 12px;
                      color: #FF5630;
                      font-weight: 500;
                      font-size: 19px;
                      line-height: 22px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                  }

                  h4 {
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 19px;
                      color: #091E42;
                  }

                  p {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 16px;
                      max-width: 236px;
                      margin: 0 auto;
                      text-align: center;
                  }
              }
          }
      }

      .btn-see-member-price {
          font-size: 15px !important;

          @media (max-width:767px) {
              font-size: 13px !important;
          }
      }

      .saving-starts-from-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: calc(100% - 161px);
          text-align: center;

          @media (max-width:991px) {
              height: calc(100% - 110px);
          }

          @media (max-width:767px) {
              height: auto;
          }

          .saving-starts-from {
              max-width: 100%;
              flex: 0 0 100%;
              width: 100%;
              padding: 18px 12px;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
          }

          .icon {
              padding-top: 12px;
              padding-bottom: 5px;

              svg {
                  height: 50px;
                  width: 50px;
              }

              .blue-color {
                  color: #1d6ec9;
              }
          }

          h4 {
              color: #36B37E;
              font-size: 36px;
              line-height: 41px;
              font-weight: 700;
              margin-bottom: 4px;
          }

          h3 {
              font-size: 14px;
              line-height: 17px;
              font-weight: 500;
              color: #435B75;
              margin-bottom: 4px;
          }

          .btn-select-room {
              min-width: 150px;
              padding: 9px;
              font-size: 14px;
              border-radius: 10px;
              width: 100%;
              min-height: 42px;

              &:before {
                  display: none;
              }
          }
      }
  }

  .hotel-detail-sort-by {
      border-radius: 10px;
      padding: 5px;
      z-index: 999;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #d2d9e7;
      background-color: #e6e7ee;

      .ant-select-item {
          font-size: 15px;
          border-radius: 6px;
          line-height: 17px;
          color: #435b75;
          cursor: pointer;
          font-weight: inherit !important;
          background-color: transparent !important;
          padding: 5px 8px;

          &:hover {
              box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff !important;
          }
      }

      .ant-select-item-option-active {
          font-weight: inherit !important;
          background-color: transparent !important;
      }
  }

  .green {
      color: #00b7a6 !important;
  }

  .red {
      color: red !important;
  }

  .flight-penalty-info {
      h3 {
          font-size: 12px;
          line-height: 12px;
          font-weight: 500;
          text-transform: uppercase;
          color: rgba(80, 95, 121, 1);
          letter-spacing: 1px;
          margin-bottom: 20px;
      }

      .a {
          margin-bottom: 20px !important;
      }

      .table-wrap {
          +.table-wrap {
              margin-top: 25px;
              margin-bottom: 25px !important;
          }

          @media (max-width:767px) {
              .table-content {
                  display: block;
                  width: 100%;
                  overflow-x: auto;
                  -webkit-overflow-scrolling: touch;
                  -ms-overflow-style: -ms-autohiding-scrollbar;

                  table {
                      border-collapse: collapse;

                      th,
                      td {
                          padding: 8px;
                          font-size: 12px;
                          line-height: 14px;
                          min-width: 100px;
                      }
                  }
              }
          }
      }

      table {
          width: 100%;
          border: 1px solid #AACCDD;
          border-radius: 4px;

          th {
              font-size: 12px;
              line-height: 14px;
              font-weight: 400;
              color: #74879D;
              text-align: center;
              padding: 13px 16px;
              border-bottom: 1px solid #AACCDD;
              border-right: 1px solid #AACCDD;
          }

          td {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #435b75;
              text-align: left;
              padding: 13px 16px;
              border-bottom: 1px solid #AACCDD;
              border-right: 1px solid #AACCDD;
          }
      }
  }

  // Responsive start
  @media(min-width:992px) and (max-width:1124px) {
      .detailPageWrap .new-card-section .price-box-col {
          .infoBox {
              .info.col {
                  max-width: 55%;
                  flex: 0 0 55%;
              }
          }
      }
  }

  @media(min-width:768px) and (max-width:1124px) {
      .detailPageWrap .new-card-section .price-box-col {
          .infoBox {
              .buttonsGroup {
                  //   height: 160px;

                  .btn {
                      max-width: 160px;
                      margin: 12px auto !important;
                  }

                  .upgradeBtn {
                      display: flex;
                      flex-direction: column;

                      .btn-cancel {
                          height: 51px;
                      }
                  }
              }
          }
      }
  }

  @media (max-width: 1024px) {

      .listingPageWrap.listingHotel,
      .listingPage.detailPageWrap {
          .mainContent {
              .filterCnt {
                  .dateAndBtnWrap {
                      .Date-picker-range {
                          .DateRangePicker {
                              .DateRangePickerInput {
                                  width: 250px;
                                  min-width: 250px;

                                  .DateRangePickerInput_arrow {
                                      width: 90px;
                                      padding-top: 0px;
                                      margin: 0 auto;

                                      .icon-arrow {
                                          margin-left: 2px;
                                          margin-right: 10px;
                                      }

                                      .endDate {
                                          margin-right: 5px;
                                      }
                                  }

                                  .DateInput #check_out {
                                      margin-right: 0px;
                                  }
                              }
                          }
                      }

                      .dropdownWrap {
                          width: 250px;
                          min-width: 250px;
                      }
                  }
              }
          }
      }

      .detailPageWrap {
          .bannerSlider {
              .content {
                  .infoBox {
                      .no-gutters {
                          display: flex;
                          flex-direction: column;
                      }



                      .btn {
                          border-radius: 22.5px;
                          min-width: 116px;
                          padding: 8px;
                      }
                  }
              }
          }
      }
  }

  @media (min-width:1090px) {
      .detailPageWrap {
          .infoBox {
              .price-box-col {
                  .infoBox {
                      .no-gutters {
                          display: flex;
                          flex-direction: column;
                      }
                  }
              }
          }
      }


  }

  @media (max-width:991px) {
      .listingPage.detailPageWrap {
          .infoBox {
              .title {
                  button {
                      padding: 0 15px 5px;

                  }
              }
          }

          .mainContent {
              .pageInternalScroll>div {
                  position: relative !important;
              }

              .filterCnt .filterCnt2 {
                  .mobileShow {
                      display: block;
                  }

                  .mobileHide {
                      display: flex;
                      padding: 0px 0px;

                  }


                  .dateAndBtnWrap {
                      display: flex;
                      flex-direction: row;
                      margin-top: 0px;

                      .mobileBtnWrap {
                          align-items: flex-start;

                          .btnBorder {
                              min-width: 70px;
                              margin: 0px;
                          }

                          .closeFilter {
                              display: none;
                          }
                      }


                      .dropdownWrap {
                          margin-left: 10px;
                          width: auto !important;
                          min-width: auto !important;
                      }
                  }
              }

              .filterCnt {

                  .dateAndBtnWrap {
                      .Date-picker-range {
                          .DateRangePicker {
                              .DateRangePickerInput {
                                  width: 100%;
                                  padding: 4px 3px;
                                  min-width: 100%;

                                  .DateInput {
                                      width: 73px !important;
                                      min-width: 73px !important;
                                  }

                                  .DateRangePickerInput_arrow {
                                      margin-left: 0px;
                                      padding-left: 0px;

                                      .icon-arrow {
                                          margin-right: 10px !important;
                                          margin-left: 0px !important;
                                          padding-left: 0px;
                                      }

                                  }
                              }

                          }
                      }
                  }
              }

          }

          .propertyDetails {
              .propertyDetailsTab {
                  .content {
                      flex-direction: column;
                  }
              }
          }

          .bannerSlider {
              .content {
                  .infoBox {

                      min-height: 225px;
                  }
              }
          }

          .showPopup.mobileSortFilter {
              .sortFilter {
                  .filterDropdownWrap {
                      +.filterDropdownWrap {
                          margin-bottom: 25px;
                      }

                      .star-rating {
                          display: flex !important;
                          max-width: 130px;
                      }

                      .flterOption {
                          width: 100%;
                      }

                      .priceRange,
                      .priceRange+.cardBody {
                          display: flex !important;
                      }

                      .priceRange,
                      .cardBody {
                          justify-content: space-between;

                          h4 {
                              margin-bottom: 10px;
                              margin-right: 10px;
                              font-weight: normal;
                              color: var(--text-color);
                              font-size: 17px;
                          }
                      }

                      .customCheckbox span {
                          font-size: 17px;
                          padding-left: 32px;
                      }
                  }

                  .showDropdown .rbt .rbt-input {
                      width: 100%;
                      padding-left: 15px !important;

                      @include placeholder-text {
                          color: #1d6ec9;
                          opacity: 1 !important;
                      }
                  }

                  .rbt .rbt-input-hint {
                      z-index: -1;
                  }
              }
          }
      }


      .listingPage.detailPageWrap {

          .mainContent {

              .filterCnt {
                  position: sticky;
                  top: 45px;
                  left: 0;
                  right: 0;
                  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                  background-color: rgb(77, 193, 255);
                  min-height: auto;
                  padding: 9.6px;
                  margin-bottom: 9px;
                  z-index: 9;

                  .mobileShow {
                      display: none;
                      color: #ffff;

                  }

                  .locationStartEndWrap {
                      position: relative;
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      margin-right: 10px;
                  }

                  .responsive-input {
                      width: 100%;
                      margin-bottom: 0px;
                      flex-grow: 1;
                      position: relative;
                      margin-top: 10px;

                      .inputStyle {
                          border-radius: 10px;
                          box-shadow: none;
                          border: none;
                          border: 1px solid rgba(255, 255, 255, 0.1);
                          background: rgba(255, 255, 255, 0.4);
                          box-sizing: border-box;
                          padding: 0px 15px 0px 28px;
                          min-width: 100%;
                          max-height: 0px;
                          min-height: 35px;
                          text-align: left;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;

                      }

                      .inputDropdown {
                          margin: 0px;
                          padding: 0px 15px 0px 25px;
                      }
                  }

                  .dateAndBtnWrap {
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      flex: 100%;
                      margin-top: 10px;

                      .date-picker-type {
                          width: 100%;

                          .date-picker-style {
                              box-shadow: none;
                              background: rgba(255, 255, 255, 0.4);
                              border: 0;
                              border-radius: 10px;
                              padding: 0px 14px 0px 8px;
                              box-sizing: border-box;
                              width: 100%;
                              display: flex;
                              min-height: 35px;
                              justify-content: space-between;
                              border: 1px solid rgba(255, 255, 255, 0.1);
                              margin-bottom: 10px;

                              .icon-invitation {
                                  font-size: 14px;
                                  margin-right: 0px;
                                  display: flex;
                                  align-items: center;

                              }

                              button {
                                  margin: 0px;
                                  padding-left: 8px;
                                  font-size: 14px;
                                  background: none;
                                  text-align: left;
                                  border: none;
                              }
                          }
                      }

                      .dropdownWrap {
                          margin-top: 0px;
                          width: auto;
                      }

                      .dropdownInWrap {
                          width: 100%;
                          z-index: 2;

                          .dropDownSubWrap {
                              .add-room-scroll {
                                  max-height: 250px !important;
                              }
                          }
                      }

                      .Date-picker-range .DateRangePicker {
                          width: 220px;
                          margin-bottom: 0px;

                          .DateRangePickerInput {
                              width: auto;
                              justify-content: flex-start;
                              margin-left: 0px;

                              .DateRangePickerInput_arrow {
                                  margin: 0 0;
                                  width: auto;
                                  margin-left: 4px;

                                  .icon-arrow {
                                      margin-right: 5px !important;
                                  }
                              }

                              #check_out {
                                  margin-right: 0px;
                              }
                          }

                      }
                  }

                  .dropdownWrap {
                      width: auto;
                      margin-left: 0px;
                      margin-bottom: 0px;

                      .btnSml {
                          width: 220px;
                          margin-right: 10px;
                      }
                  }

                  .inputStyle .dropdown-content {
                      margin-right: 0px;
                      padding: 0px 0px;

                      .rbt>div {
                          width: auto;
                          max-width: 100%;
                      }
                  }

                  .inputStyle {
                      input {
                          padding-left: 22px !important;
                      }
                  }

                  .mobileSearchBlock {
                      color: #fff;
                      display: none;
                      align-items: center;
                      padding-bottom: 5px;

                      .fa-search {
                          margin-right: 16px;
                      }

                      .selectSearchCnt {
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                          white-space: nowrap;
                          height: 34px;
                          padding-top: 3px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          max-width: calc(100% - 35px);

                          h4 {
                              font-size: 14px;
                              font-weight: normal;
                              margin-right: 10px;

                          }

                          p {
                              font-size: 12px;

                              .fa-circle {
                                  font-size: 4px;
                                  vertical-align: middle;
                                  opacity: 0.6;
                                  margin: 0 2px;
                              }
                          }
                      }

                      .fa-angle-down {
                          margin-left: auto;
                          cursor: pointer;
                      }
                  }



                  .dateAndBtnWrap {
                      display: flex;
                      flex-direction: column;
                  }


                  .pageInternalScroll>div {
                      margin-right: -9px;
                      margin-left: -9px;
                  }

              }

              .hide-content {
                  z-index: 0;

              }

              .leftSideBar {
                  display: none;
              }
          }

          .mobileShow {
              display: block;
          }

          .mobileSortFilter.showPopup {
              display: block;
              padding: 20px 15px 80px;
              z-index: 999;

              .clearFilter {
                  display: none;
              }

              .mobilePopTitle {
                  padding-bottom: 12px;
              }

              .filterTitle {
                  padding-bottom: 13px;
                  order: 2;
              }

              .closePopup {
                  top: 0px;
              }

              .sortFilter {
                  display: block;
                  align-items: flex-start;
                  padding-top: 8px;
                  flex-direction: column;

                  .filterDropdownWrap {
                      width: 100%;
                      position: relative;
                      flex-grow: 1;
                      max-width: 100%;
                      min-width: 100%;

                      +.filterDropdownWrap {
                          margin-left: 0px;
                      }

                      &.sortByOption {
                          max-width: 100%;
                          width: 100%;
                          margin-left: 0px;
                          margin-bottom: 25px;

                          .content {
                              padding: 0px 0px;
                          }

                          .filterDropdown {
                              top: 63px;
                              left: auto;
                              right: 0;
                              padding: 0;
                              width: 100%;
                              min-width: 100%;
                          }

                          .flterOption {
                              padding: 0px 0px;
                              font-size: 17px;
                              box-shadow: -2px -2px 4px 0 #ffffff, 2px 2px 3px 0 rgb(0 0 0 / 15%);
                              border: solid 1px #cfd1e1;
                              background-image: linear-gradient(to right, #ececf3, #e6e7ee);

                              >div {
                                  width: 100%;
                              }

                              .css-1s2u09g-control,
                              .css-1pahdxg-control {
                                  background-color: transparent;
                                  background: none;
                                  border: none;
                                  width: 100%;
                                  box-shadow: none;
                                  border: none;
                                  padding: 9px 10px;

                                  .css-qc6sy-singleValue {
                                      color: #1d6ec9;
                                      font-size: 17px;
                                  }

                                  .css-1hb7zxy-IndicatorsContainer {
                                      display: none;
                                  }

                                  .css-319lph-ValueContainer {
                                      padding: 0px 3px;
                                  }
                              }

                              .css-26l3qy-menu {
                                  border-radius: 10px;
                                  box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                                  background-color: #eff0f5;
                                  padding: 5px;
                                  display: block;
                                  margin-top: 15px;
                                  height: auto;
                                  min-height: 250px;
                                  box-sizing: border-box;
                                  z-index: 999;

                                  [class*=MenuList] div {
                                      font-weight: 400;
                                      display: block;
                                      color: #435b75;
                                      padding: 10px 9px;
                                      border: solid 1px transparent;
                                      border-radius: 10px;
                                      white-space: nowrap;
                                      margin-bottom: 2px;
                                  }


                                  .css-9gakcf-option,
                                  .css-1n7v3ny-option {
                                      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                                      border-color: #d2dae6;
                                      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                                  }
                              }

                              [class*=control] {
                                  background-color: transparent;
                                  background: none;
                                  border: none;
                                  width: 100%;
                                  box-shadow: none;
                                  border: none;
                                  padding: 9px 10px;
                                  padding-right: 30px;
                                  cursor: pointer;

                                  >div {
                                      padding: 0px;
                                      //display: none;
                                  }

                                  &:after {
                                      content: "\f107";
                                      display: inline-block;
                                      font: normal bold normal 18px/1 FontAwesome;
                                      -webkit-font-smoothing: antialiased;
                                      color: #1d6ec9;
                                      transform: rotate(0deg);
                                      font: normal normal normal 14px/1 FontAwesome;
                                      font-size: inherit;
                                      text-rendering: auto;
                                      right: 10px;
                                      top: 50%;
                                      position: absolute;
                                      transform: translateY(-50%);
                                  }

                                  [class*=singleValue] {
                                      color: #1d6ec9;
                                      font-size: 17px;
                                      font-weight: 400;
                                  }

                                  [class*=ValueContainer] {
                                      padding: 0px 3px;
                                      display: flex;
                                  }

                                  [class*=Input] {
                                      margin: 0px;
                                  }
                              }
                          }
                      }

                      &.rightAuto {
                          margin-right: auto;
                      }

                      .btnsCnt {
                          .borderBtn {
                              border-radius: 15px;
                              box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1),
                                  -1px -1px 4px 1px rgba(255, 255, 255, 0.25);
                              background-color: #1d6ec9;
                              font-size: 14px;
                              font-weight: normal;
                              color: $white;
                          }
                      }
                  }

                  .whiteLabel {
                      display: none;
                  }

                  .whiteLabel {
                      display: none;
                  }

                  .flterOption {
                      border-radius: 10px;
                      font-size: 17px;
                      box-shadow: none;
                      background-color: transparent;
                      font-weight: 500;
                      color: var(--text-color);
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding: 0px 0px 10px 0px;
                      margin-bottom: 0px;
                      cursor: pointer;
                      white-space: nowrap;

                      .col {
                          padding: 0px 0px;
                      }
                  }

                  .filterDropdown {
                      border-radius: 10px;
                      box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.25);
                      background-color: #eff0f5;
                      padding: 5px 15px 20px;
                      box-sizing: border-box;
                      position: absolute;
                      top: 50px;
                      min-width: 200px;
                      display: none;
                      z-index: 2;

                      .multiCol3 {
                          @media (min-width: 768px) {

                              .customCheckbox {
                                  width: 33.33%;
                                  float: left;
                                  margin-top: 0;
                              }

                              .customCheckbox {
                                  width: 33.33%;
                                  float: left;
                                  margin-top: 10px;
                                  line-height: 7px;

                                  >span:before {
                                      top: -6px;
                                  }
                              }
                          }
                      }

                      .multiColBtn {
                          clear: both;
                          text-align: right;
                          display: block;

                          .blueBtn {
                              margin-right: 5px;
                          }
                      }

                      &.priceFilter {
                          width: 280px;
                          padding-top: 17px;
                      }

                      ul {
                          list-style: none;
                          padding: 6px;

                          li {
                              margin-bottom: 2px;
                          }

                          a {
                              display: block;
                              color: var(--text-color3);
                              padding: 10px 9px;
                              border: solid 1px transparent;
                              border-radius: 6px;
                              white-space: nowrap;

                              &:hover {
                                  box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                                      inset -2px -2px 6px 0 #ffffff;
                                  border-color: #d2dae6;
                                  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                              }
                          }
                      }
                  }

                  .filterDropdown {
                      border-radius: 10px;
                      box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.25);
                      background-color: #eff0f5;
                      padding: 5px 15px 20px;
                      box-sizing: border-box;
                      position: absolute;
                      top: 50px;
                      min-width: 200px;
                      display: none;
                      z-index: 2;

                      .multiCol3 {
                          @media (min-width: 768px) {

                              .customCheckbox {
                                  width: 33.33%;
                                  float: left;
                                  margin-top: 0;
                              }

                              .customCheckbox {
                                  width: 33.33%;
                                  float: left;
                                  margin-top: 10px;
                                  line-height: 7px;

                                  >span:before {
                                      top: -6px;
                                  }
                              }
                          }
                      }

                      .multiColBtn {
                          clear: both;
                          text-align: right;
                          display: block;

                          .blueBtn {
                              margin-right: 5px;
                          }
                      }

                      &.priceFilter {
                          width: 280px;
                          padding-top: 17px;
                      }

                      ul {
                          list-style: none;
                          padding: 6px;

                          li {
                              margin-bottom: 2px;
                          }

                          a {
                              display: block;
                              color: var(--text-color3);
                              padding: 10px 9px;
                              border: solid 1px transparent;
                              border-radius: 6px;
                              white-space: nowrap;

                              &:hover {
                                  box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                                      inset -2px -2px 6px 0 #ffffff;
                                  border-color: #d2dae6;
                                  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                              }
                          }
                      }
                  }

                  .collayout-3-dropdown {
                      @media (min-width: 768px) {
                          .multiCol3 .customCheckbox>span:before {
                              top: -3px;
                          }

                          .customCheckbox input:checked+span:after {
                              top: -1px;
                          }
                      }
                  }

                  .openedOption {
                      .flterOption {
                          background-color: rgba(255, 255, 255, 0.6);
                      }

                      .filterDropdown {
                          display: block;
                      }
                  }

                  .btnsCnt {
                      display: flex;
                      align-items: center;
                      font-size: 14px;
                      padding-top: 26px;
                  }

                  .borderBtn {
                      color: #1d6ec9;
                      background: var(--white);
                      width: 100px;
                      border: 1px solid #1d6ec9;
                      border-radius: 15px;
                      margin-right: 28px;
                      padding: 6px;
                      cursor: pointer;

                      &.checked,
                      &:hover {
                          background: #fff;
                          color: #1d6ec9;
                      }
                  }

                  .filterby-input {
                      order: 2;
                      margin-bottom: 26px;

                      .showDropdown {
                          padding: 2px 2px;

                          .inputDropdown {
                              position: static;
                              min-width: 100%;
                              min-height: auto;
                              min-height: 45px;
                              box-sizing: border-box;
                              color: #1d6ec9;
                              margin: 0px 0px;
                              font-size: 17px;
                              padding: 12px 15px 12px 15px;
                              border-radius: 10px;
                              box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                              -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                              background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                              border-color: transparent;
                          }

                          .rbt-input-hint {
                              height: auto !important;
                              padding: 12px 15px 10px 15px !important;
                              color: #1d6ec9 !important;
                          }

                          #hotel_search {
                              border-radius: 10px;
                              box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                              background-color: #eff0f5;
                              padding: 5px;
                              box-sizing: border-box;
                              min-width: 200px;
                              display: none;
                              z-index: 2;
                              margin-top: 10px;

                              a {
                                  display: block;
                                  color: var(--text-color3);
                                  border: solid 1px transparent;
                                  border-radius: 6px;
                                  white-space: nowrap;
                                  margin-bottom: 2px;
                                  padding: 10px 15px;
                              }

                              a:hover {
                                  box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                                  border-color: #d2dae6;
                                  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                              }
                          }

                      }
                  }

                  .filterDropdownWrap {
                      .priceBtn {
                          display: none;
                      }

                      .priceRange {
                          padding: 17px 0px 0px;
                          display: flex;

                          h4 {
                              margin-bottom: 10px;
                              margin-right: 10px;
                              font-weight: normal;
                              color: var(--text-color);
                          }
                      }

                      .cardBody {
                          padding-bottom: 20px;

                          .inputRange {
                              .inputRange {
                                  left: 10px;
                              }
                          }
                      }
                  }

                  .cancelBtn {
                      color: var(--text-color3);

                      &:hover {
                          color: #000;
                      }
                  }

                  .priceVal {
                      color: var(--text-color);
                      font-size: 17px;
                  }

                  .rangeStyle {
                      padding: 10px 0 0;

                      input {
                          width: 100%;
                      }
                  }

                  .customCheckbox {
                      display: block;
                      white-space: nowrap;

                      +.customCheckbox {
                          margin-top: 12px;
                      }
                  }
              }
          }

          .mobileSortFilter {
              .closePopup {
                  display: block;
              }

          }

          .mobileSearchFilterButtons {
              display: block;
              margin-top: 10px;

              .d-flex {
                  width: 100%;
                  justify-content: center;
                  position: sticky;
                  z-index: 2;
                  margin: 0px 0px;

                  .mobileButtons {
                      border-radius: 17.5px;
                      border: none;
                      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                      -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                      background: linear-gradient(to right, #4d98d2 24%, #4d98d2 65%);
                      font-family: "Ubuntu";
                      font-size: 14px;
                      font-weight: normal;
                      color: #ffffff;
                      min-width: 150px;
                      min-height: 35px;
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      padding: 5px;
                      position: relative;
                      margin: 0 3.5px;
                      cursor: pointer;

                      .fa {
                          margin-right: 10px;
                      }

                      .icon-location-on {
                          display: block;
                          position: absolute;
                          top: 50%;
                          transform: translateY(-50%);
                          height: 15px;
                          width: 15px;
                          left: 33px;

                          &::before {
                              color: white !important;
                          }
                      }
                  }

              }
          }

          .slick-slider {
              .slick-arrow:before {
                  font-size: 18px;
              }

              .slick-next {
                  right: 7px;
                  // transform:rotate(90deg) translateY(-50%);
              }
          }
      }

      .bannerSlider {
          .image-content-col .customShadow {
              height: 110px !important;
          }


      }

      .filterCnt .inputStyle .icon-location-on {
          z-index: 11;
      }

  }

  // 991 end
  @media (min-width: 768px) and (max-width: 991px) {
      .detailPageWrap {

          .selectRoomContent {
              .new-card-section {
                  .imageContent {
                      .content {
                          padding: 0 10px;
                          max-width: 280px;
                          flex: 0 0 280px;
                      }


                  }
              }
          }

          .amenitiesBox {
              .leftContent {
                  ul {
                      li {
                          max-width: 50%;
                          flex: 0 0 50%;
                          padding-bottom: 12px;
                      }
                  }
              }
          }

          .SliderContent .leftContent {
              max-width: 50%;
              flex: 0 0 50%;
          }

          .new-card-section {
              .new-card-box {
                  .price-box-col {
                      .infoBox {
                          .buttonsGroup {
                              max-width: 100%;
                              flex: 0 0 100%;
                          }

                          .fromPrice.singlePrice {
                              min-height: auto;
                          }

                          .btn {
                              margin: 0;
                          }

                          .savings {
                              margin: 0 0 10px;
                          }
                      }
                  }
              }
          }
      }
  }


  @media (min-width: 841px) and (max-width: 910px) {
      .detailPageWrap {
          .new-card-section {
              .price-box-col {
                  .infoBox {
                      .btn {
                          min-width: 135px;
                      }
                  }
              }
          }
      }

  }

  @media (max-width: 767px) {
      .listingPage.detailPageWrap {
          .mainContent {
              .filterCnt {
                  background-color: rgb(77, 152, 210);
                  padding: 4.5px 10px 4.5px 15px;

                  .mobileSearchBlock {
                      display: flex;
                  }

                  .filterCnt2 {
                      .mobileHide {
                          display: none;
                          padding-top: 0px;
                      }
                  }

                  .openFilter {
                      padding-bottom: 0px;

                      .mobileHide {
                          display: flex;
                          flex-direction: column;


                          .dateAndBtnWrap {
                              display: flex;
                              flex-direction: column !important;
                              margin-top: 0px;

                              .Date-picker-range {
                                  .DateRangePicker {
                                      .DateRangePickerInput {
                                          width: 100% !important;

                                      }
                                  }
                              }

                              .dropdownWrap {
                                  width: 100% !important;
                                  min-width: 100% !important;
                                  margin-left: 0px;

                                  .btnSml {
                                      width: 100%;
                                  }
                              }

                              .mobileBtnWrap {
                                  margin-top: 10px;
                                  align-items: center;

                                  .mobileShow {
                                      flex-direction: row;

                                      &.closeFilter {
                                          display: block;

                                          i {
                                              margin-left: 3px;
                                          }
                                      }

                                  }

                                  .btnBorder {
                                      min-width: 116px;
                                  }
                              }
                          }

                          .locationStartEndWrap {
                              margin-bottom: 10px;

                              .responsive-input {
                                  margin-top: 0px;
                              }
                          }
                      }
                  }

                  .showDropdown {
                      .mobilePopup {
                          .dropdown-content {
                              .rbt {
                                  .rbt-menu {
                                      background-color: transparent;
                                      border: none;
                                      box-shadow: none;
                                      margin: 10px -8px;
                                      min-width: 103%;


                                  }
                              }
                          }
                      }
                  }
              }

              .filterCnt2.openFilter {
                  .mobileHide {
                      padding-top: 10px;
                  }
              }

          }

          .bannerSlider {
              .btn-right {
                  .centerAlign {
                      text-align: right;
                  }

              }

              .fullImages {
                  .imagesGroup {
                      img {
                          height: 100%;
                          max-height: 175px;
                      }
                  }
              }


              .image-content-col {
                  padding: 8.5px 0px;

                  .infoBox {
                      height: inherit !important;
                      max-width: 95.1%;
                      margin: 0px auto;

                      .price-box-col {
                          .box-info {
                              max-width: 100%;
                              padding: 8.5px 0px;
                          }
                      }

                      .title {
                          button {
                              padding: 0 36px 5px;
                          }
                      }
                  }

              }

              .content {
                  .infoBox {
                      .buttonsGroup {
                          padding-top: 0px;
                          padding-bottom: 0;
                      }

                      .btn {
                          min-width: 106px;
                      }
                  }
              }
          }

          .selectRoomContentMain {
              h4 {

                  font-size: 19px;

              }
          }

          .amenitiesWrapper {
              .amenitiesInfo {
                  .row {
                      .col {
                          flex: 0 0 50%;
                          max-width: 50%;
                          margin-bottom: 0px;

                      }
                  }
              }
          }

          .new-card-section {
              margin-top: 15px;

              .leftContent {
                  .price-box-col {
                      .infoBox {
                          .title {
                              h4.active:after {
                                  left: 0;
                                  min-width: 125px;
                              }
                          }

                          .buttonsGroup {
                              display: flex;
                              flex-wrap: wrap;
                              justify-content: center;
                              align-items: center;
                              padding-bottom: 0px;

                              .centerAlign {
                                  padding: 0 10px;
                              }

                              .btn {
                                  font-size: 12px;
                                  line-height: 1;
                                  min-height: 30px;
                                  padding: 9px;
                                  min-width: 142px;
                                  margin: 0;
                              }

                              .upgradeBtn {
                                  .btn {
                                      margin-right: 10px;
                                  }
                              }
                          }

                          .btnBorder2 {
                              font-size: 12px;
                              line-height: 1;
                              min-height: 30px;
                              padding: 3.5px 7px;
                              display: inline-flex;
                              align-items: center;
                              justify-content: center;
                              min-width: 142px;
                              margin: 0px;
                          }

                          .fromPrice {
                              margin: 5px 0;
                          }

                      }
                  }

                  .heading-wrap {
                      margin-bottom: 6px;
                  }

                  .hotelFacilities {
                      margin: 10px 0px 0;
                      flex-direction: column;
                  }
              }

          }

          .siteBg.detailPageWrap {
              .bannerSlider {
                  margin-top: 0px;
              }

              .mainContent {
                  .hide-content {
                      padding: 4.5px 15px !important;
                      position: sticky;

                  }
              }


          }

          .bannerSlider {
              .content {
                  .infoBox {

                      min-height: inherit;
                  }
              }
          }

          .selectRoomContent {
              .infoBoxWrapper {
                  .savings {
                      justify-content: center;
                  }
              }
          }

          .selectRoomTabs .sectionTitleWrap {
              flex-direction: column;

              .sortWrapper {
                  margin-top: 10px;
              }
          }
      }
  }

  //767 end
  @media (max-width: 520px) {
      .detailPageWrap {
          .amenitiesWrapper {
              .amenitiesInfo {
                  .row {
                      .col {
                          flex: 0 0 50%;
                          max-width: 50%;

                      }
                  }
              }
          }

          .propertyDetails {

              .content {
                  .nearby-wrap {
                      padding: 0px 0px;
                  }
              }
          }
      }
  }

  @media (max-width: 374px) {
      .listingPage.detailPageWrap {
          .bannerSlider {
              .image-content-col {
                  .infoBox {
                      .title {
                          button {
                              padding: 0 25px 5px;
                          }
                      }
                  }
              }
          }

          .mainContent {
              .filterCnt {
                  .mobileSearchBlock {
                      .fa-search {
                          margin-right: 4px;
                      }
                  }
              }
          }

          .new-card-section {
              .leftContent {
                  .price-box-col {
                      .infoBox {
                          .buttonsGroup {
                              padding-bottom: 10px;
                          }
                      }
                  }
              }
          }
      }
  }