//Map-popup
.popup-content {
    .box-content {
        margin-top: 43px;

        .map-model {
            position: absolute;
            z-Index: 1;
            margin-top: 10px;
            margin-left: 16px;

            .back-to-view {
                width: 115px;
                height: 35px;
                margin: 1px 5px 57px 0;
                padding: 8px 21px 9px 10px;
                border-radius: 10px;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
                border: solid 1px #1d6ec9;
                background-color: rgba(255, 255, 255, 0.9);
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #1d6ec9;

                span {
                    display: flex;
                    justify-content: space-between;
                }

            }
        }

        .map-popup {
            width: 398px;
            height: auto;
            padding: 5px 5px 5px 4.5px;
            margin-top: -120px;
            margin-left: -200px;
            border-radius: 9px;
            z-index: 999;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
            border: solid 1px rgba(67, 91, 117, 0.25);
            background-color: #f3f4f8;
            position: absolute;

            .d-flex {
                display: flex;
                flex-wrap: wrap;

                .item-img {
                    //  width: 27%;
                    //  height: 100%;
                    //  display: flex;
                    //  flex: 0 0 27%;
                    //  height: auto;
                    //  justify-content: center;
                    //  align-items: center;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 26%;
                    height: 90px;
                    margin-right: auto;
                    border-radius: 6px;
                    padding: 51px;
                    //  img{
                    //     width: 91px;
                    //     height: auto;

                    //     margin: 0 9.5px 0 0;
                    //     object-fit: contain;
                    //  }
                }

                .innerCnt {
                    display: flex;
                    flex-direction: column;
                    flex: 0 0 auto;
                    flex-wrap: wrap;
                    width: 72%;
                    flex: 0 0 72%;

                    .rating-info {
                        margin-bottom: 7px;
                        position: relative;
                        padding-right: 27px;

                        .cancel {
                            position: absolute;
                            right: 0;
                            top: 0;
                            border: none;
                            font-size: 12px;
                            background-color: transparent;

                            .icon-close:before {
                                font-size: 9px;
                            }
                        }

                        .cartTitle {
                            margin: 7px 0 7px 0px;
                            font-size: 17px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: #1d6ec9;
                            max-width: 240px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            // &:hover {
                            //     white-space: normal;

                            // }
                            @media (max-width: 579px) {
                                max-width: 170px;
                            }
                        }

                        .rating {
                            width: auto;
                            padding: 0px 9px 0px 0px;

                            img {
                                width: 17px;
                                height: 20px;
                            }
                        }

                        .ratingComment {
                            font-size: 12px;

                            .commentCount {
                                margin-right: 2px;
                            }
                        }
                    }
                }

                .mobileViewWrapper {
                    flex-direction: row;
                    height: 30px;
                    display: flex;
                    margin: 0px;

                    .facility-icon {
                        width: 66%;
                        flex: 0 0 66%;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;

                        img {
                            width: 20px;
                            margin-right: 9px;

                            @media (max-width: 991px) {
                                width: auto;
                                max-width: 18px;
                                margin: 0 5px;
                            }
                        }

                        span {
                            font-size: 14px;
                            color: #7691b0;
                        }
                    }

                    .price {
                        width: auto;
                        flex: auto;
                        margin-top: -4px;
                        text-align: right;

                        p {
                            color: #435b75;
                            font-size: 10px;
                            opacity: 0.6;
                        }

                        span {
                            font-size: 17px;
                            color: #435b75;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;

                            @media (max-width: 768px) and (max-width: 991px) {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:579px) {
    .popup-content {
        .box-content {
            .map-popup {
                width: 300px;
                margin: -90px auto 0;
                margin-left: -150px;

                .d-flex {
                    display: flex;
                    flex-wrap: wrap;

                    .item-img {
                        width: 15%;
                        height: auto;
                        margin-right: 0;
                        border-radius: 6px;
                        padding: 35px;
                    }

                    .innerCnt {
                        padding-left: 5px;

                        .rating-info {

                            position: relative;

                            .cancel {

                                font-size: 12px;

                                .icon-close:before {
                                    font-size: 9px;
                                }
                            }

                            .cartTitle {
                                margin: 2px 0 5px 0px;
                                font-size: 12px;
                            }

                            .rating {

                                img {
                                    width: 17px;
                                }
                            }

                            .ratingComment {
                                font-size: 12px;

                                .commentCount {
                                    margin-right: 2px;
                                }
                            }
                        }
                    }

                    .mobileViewWrapper {

                        .facility-icon {

                            img {
                                width: 13px;
                                margin-right: 3px;
                            }

                            span {
                                font-size: 13px;
                            }
                        }

                        .price {

                            span {
                                font-size: 12px;
                                margin: 0px 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 579 end
@media (max-width: 374px) {
    .popup-content .box-content .map-popup {
        width: 285px;
        margin-left: -82px;
    }
}