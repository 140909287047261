.dashboard {
    .mainContent {
        padding-top: 55px;

        .pageInternalScroll {
            max-height: calc(100vh - 107px);
            overflow-y: auto;
            padding-bottom: 0;
            padding-top: 0 !important;
        }

    }

    .navigation [class^=icon-]:before {
        color: #1d6ec9;
    }

    .footer {
        background: rgba(255, 255, 255, 0.8);
        box-shadow: none;
        width: 100%;

        .footCopy {
            color: rgba(67, 91, 117, 0.6);
        }
    }

    .network-logo-sticky {
        align-items: center;
        display: flex;

        span {
            border: 1px solid #1d6ec9;
            border-radius: 10px;
            color: #1d6ec9;
            font-size: 11px;
            margin-left: 5px;
            padding: 3px 8px;
        }

    }

}

.membersRewardsPageWrap .walletWrapper .table-heading-info .headingTablLink {
    display: flex;
    align-items: center;

    h2 {
        margin-right: 15px;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid transparent;
        cursor: pointer;

        &.active,
        &:hover {
            color: #1d6ec9;
            border-bottom: 1px solid #1d6ec9;
        }
    }
}


.membersRewardsPageWrap {
    padding: 0;

    .container {
        max-width: 1110px;
    }

    .tab-menu-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 27px;
        padding-top: 20px;

        ul {
            margin: 0 -24px;
            padding: 0;
            list-style: none;
            display: flex;

            li {
                padding: 0 24px;

                a {
                    color: $white;
                    font-size: 21px;
                    font-weight: normal;
                    position: relative;
                    transition: 0.3s;

                    &:hover {
                        &:after {
                            content: "";
                            display: block;
                            background-color: $white;
                            height: 1px;
                            width: 100%;
                            position: absolute;
                            bottom: -3px;
                        }
                    }
                }

                .active {
                    &:after {
                        content: "";
                        display: block;
                        background-color: $white;
                        height: 1px;
                        width: 100%;
                        position: absolute;
                        bottom: -3px;
                    }
                }
            }
        }

        .details-btn {
            font-size: 14px;
            font-weight: normal;
            min-width: auto;
            background: none;
            box-shadow: none;
            padding: 0;
            display: flex;
            align-items: center;
            margin-right: 24px;

            img {
                margin-right: 8px;
            }

            &::before {
                display: none;
            }
        }

        .mobile-details-btn {
            display: none;
        }
    }

    .border-bottom {
        border-bottom: 1px solid rgba(67, 91, 117, 0.2);
    }

    .border-left {
        border-left: 1px solid rgba(67, 91, 117, 0.2);
    }

    .section-title {
        padding: 21px 0;

        h2 {
            font-size: 21px;
            font-weight: normal;
            color: $white;
        }
    }

    .commission-content-wrapper {
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.8);

        .content {
            padding: 20px 16px;

            h2 {
                font-size: 45px;
                font-weight: 300;
                color: #1d6ec9;
            }

            h6 {
                font-size: 14px;
                font-weight: normal;
                color: rgba(67, 91, 117, 0.6);
                margin-bottom: 4px;
            }

            h5 {
                font-size: 25px;
                font-weight: normal;
                color: #435b75;
            }
        }

        .col {
            max-width: 25%;
            flex: 0 0 25%;
        }
    }

    .commission-content-tabs {
        padding-bottom: 30px;

        // overflow-x: auto;
        // overflow-y: hidden;
        .active-tab {
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-left: 23px solid transparent;
                border-right: 23px solid transparent;
                border-bottom: 23px solid rgba(255, 255, 255, 0.8);
                position: absolute;
                bottom: -40px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .row {
            margin: 0 -7.5px 10px;
            flex-wrap: initial;

            .col {
                padding: 0 7.5px;
                max-width: 20%;
                flex: 0 0 20%;
                // min-width: 220px;
            }

        }



        .content {
            padding: 10px 13px 10px 10px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.8);
            position: relative;

            .anchor-tab {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
            }

            h5 {
                text-align: right;
                font-size: 19px;
                font-weight: normal;
                color: #00b7a6;
            }
        }

        .image {
            width: 45px;
            height: 45px;
            flex: 0 0 45px;
            max-width: 45px;
            border-radius: 25px;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                inset -2px -2px 6px 0 #fff;
            border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .heading {
            display: flex;
            align-items: center;
            padding-bottom: 12px;

            h6 {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.21;
                color: #435b75;
                margin-left: 10px;
                min-height: 34px;
            }
        }
    }

    .commission-content-info {
        padding: 20px 15px 15px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 10px;
        display: none;

        &.active-content {
            display: block;
        }

        .light-green-text {
            color: #00b7a6;
        }

        .heading {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(67, 91, 117, 0.2);
            padding-bottom: 15px;

            h3 {
                font-size: 21px;
                font-weight: normal;
                color: #435b75;
            }

            .right-content {
                display: flex;

                .tottal-box {
                    padding: 3px 12px 4px;
                    border-radius: 6px;
                    border: solid 1px #00b7a6;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    align-self: center;

                    span {
                        font-weight: 500;
                    }
                }

                .dropdownWrap {
                    min-width: 195px;

                    .toggleBtn {
                        width: 100%;
                        background-image: none;
                        border: none;
                        box-shadow: none;

                    }

                    i {
                        color: #1d6ec9;
                        font-size: 21px;
                    }

                    [class*=control],
                    [class*=control] {
                        border-radius: 10px;
                        box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                        border: solid 1px rgba(210, 218, 230, 0.6);
                        background-color: rgba(255, 255, 255, 0.35);
                        background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                        font-size: 14px;
                        min-height: 35px;
                        width: 100%;
                        height: 35px;
                        font-weight: normal;
                        color: rgba(67, 91, 117, 0.6);
                        cursor: pointer;
                    }

                    .custom-select__single-value {
                        color: rgba(67, 91, 117, 0.6);
                    }

                    [class*=ValueContainer],
                    [class*=Input] {
                        margin-top: -3px;
                        margin-left: 5px;
                        color: rgba(67, 91, 117, 0.6);
                        padding: 0px 0px;
                    }

                    [class*=indicatorContainer],
                    [class*=indicatorContainer] {
                        padding: 0px;
                        margin-right: 10px;
                    }

                    .css-26l3qy-menu {
                        padding: 5px;
                        border-radius: 10px;
                        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
                        border: solid 1px #d2d9e7;
                        background-color: #e6e7ee;

                        .css-9gakcf-option,
                        .css-yt9ioa-option,
                        .css-1n7v3ny-option {
                            background-color: #e6e7ee;
                            border-radius: 6px;
                            color: #435b75;
                            cursor: pointer;
                        }

                        .css-9gakcf-option {
                            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                            border-color: #d2dae6;
                            color: #435b75;
                        }

                        .css-1n7v3ny-option:hover {
                            background-color: #e6e7ee;
                            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                            border-color: #d2dae6;
                            color: #435b75;
                        }

                    }
                }

                .dropdownInWrap {
                    li {
                        font-size: 14px;
                    }
                }

                .btnSml {
                    border-radius: 10px;
                    box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
                        2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                    border: solid 1px rgba(210, 218, 230, 0.6);
                    background-color: rgba(255, 255, 255, 0.35);
                    font-size: 14px;
                    font-weight: normal;
                    color: rgba(67, 91, 117, 0.6);
                }
            }
        }

        .content-wrapper {
            padding: 0 15px;

            h4 {
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                color: rgba(67, 91, 117, 0.6);
            }

            .row {
                min-height: 50px;
                align-items: center;
                border-radius: 10px;

                &:nth-child(even) {
                    background-color: rgba(255, 255, 255, 0.5);
                }

                .col {
                    text-align: center;

                    &:last-child {
                        text-align: right;
                    }

                    &:first-child {
                        text-align: left;
                    }
                }
            }

            h5 {
                font-size: 14px;
                font-weight: normal;
                color: #435b75;
            }

            .light-green-text {
                color: #00b7a6;
                font-weight: 500;
            }
        }
    }

    .popup {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        background-color: rgba(67, 91, 117, 0.6);
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: none;

        &.open-popup {
            display: flex;
        }

        .popup-content {
            position: relative;
            max-width: 640px;
            margin: 0 auto;
            width: 100%;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #cfd1e1;
            background-color: #eff0f5;
            padding: 15px 21px;
        }

        .popup-title {
            padding-bottom: 0px;
            display: flex;
            justify-content: space-between;

            h3 {
                font-size: 17px;
                font-weight: 500;
                margin-bottom: 0;
                color: #435b75;
                display: flex;
                align-items: center;

                img {
                    margin-right: 10px;
                }
            }

            .close {
                margin-top: -4px;
                padding: 4px;
                font-weight: 400;
                font-size: 18px;
                position: absolute;
                top: 10px;
                right: 8px;
                line-height: 1;
                min-width: auto;
                box-shadow: none;
                background: none;
                border-radius: 0;

                &:before {
                    display: none;
                }
            }
        }

        .box-content {
            max-height: 80vh;
            overflow-y: auto;
            overflow-x: hidden;

            h4 {
                font-size: 14px;
                font-weight: 500;
                color: #1d6ec9;
                margin-bottom: 1px;
            }

            p {
                font-size: 14px;
                font-weight: normal;
                color: rgba(67, 91, 117, 0.6);
                margin-bottom: 20px;
            }

            h3 {
                font-size: 17px;
                font-weight: 500;
                color: #435b75;
                margin-bottom: 20px;
            }
        }
    }

    .premium-members-invites {
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(97deg,
                #0078d9 0%,
                #00b5b4 135%,
                #00e0f4 135%);
        background-image: url("../../../img/triptek-rewards-bg.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 22px 40px 10px;
        color: $white;

        h1 {
            font-size: 25px;
            font-weight: normal;
            color: $white;
            margin-bottom: 15px;
        }

        p {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.21;
            margin-bottom: 18px;
        }

        .leftContent {
            max-width: 72%;
            flex: 0 0 72%;

            .container {
                max-width: 710px;
                margin: 0px;
                padding: 0px;
            }
        }

        .rightContent {
            text-align: center;

            .right-content {
                display: none;
            }
        }
    }

    .overview-content-wrapper {
        .premium-members-invites {
            border-radius: 10px 10px 0 0;
        }

        .overview-content {
            border-radius: 0 0 10px 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.8);

            ul {
                list-style: none;
                display: flex;
                margin: 0;
                padding: 0;
                flex-wrap: wrap;

                li {
                    max-width: 25%;
                    flex: 0 0 25%;
                    text-align: center;
                    border-right: 1px solid rgba(67, 91, 117, 0.2);
                    padding: 15.5px 10px;
                    font-size: 17px;
                    font-weight: normal;
                    color: #1d6ec9;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        margin-right: 11px;
                    }
                }
            }
        }
    }

    .note-wrapper {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.15);
        padding: 12px 20px;
        border-radius: 10px;
        margin-bottom: 20px;
        position: relative;
        margin-top: 5px;

        &:after {
            content: "";
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid rgba(255, 255, 255, 0.15);
            position: absolute;
            left: 30px;
            top: -12px;
        }

        p {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.21;
            color: $white;
        }
    }

    .sharingWrapper {
        margin-bottom: 0px;

        .card {
            padding: 12px 16px 7px 15px;
        }

        .row {
            margin: 0 -10px;

            .col {
                padding: 0 10px;
            }
        }

        .contentWrapper {
            display: none;

            &.active-tab-content {
                display: block;
            }
        }

        .formWrapper {
            .buttonWrap {
                .btn {
                    font-size: 14px;
                    font-weight: normal;
                    color: #ffffff;
                    border-radius: 20px;
                    box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 20%), -2px -2px 5px 2px #ffffff;
                    background-image: linear-gradient(to right, #3763d0 -12%, #00d0d4 100%);
                    min-width: 140px;
                    margin-bottom: auto;


                }

                .btn:before {
                    display: none;
                }
            }
        }
    }

    .sharingWrapperSpace {
        padding-bottom: 15px;

        .row {
            margin-bottom: 15px;
        }
    }

    .marketing-page-wrapper {
        .video-popup {
            position: fixed;
            height: 100%;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 999;
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
            background-color: rgba(67, 91, 117, 0.5);
            align-items: center;
            justify-content: center;
            flex-direction: column;
            display: none;

            &.open-popup {
                display: flex;
            }

            .close {
                padding: 4px;
                font-weight: 400;
                font-size: 18px;
                position: absolute;
                top: -5px;
                right: -5px;
                line-height: 1;
                min-width: auto;
                box-shadow: none;
                background: none;
                cursor: pointer;
                z-index: 9;
                border-radius: 0;

                &:before {
                    display: none;
                }
            }

            .popup-content-wrap {
                max-width: 600px;
                margin: 0 auto;
                border-radius: 10px;
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #cfd1e1;
                background-color: #eff0f5;
                border: solid 1px #d2dae6;
                padding: 15px;

                .popup-content-inner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    flex-direction: column;
                }

                video {
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 0;
                }

                .popup-content {
                    position: relative;
                    display: block;
                    width: 500px;
                    height: 330px;
                }
            }
        }

        .commission-content-tabs {
            padding: 30px 19px;

            .row {
                .col {
                    max-width: 20%;
                    flex: 0 0 20%;
                    padding: 0 7px;
                }
            }

            .row .col:last-child {
                .image {
                    width: 56px;
                    height: 48px;
                    flex: 0 0 46px;
                    max-width: 46px;

                    img {
                        box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 60%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                        border-radius: 23px;
                        max-width: 46px;
                    }
                }
            }

            .heading {
                justify-content: center;
                padding-bottom: 0;
                min-height: 56px;
                @media(max-width:767px) {
                    min-height: 42px;
                }
            }

            .image {
                width: 56px;
                height: 56px;
                flex: 0 0 56px;
                max-width: 56px;
                background-color: transparent;
                box-shadow: none;
                border: none;

                img {
                    box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 60%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                    border-radius: 23px;
                    max-width: 46px;
                }
            }

            .content {
                padding: 7px 13px;

                h6 {
                    font-size: 17px;
                    font-weight: normal;
                    color: #435b75;
                    margin-top: 10px;

                }
            }
        }

        .commission-content-info {
            box-shadow: none;
            background-color: transparent;
            border-radius: 0px;
            padding: 0;

            .commission-content-info-inner {
                padding: 20px 0 15px;
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 10px;
                margin-bottom: 20px;
                overflow: hidden;

            }

            .commission-content-videos {
                .content-wrapper {
                    position: relative;
                }

                .popup-link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }

            .heading {
                padding: 0 15px 15px;
            }

            .content-wrapper {
                padding: 10px 9px 4px;
                border-radius: 10px;
                box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #fff;
                border: solid 1px #dae2ee;
                background-color: rgba(255, 255, 255, 0.35);

                img {
                    width: 100%;
                }
            }
        }

        .image-container {
            padding: 30px 0 0;
            .col {
                max-width: 360px;
            }
            .row {
                border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                margin-bottom: 30px;
                padding: 0 30px;

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;

                    .download-link {
                        padding-bottom: 0;
                    }
                }
            }

            .download-link {
                padding: 15px 0;

                h4 a {
                    font-size: 14px;
                    font-weight: normal;
                    color: #1d6ec9;
                    display: inline-flex;
                    align-items: center;
                }

                img {
                    margin-right: 5px;
                }
            }
        }
    }

    .walletWrapper {
        padding-bottom: 10px;

        .btnSml {
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
                2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px rgba(210, 218, 230, 0.6);
            background-color: rgba(255, 255, 255, 0.35);
            min-height: 35px;
            display: inline-flex;
            align-items: center;

            .fa {
                font-size: 20px;
            }
        }

        .card2 {
            margin-bottom: 30px;
        }

        .wallet-heading-info {
            padding: 0;

            h6 {
                font-size: 14px;
                font-weight: normal;
                color: rgba(67, 91, 117, 0.6);
                margin-bottom: 11px;
            }

            h3 {
                font-size: 45px;
                font-weight: 300;
                color: #1d6ec9;
            }

            .filter-wrapper {
                border-right: 1px solid rgba(67, 91, 117, 0.2);
                padding: 15px 20px;
            }

            .transaction-heading-content {
                padding: 15px 20px;
            }

            .dateWrapper {
                .dateBox {
                    position: relative;
                    border-radius: 10px;
                    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                        inset -2px -2px 6px 0 #ffffff;
                    border: solid 1px #dae2ee;
                    min-height: 40px;
                    background-color: rgba(255, 255, 255, 0.35);
                    padding: 0 5px;
                }

                .toDate,
                .fromDate {
                    padding: 0 10px;
                    position: relative;

                    &:before {
                        content: "";
                        @include background("../img/date.png");
                        background-size: contain;
                        width: 14px;
                        height: 16px;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        transform: translateY(-50%);
                    }

                    p {
                        padding-left: 25px;
                        color: #1d6ec9;
                        font-size: 14px;
                        font-weight: normal;
                    }
                }

                .toDate {
                    position: relative;

                    &:after {
                        content: "";
                        @include background("../img/right-arrow.png");
                        width: 15px;
                        height: 10px;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 115%;
                        transform: translateY(-50%) translateX(-115%);
                    }
                }

                .fromDate {
                    margin-left: 20px;
                }

                img {
                    height: auto;
                    margin-left: 0;
                }
            }
        }

        .row {
            .left-content {
                display: flex;
                justify-content: space-between;
                border-right: 1px solid rgba(67, 91, 117, 0.2);
                padding: 15.5px 20px;
                max-width: 50%;
                flex: 0 0 50%;

                .btn {
                    min-width: 120px;
                    font-size: 14px;
                    padding: 9.5px;
                }
            }

            .right-content {
                display: flex;
                justify-content: space-between;
                max-width: 50%;
                flex: 0 0 50%;
            }

            .payout-option {
                max-width: 60%;
                flex: 0 0 60%;
            }

            .dropdown-content {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .dropdownWrap {
                    width: 100%;
                    margin-right: 10px;
                }

                .btn {
                    &::before {
                        display: none;
                    }
                }
            }
        }


        .table-heading-info {
            display: flex;
            justify-content: space-between;
            padding: 10px 15.5px 18px 30px;

            h2 {
                font-size: 21px;
                font-weight: normal;
                color: #435b75;
            }

            .formGroup {
                position: relative;
                border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                padding-bottom: 4px;
                min-width: 248px;
                display: flex;

                input {
                    color: rgba(67, 91, 117, 0.6);
                    font-size: 14px;
                    font-weight: normal;
                    width: 100%;

                    @include placeholder-text {
                        color: rgba(67, 91, 117, 0.6);
                    }
                }

                .btn {
                    font-size: 12px;
                    min-width: auto;
                    background: none;
                    border: none;
                    box-shadow: none;
                    padding: 0 5px 0 0;
                    flex: 0 0 27px;
                    max-width: 27px;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .navigation [class^=icon-]:before {
        color: #1d6ec9;
    }

}

@media (max-width: 991px) {
    .tabletViewDisplayBlock {
        .dropdownSearchWrapper .headingTablLink {
            display: flex;
            margin-bottom: 15px;
            margin-top: 10px;
            h2 {
                margin-right: 10px;
                &.active {
                    color: #1d6ec9;
                }
            }
        }
    }
    .dashboard,
    .membersRewardsPageWrap {
        .mainContent {
            padding-bottom: 30px;

            .tab-menu-wrapper {
                padding-top: 0px;
                padding-left: 5px;
            }

        }
    }

    .membersRewardsPageWrap {
        .marketing-page-wrapper {
            .commission-content-tabs {
                .row {
                    .col:last-child {
                        .image {
                            width: 48px;
                            height: 48px;
                            flex: 0 0 48px;
                            max-width: 48px;
                        }
                    }
                }
            }
        }
    }

}

@media (min-width: 992px) {
    .membersCheckoutPageWrap {
        .mainContent {
            .pageInternalScroll {
                padding-top: 0px;
            }
        }
    }
}

.dashboard-pages {
    .common-wrapper {
        padding-bottom: 120px !important;

        @media(max-width:1120px) {
            padding-bottom: 60px !important;
        }
    }

    @media(max-width:991px) {
        .mainContent {
            padding-bottom: 0px !important;
            min-height: 100% !important;
        }
        
    }
    @media (min-width:768px) and (max-width:991px) {
        &.membersCheckoutPageWrap {
            .mainContent {
                .pageInternalScroll {
                    height: inherit !important;
                    .common-wrapper {
                        height: calc(100vh - 110px) !important;
                        overflow-y: auto !important;
                    }
                }
            }   
        }
    }
}