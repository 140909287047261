.siteBg.affiliateCheckoutPageWrap {
    .header {
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        background-color: #eff0f5;
    }

    .mainContent {
        padding-top: 55px;
    }

    .membership-content {
        @include background("../img/affiliate-checkout.jpg");

        .planName {
            font-size: 17px;
            line-height: 1.1;
            margin-top: 14px;
        }
    }

    .rightContent {
        margin-bottom: auto;
        padding-bottom: 17px;
    }

    .features ul {
        padding-bottom: 2px;
    }

    .PaypalInfo {
        .sectionInfo {
            display: flex;
            align-items: center;

            p {
                font-size: 10px;
                color: rgba(67, 91, 117, 0.6);
                font-weight: 400;
                margin-bottom: 6px;

                a {
                    text-decoration: underline;
                    color: #1d6ec9;
                }
            }

            .paypal-logo {
                max-width: 40px;
                flex: 0 0 40px;
            }
        }
    }

    .checkoutWrapper {
        padding-bottom: 20px;
    }

    .billingInfo .checkBoxWrap p {
        padding-left: 35px;
    }
}
.siteBg.affiliateupgradeCheckoutPageWrap {
    .membership-content {
      @include background("../img/affiliate-upgrade-checkout.jpg");
    }
  
    .member-number-wrap {
      display: flex;
      justify-content: space-between;
  
      .member-number-box {
        text-align: center;
        max-width: 40%;
        flex: 0 0 40%;
  
        input {
          text-align: center;
        }
      }
    }
  }
  
  // affiliateCheckoutPageWrap end
// Responsive css start
@media (min-width: 992px) {
    .siteBg.affiliateCheckoutPageWrap .mainContent .pageInternalScroll {
        padding-top: 0;
        height: calc(100vh - 120px) !important;
    }
}

// 992 end
@media (min-width: 992px) and (max-width: 992px) {
    .siteBg.affiliateCheckoutPageWrap {
        .leftContent {
            padding: 0 15px;
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }

        .rightContent {
            max-width: 320px;
            flex: 0 0 320px;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .siteBg.affiliateCheckoutPageWrap {
        .mainContent {

            .pageInternalScroll {
                height: 100% !important;
            }
        }

        .leftContent {
            order: 0;
        }

        .rightContent {
            order: 0;
            margin-bottom: 16px;
        }

        .rightContent,
        .leftContent {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .row {
            margin: 0;
        }
    }
}

@media (max-width: 767px) {
    .siteBg.affiliateCheckoutPageWrap {
        background-image: linear-gradient(125deg,
                #3763d0 20%,
                #3665cf 20%,
                #00c4b2 49%) !important;

        .mainContent {
            .pageInternalScroll {
                padding-top: 0;
                height: 100% !important;
            }
        }

        .membership-content {
            border-radius: 6px 6px 0 0;
        }

        .toggleButtons {
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 14px;
            font-weight: normal;
            color: #1d6ec9;
            cursor: pointer;
            margin: 0 auto;

            .fa {
                margin-left: 6px;
            }
        }

        .rightContent {
            margin-bottom: 10px;
        }

        .buttonWrap {
            h4 {
                margin-bottom: 4px;
            }

            h4:last-child {
                color: rgba(67, 91, 117, 0.6);
            }

            .btn {
                align-self: center;
                display: flex;
                justify-content: center;
                margin: 20px auto;
            }
        }

        .PaypalInfo .sectionTitle {
            margin-bottom: 0;

            h2 {
                margin-bottom: 5px;
            }
        }

        .features ul {
            padding-bottom: 13px;
        }

        .checkoutWrapper {
            max-width: 500px;
            margin: 0 auto;
        }

        .billingInfo {
            .buttonWrap .btn {
                max-width: 150px;
                margin: 16px auto 0;
            }
        }
    }
}

// 767 end