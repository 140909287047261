.profile-info-popup {
    display: flex;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(67, 91, 117, 0.6);
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .popup-content {
        position: relative;
        max-width: 580px;
        top: 50%;
        left: 50%;
        margin: 0 !important;
        transform: translate(-50%, -50%);
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        border: solid 1px #cfd1e1;
        background-color: hsl(230, 23%, 95%);
        padding: 15px 21px;

        .button-wrap {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            .orange-btn {
                margin: 0;
            }
        }

        .popup-footer .btn {
            margin: 0 10px;
            min-width: 130px;
            font-size: 16px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 10px 12px 10px 42px;
            background-position: left center;
        }

        .btn:before {
            content: "";
            left: 0px;

        }


    }

}

.profilePage {
input.btn {
    font-weight: 400;
}
    .leftSideBar .card2,
    .mainWrap form:first-child .card2 {
        // min-height: 500px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none !important;
        -webkit-text-fill-color: #435b75;
        -webkit-box-shadow: 0 0 0px 1000px inherit inset;
        transition: background-color 5000s ease-in-out 0s;
        font-size: 17px !important;
        background-image: none !important;
    }

    .mainWrap form .card2 {
        padding-bottom: 26px;
    }

    [class*=indicatorSeparator],
    [class*=indicatorContainer],
    [class*=IndicatorSeparator],
    [class*=IndicatorContainer] {
        display: none;
    }

    .profile-info-card,
    .information-card {
        min-height: 500px;

        @media(max-width:767px) {
            min-height: auto;
        }
    }

    .profile-name-wrap {
        position: relative;
    }

    .selectWrap {
        margin: 0 auto;
        width: calc(100% - 4px);

        [class*="control"] {
            min-width: 100%;
            border-radius: 10px;
            -webkit-box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px rgba(210, 218, 230, 0.6);
            background-image: none;
            background-color: #ffffff59;
            min-height: 38px;
            z-index: 1;
            font-size: 17px;
            color: rgba(67, 91, 117, 0.6);
            padding-right: 28px;
            cursor: pointer;

            input {
                min-height: auto;
            }

            [class*="IndicatorsContainer"] {
                display: none;
            }

            [class*="css"] {
                padding: 0 8px;
                width: 100%;
                [class*="css"] {
                    padding: 0;
                }
            }

            [class*="ValueContainer"] {
                padding: 0px 8px;
                margin-top: -4px;
                color: #7d91a9;
            }


            [class*="singleValue"],
            [class*="placeholder"] {
                // color: #7d91a9;
                color: #435b75;
            }

        }
    }

    .selectWrap {
        position: relative;

        &:after {
            content: "";
            background-image: url("../img/dropdown-arrow.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: contain;
            height: 14px;
            width: 14px;
            z-index: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            opacity: 0.5;
        }


    }

    .inputStyle {
        input {
            background-image: none;
            color: #435b75;
            min-width: 100%;
            min-height: 40px;
            -webkit-text-fill-color: #435b75;
            opacity: 1;

            @include placeholder-text {
                -webkit-text-fill-color: #7d92a9;
            }
        }
    }
    .inputPasswordWrap {
        input {
            text-overflow: ellipsis;
            overflow: hidden;
        }
        svg {
            font-size: 18px;
        }
    }

    .mainWrap {
        padding-right: 0px;

        .right-content {
            .add-traveller-btn {
                font-size: 14px;
            }
        }
    }

    .passwordBox {
        .col3 {
            .inputStyle {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            .col {
                margin-bottom: 13px;
                width: 100%;

                input {
                    // min-width: 230px;
                }
            }
        }
    }

    .save-traveller-box {
        .btn:before {
            display: none;
        }

        .top-content {
            display: flex;
            justify-content: space-between;

            .boxTitle {
                margin-bottom: 6px;
            }

            h6 {
                color: #435b75;
                font-size: 14px;
                font-weight: 500;
            }
        }

        .travellers-info {
            margin-top: 35px;

            .travellers-item {
                margin-bottom: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left-content {
                    display: flex;
                    align-items: center;

                    .icon-image {
                        i {
                            font-size: 28px;
                        }

                        .icon-account-circle:before {
                            color: #1d6ec9;
                        }
                    }

                    .travellers-name {
                        padding-left: 10px;

                        h6 {
                            color: #435b75;
                            font-size: 13px;
                            font-weight: 500;
                        }
                    }
                }

                button {
                    border: none;
                    background: transparent;
                    color: #1d6ec9;
                    font-size: 14px;
                    min-width: 75px;
                }
            }
        }
    }

    .paypal-validation-card {
        .header-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .image {
                max-width: 40px;
                flex: 0 0 40px;
            }
        }

        .content-wrap {
            p {
                color: rgba(67, 91, 117, .6);
                font-size: 15px;
                line-height: 20px;
            }
        }

        .button-wrap {
            max-width: 160px;
            margin: 20px 0 0 auto;
            text-align: center;

            .btn {
                &:before {
                    display: none;
                }
            }

            h5 {
                display: flex;
                align-items: center;
                font-size: 18px;
                color: rgba(67, 91, 117, 0.6);
                font-weight: 400;

                svg {
                    margin-left: 8px;
                }
            }
        }
    }
}


.profilePage {
    &.open-modal {
        .profile-info-popup {
            display: flex;
        }
    }

    .passwordBox {
        .col3 {
            margin: 0 -7.5px;

            .col {
                padding: 0 7.5px;
            }
        }
    }

    .member-info {
        text-align: center;
        max-width: 100%;
        margin: 15px auto 0;

        .labelStyle {
            font-size: 13px;
        }

        .input-info {
            padding: 10px 10px 8px 13px;
            width: 100%;
            margin: 0 auto;
            max-width: 250px;
            box-sizing: border-box;
            color: #435b75;
            background: rgba(255, 255, 255, 0.35);
            min-width: auto;
            text-overflow: ellipsis;
            border-radius: 10px;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                inset -2px -2px 6px 0 #ffffff;
            -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                inset -2px -2px 6px 0 #ffffff;
            font-size: 13px;
        }

        .cancel-subscription {
            margin-top: 20px;
            margin-bottom: 10px;

            .custom-switch {
                min-height: 20px;
                outline: none;
                position: relative;
                max-width: 40px;
                margin: 0 auto;

                .custom-control-input {
                    position: absolute;
                    left: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    box-sizing: border-box;
                    padding: 0;
                    outline: none;
                    box-shadow: none;
                    overflow: visible;
                }

                .custom-control-label {
                    position: relative;
                    margin-bottom: 0;
                    vertical-align: top;
                    color: #000000;
                    line-height: 20px;
                    font-size: 14px;
                    opacity: 1;
                    display: block;

                    &:before {
                        position: absolute;
                        top: 0.25rem;
                        display: block;
                        height: 20px;
                        pointer-events: none;
                        content: "";
                        background-color: #fff;
                        border: #adb5bd solid 1px;
                        left: 0;
                        width: 40px;
                        pointer-events: all;
                        border-radius: 15px;
                        color: #fff;
                        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                            inset -2px -2px 6px 0 #ffffff;
                        -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                            inset -2px -2px 6px 0 #ffffff;
                    }

                    &:after {
                        position: absolute;
                        display: block;
                        content: "";
                        background: 50%/50% 50% no-repeat;
                        background-color: #000;
                        border-radius: 0.5rem;
                        top: calc(4px + 3.5px);
                        left: auto;
                        right: 5px;
                        width: 12px;
                        height: 12px;
                        transition: background-color 0.15s ease-in-out,
                            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
                            -webkit-transform 0.15s ease-in-out;
                        transition: transform 0.15s ease-in-out,
                            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
                            box-shadow 0.15s ease-in-out;
                        transition: transform 0.15s ease-in-out,
                            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
                            box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
                    }
                }

                .custom-control-input:checked~.custom-control-label::after {
                    -webkit-transform: translateX(-18px);
                    transform: translateX(-18px);
                    background-color: #1d6ec9;
                }
            }

            .switcher {
                max-width: 80px;
                margin: 0 auto;
            }

            .button-switcher {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;
            }
        }
    }

    .profile-info-popup {
        display: none;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        background-color: rgba(67, 91, 117, 0.6);
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .popup-content {
            position: relative;
            max-width: 640px;
            margin: 0 auto;
            width: 100%;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
            border: solid 1px #cfd1e1;
            background-color: #eff0f5;
            padding: 15px 21px;
        }

        .popup-title {
            padding-bottom: 15px;
            display: flex;
            justify-content: space-between;
            border-bottom: solid 1px #dae2ee;

            h3 {
                font-size: 17px;
                font-weight: 500;
                margin-bottom: 0;
                color: #435b75;
                display: flex;
                align-items: center;
            }
        }

        .close {
            margin-top: -4px;
            padding: 4px;
            font-weight: 400;
            font-size: 18px;
            position: absolute;
            top: 10px;
            right: 8px;
            line-height: 1;
            min-width: auto;
            box-shadow: none;
            background: none;
            border-radius: 0;

            &:before {
                display: none;
            }
        }

        .popup-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-top: solid 1px #dae2ee;
            padding-top: 15px;
            margin: 0 -10px;

            .button-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .btn {
                margin: 0 10px;
                min-width: 130px;
                font-size: 16px;
                line-height: 1.2;
                display: inline-flex;
                justify-content: flex-start;
                min-height: 35px;
                height: auto;
                padding: 10px 12px 10px 42px;
                background-position: left center;
            }

            .blue-btn {
                background-color: transparent;
                border-radius: 22.5px;
                box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
                @include background("../img/profile/blue-btn-bg.png");
                background-size: 100% 100%;

                &:hover,
                &:focus {
                    @include background("../img/profile/blue-btn-bg.png");
                    background-size: 100% 100%;
                }

                &::before {
                    display: none;
                }
            }
        }

        .popup-content-box {
            padding: 30px 0;
            color: #435b75;

            h3 {
                font-size: 16px;
                color: #435b75;
                font-weight: 400;
            }
        }
    }

    .inputStyle2 {
        input {
            padding: 9px 10px 7px 13px;
            border: solid 1px #d2dae6;
        }

        .labelStyle {
            margin-bottom: 6px;
            font-weight: normal;
        }

        .react-tel-input {
            .form-control {
                padding-left: 45px;
            }
        }
    }
}

@media(max-width:1020px) {
    .profilePage {
        .mainWrap {
            padding-right: 10px;
        }

        .mainContent {
            .pageInternalScroll {
                >div {
                    padding-right: 0px;
                    overflow-x: hidden !important;
                }
            }
        }
    }
}

@media(min-width:992px) {
    .profilePage {
        .mainContent {
            padding-top: 55px;

            .pageInternalScroll {
                height: calc(100vh - 120px) !important;
            }
        }


    }
}

@media(max-width:992px) {
    .profilePage {
        .leftSideBar {
            width: 220px;
        }

        .member-info {
            .cancel-subscription {
                margin-top: 14px;
                margin-bottom: 10px;
            }
        }

        .profileCnt {
            padding-bottom: 10px;

            .profileImage {
                max-width: 160px;
                margin: 0 auto 10px;

                img {
                    max-width: 140px;
                    height: 140px;
                    width: 140px;

                }
            }
        }
    }
}

@media(max-width:768px) {
    .profilePage {
        .mainWrap {
            padding-right: 0px;
        }
    }
}

@media(max-width:400px) {

    .profilePage {
        .save-traveller-box {
            .top-content {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;

                .right-content {
                    margin-top: 22px;
                    text-align: right;
                }
            }
        }
    }
}