.siteBg .mainContent {
  padding-top: 55px;
}

video {
  max-width: 100%;
  object-fit: cover;
}

.whiteLink {
  color: #fff !important;
  text-decoration: underline !important;

  &:hover {
    opacity: 1 !important;
    text-decoration: none !important;
  }
}

.full-video-wrapper {
  .video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .video-box {
    border-radius: 10px;
    overflow: hidden;
    min-height: 520px;
    padding-bottom: 56.25%;
    position: relative;
  }

  video {
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
  }
}

.successMessage {
  color: #fafdfe;
}

.selectWrap {
  input {
    box-shadow: none !important;
  }

}

// full video wrapper end
.singlePrice {
  .priceDetailsWrapper {
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
}

.priceDetailsWrapper {

  .pricedetail-dropdown {
    width: 220px;
    padding: 9px 15px 12px 15px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.25);
    background-color: #eff0f5;
    position: absolute;
    top: calc(100%);
    left: 0;
    z-index: 1;
    display: none;

    h3 {
      @include ubuntu(500);
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      color: #435b75;
      margin-bottom: 14px;
    }

    h4 {
      font-size: 14px;
      @include ubuntu(400);
      line-height: normal;
      letter-spacing: normal;
      color: rgba(67, 91, 117, 0.6);
      margin-bottom: 9px;
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 500;
        color: #435b75;
      }
    }

    h5 {
      @include ubuntu(400);
      font-size: 12px;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(67, 91, 117, 0.6);
      margin-bottom: 14px;
      margin-top: -2px;
    }

    .total-amount {
      h4 {
        font-size: 17px;
      }
    }

    .saving-box {
      h4 {
        color: #00b7a6;
      }
    }

    .note {
      font-size: 12px;
      @include ubuntu(400);
      line-height: normal;
      letter-spacing: normal;
      color: #00b7a6;
      max-width: 147px;
    }

    .dropdown-content {
      border-bottom: 1px solid rgba(67, 91, 117, 0.2);
      // margin-bottom: 11px;
      // padding-bottom: 5px;
    }
  }
}

.priceDetails {
  margin-bottom: 4px;
  color: #1d6ec9;
  position: relative;
  display: inline-flex;
  cursor: pointer;

  &:hover,
  &:focus {
    .pricedetail-dropdown {
      display: block;
    }
  }

  h4 {
    font-size: 12px;
    font-weight: 400;
  }
}

.apple-wallet-wrap {
  display: flex;
  justify-content: center;
  max-width: 200px;
  margin: 0 auto;
  align-items: center;

  a {
    margin: 0 8px;
  }

  img {
    max-width: 97px;
    height: 31px;
  }
}

.not-found-content {
  display: flex;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  background-color: rgba(243, 244, 248, 0.8);
  vertical-align: middle;
  align-items: center;
  min-height: 390px;
  justify-content: center;
  margin: 15px 0;
  padding: 0 10px;
  color: #435b75;

  @media (max-width: 767px) {
    min-height: 280px;
  }

  h2 {
    color: #435b75;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
    color: #435b75;
  }

  .btn {
    min-width: 160px;
    display: block;
    max-width: 200px;
    margin: 10px auto;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #435b75;
  -webkit-box-shadow: 0 0 0px 1000px inherit inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: inherit;
}


.custom-select__menu,
.css-26l3qy-menu, .custom-month-year-wrapper [class*="menu"], .selectWrap  [class*="menu"] {
  border-radius: 10px !important;
  padding: 5px;
  z-index: 999 !important;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%) !important;
  border: solid 1px #d2d9e7 !important;
  background-color: #e6e7ee !important;

  .css-1n7v3ny-option,
  .css-9gakcf-option,
  .css-yt9ioa-option, [class*="option"] {
    background-color: transparent;
    color: #435b75;
    padding: 10px 10px;
    cursor: pointer;
    font-size: 17px;
    // font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

  }

  .css-yt9ioa-option,[class*="option"] {
    padding: 10px;
  }

  .css-9gakcf-option:active, [class*="option"]:active {
    background-color: transparent;
  }

  .css-yt9ioa-option:active, [class*="option"]:active {
    background-color: transparent;
  }

  .css-1n7v3ny-option:active, [class*="option"]:active {
    background-color: transparent;
  }


  [class*=option] :active {
    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
    border-color: #d2dae6;
    border-radius: 6px;
  }

  [class*=option]:hover {
    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
    border-color: #d2dae6;
    padding: 10px;
    border-radius: 6px;
    background-color: transparent;
  }
}

.GlobalPaymentIntegrationWrap {
  ul {
    list-style: none;
  }

  .billingDetailsToggle {
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(67, 91, 117, 0.2);

    @media (max-width: 600px) {
      border: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 5px;
      justify-content: center;
      // text-align: center;
      max-width: 480px;
      margin-bottom: 0px;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      li {
        max-width: 50%;
        flex: 0 0 50%;

        @media (max-width: 600px) {
          max-width: 100%;
          flex: 0 0 100%;
          padding-bottom: 12px;

          .active {
            border: none;
          }
        }

        a {
          font-weight: normal;
          color: rgba(67, 91, 117, 0.6);
          font-size: 16px;
          padding: 0 10px 5px;
        }

        .tablink {
          border-bottom: 2px solid transparent;
        }

        .active {
          border-bottom: 2px solid #1d6ec9;
          color: #1d6ec9;
          margin-bottom: -1px;
        }
      }
    }
  }

  .tabContent {
    display: none;
    padding: 0;
  }

  .activeTab {
    display: block;
  }

  .checkBoxContent {
    h6 {
      color: #435b75;
      font-size: 14px;
      font-weight: 400;
      margin: 6px 0 0;
    }

  }

  .checkBoxWrap .checkBoxContent .checkBoxContentWrap p {
    padding-left: 35px;
    margin-top: 3px;

    +p {
      margin-top: 8px !important;
    }
  }

  .wallet-icon-wrap {
    margin-left: 30px;
  }

  .wallet-icon {
    max-width: 55px;
    min-height: 28px;
    padding: 5px;
    min-width: 55px;
    background-color: #fff;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 18px;
    }
  }

  .walletOptionWrap {

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 13px;

      li {
        max-width: 20%;
        flex: 0 0 20%;
        padding: 5px 10px;

        @media (max-width: 767px) {
          max-width: 33.33%;
          flex: 0 0 33.33%;
          padding: 10px;
        }

        @media (max-width: 480px) {
          max-width: 50%;
          flex: 0 0 50%;
        }

        .customCheckbox>span:before {
          top: 4px;
        }

        .customCheckbox input:checked+span:after {
          top: 9px;
        }
      }
    }
  }
}

.custom-switcher {
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 16px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .switcher {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b7b7b7;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: #1d6ec9;
      -webkit-transition: .4s;
      transition: .4s;
    }

    &.round {
      border-radius: 34px;
    }

    &.round:before {
      border-radius: 50%;
    }
  }

  input:checked+.switcher {
    background-color: #99bdf9;
  }

  input:focus+.switcher {
    box-shadow: 0 0 1px #99bdf9;
  }

  input:checked+.switcher:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
}

// Responsive start

@media (max-width: 1199px) {
  .priceDetailsWrapper {
    .pricedetail-dropdown {
      right: 0;
      left: auto;
    }
  }
}

// 1199 end
@media (max-width: 991px) {
  .full-video-wrapper {
    .video-box {
      min-height: 350px;
    }
  }
}

// 991 end
@media (max-width: 767px) {
  .full-video-wrapper {
    .video-box {
      padding: 9px;
      border-radius: 10px;
      box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%),
        2px 2px 3px 0 rgb(0 0 0 / 15%);
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      height: 100%;
      min-height: 180px;
      max-width: 560px;
      margin: 0 auto;
    }

    .video-box-main-wrap {
      display: block;
      padding: 9px;
      border-radius: 8px;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
        inset -2px -2px 6px 0 #fff;
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      height: 200px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .video-box-wrap {
      position: relative;
      overflow: hidden;
      height: 180px;
    }

    video {
      border-radius: 4px;
    }
  }
}

.priceDetailsWrapper {
  .pricedetail-dropdown {
    width: 220px;
    padding: 9px 15px 12px 15px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.25);
    background-color: #eff0f5;
    position: absolute;
    top: calc(100%);
    left: 0;
    z-index: 1;
    display: none;


    .note {
      font-size: 12px;
      @include ubuntu(400);
      line-height: normal;
      letter-spacing: normal;
      color: #00b7a6;
      max-width: 147px;
    }

  }
}

.apple-wallet-wrap {
  display: flex;
  justify-content: center;
  max-width: 200px;
  margin: 0 auto;

  a {
    margin: 0 8px;
  }
}

#back-to-top {
  position: fixed;
  right: 10px;
  z-index: 999;
  bottom: 50px;
  display: none;

  .btn {
    background: none;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    line-height: 1;
    padding: 0;
    text-align: center;
    opacity: 1;
    background-color: #ffffff;
    background-image: linear-gradient(138deg, #0072d1, #00dcdf 100%);
    filter: alpha(opacity=70);
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    box-shadow: 0 1px 2px rgb(43 59 93 / 40%);
    min-width: auto;

    &::before {
      display: none;
    }

    svg {
      max-width: 14px;
      height: auto;
    }

    svg * {
      fill: #fff !important;
    }
  }
}

video {
  max-width: 100%;
  object-fit: cover;
}

.p-10 {
  padding-bottom: 10px !important;
}

@media (min-width: 900px) {
  #back-to-top {
    display: none;
  }
}

.cluster-marker {
  color: white;
  background-image: url(../../Assets/img/rectangle-copy.png);
  position: absolute;
  height: 45px;
  width: 77px;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 12px;
  align-self: center;
  // align-items: self-end;
  // font-size: 9.5px;
}

.hotelInfoWindow {
  width: 20vw;
  height: 10vh;
  background-color: #a2c9f3;
  position: absolute;
  z-index: 1;
}

.single-marker {
  color: white;
  background: url(../../Assets/img/rectangle-copy.png);
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  height: 47px;
  width: auto;
  min-width: 120px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 12px;
  align-self: center;
}

// .single-marker:active{
//   background-image: url(../../Assets/img/hotel/rectangle-copy.png);
// }

.active-single-marker {
  color: white;
  background-image: url(../../Assets/img/hotel/rectangle-copy.png);
  position: absolute;
  height: 45px;
  width: 80px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 12px;
  align-self: center;
  z-index: 1;
}

.invalid-feedback {
  position: absolute;
  top: 100%;
  color: red;
  font-size: 14px;
  padding-left: 10px;
  margin-top: 5px;
}

.cars-invalid-feedback {
  position: absolute;
  top: 100%;
  color: red;
  font-size: 14px;
  padding-left: 10px;
  margin-top: 2px;
}

.error-messages {
  font-size: 14px;
  color: red !important;
  -webkit-text-fill-color: red !important;
  margin: 4px 0px;

  ::placeholder {
    color: red !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red !important;
  }
}

.error-messages::placeholder {
  color: red !important;
}

.success-messages {
  color: green !important;
  margin-top: 8px;
  font-size: 14px;
}

.color-red, .red-text {
  color: red !important;
}

.opacity0 {
  visibility: hidden;
  opacity: 0;
}

// + add style
.addRoom {
  button {
    font-size: 14px;
    color: #1d6ec9;
  }
}

.select-user-label {
  display: none !important;
}

.custom-radio {
  label {
    color: rgba(67, 91, 117, 0.6);
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;

    input {
      margin-right: 3px;
    }
  }
}

.detail-hotel-url {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.blueBtn {
  border-radius: 6px;
  background-color: #1d6ec9;
  color: #fff;
  font-size: 14px;
  padding: 6px 14px;
  border: 0;
  min-width: 100px;
}

.react-tel-input {
  .form-control {
    background-image: none;
    background-color: rgba(255, 255, 255, 0.35);
    -webkit-text-fill-color: #435b75;
    opacity: 1;
    color: #435b75;
    border-color: transparent;
    border-radius: 10px;
    font-size: 17px;
    height: 100%;
    width: 100%;
    line-height: 1.1;
    height: 40px;
  }

  .flag-dropdown {
    background-color: transparent !important;
    border: 1px solid transparent;
    border-radius: 0;
    border-right: 1px solid rgba(67, 91, 117, .2);
  }

  .selected-flag {
    background: transparent !important;
    border-radius: 0 !important;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  .country-list .country .dial-code {
    color: #435b75;
  }
}

.selectWrap,
.selectWrap {
  .css-b62m3t-container {

    .css-1s2u09g-control,
    .css-1pahdxg-control {
      z-index: 1;
    }
  }
}

.InputContainer .InputElement {

  // color:yellow;
  @include placeholder-text {
    color: inherit;
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  &.icon-info {
    margin-left: 5px;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 115px;
  border-radius: 6px;
  box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
  background-color: #eff0f5;
  color: #fff;
  text-align: center;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  letter-spacing: normal;
  color: #435b75;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 5px;
  border-style: solid;
  border-color: #eff0f5 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.DateInput_input__disabled {
  background: transparent;
  font-style: normal;
}

.ril__outer {
  background-color: rgba(0, 0, 0, 1);

}

.ReactModal__Body--open {
  overflow: hidden !important;
}

.data-not-found {
  align-items: center;
  background: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: 15px 0;
  min-height: 250px;
  padding: 0 10px;
  vertical-align: middle;

  h2 {
    color: #435b75;
  }
}

input[type="email"],
input[type="tel"] {
  -webkit-appearance: none;
}

.custom-month-year-wrapper {
  display: flex;
  justify-content: center;
  margin-top: -10px;

  [class*="control"] {
    background: transparent;
    border: none;

    [class*="IndicatorsContainer"] {
      display: none;
    }

    [class*="singleValue"] {
      color: #435b75
    }
  }

  .css-b62m3t-container,[class*="container"] {

    [class*="control"],
    [class*="Input"],
    [class*="singleValue"] {
      background-color: transparent;
      border-radius: 0;
      border: none;
      color: #435b75;
      padding: 0;
      margin: 0;
      box-shadow: none;
    }

    [class*="indicatorSeparator"],
    [class*="indicatorContainer"],
    [class*="IndicatorsContainer"] {
      display: none;
    }
  }

  .css-26l3qy-menu, [class*="menu"] {
    min-width: 160px;
    margin-top: 30px;
  }
}

.google-recaptcha-wrap {
  margin-top: 10px;
}

.CookieConsent {
  position: relative;

  // background: linear-gradient(124deg, #006bbf 100%, var(--aqua-blue) 115%)!important;
  // box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
  // border: solid 1px #d2dae6;
  .cookies-content-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width:767px) {
    .cookies-content-wrap {
      flex-direction: column;

      .btn-accept {
        margin-top: 20px;
      }
    }

    #rcc-confirm-button {
      position: absolute;
      bottom: 26px;
      right: calc(50% - 220px);
      -webkit-transform: translateY(-50%);
      transform: translateY(50%)
    }
  }

  @media (max-width:500px) {
    .cookies-content-wrap {
      align-items: flex-start;
    }

    #rcc-confirm-button {
      right: auto;
      left: 210px;
    }
  }

  @media (max-width:374px) {
    font-size: 11px;

    button {
      font-size: 12px !important;
    }

    #rcc-confirm-button {
      min-width: 100px;
      left: 190px;
    }
  }
}

// Responsive CSS Start
@media (max-width: 1160px) {
  .pageInternalScroll {
    padding-right: 0px;
  }

  .listingHotel {
    .contentWrapper.row {
      margin: 0 -10px;
    }
  }
}


@media (max-width: 997px) {
  .pageInternalScroll {
    margin-right: 0px;

    >div {
      padding-right: 0px;
    }
  }
}

.listingHotel {
  .contentWrapper.row {
    margin: 0px -15px;
  }
}

@media(max-width:768px) {
  .cars-invalid-feedback {

    top: 70%;

  }
}

::-moz-placeholder {
  opacity: 1
}

.flight-sold-out-wrap {
  text-align: center;
  padding: 10px 0;
  max-width: 100%;
  flex: 0 0 100%;

  h2 {
    background: rgba(255, 86, 48, 0.05);
    border: 1px dashed #FF5630;
    border-radius: 4px;
    width: 130px;
    height: 35px;
    margin: 0 auto 8px;
    color: #FF5630;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #091E42;
    margin-bottom: 5px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    max-width: 236px;
    margin: 0 auto;
    text-align: center;
  }
}

.btn-style-removed {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

.mt-10 {
  margin-top: 10px;
}

.from-text-label {
  color: red !important;
}

.footer-wrap {
  .footer-new-links {
    color: rgba(67, 91, 117, 0.6);
    font-size: 12px;
    display: none;
  }


  .footCopyWrap {
    max-width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width:767px) {
      max-width: 100%;
      text-align: center;
    }
  }
}

.indexPageWrap {
  .footer-wrap {
    .footCopyWrap {
      min-height: 51px;

      @media (max-width:767px) {
        min-height: auto;
      }
    }

    .snsIcons {
      min-height: 35px;

      @media (max-width:767px) {
        min-height: auto;
        padding-bottom: 3px;
      }
    }

    .footer-new-links {
      display: inline-block;
    }
  }
}
.logo-image {
  img {
    max-width: 148px;
  }
}
.font-weight-700 {
  font-weight: 700 !important;
}
.freemium-member-price-text {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 6px;
  color: var(--text-green);
}
.home-join-free-btn {
  a {
    text-transform: uppercase;
    background-image: linear-gradient(90deg,#00d0d4 -12%,#3763d0 114%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    color: #ffffff;
    font-size: 24px;
    line-height: 27px;
    border-radius: 4px;
    min-width: 180px;
  }
}