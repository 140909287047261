.slide-show-desktop {
    display: block;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;




    .slick-next:before {
        padding: 0px;
        border-radius: 100px;
        content: "\f107";
        display: inline-block;
        position: absolute;
        font: normal normal normal 120px/1 FontAwesome;
        -webkit-font-smoothing: antialiased;
        color: rgb(255, 255, 255, 0.8);
        background-color: transparent;
        right: -22px;
        bottom: -135px;
        transform: rotate(180deg);
    }

    .slick-prev:before {
        padding: 0px;
        border-radius: 100px;
        content: "\f107";
        display: inline-block;
        position: absolute;
        font: normal normal normal 120px/1 FontAwesome;
        -webkit-font-smoothing: antialiased;
        color: rgb(255, 255, 255, 0.8);
        background-color: transparent;
        right: -114px;
        top: -47px;
        transform: rotate(90deg);
    }

    .slider-wrapper {
        width: 100%;
        max-width: 1400px;
        padding: 49px 0px;
        box-sizing: border-box;
        margin-right: auto;
        margin-left: auto;

    }

    .slider-images {
        height: 100%;
        margin: 0 auto;

        img {
            display: block;
            margin: 0 auto;
            max-width: 840px;
        }
    }



    .slick-slider {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
        position: relative;

        .slick-prev {
            left: 40px;

        }

        .slick-next {
            right: 40px;

        }
    }

    .slick-dots {
        position: absolute;
        bottom: -80px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        justify-content: center;
        margin: 0 -7px;

        li {
            padding: 0 7px;

            button {
                width: 12px;
                height: 12px;
                box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
                background-color: #aebdcd;
                border: none;
                outline: none;
                background-color: transparent;
                border-radius: 100%;
                text-indent: -99em;
                overflow: hidden;
            }

            button::before {
                display: none;
            }

        }

        li.slick-active {
            button {
                box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
                background-image: linear-gradient(to right, #3763d0 -12%, #00d0d4 114%);
            }


        }

    }
}



@media(max-width:1400px) {
    .slide-show-desktop {
        .slider-wrapper {
            max-width: 1200px;
            padding: 30px 0px;

        }

        .slick-dots {
            position: absolute;
            bottom: -65px;
        }
    }
}

@media (max-width:1200px) {

    .slide-show-desktop {
        .slider-wrapper {
            max-width: 1000px;

        }



        .slick-next:before {
            font: normal normal normal 80px/1 FontAwesome;
            right: -8px;
            bottom: -32px;

        }

        .slick-prev:before {

            font: normal normal normal 80px/1 FontAwesome;
            right: -18px;
            top: -27px;
        }
    }
}

@media(max-width:991px) {
    .slide-show-desktop {
        .slider-wrapper {
            max-width: 768px;

        }



        .slider-images {
            height: 100%;
            margin: 0 auto;
            max-width: auto;

            img {
                display: block;
                margin: 0 auto;
                max-width: 640px;
            }

        }

        .slick-next:before {
            font: normal normal normal 60px/1 FontAwesome;
            right: 0px;
            bottom: -0px;
            transform: rotate(180deg);
        }

        .slick-prev:before {
            font: normal normal normal 60px/1 FontAwesome;
            right: 9px;
            top: -20px;
            transform: rotate(90deg);
        }

        .slick-dots {
            position: absolute;
            bottom: -55px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
            justify-content: center;
            margin: 0 -2px;

            li {
                padding: 0 4px;
                width: 15px;
                height: 15px;

                button {
                    width: 8px;
                    height: 8px;
                    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
                    background-color: #aebdcd;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    border-radius: 100%;
                    text-indent: -99em;
                    overflow: hidden;
                }

                button::before {
                    display: none;
                }

            }

            li.slick-active {
                button {
                    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
                    background-image: linear-gradient(to right, #3763d0 -12%, #00d0d4 114%);
                }


            }

        }

    }
}

@media(max-width:767px) {
    .slide-show-desktop {
        display: none;
    }

    .slide-show-mobile {
        display: block;
        width: 100%;
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: auto;
        padding: 10px 0px;

        .mobile-slider-wrapper {
            width: 100%;
            max-width: 100%;
            padding: 0px 0px;
            box-sizing: border-box;
            margin-right: auto;
            margin-left: auto;

        }



        .slider-images {
            img {
                width: auto;
                margin: 0 auto;
                max-width: auto;
                max-height: 300px;
            }
        }

        .slick-dots {
            position: absolute;
            bottom: -25px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
            justify-content: center;
            margin: 0 -2px;

            li {
                padding: 0 4px;
                width: 8px;
                height: 8px;

                button {
                    width: 4px;
                    height: 4px;
                    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
                    background-color: #aebdcd;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    border-radius: 100%;
                    text-indent: -99em;
                    overflow: hidden;
                }

                button::before {
                    display: none;
                }

            }

            li.slick-active {
                button {
                    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
                    background-image: linear-gradient(to right, #3763d0 -12%, #00d0d4 114%);
                }


            }

        }

    }
}

@media(min-width:768px) {
    .slide-show-mobile {
        display: none;
    }
}