.flight-checkout {
    .mainWrap {
        padding-right: 0px;

        .card2 {
            padding: 17px 20px;

            .cardPaymentOption {
                .col {
                    margin-bottom: 13px;
                }

            }

        }

        .inputStyle {
            input {
                appearance: none !important;
                -webkit-appearance: none !important;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                border: none !important;
                -webkit-text-fill-color: #435b75 !important;
                -webkit-box-shadow: 0 0 0px 1000px inherit inset;
                transition: background-color 5000s ease-in-out 0s;
                font-size: 17px !important;
                background-image: none !important;
            }
        }

        .cardWalletInfo .customCheckbox input:checked+span:after {
            top: 1px;
            left: 5px;
        }

        .primaryInfo {
            .primaryInfoTitle {
                display: flex;
                justify-content: space-between;
                padding-bottom: 10px;

                @media (max-width:479px) {
                    display: block;
                    padding-bottom: 0;

                    .select-user-label {
                        display: block !important;
                    }
                }

                .selectWrap {
                    color: #1d6ec9;
                    font-size: 14px;
                    min-height: 40px;
                    min-width: 180px;
                }
            }
        }
    }

    .selectInWrap {
        margin-bottom: 14px;

        .col {
            padding: 0px 0px;
        }
    }

    .billingInfo {
        .sectionTitle {
            margin-bottom: 25px;
        }

        .cardDetailsBox {
            input {
                height: 40px;
            }
        }
    }

    .selectWrap {
        [class*=indicatorContainer] {
            padding: 5px;
        }


        [class*=control] {
            min-width: 100%;
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgba(255 255 255 / 85%), 2px 2px 3px 0 rgba(0 0 0 / 15%);
            -webkit-box-shadow: -2px -2px 4px 0 rgba(255 255 255 / 85%), 2px 2px 3px 0 rgba(0 0 0 / 15%);
            -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px rgba(210, 218, 230, 0.6);
            background-image: none;
            background-color: rgba(255, 255, 255, 0.35);
            min-height: 38px;
            z-index: 1;
            font-size: 17px;
            color: rgba(67, 91, 117, 0.6);
            cursor: pointer;

            >div {
                padding: 0px 8px;
                margin-top: 0;
            }

            input {
                height: auto;
            }

            [class*=singleValue] {
                color: #435b75;
                margin: 0;
            }

            [class*=ValueContainer] {
                padding: 0px 8px;
                margin-top: 0;
            }

            [class*=Input] {
                margin: 0px;
                padding: 0;
            }

            .css-yt9ioa-option,
            .css-1n7v3ny-option {
                cursor: pointer;
            }
        }

    }

    .primaryInfo {
        input {
            background-image: none;
            padding: 9px 15px 10px;
            color: #435b75;
            min-width: auto;
            height: 40px;
        }

        .col3 {
            .col:first-child {
                padding-left: 0px;
            }

            .col:last-child {
                padding-right: 0px;
            }


        }

        .col {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
            padding-right: 7.5px;
            width: 100%;
        }
    }

    .roomsInfo {
        .profileTitle2 {
            width: 190px;
        }

        .inputStyle {
            input {
                background-image: none;
                padding: 9px 15px;
                color: #435b75;
                min-width: auto;
                height: 40px;
            }
        }

        .new-lead-roomss-row {
            display: flex;
            justify-content: flex-start;

            .wrapper-padding {
                padding: 0px 7.5px;
                width: 33.33%;

                .col {
                    padding: 0px 0px;
                }
            }

            .col-room {
                padding: 0px;
                width: 33.33%;
                -ms-flex-preferred-size: 0;
                flex-basis: 0;
                -ms-flex-positive: 1;
                flex-grow: 1;
                max-width: 100%;

            }

            .single-date-picker-wrap {
                display: flex;

                .month-selecter {
                    [class*=MenuList] {
                        max-height: 200px;
                        overflow-y: auto;
                    }
                }

                .SingleDatePicker {
                    display: flex;
                    width: 100%;

                    >div {
                        width: 100%;
                    }
                }

                .SingleDatePickerInput {
                    padding: 7px 15px 7px;
                    font-size: 17px;
                    color: #1d6ec9;
                    background: rgba(255, 255, 255, 0.35);
                    border-radius: 10px;
                    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                    -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                    background-image: none;
                    min-width: 100%;
                    border-color: transparent;
                    height: 40px;
                }

                .DateInput_input {
                    background-color: transparent;
                    padding: 0px 7px;
                }
            }
        }
    }

    .sideBar {
        .flightInfo-1 {
            padding: 0px 10px;
        }

        .flightInfoRow {
            padding: 0px 10px;
        }

        .flightInfoRow {
            border-top: 1px solid #afd3e3;
            padding-top: 35px;
            margin-top: 28px;
        }

    }

    .GlobalPaymentIntegrationWrap {
        .billingDetailsToggle ul {

            margin: 0px;
            padding-bottom: 5px;

        }

        .checkBoxWrap {
            .checkBoxContent {
                .checkBoxContentWrap p {
                    padding-left: 29px;
                    margin-top: 5px;

                    @media (max-width:580px) {
                        margin-top: 0;
                    }
                }
            }
        }

        .customCheckbox>span:before {
            height: 24px;
            width: 24px;
        }

        .boxTitle {
            margin-bottom: 10px;
        }

        .sectionTitle {
            margin-bottom: 19px;
        }

        .cardDetailsBox {
            .col {
                padding: 0 7.5px;
                -ms-flex-preferred-size: 0;
                flex-basis: 0;
                -ms-flex-positive: 1;
                flex-grow: 1;
                max-width: 100%;
                margin-bottom: 0 !important;

                @media(max-width:767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                @media(max-width:374px) {
                    padding-bottom: 12.5px;
                }
            }

            .cardHolder {
                max-width: 245px;

                @media(max-width:767px) {
                    max-width: 100%;
                    padding-bottom: 12.5px;
                }

                input {
                    min-width: 100%;
                }
            }
        }

        .walletOptionWrap {
            min-height: 77px;
        }
    }

    .buttonWrap {
        .icon-lock {
            margin-right: 10px;
        }
    }

    .SingleDatePicker,
    .SingleDatePicker_picker {
        .SingleDatePickerInput {
            display: flex;
        }

        input {
            font-weight: 400;
            color: #435b75;
            font-size: 17px;

            @include placeholder-text {
                font-weight: 400;
                color: #7d92a9;
            }
        }

        .DateInput {
            width: 100%;
        }

        select {
            border: transparent;
            background-color: transparent;
        }
    }

    .roomsInfo .new-lead-roomss-row .single-date-picker-wrap .SingleDatePickerInput {
        height: 38px;
    }

    .genderBtnGroup {
        .button-group-wrapper {
            display: flex;

            .genderBtn {
                max-width: 50%;
                flex: 0 0 50%;
            }
        }
    }

    .checkBoxWrap {
        .checkboxDoubleWrap {
            margin-top: 15px;

            .checkBoxContent {
                margin-bottom: 20px;
            }

            .btn-confirm {
                background-color: transparent;
                border: none;
                box-shadow: none;
                margin-left: 4px;
                color: #1d6ec9;
            }
        }
    }

}

.flightDetailPopup {
    position: fixed;
    right: 0;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background-color: rgba(67, 91, 117, 0.6);
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .popupTitle {
        border-bottom: solid 1px #cfd1e1;
        padding: 0 0 10px 5px;
        margin: 0 10px;
    }

    .popupCloseBtn {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 9px;
        background-color: transparent;
        border: none;
        color: #1d6ec9;

        .icon-close {
            &::before {
                color: #1d6ec9;
            }
        }
    }

    .card2 {
        background-color: transparent;
        box-shadow: none;
        font-size: 14px;
        color: var(--text-color);
    }

    .popUpBox {
        max-width: 90%;
        margin: 30px auto;
        border-radius: 10px 10px;
        background-color: #cce4f3;
        position: relative;
        padding: 25px 0;

        .valueStyle {
            [class*="icon"] {
                margin-right: 4px;
            }
        }

        .flightInfoRow {
            border-top: solid 1px #cfd1e1;
            padding-top: 30px;
        }

        .flightStartEnd {
            display: flex;
            align-items: center;
            flex-grow: 1;
            justify-content: space-between;
            padding: 0 2px 0 0px;
            width: 100%;
            margin-bottom: 22px;
        }

        .flightStartInfo {
            width: 72px;
        }

        .flightLocation,
        .flgithDate {
            display: block;
            font-size: 12px;
            color: var(--text-color4);
        }

        .flightTime {
            font-size: 15px;
            font-weight: normal;
            color: var(--text-color);
            margin-bottom: 2px;
            margin-top: 10px;
        }

        .flightDurNstop {
            flex-grow: 1;
            text-align: center;
            position: relative;
            margin-left: 0;
            margin-right: 11px;
        }

        .flightDuration {
            border-radius: 15px;
            box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            border: solid 1px rgba(210, 218, 230, 0.6);
            background-color: rgba(255, 255, 255, 0.35);
            color: var(--text-color3);
            padding: 3px 10px;
            display: inline-flex;
            align-items: center;
            box-sizing: border-box;
            position: relative;
            top: -11px;

            .icon-timer {
                margin-right: 5px;
            }
        }

        .flightBar {
            margin-bottom: 9px;
            height: 2px;
            border-radius: 3px;
            box-shadow: -1px -1px 2px 0 #ffffff, 1px 1px 2px 0 rgb(0 0 0 / 30%);
            background-color: #7e94a8;
            border: 0;
            position: relative;
            text-align: center;

            .fa-circle {
                top: -10px;
                color: #1d6ec9;
                font-size: 10px;
                margin: 0 22px;
                position: relative;
            }

            .icon-flight {
                right: -20px;
                font-size: 19px;
                top: -8px;
                position: absolute;
                text-shadow: -2px -2px 1px rgb(255 255 255 / 50%);

                *:before {
                    color: #7e94a8;
                }
            }
        }

        .flightEndInfo {
            width: 75px;
            text-align: right;
            position: relative;
        }

        .card2 {
            padding: 0;
        }

        .popupScrollBox {
            padding: 0 15px;
            max-height: 80vh;
            overflow-y: auto;
        }

        .flgithDate {
            display: block;
        }

        .labelNvalue {
            padding-bottom: 22px;

            .valueStyle {
                .arrow {
                    margin: 0 3px;
                }
            }
        }

        .icon-person .path1:before,
        .icon-flight .path1:before {
            color: #7d92a9;
        }

        .valueStyle {
            [class*="icon"] {
                &::before {
                    color: #7d92a9;
                }
            }
        }
    }

    .flightLogo {
        padding-bottom: 20px;
        padding-top: 7px;

        img {
            max-height: 80px;
        }
    }

    .popupTitle {
        border-bottom: solid 1px #cfd1e1;
        padding: 0 0 10px 5px;
        margin: 0 10px;
        font-size: 23px;
        color: var(--text-color);
    }

    .flgithStops.nonStopFlight {
        color: var(--text-green);
    }

    .shadowBox {
        border-radius: 8px;
        box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
        border: solid 1px #dae2ee;
        background-color: rgba(255, 255, 255, 0.35);
        margin: 3px -6px 0;
        padding: 17px 15px 8px;

        .rightValue {
            font-size: 17px;
            color: var(--text-color);
        }

        .dflex {
            padding-bottom: 6px;
        }

        .labelStyle {
            margin-bottom: 0;
        }

        .totalValueBox {
            border-top: 1px solid #afd3e3;
            padding-top: 11px;
            margin-top: 7px;

            .rightValue {
                font-size: 21px;
                font-weight: 500;
            }
        }
    }
}

.flight-baggage-info {
    .row {
        margin-left: -15px !important;
        margin-right: -15px !important;

        .col-6 {
            max-width: 50%;
            padding: 0 15px;

            @media (max-width:680px) {
                max-width: 100%;

                +.col {
                    margin-top: 25px;
                }
            }
        }
    }

    .box-style {
        background: rgba(229, 243, 250, 1);
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25) inset;
        border-radius: 4px;
        min-height: 136px;
        padding: 24px;
    }

    h4 {
        color: rgba(80, 95, 121, 1);
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 13px;
        font-weight: 400;
        // letter-spacing: 1px;
        display: flex;
        align-items: center;

        img {
            margin-right: 12px;
        }
    }

    p {
        color: rgba(116, 135, 157, 1);
        font-size: 12px;
        line-height: 14px;
        margin-top: 5px;
    }

    .content-box {
        background-color: rgba(236, 241, 237, 1);
        border-radius: 4px;
        padding: 20px;
        margin-top: 20px;
    }
}

.flight-penalty-info {
    h3 {
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        text-transform: uppercase;
        color: rgba(80, 95, 121, 1);
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    .table-wrap {
        +.table-wrap {
            margin-top: 25px;
            margin-bottom: 8px;
        }

        @media (max-width:767px) {

            overflow-x: auto;
            .table-content {
                display: block;
                width: 100%;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar;

                table {
                    border-collapse: collapse;

                    th,
                    td {
                        padding: 8px;
                        font-size: 12px;
                        line-height: 14px;
                        min-width: 100px;
                    }
                }
            }
        }
    }

    table {
        width: 100%;
        border: 1px solid #AACCDD;
        border-radius: 4px;

        th {
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            color: #74879D;
            text-align: center;
            padding: 13px 16px;
            border-bottom: 1px solid #AACCDD;
            border-right: 1px solid #AACCDD;
        }

        td {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #435b75;
            text-align: left;
            padding: 13px 16px;
            border-bottom: 1px solid #AACCDD;
            border-right: 1px solid #AACCDD;
        }
    }
}

.flight-free-cancelation {

    .content {
        padding: 20px 14px;
    }

    h4 {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        margin-bottom: 20px;
        justify-content: center;
        img {
            margin-right: 12px;
        }
    }

    p {
        font-size: 12px;
        line-height: 14px;
        color: #74879D;
        font-weight: 400;
    }
}


// Responsive css end
@media(min-width:992px) {

    .flight-checkout {
        .mainContent {
            padding-top: 55px;

            .pageInternalScroll {
                padding-top: 0px;
                height: calc(100vh - 120px) !important;
                overflow-y: auto;
                padding-bottom: 0;
                overflow-x: hidden;
            }
        }


    }
}

@media(max-width:991px) {
    .flight-checkout {

        .mainWrap {
            padding-right: 0px;
        }

        .mainContent {
            padding-top: 55px;
        }

        .primaryInfo {
            .dropdown {
                margin-bottom: 14px;
            }
        }

        .col3 {
            .col {
                padding: 0px 0px;
            }
        }

        .cardPaymentOption {
            .formGroup {
                margin-bottom: 14px;
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

@media(max-width:767px) {
    .flight-checkout {
        .mainWrap {
            width: 100%;
            padding-right: 0px;
        }

        .roomsInfo {

            .new-lead-roomss-row.mobile-view-room {
                display: flex;
                flex-direction: row;

                .wrapper-padding.profileTitle2 {
                    width: 190px;
                    margin-bottom: 14px;
                }

                .wrapper-padding {
                    padding: 0px 7.5px;
                    width: auto;
                    margin-bottom: 0px;

                    .selectInWrap {
                        max-width: 190px;
                    }
                }

                .mobile-view-width {
                    width: 100%;
                }
            }

            .profileTitle2 {
                margin-bottom: 14px;

            }

            .new-lead-roomss-row {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .wrapper-padding {
                    padding: 0px 7.5px;
                    width: 100%;
                    margin-bottom: 14px;
                }

                .col-room {
                    width: 100%;

                    .col {
                        margin-bottom: 14px;
                    }
                }
            }
        }

        .roomsInfo .SingleDatePicker_picker {
            left: -25px !important;
            right: 0;
        }

        .primaryInfo {
            .fName {
                max-width: 100%;
                flex: 0 0 100%;
            }

            .col {
                flex: 0 0 100%;
                padding-bottom: 12.5px !important;
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .flight-checkout {
        .container.dflex {
            flex-direction: column;
        }

        .mainWrap {
            order: 1;
            width: 100%;
        }

        .sideBar {
            order: 0;
            margin: 0 0 6px;
            max-width: 100%;
            width: 100%;
        }

        .roomsInfo .SingleDatePicker_picker {
            left: auto !important;
            right: 0;
        }
    }
}

@media (max-width: 420px) {
    .flight-checkout {
        .roomsInfo {
            .new-lead-roomss-row.mobile-view-room {
                .col {
                    max-width: 100%;
                    flex: 0 0 100%;
                    width: 100%;
                    margin-bottom: 14px;
                }
            }
        }
    }
}

@media (max-width: 359px) {

    .flight-checkout {
        .card2 {
            .row {
                // margin: 0px;
            }

            .primaryInfo {
                .col {
                    padding-right: 0 !important;
                    padding-left: 0px;
                }

                .dropdown {
                    max-width: 100%;
                    flex: 0 0 100%;

                }
            }
        }

        .cardWalletInfo {
            .boxTitle {
                small {
                    font-size: 16px;
                }
            }
        }

    }
}