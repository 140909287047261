.checkoutPageWrap {
    background-image: url(../../../img/widget-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: repeat-y;
}
.InputElement.Input::placeholder {
    background: red !important;
}
.siteBg.checkoutPageWrap {
    
    // checkoutpage internalscroll bar 
    .mainContent {
        padding-bottom: 0px;


        .container {
            .checkoutWrapper {

                // common style for select drowpdown
                form {
                    .selectWrap {
                        margin-top: 9.5px;

                        [class*="control"] {
                            min-width: 100%;
                            border-radius: 10px;
                            box-shadow: -2px -2px 4px 0 rgba(255 255 255 / 85%), 2px 2px 3px 0 rgba(0 0 0 / 15%);
                            -webkit-box-shadow: -2px -2px 4px 0 rgba(255 255 255 / 85%), 2px 2px 3px 0 rgba(0 0 0 / 15%);
                            -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                            border: solid 1px rgba(210, 218, 230, 0.6);
                            background-image: none;
                            background-color: rgba(255, 255, 255, 0.35);
                            min-height: 36px;
                            font-size: 17px;
                            z-index: 0;
                            color: rgba(67, 91, 117, 0.6);
                            line-height: 17px;
                            cursor: pointer;

                            [class*="singleValue"] {
                                color: #435b75;

                            }

                            [class*="indicatorContainer"] {
                                padding: 0px 8px;
                            }



                            // .css-1hb7zxy-IndicatorsContainer {
                            //     display: none;
                            // }

                            [class*="ValueContainer"] {
                                padding: 0px 8px;
                                margin-top: -4px;
                            }

                            .css-yt9ioa-option,
                            .css-1n7v3ny-option {
                                cursor: pointer;
                            }
                        }
                    }

                    .customCheckbox {
                        >span {
                            &:before {
                                height: 22px;
                                width: 22px;

                            }
                        }
                    }
                }
            }
        }
    }

    .GlobalPaymentIntegrationWrap {
        .cardPaymentOption {
            .stripe-card {
                display: block;
                align-items: center;
                background-color: rgba(255, 255, 255, 0.35);
                border-radius: 10px;
                border: 1px solid #dae2ee;
                box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                width: 100%;
                padding: 10px 30px 11px 10px;
                box-sizing: border-box;
                min-height: 40px;
                font-size: 17px;


                .ElementsApp {
                    input {
                        font-size: 17px;
                    }
                }


            }




        }
    }
}

.hotel-checkout {
    .add-traveller-button  {
        justify-content: flex-end !important;
    }
    .child-group-wrap {
        margin-top: 20px;
    }
    .section-lead-main-row {
        .row {
            margin: 0 0 10px;
        }

        .section-lead-col {
            max-width: 33.33%;
            flex: 0 0 33.33%;
            padding: 0;

            @media(max-width:767px) {
                max-width: 50% !important;
                flex: 0 0 50% !important;
            }

            @media(max-width:479px) {
                max-width: 100% !important;
                flex: 0 0 100% !important;
            }
        }
    }

    .card2 {
        .dropdown .selectWrap:after {
            background: none;
        }

        .customCheckbox input:checked+span:after {
            left: 5px;
            top: 5px;
        }
    }

    .cardWalletInfo {
        .customCheckbox input:checked+span:after {
            left: 5px;
            top: 1px;
        }
    }

    .card-primary-contact {
        .sectionTitle {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;

            @media (max-width: 479px) {
                display: block;
                padding-bottom: 0;

                .select-user-label {
                    display: block !important;
                }
            }

            .selectWrap {
                color: #1d6ec9;
                font-size: 14px;
                min-height: 30px;
                min-width: 180px;
            }
        }
    }

    .rightContent {
        .amountCalculation {
            .payWrapper {

                .savings {
                    margin-bottom: 10px;
                }

                .tooltip-wrap {
                    position: relative;

                    .pricedetail-dropdown {
                        width: 250px;

                        .dropdown-info {
                            h4 {
                                span {
                                    min-width: 100px;
                                }
                            }
                        }
                    }

                    img {
                        cursor: pointer;
                    }

                    &:hover {
                        .pricedetail-dropdown {
                            display: block;
                            left: -50px;
                        }
                    }

                }

                .pricedetail-dropdown {
                    .note {
                        p {
                            color: inherit;
                            margin: 0;
                        }
                    }

                    .dropdown-info {
                        h4 {
                            font-size: 12px;
                            font-family: "Ubuntu", sans-serif;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: normal;
                            color: rgba(67, 91, 117, 0.6);
                            margin-bottom: 9px;
                            display: flex;
                            justify-content: space-between;
                        }
                    }

                    .resortFeessection {
                        p {
                            font-size: 10px;
                        }
                    }
                }

                .pay-deduction {
                    border-top: 1px solid rgba(67, 91, 117, 0.2);
                    margin-top: 0px;
                    padding-top: 10px;
                }
            }


        }
    }
}

@media (min-width: 992px) and (max-width: 1120px) {
    .hotel-checkout {
        .cardWalletInfo {
            .input-field h5 {
                min-height: 32px;
            }

            .wallet-amount-with-button {
                .row {
                    align-items: flex-end;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .checkoutPageWrap {
        .checkoutWrapper {
            .leftContent {
                order: 1;
                flex: 100%;
                max-width: 100%;
            }

            .leftContent,
            .rightContent {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }
}


@media (max-width: 767px) {
    .checkoutPageWrap {
        .checkoutWrapper {
            .row {
                .col {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding: 0 0 12.5px;
                }
            }
        }

        .mobile-popup-bar {
            margin: 10px 10px 15px;
        }
    }

    .hotel-checkout {
        .checkoutWrapper {
            .email-section {
                display: block;
            }
        }
    }
}

@media(min-width:992px) {

    .checkoutPageWrap.hotel-checkout {
        .mainContent {
            .pageInternalScroll {
                padding-top: 0px;
                height: calc(100vh - 120px) !important;
                overflow-y: auto;
                padding-bottom: 0;
                overflow-x: hidden;
            }
        }

        .checkoutWrapper {
            padding-top: 20px;
            padding-bottom: 30px;
        }
    }
}