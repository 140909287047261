/* color variable classes */
$blueText-1: #1d6ec9;

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?xf3x1y");
  src: url("../fonts/icomoon.eot?xf3x1y#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?xf3x1y") format("truetype"),
    url("../fonts/icomoon.woff?xf3x1y") format("woff"),
    url("../fonts/icomoon.svg?xf3x1y#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap");

@mixin ubuntu($weight) {
  font-family: "Ubuntu", sans-serif;
  font-weight: $weight;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* global css start */

/*color-classes*/
$white: #ffffff;
$persian-green: #00b7a6;

@mixin background($imgpath,
  $position: center center,
  $repeat: no-repeat,
  $size: cover) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
    size: $size;
  }
}

@mixin placeholder-text {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-o-placeholder {
    @content;
  }

  &::-ms-input-placeholder {
    @content;
  }
}

img,
svg {
  max-width: 100%;
}

.lightBlueBg {
  background-color: #99d7ff;
}

.d-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.row {
  @extend .d-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.col {
  padding: 0 15px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  >.col,
  >[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.align-items-between {
  -ms-flex-pack: justify;
  align-items: space-between;
}

.align-items-center {
  -ms-flex-pack: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.px-5 {
  padding: 0 4.5px;
}

.pr-0 {
  padding-right: 0;
}

.m-0 {
  margin: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.whiteText {
  color: $white;
}

.textRight {
  text-align: right;
}

.text-center {
  text-align: center;
}

.d-block {
  display: block;
}

.customShadow {
  border-radius: 6px;
  box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
    inset -2px -2px 6px 0 #ffffff;
  border: solid 1px #dae2ee;
  background-color: rgba(255, 255, 255, 0.35);
  padding: 8px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-moz-range-track,
input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #1d6ec9, 0px 0px 1px #1d6ec9;
  background: #1d6ec9;
  border-radius: 15px;
  border: 0.2px solid #1d6ec9;
}

input[type="number"] {
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none;
    margin: 0 !important; 
    display: none !important;
    opacity: 0;
}
.desktopViewDisplayNone,
.tabletViewDisplayBlock {
  display: none;
}

// .bgImg {
//   @include background("");
// }

.pageWrap.pageWrapInner {
  position: inherit;
  top: inherit;
  left: inherit;
  bottom: inherit;
  right: inherit;
  overflow: hidden;
}

.inputStyle {
  input {
    text-overflow: ellipsis;
  }
}

.nightlyPriceBox {
  display: none;
}

.dnone,
.desktop-hide {
  display: none;
}

.dblock {
  display: block;
}

.min-height-100vh {
  min-height: 100vh;
}

.mCSB_scrollTools {
  width: 10px;
}

.mCSB_inside>.mCSB_container {
  margin-right: 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 10px;
  border-radius: 0;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 10px;
  border-radius: 0;
}

.white-icon {
  .icon-white {
    path {
      fill: #fff !important;
      filter: drop-shadow(0px 80px 70px rgba(0, 0, 0, 0.452));
    }
  }
}
h1,h2,h3,h4,h5,h6, p {
  margin-bottom: 0;
  color: inherit;
}
sub {
  bottom: 0;
  line-height: inherit;
}
ol,ul {
  margin-bottom: 0;
}