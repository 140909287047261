.paginationWrapper {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 992px) {
  .siteBg.listingPageWrap .paginationWrapper {
    padding-bottom: 70px;
  }
}

.paginationWrapper {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }

  .pagination {
    li {
      padding: 0 0px 0px;

      &:first-child,
      &:last-child {
        span {
          min-width: 90px;
        }
      }

      span {
        min-width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 35px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.4);
        font-size: 15px;
        color: #1d6ec9;

        &.active {
          background-color: #1d6ec9;
          color: $white;
          border: solid 1px rgba(198, 204, 225, 0.4);
        }
      }

      .disabled {
        color: rgba(67, 91, 117, 0.6);
      }
    }
  }

  .results {
    li {
      color: $white;
      padding: 0 5px;
      font-size: 14px;
    }

    span {
      min-width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 35px;
      border-radius: 8px;
      font-size: 14px;
      color: $white;
      cursor: pointer;


      &.active {
        border: 1px solid $white;
        color: $white;
      }
    }
  }
}

// React Paginate css

.pagi {
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  color: #1d6ec9;
  margin: 2px;
  cursor: pointer;

  a {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1d6ec9;

    &:hover {
      background-color: #1d6ec9;
      color: $white;
    }
  }

  &.pagiActive {
    background-color: #1d6ec9;
    color: $white;
    border: solid 1px rgba(198, 204, 225, 0.4);

    a {
      background-color: #1d6ec9;
      color: $white;
    }
  }

  &:hover {
    background-color: #1d6ec9;
    color: $white;
    border: solid 1px rgba(198, 204, 225, 0.4);
  }
}

.pagiPrevNext {
  padding: 7px;
  padding: 10px;
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  color: #1d6ec9;
  margin: 2px;
  cursor: pointer;

  a {
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
    color: #1d6ec9;
    &:hover {
      color:$white;
    }
  }

  &:hover {
    background-color: #1d6ec9;
    color: $white;
    border: solid 1px rgba(198, 204, 225, 0.4);
  }

  // Add css for disabled button next & previous

  &.pagiDisabled {
    color: #464343;
    background-color: #e6e7ee;
    border: solid 1px rgba(198, 204, 225, 0.4);
  }
}

// end  react paginate css //

// listingpage paginationwrapper
.siteBg.listingPageWrap {
  .paginationWrapper {
    padding-top: 70px;

    @media (max-width: 991px) {
      padding-top: 20px;
    }
  }
}

@media (max-width: 767px) {
  .siteBg.listingPageWrap {
    .paginationWrapper {
      padding-top: 0px;
    }
  }
}