.academy-wrapper {
    .container {
        .rightContent-bottom {
            padding-bottom: 30px;
        }
    }

    .leftContent {
        max-width: 24.2%;
        flex: 0 0 24.2%;

        .tabsWrapper {
            ul {
                li {
                    max-width: 240px;
                    padding-bottom: 4px;
                }

                li.active a {

                    color: #1d6ec9;
                    background-color: rgba(255, 255, 255, 0.6);
                    border-radius: 25.5px;

                }

                a {
                    font-size: 16px;
                    font-weight: normal;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    padding: 12px 15px;
                    border-radius: 25.5px;

                    img {
                        width: 32px;
                        height: 30px;
                    }
                }


            }
        }
    }

    .faqWrapper {
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
        background-color: rgba(255, 255, 255, 0.8);
        margin-bottom: 5px;
        margin-top: 70px;
    }

    .card3 {
        height: 260px;
        width: 100%;
        background-color: none;
        border-radius: 10px;
        background-image: url("../../../img/premium-sign-up/upgrade-now-wrapper-bg.png ");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-size: 100% 100%;
        padding: 35px 30px 47px;
        margin: 40px 0;
        color: #ffffff;

        .no-gutters {
            position: relative;

            .leftContent {
                max-width: 100%;
                flex: 0 0 100%;

                .container {
                    max-width: 60 0px;
                    padding: 0;
                    margin: 0;
                }

                h1 {
                    font-size: 20px;
                    line-height: 23px;
                    font-weight: normal;
                    color: #ffffff;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 15px;
                    line-height: 20px;
                    margin-bottom: 10px;

                    span {
                        display: block;
                        margin-bottom: 10px;
                    }
                }

                .content-wrapper {
                    margin-bottom: 40px;
                }

                .text-right {
                    text-align: right;

                    p {
                        font-size: 12px;
                        line-height: 16px;
                        margin-bottom: 5px;
                    }
                }


            }

            .rightContent {
                position: absolute;
                right: 0px;
                top: -10px;

                img {
                    width: 60px;
                }




            }
        }
    }
}

@media(max-width:1100px) {
    .academy-wrapper {
        .card3 {
            height: 260px;
            padding: 35px 30px 47px;

            .no-gutters {
                .leftContent {
                    .content-wrapper {
                        h1 {
                            font-size: 17px;
                            line-height: 20px;
                            font-weight: normal;
                            color: #ffffff;
                            margin-bottom: 15px;
                        }

                        p {
                            max-width: 700px;
                            font-size: 14px;
                            line-height: 28px;
                            margin-bottom: 9px;

                            span {
                                display: inline;
                            }
                        }
                    }

                    .content-wrapper {
                        margin-bottom: 30px;
                    }
                }
            }

        }
    }
}

@media (max-width: 991px) {
    .academy-wrapper {
        .card3 {
            margin: 20px 0px;
            padding: 40px 40px 47px;

            .no-gutters {
                .leftContent {
                    .content-wrapper {
                        p {
                            max-width: 650px;
                            line-height: 20px;

                            span {
                                display: inline;

                            }
                        }
                    }

                }

                .rightContent {
                    right: -7px;
                    top: -16px;


                    img {
                        width: 45px;
                    }
                }
            }

        }

        .leftContent {
            .tabsWrapper {

                ul {
                    li {
                        max-width: 230px;
                        flex: 0 0 230px;
                    }
                }
            }

        }

        .faqWrapper {
            margin-top: 20px;
        }
    }
}

@media (max-width: 767px) {
    .academy-wrapper {
        .card3 {
            padding: 40px 40px 50px;
            margin: 20px 0px;
            background-image: url("../../../img/premium-sign-up/upgrade-now-wrapper-mobilebg.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;
            height: auto;

            .no-gutters {
                .leftContent {
                    .content-wrapper {
                        p {
                            max-width: auto;

                            span {
                                display: inline;

                            }
                        }
                    }

                    .text-right {
                        text-align: center;

                        p {
                            font-size: 10px;
                            line-height: 12px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }

        .leftContent {
            .tabsWrapper {
                ul {

                    li {
                        max-width: 190px;
                        flex: 0 0 190px;

                        a {
                            font-size: 14px;
                            padding: 10px 10px;

                            img {
                                width: 28px;
                                height: 25px;
                            }
                        }
                    }
                }
            }

            p {
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 10px;

                span {
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }
        }

        .faqWrapper {
            padding: 15px;

        }
    }
}