//   home-page accordion button style
.bannerSec {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 80px;

  .bannerImg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  h3 {
    font-size: 30px;
    font-weight: normal;
    padding-bottom: 10px;
    color: inherit;
  }

  p {
    font-size: 17px;
    padding-bottom: 20px;
    color: inherit;
  }

  .bannerCont,
  .header {
    position: relative;
    z-index: 1;

    .logo-sticky {
      display: none;
    }
  }

  .bannerCont {
    color: #fff;
  }

  .scrollBtm {
    background-image: linear-gradient(92deg, #0072d1, #00dcdf);
    border: 0;
    display: block;
    text-align: center;
    width: 100vw;
    color: #fff;
    padding: 9px;
    font-size: 28px;
    cursor: pointer;

    &:hover {
      background-image: linear-gradient(92deg, #00dcdf, #0072d1);
    }

    .mousey {
      width: 3px;
      padding: 10px 10px;
      height: 15px;
      border: 2px solid #fff;
      border-radius: 25px;
      opacity: 0.75;
      box-sizing: content-box;
    }

    .scroller {
      width: 3px;
      height: 10px;
      border-radius: 25%;
      background-color: #fff;
      animation-name: scroll;
      animation-duration: 2.2s;
      animation-timing-function: cubic-bezier(.15, .41, .69, .94);
      animation-iteration-count: infinite;
    }

    @keyframes scroll {
      0% {
        opacity: 0;
      }

      10% {
        transform: translateY(0);
        opacity: 1;
      }

      100% {
        transform: translateY(15px);
        opacity: 0;
      }
    }
  }

  .header {
    font-size: 14px;
    padding: 18px 15px;

    .navigation {
      margin-right: 15px;

      ul a {
        padding: 8px 0;
        font-size: 19px;
      }
    }

    .btnBorder3 {
      font-size: 19px;
      padding: 7.5px 10px;

      &:hover {
        background-color: inherit;
        color: #fff;
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.home-banner {
  position: relative;
  // background-image: url('../../img/home/Homepage-Banner-Video.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  .container {
    position: relative;
  }

  .home-iframe-wrapper {
    // pointer-events: none;
    background: url('../../../img/home/Homepage-Banner-Video.webp') no-repeat center center/cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  iframe,
  video,
  .video-js,
  video.vjs-tech {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0 !important;
    left: 0 !important;
  }

  .home-banner-video {

    video,
    .video-js,
    video.vjs-tech {
      transform: none;
    }
  }

  .video-js .vjs-big-play-button {
    display: none;
  }

  .scrollBtm {
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    z-index: 1;
    cursor: pointer;
    transform: none;

    &:hover {
      background: none !important;
    }

    a {
      max-width: 80px;
      margin: 0 auto;
    }

    .mousey-wrap {
      height: 80px;
      width: 80px;
      border: 1px solid #ffffff;
      margin: 0 auto;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      svg {
        max-width: 10px;
        margin-top: 3px;

        * {
          fill: #00e0e5;
          stroke-width: 2px;
        }
      }
    }

    .mousey {
      width: 3px;
      padding: 8px 7px;
      height: 12px;
      border: 2px solid #00e0e5;
      border-radius: 25px;
      opacity: 0.75;
      margin: 0 auto;
      box-sizing: content-box;
    }

    .scroller {
      width: 3px;
      height: 6px;
      border-radius: 25%;
      background-color: #00e0e5;
      animation-name: scroll;
      animation-duration: 2.2s;
      animation-timing-function: cubic-bezier(.15, .41, .69, .94);
      animation-iteration-count: infinite;
    }

    @keyframes scroll {
      0% {
        opacity: 0;
      }

      10% {
        transform: translateY(0);
        opacity: 1;
      }

      100% {
        transform: translateY(15px);
        opacity: 0;
      }
    }
  }

  .mobile-video {
    display: none;
  }
}

.vjs-big-play-button {
  display: none;
}

.indexPageWrap {
  padding-bottom: 0;

  .accordWrap {
    .accordBody button {
      font-family: "Ubuntu", sans-serif;
      font-weight: 500;
      font-size: 17px;
      line-height: normal;
      letter-spacing: normal;
      color: #435b75;
      border: none;
      outline: none;
      width: 100%;
      background-color: transparent;
      cursor: pointer;
      text-align: left;
      padding: 17px 22px 17px;
      display: flex;
    }
  }

  .colCard {
    .col {
      max-width: 25%;
      flex: 0 0 25%;
      width: 100%;
      padding: 0 9px 15px;
    }
  }

  .way-wrapper {
    padding-bottom: 45px;
  }

  footer {
    padding: 10px 15px;
    min-height: 58px;

    .credit-logo-wrap {
      display: block;
    }
  }
}

.triptek-difference-wrapper {
  padding: 0 0 50px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;

  p {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 19px;

    strong {
      font-weight: 500;
    }
  }

  .sctionTitle {
    color: #fff;
  }
}

.tripello-difference-wrapper {
  text-align: center;
  padding: 74px 0 78px;
  @include background("");

  @media (max-width:767px) {
    background-image: linear-gradient(138deg, #0072d1, #00dcdf 100%) !important;
    padding: 45px 0 23px;
  }

  .sctionTitle {
    padding-bottom: 19px;
  }

  .content-wrapper {
    padding-top: 29px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 767px) {
      display: block;
      padding-top: 0;
    }
  }

  .col {
    max-width: 331px;
    width: 100%;
    padding: 0 15px;

    &:first-child {
      max-width: 286px;
    }

    &:nth-child(2) {
      max-width: 353px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      @media (max-width: 767px) {
        .content strong {
          display: block;
        }

      }
    }

    @media (max-width: 767px) {
      margin: 0 auto 30px;

      &:first-child {
        margin-top: 30px;
        max-width: 231px;
      }

      &:nth-child(2) {
        max-width: 303px;
      }

      &:last-child {
        max-width: 253px;
      }
    }

    i {
      background-image: url("../../../img/icons/transparent-circle.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-size: 100% 100%;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      img {
        display: block;
        max-width: 28px;
      }

      &:first-child {
        background-image: url("../../../img/icons/circle-1.png");
      }

      &:nth-child(1) {
        background-image: url("../../../img/icons/circle-2.png");
      }

      @media (max-width: 992px) {
        width: 40px;
        height: 40px;

        img {
          display: block;
          max-width: 22px;
        }
      }
    }

    .heading {
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: normal;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      @media (max-width: 992px) {
        font-size: 16px;
      }

      @media (max-width: 767px) {
        justify-content: flex-start;
        padding-left: 15%;
      }
    }

    .content {
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: center;
      color: #fff;

      @media (max-width: 992px) {
        font-size: 14px;
      }
    }
  }

  h3 {
    color: #fff;
  }

  .content-wrapper {
    // max-width: 890px;
    margin: 0 auto;
  }

  p {
    @include ubuntu(300);
    font-size: 19px;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-bottom: 22px;

    strong {
      font-weight: 500;

      @media (max-width: 767px) {
        display: block;
      }

    }
  }
}

.triptek-inventory-wrapper {
  .cardStyle {
    background-image: linear-gradient(90deg, #ececf3, #e6e7ee);
    border: 1px solid #d2dae6;
    border-radius: 10px;
    box-shadow: -2px -2px 4px 0 hsl(0deg 0% 100% / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
    height: 100%;
    padding: 0 0 6px;
    display: block;

    .cardImg {
      align-items: center;
      background-image: linear-gradient(90deg, #ececf3, #e6e7ee);
      border: none;
      border-radius: 8px;
      box-shadow: none;
      display: flex;
      justify-content: center;
      margin: 9px;

      img {
        border-radius: 8px;
        object-fit: cover;
        overflow: hidden;
      }
    }

    .cardText {
      line-height: 19px;
    }
  }
}

.tripello-magic-content {
  margin-top: 20px;
  max-width: 1110px;
  margin: 20px auto 0;

  .featured-on-icons {
    max-width: 550px;
    margin: 0 auto 50px;

    @media(max-width:767px) {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: 500;

    button {
      border: none;
      background: transparent;
      color: #435b75;
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 19px;
      padding: 0px 5px;
      cursor: pointer;
    }
  }
}

.choosePlan {
  display: flex;
  justify-content: center;
  width: 100%;

  .col {
    flex-grow: 1;
    padding: 0 18px;
    max-width: 412px;
    width: 50%;
  }

  .cardStyle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    box-sizing: border-box;
    height: 100%;
    text-align: left;
  }

  .planImgBox {
    padding: 10px 10px 8px;
    border-radius: 6px;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
    border: solid 1px #dae2ee;
    background-color: rgba(255, 255, 255, 0.35);
  }

  .planImgInner {
    background: url("../../../img/planBg.jpg") center center no-repeat;
    background-size: cover;
    border-radius: 6px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .logo-image {
      img {
        max-width: 148px;
      }
    }

    .planName {
      margin-top: 10px;
      color: $white;
      font-size: 17px;
    }

    p {
      color: $white;
      font-size: 14px;
      font-weight: normal;
      margin-top: 2px;
    }

    .plus-img {
      position: absolute;
      top: 6px;
      right: 8px;
    }
  }

  .planNote {
    color: rgba(67, 91, 117, 0.6);
    margin-bottom: 17px;
    font-size: 14px;
  }

  .planDetails {
    margin-bottom: auto;
    margin-left: 0;
    padding: 14px 20px 2px;
    list-style: none;
    color: #435b75;
    font-size: 14px;

    li {
      margin-bottom: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 14px;

      .icon-tick {
        color: #00b7a6;
        margin-right: 9px;
        font-size: 11px;
      }
    }
  }

  .planBtmBox {
    padding: 6px 16px 12px;

    .planRateBox {
      border-top: 1px solid rgba(67, 91, 117, 0.2);
      padding-top: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: #435b75;
      text-align: center;

      h4 {
        color: #435b75;
        font-size: 21px;
        font-weight: 500;
        padding-bottom: 5px;

        span {
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0;
        }
      }

      .btn {
        min-width: 155px;
        text-align: center;
        box-sizing: border-box;
        font-size: 14px;
        margin-top: 12px;

        &:before {
          display: none;
        }
      }
    }
  }

  .paidPlan {
    .planBtmBox .planRateBox {
      h4 {
        font-size: 25px;
        letter-spacing: normal;

        sub {
          vertical-align: baseline;
          font-size: 19px;
          font-weight: normal;
          letter-spacing: 0;
        }
      }
    }
  }


}

.video-js .vjs-big-play-button {
  display: none;
}

.tripello-magic-wrapper {
  .cardStyle {
    padding: 10px;
    border-radius: 10px;
    box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #e1e6ee;
    background-color: #edeef5;
  }

  .videoBox {
    .video-play-btn img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
  }
}

.accordWrap {
  max-width: 720px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;

  .accordBody+.accordBody {
    border-top: 1px solid rgba(67, 91, 117, 0.2);
  }

  .accordion-border {
    border-bottom: 1px solid rgb(67, 91, 117, 0.2);
  }

  .accordBody {
    &.opened {

      .accordCnt {
        max-height: 600px;
      }

    }
  }

  .accordHead {
    font-weight: normal;
    font-size: 14px;
    color: #435b75;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 11px;
    cursor: pointer;

    .fa {
      font-size: 22px;
    }

    .fa-question-circle-o {
      display: none;
      margin-right: 12px;
      text-shadow: 0 0 2px #ddd;
      font-size: 20px;
    }

    .fa-angle-down {
      color: #2571ca;
      margin-left: 10px;
    }
  }

  .accordCntSpace {
    padding: 0 10px 9px;
  }

  .accordCnt {
    font-size: 14px;
    color: #435b75;
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s ease-in-out;
  }

  .active-btn {
    .fa-angle-down {
      transform: rotate(180deg);
    }
  }
}

// Home-page-faq

.home-faq {
  .accordWrap {
    .accordHead {
      justify-content: flex-start;
      align-items: flex-start;

      img {
        max-width: 26px;
        margin-right: 12px;
        margin-top: -1px;
      }
    }
  }
}

// Home-page-faq end

.faq-wrapper {
  .accordion {
    button img {
      margin-right: 10px;
      max-width: 22px;
      margin-top: -2px;
    }
  }
}

.indexPageWrap {
  .planCont {
    .choosePlan {
      align-items: center;

      .col {
        .cardStyle {
          height: 530px;
          max-width: 300px;
          margin: 0 auto;
          box-shadow: none;
          border: none;
        }

        &:nth-child(2) {
          .cardStyle {
            height: 590px;
            max-width: 340px;
            background: #ffffff;
          }
        }
      }

      .planImgBox {
        padding: 5px 5px 0;
        box-shadow: none;
        border: none;
        background-color: transparent;
      }

      .planDetails {
        list-style: none;
        padding-left: 22px;

        li {
          list-style: none;
          position: relative;

          &:before {
            background-color: #435b75;
            border-radius: 100%;
            content: "";
            display: block;
            height: 4px;
            left: -10px;
            position: absolute;
            top: 7px;
            width: 4px;
          }

          svg {
            display: none !important;
          }
        }
      }

      .planNote {
        font-size: 12px;
      }

      .planRateBox {
        .btn {
          width: 200px;
          height: 35px;
          border-radius: 4px;
          box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
          background-image: linear-gradient(to right, #3763d0 -12%, #00d0d4 114%);
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .slide-show-desktop {
    // min-height: 400px;
    height: 100%;

    .slider-wrapper {
      max-width: 100%;
      padding: 0;

      .slick-slider {
        max-height: 100%;
      }

      .slick-track {
        display: flex;
      }

      .slick-dots {
        bottom: 25px;

        li {
          margin: 0 4px;
          padding: 0;
          width: 16px;
          height: 16px;

          button {
            box-shadow: none;
            border: none;
            height: 12px;
            width: 12px;
            margin: 0;
            background: #ffffff;
          }
        }

        .slick-active {
          button {
            height: 16px;
            width: 16px;
          }
        }
      }

      .slick-slide {
        padding: 49px 10px;
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.2);
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
        }

        &.slick-active {
          &::before {
            display: none;
          }
        }
      }

      .slider-images img {
        max-width: 100%;
      }

      .slick-arrow {
        top: 50%;
        height: 40px;
        width: 40px;
        border-radius: 100%;
        border: 1px solid #ffffff;
      }

      .slick-prev {
        left: 160px;
        transform: translateY(-50%) rotate(90deg);

        &::before {
          position: static;
          top: 50%;
          font-size: 30px;
          transform: translateY(-50%);
          right: 0;
          opacity: 1;
          margin-top: 20px;
        }
      }

      .slick-next {
        right: 160px;
        transform: translateY(-50%) rotate(270deg);

        &:before {
          position: static;
          top: 50%;
          font-size: 30px;
          transform: translateY(-50%);
          right: 0;
          opacity: 1;
          margin-top: 20px;
        }
      }
    }
  }

  .tripello-way-wrapper {
    padding-bottom: 78px;

    .section-title {
      padding: 60px 0 35px;

      h2 {
        margin-bottom: 18px;
        padding-bottom: 0;
      }

      h3 {
        margin: 0 0 36px;
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: center;
        color: #435b75;

        strong {
          font-weight: 500;
        }
      }
    }

    .cardStyle {
      border: none;
      box-shadow: none;
      border-radius: 0;

      .img-wrap {
        width: 60px;
        height: 60px;
        box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
        border: solid 1px #e1e6ee;
        background-color: #edeef5;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -60px auto 12px;
        position: relative;
      }

      h4 {
        font-size: 19px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: normal;
        color: #1b71cf;
        margin-bottom: 13px;
      }

      p {
        line-height: 1.3;
        margin: 0;

        @media (max-width:991px) {
          br {
            display: none;
          }
        }
      }

      a {
        color: #1d6ec9;
      }

      button {
        background-color: transparent;
        border: none;
        color: #1d6ec9;
        font-size: inherit;
        font-weight: inherit;
      }

      .card-col {
        max-width: 33.33%;
        flex: 0 0 33.33%;
        padding: 0 15px;
      }
    }

    .cardBox {
      padding: 27px 18px 27px;
      border-radius: 10px;
      box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      text-align: center;
      height: 100%;
      position: relative;

      .link-url {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}

// Responsive start
@media (max-width: 1400px) {
  .indexPageWrap .triptek-inventory-wrapper .colCard {
    margin: 0;
  }
}

@media (max-width:1199px) {
  .indexPageWrap {
    .tripello-way-wrapper {
      .container {
        padding: 0 15px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .indexPageWrap {
    .slide-show-desktop {
      .slider-wrapper {
        .slick-prev {
          left: 70px;
        }

        .slick-next {
          right: 70px;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 991px) {
  .choosePlan .planDetails li {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .indexPageWrap {
    .planCont {
      .choosePlan {
        align-items: center;

        .col {
          .cardStyle {
            height: auto;
            max-width: 340px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .bannerSec {
    .header {
      .logoWrap img {
        height: inherit;
        max-width: 147px;
      }

      .navigation {
        margin-right: 50px;
      }
    }

    .btnBorder3 {
      min-width: 120px;
    }

    h3 {
      font-size: 50px;
      padding-bottom: 30px;
    }

    p {
      font-size: 25px;
    }

    .home-banner-content {
      padding-bottom: 80px;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .triptek-inventory-wrapper .cardStyle .cardImg {
    height: auto;
    padding-bottom: 100%;
  }
}

@media (max-width: 767px) {
  .tripello-magic-wrapper {
    background: none;
    padding-top: 0;
    margin-top: 43px;
    padding-bottom: 30px;

    .sctionTitle,
    p {
      color: #435b75;
      margin-bottom: 0;
    }

    p {
      padding-bottom: 25px;
    }

    .videoBox {
      padding: 10px;
      border-radius: 8px;
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      // min-height: 210px;
      overflow: hidden;

      .videoInBox {
        min-height: 180px;
      }

      video,
      iframe {
        height: 100%;
        width: 100%;
      }

      .desktop-video {
        display: none;
      }

      .mobile-video {
        display: block;
      }

      .video-play-btn {
        img {
          max-width: 70px;
        }
      }

      .videoInBox {
        &:after {
          width: 84px;
          height: 84px;
          background-size: contain;
        }
      }
    }

    .cardStyle {
      border-radius: 10px;
      box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.8), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #d2dae6;
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      padding: 10px;
    }
  }

  .bannerSec .header {
    .navigation ul {
      a {
        font-size: 14px;
      }
    }

    .btnBorder3 {
      font-size: 14px;
    }
  }

  .home-banner {
    background-image: url("../../../img/home/Homepage-Banner-Video.jpg");

    .mobile-video {
      display: block;
    }

    .desktop-video {
      display: none;
    }

    .scrollBtm {
      bottom: -20px;

      .mousey-wrap {
        height: 60px;
        width: 60px;

        .mousey {
          height: 9px;
          padding: 6px;
          width: 2px;
        }

        svg {
          margin-top: 3px;
          max-width: 10px;
        }
      }
    }

    .bannerCont {
      position: absolute;
      bottom: 160px;
      left: 0;
      right: 0;

      p {
        padding-bottom: 20px;
      }
    }
  }

  .home-faq {
    .accordWrap {
      .accordBody {
        padding-right: 30px;
      }

      .moreAccordBody {
        padding-right: 0;
      }

      .accordHead {
        display: flex;
        align-items: center;
        position: relative;
        padding: 12px 0 12px 10px;

        &:after {
          content: "";
          display: block;
          background-image: url('../../../img/network/dropdown.svg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 14px;
          width: 14px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -20px;
        }

        &.active-btn {
          font-size: 17px;

          &:after {
            background-image: url('../../../img/network/dropdown.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        img {
          display: none;
        }
      }
    }
  }

  .sctionTitle {
    max-width: 250px;
    margin: 0 auto;
  }

  .triptek-difference-wrapper p {
    font-size: 17px;
    margin-bottom: 16px;
  }

  .indexPageWrap {
    padding-bottom: 0;

    .accordWrap {
      .accordBody button {
        padding: 12px 0 12px 10px;
        font-size: 14px;
      }
    }

    .triptek-inventory-wrapper {
      max-width: 375px;
      padding: 45px 15px 0px;

      .colCard .col {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
        padding: 0 0 15px;

        .cardStyle {
          flex-direction: row;
          height: 100%;
          box-sizing: border-box;
          padding: 0px;
          display: flex;
        }
      }
    }

    .colCard .cardStyle .cardText {
      padding: 8px 9px 4px;
    }

    .tripello-magic-wrapper {
      .cardStyle {
        border: none;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
      }

      .videoBox {
        background-image: linear-gradient(90deg, #ececf3, #e6e7ee);
        border: 1px solid #d2dae6;
        border-radius: 10px;
        box-shadow: -2px -2px 4px 0 hsl(0deg 0% 100% / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
        height: 100%;
        min-height: 180px;
        padding: 5px;
      }
    }

    .slide-show-mobile {
      padding: 20px 0;

      .slick-slide {
        padding-bottom: 25px;
      }

      .slick-dots {
        bottom: -10px;

        li {
          margin: 0 2px;
          width: 14px;
          height: 14px;

          button {
            height: 10px;
            width: 10px;
            margin: 0;
            background: #ffffff !important;
            box-shadow: none;
          }
        }

        .slick-active {
          button {
            height: 13px;
            width: 13px;
            box-shadow: none;
          }
        }
      }
    }

    .tripello-way-wrapper {
      padding-bottom: 11px;

      .section-title {
        padding: 30px 10px 27px;

        h2 {
          margin-bottom: 12px;
        }

        h3 {
          font-size: 14px;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          text-align: center;
          color: #435b75;
          margin-bottom: 10px;

          strong {
            font-weight: 500;
          }
        }
      }

      .container {
        max-width: 630px;
      }

      .cardStyle {
        padding: 22px 0 0px;

        .cardBox {
          padding: 27px 20px 15px;
        }

        p {
          line-height: 19px;
          font-size: 14px;

          br {
            display: none;
          }
        }

        h4 {
          font-size: 17px;
          line-height: 1.76;
          margin-bottom: 4px;
        }

        .img-wrap {
          height: 50px;
          width: 50px;
          margin: -50px auto 5px;
        }

        .card-col {
          max-width: 100%;
          flex: 0 0 100%;
          padding-bottom: 40px;
        }

      }
    }

    .footer {
      padding: 7px 5px;

      .container {
        flex-direction: column;
        padding: 0 8px;
      }

      .credit-logo-wrap {
        padding: 5px 0 3px;
      }
    }
  }
}

// 767 end