.DateInput_input {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  color: inherit;
  @include placeholder-text {
    color: inherit;
  }
}




.landingPage {
  .dropdownWrap {
    .css-1s2u09g-control {
      border-radius: 10px;
      box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
      border: solid 1px rgba(210, 218, 230, 0.6);
      background-color: rgba(255, 255, 255, 0.35);
      background-image: linear-gradient(to right, #ececf3, #e6e7ee);
      font-size: 14px;
      font-weight: normal;
      color: rgba(67, 91, 117, 0.6);

      .css-1okebmr-indicatorSeparator {
        display: none;
      }
    }
  }
}

// widget-page-select field style

.dropDownSubWrap {
  .css-b62m3t-container .css-1s2u09g-control {
    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
      inset -2px -2px 6px 0 #ffffff;
    border: solid 1px #d2dae6;
    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    margin-top: 0px;
    min-height: 30px;
    position: relative;
    box-sizing: border-box;
  }

  .custom-select__control {
    .custom-select__indicator-separator {
      display: none;
    }
  }

  .custom-select__menu {
    background-color: #e6e7ee;
  }

  .css-9gakcf-option {
    background-color: grey;
  }
}


// flight-tab select-field

.flex-input-wrapper {
  display: flex;
}

.landingPage {
  .tabContentWrap {
    .flexStart .flatDropdown {
      .custom-select__control {
        border: none;
        width: 100%;
        height: 28px;
        box-shadow: none;
        min-height: auto;
        background: transparent;
        padding: 0px 0px 0px 0px;
        margin: 0px;
        outline-style: none;
        cursor: pointer;

        &:hover {

          .custom-select__single-value,
          .custom-select__value-container .custom-select__input-container,
          .custom-select__indicator .fa-angle-down * {
            color: rgb(0, 0, 0);
            fill: rgb(0, 0, 0);
          }
        }

        .custom-select__value-container {
          padding: 2px 0px 2px 14px;
        }

        .custom-select__indicators,
        .custom-select__indicator {
          padding: 0px 5.5px;

          .fa-angle-down {
            font-size: 21px;
            margin-top: -3px;
            color: #1d6ec9;
          }

          &:hover {
            .fa-angle-down {
              color: rgb(0, 0, 0);
              fill: rgb(0, 0, 0);

            }
          }
        }

        .custom-select__single-value,
        .custom-select__input-container {
          color: #1d6ec9;
        }
      }


    }

    .flightCnt .startEndLocation {
      .startNendWrap input {
        min-width: 100% !important;
      }

      .startEndDate {
        padding: 0px 14px !important;
        position: relative;

        .form-group {
          width: 100%;
          text-align: left;
          padding: 0px;
        }

        .DateInput_input {
          padding: 10px 3px 9px;
        }
      }
    }
  }

  .custom-select__indicator-separator {
    display: none;
  }

  .flight-class {
    .custom-select__menu {
      top: 100%;
      background-color: rgb(230, 231, 238);
      border-radius: 6px;
      box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%),
        0 4px 11px hsl(0deg 0% 0% / 10%);
      margin-bottom: 8px;
      margin-top: 8px;
      position: absolute;
      width: auto;
      height: 180px;
      z-index: 999;
      padding: 6px;
      box-sizing: border-box;
    }

    .custom-select__menu-list>div {
      padding: 11px 8px;
      font-size: 15px;
      border-radius: 6px;
      color: #435b75 !important;
      cursor: pointer;
    }

    .css-1n7v3ny-option,
    .custom-select__option--is-selected,
    .css-9gakcf-option {
      background-color: rgb(230, 231, 238) !important;
      color: #435b75 !important;
    }

    .css-1n7v3ny-option,
    .custom-select__option--is-selected,
    .css-9gakcf-option :hover {
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
        inset -2px -2px 6px 0 #ffffff !important;
      border-color: #d2dae6 !important;
      color: #435b75 !important;
      padding: 11px 8px;
    }
  }


  .roomNpersonWrap {
    .dropdownWrap {
      .btnSml {
        padding: 6px;
        margin-right: 7px;
        min-width: 120px;
        font-family: "Ubuntu", sans-serif;

        &.width180 {
          margin-right: 13px;
          padding: 6px 9px 6px 16px;
        }

        .fa-angle-down {
          font-size: 21px;
          margin-top: -1px;
          display: inline-block;
          margin-right: 2px !important;
        }
      }
    }
  }

  .dropdownWrap {
    .btnSml {
      padding: 6px 10px 6px 10px;
      margin-right: 7px;
      min-width: 120px;
      min-height: 30px;

      &.width180 {
        margin-right: 0px;
        padding: 0px;
      }
    }
  }

  .addFlight,
  .removeFlight {
    border: none;
    font-size: 17px;
    background: transparent;
    color: rgb(255, 255, 255);
  }

  .removeFlight {
    position: absolute;
    bottom: -76px;
    left: 19%;

    .icon-minus {
      margin-right: 10px;
    }
  }
}

// RoundTrip and oneway tab select field styles
#oneWay,
#rounttripBox {
  .startNendWrap {
    .inputStyle .icon-location-on {
      left: 15px;
      top: 50%;
      position: absolute;
      z-index: 2;
      transform: translateY(-50%);
      font-size: 16px;
    }

    .dorpdown-content .icon-location-on {
      left: 10px;
    }
  }

  .Date-picker-range {
    .DateRangePickerInput {
      .DateRangePickerInput_calendarIcon {
        margin-right: -8px;
        vertical-align: top;
        margin-top: -4px;
        margin-left: 5px;
        padding: 10px 9px;
      }

      .DateRangePickerInput_arrow {
        margin-left: 0px;

        .icon-arrow {
          margin: 7px 14px 0px -10px;
        }
      }
    }

    .DateInput {
      margin-left: 0px;
      margin-right: 20px;

      .DateInput_input {
        margin-left: 0px;
        font-size: 17px;
      }


    }

  }
}

@media (max-width: 991px) {
  .landingPage {
    .css-26l3qy-menu {
      left: -41px;
      width: 150px;
    }
  }
}

// Flight-tab  responsive

@media (max-width: 767px) {
  #flightsTab {
    .startEndLocation {
      .locationAndDateWrap .startNendWrap {
        .inputStyle .grow1 {
          border-radius: 10px;
          box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
          border: solid 1px #d2dae6;
          background-image: linear-gradient(to right, #ececf3, #e6e7ee);
          padding: 12px 20px 12px 35px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          font-size: 17px;
          font-weight: 400;
          vertical-align: text-bottom;
          cursor: pointer;
        }
      }

      .inputStyle:first-child {
        margin-bottom: 0px;
      }
    }
  }

  #oneWay,
  #rounttripBox {
    .startNendWrap {
      .inputStyle .icon-location-on {
        left: 14px;
        top: 49%;
        position: absolute;
        z-index: 2;
        transform: translateY(-50%);
        font-size: 16px;
      }
    }
  }
}