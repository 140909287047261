.icon-verified-user:before {
    content: "\e96c";
    color: #1d6ec9;
}

.icon-gear:before {
    content: "\e96b";
    color: #435b75;
}

.icon-suitcase2:before {
    content: "\e96a";
    color: #7f95a8;
}

.icon-creditCard:before {
    content: "\e966";
    color: #00b7a6;
}

.icon-door:before {
    content: "\e967";
    color: #8193a7;
}

.icon-navigation:before {
    content: "\e968";
    color: #7f95a8;
}

.icon-seater:before {
    content: "\e969";
    color: #435b75;
}

.icon-arrow:before {
    content: "\e965";
    color: #435b75;
}

.icon-group:before {
    content: "\e95f";
    color: #435b75;
}

.icon-location:before {
    content: "\e960";
    color: #435b75;
}

.icon-room:before {
    content: "\e961";
    color: #435b75;
}

.icon-speedometer:before {
    content: "\e962";
    color: #00b7a6;
}

.icon-tick2:before {
    content: "\e963";
    color: #435b75;
}

.icon-transmission:before {
    content: "\e964";
    color: #435b75;
}

.icon-phone:before {
    content: "\e900";
    color: #435b75;
}

.icon-facebook .fb-icon:before {
    content: "\e904";
    color: rgb(0, 0, 0);
    opacity: 0.6049;
}

.icon-facebook .path2:before {
    content: "\e902";
    margin-left: -0.5595703125em;
    color: rgb(126, 148, 168);
    opacity: 0.6049;
}

.icon-insta .insta-icon:before {
    content: "\e906";
    color: rgb(0, 0, 0);
    opacity: 0.6049;
}

.icon-insta .path2:before {
    content: "\e904";
    margin-left: -1.0419921875em;
    color: rgb(126, 148, 168);
    opacity: 0.6049;
}

.icon-linkedin .linkedin-icon:before {
    content: "\e908";
    color: rgb(0, 0, 0);
    opacity: 0.6049;
}

.icon-linkedin .path2:before {
    content: "\e906";
    margin-left: -1.0830078125em;
    color: rgb(126, 148, 168);
    opacity: 0.6049;
}

.icon-tick:before {
    content: "\e909";
    color: #00b7a6;
}

.icon-twitter .twit-icon:before {
    content: "\e90a";
    color: rgb(0, 0, 0);
    opacity: 0.6049;
}

.icon-twitter .path2:before {
    content: "\e909";
    margin-left: -1.173828125em;
    color: rgb(126, 148, 168);
    opacity: 0.6049;
}

.icon-youtube .youtube-icon:before {
    content: "\e90e";
    color: rgb(0, 0, 0);
    opacity: 0.6049;
}

.icon-youtube .path2:before {
    content: "\e90b";
    margin-left: -1.2724609375em;
    color: rgb(126, 148, 168);
    opacity: 0.6049;
}

.icon-TikTok-_1 .tiktok-icon:before {
    content: "\e902";
    color: rgb(0, 0, 0);
    opacity: 0.6049;
}

.icon-pinterest .pinterest-icon:before {
    content: "\e901";
    color: rgb(0, 0, 0);
    opacity: 0.6049;
}

.icon-accessible .path1:before {
    content: "\e90c";
    color: rgb(0, 0, 0);
}

.icon-accessible .path2:before {
    content: "\e90d";
    margin-left: -0.76953125em;
    color: rgb(126, 148, 168);
}

.icon-account-circle:before {
    content: "\e910";
    color: #fff;
}

.icon-add:before {
    content: "\e911";
    color: #fff;
}

.icon-apartments .path1:before {
    content: "\e912";
    color: rgb(0, 0, 0);
}

.icon-apartments .path2:before {
    content: "\e913";
    margin-left: -1em;
    color: rgb(230, 231, 238);
}

.icon-arrow-down:before {
    content: "\e912";
    color: #1d6ec9;
}

.icon-bed:before {
    content: "\e913";
    color: #435b75;
}

.icon-border-plain:before {
    content: "\e951";
    color: #00b7a6;
}

.icon-building .path1:before {
    content: "\e917";
    color: rgb(0, 0, 0);
}

.icon-building .path2:before {
    content: "\e913";
    margin-left: -1.029296875em;
    color: rgb(230, 231, 238);
}

.icon-close:before {
    content: "\e919";
    color: #435b75;
}

.icon-diamond:before {
    content: "\e918";
    color: #435b75;
}

.icon-dinner:before {
    content: "\e919";
    color: #435b75;
}

.icon-dinnerPlate:before {
    content: "\e91a";
    color: #7691b0;
}

.icon-family .path1:before {
    content: "\e91b";
    color: rgb(0, 0, 0);
}

.icon-family .path2:before {
    content: "\e91c";
    margin-left: -1.1171875em;
    color: rgb(126, 148, 168);
}

.icon-family .path3:before {
    content: "\e91d";
    margin-left: -1.1171875em;
    color: rgb(0, 0, 0);
}

.icon-family .path4:before {
    content: "\e91e";
    margin-left: -1.1171875em;
    color: rgb(126, 148, 168);
}

.icon-family .path5:before {
    content: "\e91f";
    margin-left: -1.1171875em;
    color: rgb(0, 0, 0);
}

.icon-family .path6:before {
    content: "\e920";
    margin-left: -1.1171875em;
    color: rgb(126, 148, 168);
}

.icon-flight .path1:before {
    content: "\e923";
    color: rgb(0, 0, 0);
}

.icon-flight .path2:before {
    content: "\e924";
    margin-left: -1.0302734375em;
    color: rgb(230, 231, 238);
}

.icon-fun .path1:before {
    content: "\e923";
    color: rgb(0, 0, 0);
}

.icon-fun .path2:before {
    content: "\e924";
    margin-left: -0.888671875em;
    color: rgb(230, 231, 238);
}

.icon-fun .path3:before {
    content: "\e925";
    margin-left: -0.888671875em;
    color: rgb(0, 0, 0);
}

.icon-fun .path4:before {
    content: "\e926";
    margin-left: -0.888671875em;
    color: rgb(230, 231, 238);
}

.icon-fun .path5:before {
    content: "\e927";
    margin-left: -0.888671875em;
    color: rgb(0, 0, 0);
}

.icon-fun .path6:before {
    content: "\e928";
    margin-left: -0.888671875em;
    color: rgb(230, 231, 238);
}

.icon-fun .path7:before {
    content: "\e929";
    margin-left: -0.888671875em;
    color: rgb(0, 0, 0);
}

.icon-fun .path8:before {
    content: "\e92a";
    margin-left: -0.888671875em;
    color: rgb(230, 231, 238);
}

.icon-fun .path9:before {
    content: "\e92b";
    margin-left: -0.888671875em;
    color: rgb(0, 0, 0);
}

.icon-fun .path10:before {
    content: "\e92c";
    margin-left: -0.888671875em;
    color: rgb(230, 231, 238);
}

.icon-fun .path11:before {
    content: "\e92d";
    margin-left: -0.888671875em;
    color: rgb(0, 0, 0);
}

.icon-fun .path12:before {
    content: "\e92e";
    margin-left: -0.888671875em;
    color: rgb(230, 231, 238);
}

.icon-fun .path13:before {
    content: "\e92f";
    margin-left: -0.888671875em;
    color: rgb(0, 0, 0);
}

.icon-fun .path14:before {
    content: "\e930";
    margin-left: -0.888671875em;
    color: rgb(230, 231, 238);
}

.icon-globe:before {
    content: "\e933";
    color: #fff;
}

.icon-gym:before {
    content: "\e932";
    color: #7691b0;
}

.icon-heart:before {
    content: "\e933";
    color: #435b75;
}

.icon-help:before {
    content: "\e936";
    color: #fff;
}

.icon-icon1:before {
    content: "\e935";
    color: #7691b0;
}

.icon-icon2:before {
    content: "\e936";
    color: #7691b0;
}

.icon-icon3:before {
    content: "\e939";
    color: #7691b0;
}

.icon-info:before {
    content: "\e93a";
    color: #435b75;
}

.icon-invitation:before {
    content: "\e93b";
    color: #1d6ec9;
}

.icon-laptop:before {
    content: "\e93a";
    color: #7691b0;
}

.icon-laundry .path1:before {
    content: "\e93b";
    color: rgb(0, 0, 0);
}

.icon-laundry .path2:before {
    content: "\e93c";
    margin-left: -0.845703125em;
    color: rgb(126, 148, 168);
}

.icon-location-on:before {
    content: "\e960";
    color: #1d6ec9;
}

.icon-lock:before {
    content: "\e940";
    color: #fff;
}

.icon-lock2:before {
    content: "\e93f";
    color: #435b75;
}

.icon-mail:before {
    content: "\e942";
    color: #1d6ec9;
}

.icon-parking:before {
    content: "\e941";
    color: #7691b0;
}

.icon-person .path1:before {
    content: "\e944 ";
    color: rgb(0, 0, 0);
}

.icon-person .path2:before {
    content: "\e943";
    margin-left: -0.9375em;
    color: rgb(126, 148, 168);
}

.icon-pets:before {
    content: "\e944";
    color: #7691b0;
}

.icon-pool .path1:before {
    content: "\e945";
    color: rgb(0, 0, 0);
}

.icon-pool .path2:before {
    content: "\e946";
    margin-left: -1.0830078125em;
    color: rgb(126, 148, 168);
}

.icon-pool .path3:before {
    content: "\e947";
    margin-left: -1.0830078125em;
    color: rgb(0, 0, 0);
}

.icon-pool .path4:before {
    content: "\e948";
    margin-left: -1.0830078125em;
    color: rgb(126, 148, 168);
}

.icon-printer:before {
    content: "\e94b";
    color: #fff;
}

.icon-quote:before {
    content: "\e94a";
    color: #435b75;
}

.icon-reception .path1:before {
    content: "\e94b";
    color: rgb(0, 0, 0);
}

.icon-reception .path2:before {
    content: "\e94c";
    margin-left: -0.888671875em;
    color: rgb(126, 148, 168);
}

.icon-reception .path3:before {
    content: "\e94d";
    margin-left: -0.888671875em;
    color: rgb(0, 0, 0);
}

.icon-reception .path4:before {
    content: "\e94e";
    margin-left: -0.888671875em;
    color: rgb(126, 148, 168);
}

.icon-rounded-plain-white:before {
    content: "\e94f";
    color: #fff;
}

.icon-rounded-plain:before {
    content: "\e950";
    color: #00b7a6;
}

.icon-rounded-tick:before {
    content: "\e951";
    color: #435b75;
}

.icon-shape .path1:before {
    content: "\e954";
    color: rgb(0, 0, 0);
}

.icon-shape .path2:before {
    content: "\e954";
    margin-left: -1.193359375em;
    color: rgb(230, 231, 238);
}

.icon-star .path1:before {
    content: "\e954";
    color: rgb(0, 0, 0);
}

.icon-star .path2:before {
    content: "\e955";
    margin-left: -1.0439453125em;
    color: rgb(236, 185, 37);
}

.icon-suitcase .path1:before {
    content: "\e956";
    color: rgb(0, 0, 0);
}

.icon-suitcase .path2:before {
    content: "\e957";
    margin-left: -1.0322265625em;
    color: rgb(126, 148, 168);
}

.icon-swap-horiz:before {
    content: "\e95a";
    color: #1d6ec9;
}

.icon-timer:before {
    content: "\e95b";
    color: #1d6ec9;
}

.icon-tv:before {
    content: "\e95c";
    color: #7691b0;
}

.icon-wifi:before {
    content: "\e95d";
    color: #435b75;
}

.icon-work:before {
    content: "\e95e";
    color: #435b75;
}