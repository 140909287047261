.support-page {
    background: url("../img/widget-bg.jpg") 0 0;
    background-size: 100% 100vh;
    background-attachment: fixed;
    min-height: 100vh;

    .pageInternalScrollTopPadding {
        padding-top: 20px;
    }

    .pageInternalScroll {
        >div {
            overflow: auto !important;
        }
    }

    .coming-soon-wrap {
        min-height: 347px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        color: #1d6ec9;
        font-size: 28px;

        @media (max-width:991px) {
            min-height: 346px;
        }
        @media (max-width:767px) {
            font-size: 19px;
        }
    }

    .rewardsSection {
        @include background("../img/support/customer-support-heading.png");
        padding: 25px 53px 23px 30px;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgb(11 170 225);
        color: $white;
        margin-bottom: 20px;

        h1 {
            font-size: 25px;
            font-weight: normal;
            margin-bottom: 16px;
        }

        .leftContent {
            p {
                font-size: 17px;
                font-weight: normal;
                line-height: 1.24;

                +p {
                    margin-top: 16px;

                }
            }

            .container {
                padding: 0;
            }
        }

        .imgContent {
            display: flex;
            justify-content: flex-end;

            .image {
                max-width: 70px;
                flex: 0 0 70px;
                margin-top: -2px;
            }
        }

        .rightContent {
            max-width: 90px;
            flex: 0 0 90px;
        }
    }

    .condition-base-data {
        min-height: 382px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        color: #1d6ec9;
        font-size: 24px;
    }

    .css-26l3qy-menu {
        bottom: 40px;
        top: auto;
    }

    .tabs-wrapper {
        background-color: #e6e7ee;
        border-radius: 10px;
        margin-bottom: 50px;

        .ant-tabs-nav-operations {
            display: none;
        }

        .ant-tabs-top>.ant-tabs-nav::before {
            border-bottom: 1px solid #cfd1e1;
        }

        .ant-tabs-ink-bar {
            background: #1d6ec9;
            height: 3px;
        }

        .ant-tabs-tab {
            padding: 0;
            font-size: 19px;

            .icon-image {
                min-width: 32px;
            }
        }

        .ant-tabs-nav-wrap {
            padding: 0 21px;

            img {
                max-width: 32px;
                text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);

            }

            .active-img {
                display: none;
            }

            [class^=icon-] {
                font-size: 32px;
                vertical-align: middle;

                .path1:before {
                    text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
                    color: #e6e7ee;
                }
            }

            .membership-icon {
                min-width: 25px;
            }

            .icon-image {
                text-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
                filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.4));
            }

            .ant-tabs-tab-btn {
                color: #8696bc;
                padding: 14px;
                display: flex;
                align-items: center;

                .tab-title {
                    margin-left: 10px;
                    font-size: 19px;
                    line-height: 1;
                }
            }
        }

        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: #1d6ec9;
            }

            [class^=icon-] {
                .path1:before {
                    color: #1d6ec9;
                }
            }

            .icon-image svg * {
                fill: #1d6ec9;
            }

            .active-img {
                display: block;
            }

            .default-img {
                display: none;
            }
        }
    }

    .customer-portal-tab-ui {
        padding: 10px 30px 25px;

        .form-group {
            margin-bottom: 15px;

            .col {
                padding: 0;
            }

            .selectWrap {
                margin: 0;
            }
        }

        .css-b62m3t-container {
            [class*="control"] {
                -webkit-text-fill-color: #435b75;
                -webkit-appearance: none !important;
                appearance: none !important;
                background-color: hsla(0, 0%, 100%, .35);
                border: 1px solid #dae2ee;
                border-radius: 10px;
                box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #fff;
                box-sizing: border-box;
                color: #435b75;
                font-family: Ubuntu, sans-serif;
                font-size: 17px;
                font-weight: 400;
                min-height: 40px;
                opacity: 1;
                overflow: visible;
                padding: 6px 15px;
            }

            [class*="indicatorSeparator"] {
                display: none;
            }

            [class*="Input"],
            [class*="placeholder"],
            [class*="ValueContainer"] {
                padding: 0;
                margin: 0;
                color: inherit;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            [class*="indicatorContainer"] {
                color: inherit;
                padding: 0;
            }
        }

        .form-control {
            -webkit-text-fill-color: #435b75;
            -webkit-appearance: none !important;
            appearance: none !important;
            background-color: hsla(0, 0%, 100%, .35);
            border: 1px solid #dae2ee;
            border-radius: 10px;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #fff;
            box-sizing: border-box;
            color: #435b75;
            display: block;
            font-family: Ubuntu, sans-serif;
            font-size: 17px;
            font-weight: 400;
            min-height: 40px;
            opacity: 1;
            overflow: visible;
            padding: 6px 15px;
        }

        textarea.form-control {
            max-width: 100%;
            width: 100%;
            min-height: 180px;
            resize: none;
        }

        .btn-submit {
            align-items: center;
            background-image: linear-gradient(90deg, #326ed1, #06c4d4);
            border-radius: 4px;
            box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 20%), -2px -2px 5px 2px #fff;
            display: inline-flex !important;
            font-size: 14px;
            justify-content: center;
            max-height: 40px;
            padding: 12px;
            min-width: 120px;

            &:before {
                display: none;
            }
        }

        .form-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .file-upload {
            position: relative;

            img {
                max-width: 20px;
            }

            #upload-photo {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                cursor: pointer;
            }

            label {
                margin-left: 8px;
                color: #435b75;
            }
        }
    }

    // File Upload 
    .fileNameDisplay {
        color: #161618;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;
        margin-top: 8px;

        button {
            border: 1px solid #E63946;
            color: #E63946;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            margin-left: 10px;
        }
    }

    .rbt {
        .form-control {
            width: 100%;
        }

        .dorpdown-content {
            flex-direction: column;
            align-items: flex-start;
        }

        .dropdown-dropdown-title {
            font-size: 17px;
        }

        .dorpdown-item+.dorpdown-item {
            margin-top: 10px;
        }

        .dot {
            height: 8px;
            width: 8px;
            background-color: #435b75;
            border-radius: 100%;
            margin: 0 10px;
            display: inline-block;
        }

        .booking-id-content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
        }

        .rbt-aux {
            .rbt-close {
                color: #1d6ec9;
            }
        }
    }
}

// Responsive Css start
@media (min-width: 992px) {
    .support-page .mainContent .pageInternalScroll {
        padding-bottom: 0;
        padding-top: 0;
        height: calc(100vh - 120px) !important;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media (max-width: 1024px) {
    .support-page {
        .tabs-wrapper {
            .ant-tabs-tab+.ant-tabs-tab {
                margin-left: 20px;
                font-size: 17px;
            }
        }
    }
}

// 1024 end
@media (max-width: 991px) {
    .support-page {
        .rewardsSection .leftContent {
            .content {
                p {
                    &:first-child {
                        margin-bottom: 16px;
                    }
                }
            }
        }

        .tabs-wrapper {
            padding: 0;

            .ant-tabs-tab+.ant-tabs-tab {
                margin-left: 0px;
                font-size: 17px;
            }

            .ant-tabs-nav-wrap .ant-tabs-tab-btn {
                padding: 10px;

                .tab-title {
                    font-size: 17px;
                }
            }
        }
    }
}

// 991 end
@media (max-width: 767px) {
    .support-page {
        .css-26l3qy-menu {
            bottom: auto;
            top: 100%;
        }

        .rewardsSection {
            padding: 20px;

            h1 {
                font-size: 21px;
            }

            .rightContent {
                max-width: 100%;
                flex: 0 0 100%;

                .imgContent {
                    justify-content: center;
                    margin-top: 15px;
                }
            }

            .leftContent p {
                font-size: 14px;
            }
        }

        .tabs-wrapper {

            .ant-tabs-nav-wrap {
                .ant-tabs-tab {
                    +.ant-tabs-tab {
                        margin-left: 20px;
                    }
                }

                .ant-tabs-tab-btn {
                    flex-direction: column;
                    padding: 10px;

                    .tab-title {
                        margin: 5px 0 0;
                        font-size: 17px;
                    }
                }
            }
        }

        .condition-base-data {
            font-size: 19px;
        }

        .dropdown-menu {
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #cfd1e1;
            background-color: #e6e7ee;
            width: 100% !important;
            min-width: 100% !important;
            padding: 0;
        }
    }
}

// 767 end
@media (max-width: 640px) {
    .support-page {
        .customer-portal-tab-ui {
            padding: 10px 15px 15px;

            .file-upload label {
                font-size: 13px;
            }
        }

        .condition-base-data {
            min-height: 371px;
        }

        .tabs-wrapper .ant-tabs-nav-wrap {
            .ant-tabs-tab-btn .tab-title {
                font-size: 14px;
            }

            [class^=icon-] {
                font-size: 22px;
            }

            .icon-image {
                min-width: 22px;

                svg {
                    max-width: 28px;
                    height: auto;
                }
            }

            .ant-tabs-tab+.ant-tabs-tab {
                margin-left: 10px;
            }
        }
    }
}

// 640 end
@media (max-width: 499px) {
    .support-page .tabs-wrapper .ant-tabs-nav-wrap {
        padding: 0 5px;

        .ant-tabs-nav-list {
            justify-content: space-between;
            width: 100%;
        }

        .ant-tabs-tab-btn {
            padding: 8px 4px;

            .tab-title {
                font-size: 11px;
            }
        }
    }
}

// 499 end
@media (max-width:374px) {
    .support-page {
        .tabs-wrapper .ant-tabs-nav-wrap {

            [class^=icon-] {
                font-size: 20px;
            }

            .icon-image {
                min-width: 20px;

                svg {
                    max-width: 24px;
                    height: auto;
                }
            }

            .ant-tabs-tab+.ant-tabs-tab {
                margin-left: 5px;
            }

            .ant-tabs-tab-btn .tab-title {
                font-size: 10px;
            }
        }

        .condition-base-data {
            font-size: 17px;
        }
    }
}