// Flights Features
.featuresGlobalDetails {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    z-index: 999;
    color: #435b75;
    background-color: rgba(67, 91, 117, 0.5);
    font-family: "Ubuntu", sans-serif;

    .noRoomsAvailable h5 {
        padding: 0 20px;
    }

    .infoToolTip {
        margin-left: 3px;
    }

    .modalScroll {
        height: auto;
        max-height: none;

        &.slides_2 {
            // width: 1000px;
        }
    }

    .noRoomsAvailable {
        max-width: 450px;
        margin: 0 auto;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h5 {
            margin: 20px 0;
            font-size: 14px;
        }

        h3 {
            font-size: 21px;
        }

        .btn {
            max-width: 160px;
        }
    }

    .flightFeaturesContent {
        position: relative;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
        background-color: #e6e7ee;
        border-radius: 10px;
        z-index: 100;
        max-width: 1200px;
        width: max-content;

        .slick-initialized .slick-slide {
            margin: 0px 0px;
        }

        >h3.modalTitle {
            font-size: 18px;
            line-height: 22px;
            color: #435b75;
            padding-left: 100px;
            margin-bottom: 30px;
        }

        .closeModal {
            position: absolute;
            top: 30px;
            right: 20px;
            background: none;
            border: none;
        }
    }

    ul {
        border-bottom: 1.13px solid #C9D3DF;
        padding: 0 100px;
        margin-bottom: 15px;

        .tabLink {
            background: none;
            border: none;
            padding-bottom: 9px;
            margin-right: 50px;
            border-bottom: 1px solid #C9D3DF;
            margin-bottom: -1px;
            font-size: 16px;
            line-height: 19px;

            &.active,
            &:hover {
                color: #1d6ec9;
                border-color: #1d6ec9;
            }
        }
    }

    .colWrapper {
        display: flex;

        &.footer {
            background-color: #3A405F;
            color: $white;
            border-radius: 0 0 12px 12px;
        }

        &.topWrapper .col:first-child {
            color: #435b75;
            font-size: 16px;
            line-height: 24.91px;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            small {
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }

    .col {
        padding: 0;
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 32px;
        min-height: 25px;
        font-size: 14px;
        border-bottom: 1px solid #E3EBF3;
        min-height: 59px;

        img {
            max-height: 16px;
            margin-right: 7px;

            &.allowed {
                max-height: 23px;
            }
        }

        &.first-child {
            min-height: 100px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .legWrapper {
        border-bottom: 1.13px solid #C9D3DF;

        .col:nth-child(2),
        .col:nth-child(3) {
            background-color: rgba(110, 110, 115, 0.05);
        }

        .col:nth-child(4) {
            background-color: rgba(77, 0, 255, 0.05);
        }

        .colWrapper:last-child .col {
            padding-bottom: 38px;
        }

        .col:first-child {
            align-items: flex-start;
        }
    }

    .flightIcon {
        margin: 0 5px;
    }

    .classLabel {
        border: 1px solid #C9D3DF;
        border-radius: 6px;
        width: 225px;
        min-height: 48px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        // position: absolute;
        background: $white;
        padding: 10px !important;

        &.popular {
            border: 1px solid #1d6ec9;
            color: #1d6ec9;

            span {
                position: absolute;
                top: -9px;
                right: -11px;
                background-color: #1d6ec9;
                border-radius: 9px;
                width: 55px;
                height: 18px;
                color: #FFFFFF;
                font-size: 10px;
                line-height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }

        &.seatsAvailable {
            span {
                position: absolute;
                top: -9px;
                right: -11px;
                background-color: red;
                border-radius: 9px;
                width: max-content;
                padding: 0 5px;
                height: 18px;
                color: #FFFFFF;
                font-size: 10px;
                line-height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }
    }

    .wrapperRow {
        display: flex;
        padding: 0 100px 30px;

        &:first-child {
            max-height: calc(100vh - 222px);
            height: 100vh;
            overflow: auto;
        }

        >.rowWrapper {

            // padding-top: 37px;

            .legFeatureWrapper {
                background: none;
                position: relative;

                // &:after {
                //     content: "";
                //     background-color: #C9D3DF;
                //     height: 1px;
                //     position: absolute;
                //     bottom: 0px;
                //     width: 1000px;
                // }

                .col:first-child {
                    // min-height: 25px !important;
                }
            }

            .col {
                align-items: center;
                color: #435b75;
                font-size: 14px;
                line-height: 17px;
                flex-direction: row;
                justify-content: flex-start;

                &:first-child {
                    // margin-bottom: 35px;
                }

                small {
                    font-size: 10px;
                    margin-top: 2px;
                    margin-left: 4px;
                    line-height: 18.91px;
                }

                &:last-child {
                    margin-bottom: 0 !important;
                    //padding-bottom: 29px !important;
                    // border-bottom: none;
                }
            }

            .legFeatureWrapper {
                margin: 0;
                min-width: 300px;
            }

        }

        .footerLabel {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;

            h4,
            span {
                font-size: 14px;
                line-height: 17px;
            }

        }

        .featureColWrapper {
            >.col:first-child {
                padding-top: 30px;
            }

            &:first-child .col:first-child {
                padding-top: 0px;
            }

            .legFeatureWrapper {
                .col:first-child {
                    // min-height: 59px;
                }
            }
        }

        &.footer {
            width: 100%;
            height: 121px;
            background-color: #e6e7ee;
            border-radius: 0 0 12px 12px;

            .slick-slider {
                background-color: #e3ebf3;
                border-radius: 5px;
                box-shadow: inset 2px 2px 7px 0 rgb(25 25 25 / 20%);
                // display: flex;
                margin: 5px 0;
                height: calc(100% - 5px);
            }

            .rowWrapper {
                width: 100%;
                display: flex;

            }

            button {
                // background-color: #FFFFFF;
                // border: 1px solid #C9D3DF;
                // border-radius: 4px;
                // width: 135px;
                // height: 36px;
                // color: #435b75;
                margin-top: 12px;
                padding: 5px;
                height: 35px;
                min-width: 140px;

                &::before {
                    display: none;
                }
            }

            .col {
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: #435b75;
                border: none;
            }

            .col {
                border: none !important;

                span {
                    color: #1D6EC9;
                    font-size: 18px;
                    letter-spacing: -0.45px;
                    line-height: 22px;
                }
            }

            .slick-current .featureFooterWrapper {
                margin: 0;
                margin-top: 5px;
                margin-bottom: 5px;


            }
        }

        .slick-track,
        .slick-current .featureColWrapper {
            margin: 0;
        }

        .featureColWrapper:last-child {
            .legFeatureWrapper:after {
                display: none;
            }
        }
    }

    .featureColWrapper {

        // background-color: rgba(110, 110, 115, 0.05);
        // margin: 0 28px;
        &.last .legFeatureWrapper {
            background-color: rgba(29, 110, 201, 0.1);
        }
    }

    .featureColWrapper.first {
        min-width: 300px;
        max-width: 300px;
        background: none;
        margin: 0;

        .col {
            margin: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;

            svg {
                margin-right: 15px;
                max-height: 16px;

                * {
                    fill: #435b75;
                }
            }
        }
    }

    .slick-slider {
        width: calc(100% - 300px);

        // width: 100%;

        .featureColWrapper {
            // padding-top: 22px;
        }

        .featureColWrapper,
        .featureFooterWrapper {
            min-width: 225px;
            margin:5px 0;
        }

        .featureColWrapper {
            margin: 0;
        }

        .slick-list {
            height: auto;
            object-fit: none;
            position: inherit;

        }
        .slick-arrow:before {
            font: normal bold normal 24px/1 FontAwesome;
        }
        .slick-next:before {
            right: 0;
        }
        .slick-arrow.slick-disabled {
            opacity: 0.6;
        }
    }

    .legFeatureWrapper {
        background-color: rgba(110, 110, 115, 0.05);
        min-width: 221px;
        // max-width: 221px;
        margin: auto;

        .col:last-child {
            margin-bottom: 0;
            // padding-bottom: 30px;
        }
    }

    .modalTitle {
        text-align: center;
        padding: 5px 0 15px;
    }

    .footer {
        box-shadow: none;
    }

    .flightFeaturesContent {
        padding: 10px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;

        .closeModal {
            top: 10px;
            right: 10px;
        }

        .modalScroll {
            background-color: #FFFFFF;
            border-radius: 13px;
            box-shadow: 5px 5px 11px 0 rgba(6, 29, 47, 0.1);
            // height: calc(100vh - 140px);
            padding: 10px;

            .modalScrollInner {
                background-color: #FFFFFF;
                border-radius: 9px;
                box-shadow: inset 2px 2px 7px 0 rgba(25, 25, 25, 0.2);
            }
        }

    }

    .wrapperRow {
        padding: 0;

        >.rowWrapper .legFeatureWrapper {
            .col {

                padding-left: 16px;
            }
        }

        &.footer {
            background-color: transparent;
            border: 0;
            border-top: 1px solid #E3EBF3;
        }
    }

    .col {
        padding-bottom: 16px;
        padding-top: 16px !important;
        background-color: transparent;
        border-left: 1px solid #E3EBF3;
    }

    .classLabel {
        border: 0;
        height: 64px;
        background-color: transparent;
    }

    .legFeatureWrapper {
        background-color: transparent !important;
    }

    .slick-list .classLabel {
        padding: 8px !important;
        width: auto;
        display: block;
        border-left: 1px solid #E3EBF3;
        border-radius: 0;
        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        background-color: transparent;

        span {
            top: 2px !important;
            right: 3px !important;
        }

        .classLabelInner {
            background-color: #E3EBF3;
            border-radius: 5px;
            box-shadow: inset 2px 2px 7px 0 rgba(25, 25, 25, 0.2);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;
            line-height: 48px;
            color: #1D6EC9;

            @media (max-width:991px) {
                line-height: 1;
                font-size: 13px;
            }
        }
    }

    .wrapperRow:first-child {
        height: 65vh;
    }

    .flightModalTabs {
        background-color: #FFFFFF;
        border-radius: 6px 6px 0 0;
        box-shadow: 5px 5px 11px 0 rgba(6, 29, 47, 0.1);
        padding: 10px;
        margin: 0 40px;

        >ul {
            border-radius: 6px;
            box-shadow: inset 2px 2px 7px 0 rgba(25, 25, 25, 0.2);
            list-style: none;
            margin: 0;
            display: flex;
            text-align: center;
            border: 0;
            padding: 0;
            display: flex;
            justify-content: center;

            >li {
                padding: 0 10px 10px;
                border-left: 0.5px solid rgba(173, 186, 202, 0.2);
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
                overflow: hidden;

                &:first-child {
                    border: none;
                }
            }

            h4 {
                padding-top: 22px;
                padding-bottom: 10px;
            }
        }

        .stopListTabs {
            background-color: #E3EBF3;
            border-radius: 22.5px;
            white-space: nowrap;
            overflow: hidden;
            overflow-x: auto;

            .tabLink {
                margin-right: 0;
                padding: 12px 15px;
                border: 0;
                color: #8696BC;
                font-size: 16px;
                line-height: 19px;
                min-width: 130px;

                svg {
                    margin: 0 10px;
                }
            }

            .active {
                background-image: linear-gradient(135deg, #178BFE 0%, #00E1ED 100%);
                border-radius: 22.5px;
                color: #fff;

                svg * {
                    fill: $white;
                }
            }
        }
    }
}

// Responsive css Start
@media (max-width:1200px) {
    .featuresGlobalDetails {
        display: flex;
        align-content: center;
        justify-content: center;

        .flightFeaturesContent {
            top: auto;
            transform: translateY(0);
        }

        ul {
            padding: 0 60px;
        }

        .flightFeaturesContent {
            max-width: 90%;
        }

        .legFeatureWrapper,
        .slick-slider .featureColWrapper,
        .slick-slider .featureFooterWrapper {
            min-width: 100%;
        }

        .slick-slide {
            padding:0px;
        }

        .wrapperRow {
            @media (orientation: landscape) {
                &:first-child {
                    height: 50vh !important;
                }
            }

            @media (orientation: landscape) and (max-height: 400px) {
                &:first-child {
                    height: 40vh !important;
                }
            }


            >.rowWrapper .legFeatureWrapper {
                min-width: 100%;
            }

            .slick-slider {
                width: calc(100% - 250px);
            }

            .featureColWrapper.first {
                min-width: 250px;
                max-width: 250px;
            }

            .featureColWrapper {
                position: relative;

                .classLabel {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

// 1200 end
@media (max-width:991px) {
    .featuresGlobalDetails {
        ul {
            padding: 0 30px;
        }

        .flightFeaturesContent {
            max-width: 95%;
        }
        .classLabel {
            width: 100%;
        }

        .wrapperRow {

            .featureColWrapper.first {
                min-width: 200px;
                max-width: 200px;
            }

            .slick-slider {
                width: calc(100% - 200px);
            }
        }
    }
}

// 991 end
@media (max-width:767px) {
    .featuresGlobalDetails {
        background-color: transparent;
        box-shadow: none;
        border: none;

        .flight-info-wrap {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
        }

        .col {
            border: none;
        }

        .flightModalTabs {
            margin: 0 0 25px;
            box-shadow: none;
            border: none;
            padding: 0;
            border-radius: 0;
            background-color: transparent;

            >ul {
                box-shadow: none;
                border: none;
                border-radius: 0;
                display: block;
                padding-left: 15px;

                li {
                    padding: 0;
                }

                h4 {
                    color: #435B75;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 20px;
                    text-align: left;
                    padding-bottom: 25px;
                    padding-top: 10px;
                }
            }

            .stopListTabs {
                background-color: #ffffff;
                border-radius: 22.5px 0 0 22.5px;
                display: flex;
                overflow: hidden;
                overflow-x: auto;
                white-space: nowrap;

                button {
                    min-width: 135px;
                }
            }
        }

        .flightFeaturesContent {
            background-color: #ffffff;
            box-shadow: none;
            border: none;
            height: 100%;
            border-radius: 0;
            padding: 0;

            .modalScroll {
                padding: 0;
                box-shadow: none;
                border: none;
                border-radius: 0;
                background-color: transparent;
                max-height: calc(100% - 70px);
                overflow-y: auto;
            }
        }

        .flightFeatures-top {
            padding: 26px 16px 15px;
            // background-color: #435b75;

            .modalTitle {
                margin-bottom: 0px;
                padding-bottom: 0;
                text-align: left;
                font-size: 18px;
                line-height: 22px;
                font-weight: normal;
            }

            .closeModal {
                color: #435b75;

                svg {
                    * {
                        fill: #435b75;
                    }
                }
            }

            ul {
                padding: 0;
                margin-bottom: 0;
            }
        }

        ul {
            padding: 0 16px;
            border: none;

            .tabLink.active,
            .tabLink:hover {
                // color: #ffffff;
                // border-color: #ffffff;
            }
        }

        .col {
            margin-bottom: 10px;
        }

        .price-btn-wrapper {
            .btn {
                &::before {
                    display: none;
                }
            }
        }

        .box-heading-col {
            max-width: 100%;
            flex: 0 0 100%;
            padding: 0;
            display: block;
        }

        .wrapperRow {
            padding: 0px;
            &:first-child {
                height: 100% !important;
            }
            .featureColWrapper>.col:first-child {
                padding-top: 0;
            }
        }

        .wrapperRow:first-child {
            height: 100%;
            max-height: 100%;
            padding-bottom: 130px;
        }

        .col {
            min-height: auto;
        }

        .box-heading {
            background-color: transparent;
            border: none;
            border-radius: 6px 6px 0 0;
            padding: 0;

            h3 {
                color: #1D6EC9;
                font-weight: normal;
                font-size: 17px;
                line-height: 20px;
                text-align: center;
            }

            .ant-radio-wrapper {
                display: flex;
            }

            .ant-radio {
                + {
                    span {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                    }
                }
            }
        }

        .legFeatureWrapper {
            display: flex;
            background-color: transparent !important;
            flex-wrap: wrap;
            max-width: 100%;
            padding: 0 15px;
        }

        .featureColWrapper {
            background-color: #E6E7EE;
            border: 1px solid #DAE2EE;
            border-radius: 10px;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 rgba(255, 255, 255, 0.5);
            max-width: calc(100% - 30px);
            margin: 0 auto 15px;
        }

        .info-col {
            flex-direction: column;
            max-width: 33.33%;
            flex: 0 0 33.33%;
            text-align: center;
            padding-bottom: 5px;

            h3 {
                margin-bottom: 11px;
                color: #435b75;

            }

            h4 {
                font-size: 11px;
                line-height: 13px;
                font-weight: 400;
                margin-bottom: 6px;
                color: #435b75;
            }

            h5 {
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                color: #435b75;
            }
        }

        .flightFeaturesContent {
            max-width: 100%;
            background-color: #E6E7EE;
        }

        .wrapperRow {
            flex-direction: column;

            .featureColWrapper.first {
                min-width: 100%;
                max-width: 100%;
            }

            .price-btn-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 8px auto;
                // width: calc(100% - 16px);
                // max-width: calc(100% - 16px);
                flex: 0 0 100%;
                padding: 11px 10px;
                background-color: #E6E7EE;
                border: 1px solid #DAE2EE;
                border-radius: 5px;
                box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 rgba(255, 255, 255, 0.5);

                .btn {
                    min-width: 100px;
                    padding: 9px;
                    font-size: 14px;
                    line-height: 17px;
                }

                h4 {
                    color: #1D6EC9;
                    font-size: 10px;
                    line-height: 18px;
                    font-weight: 500;

                    strong {
                        color: #1D6EC9;
                        font-size: 16px;
                        letter-spacing: -0.4px;
                        line-height: 19px;
                    }
                }
            }
        }
    }

}

// 767 end