@media (max-width:991px) {
    .listingPageWrap, .detailPageWrap {
    .inputRange {
        width: calc(100% - 20px);

        .input-range__track {
            height: 6px;
            border-radius: 3px;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #fff;
            border: solid 1px #d2dae6;
            background: none;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);

            .input-range__track--active {
                border-radius: 4px;
                background-color: #1d6ec9 !important;
                box-shadow: none;
                background: rgb(63, 117, 255);
                border: 1px solid rgb(63, 117, 255);
            }

            .input-range__slider {
                width: 24px;
                height: 24px;
                transform: translateY(-50%);
                margin-top: -5px;
                object-fit: contain;
                box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #d2dae6 !important;
                background-color: #f0f1f6 !important;
            }
        }
    }
}
}