// navigation toggle menu
.fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.support-page-url {
  a {
    padding-top: 4px !important;
  }

  img {
    max-width: 26px;
  }

  .support-mobile-url {
    display: none;
  }
}

.landingPageWrap,
.listingPage,
.dashboard-pages,
.FaqPageWrap {
  header {
    .menuBtn {
      border: none;
      background: none;

      .navbar-toggler-icon span {
        margin: 0.1px;
      }
    }
  }

  .navigation {
    .dropdownMenu {
      button {
        color: #fff;
        border: none;
        text-align: left;
        background: transparent;
      }
    }

    .logout-btn {
      button {
        color: #1d6ec9;
      }
    }
  }
}


.profile-dropdown {
  top: 34px;
  box-sizing: border-box;
  width: 150px;
  left: -15px;
  overflow: hidden;
  padding: 5px;
  display: none;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
  border: solid 1px #d2d9e7;
  background-color: #e6e7ee;
  position: absolute;
  z-index: 99;

  @media (max-width: 991px) {
    left: 25px;
  }

  .dropdown-content {
    display: block;

    .profile-info {
      display: flex;
      flex: 100%;
      flex-direction: row;
      padding: 2px;
      margin-bottom: 5px;

      .user {
        width: 35%;
        height: 50px;
        flex: 0 0 35%;
        justify-content: flex-end;

        .user-img {
          border-radius: 100px;
          padding: 4px;
          width: 50px;
          height: 50px;
          box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
          border: solid 1px #dae2ee;
          background-color: rgba(255, 255, 255, 0.35);

          .img {
            background-image: url('../../../img/profileDetault.jpeg');
            background-size: cover;
            height: 100%;
            border-radius: 100px;
            z-index: 99;
            background-repeat: no-repeat;
          }
        }
      }

      .user-info {
        width: 65%;
        padding: 9px 8px 9px 8px;
        flex: 0 0 65%;
      }


    }

    h4 {
      font-size: 12px;
      color: #1d6ec9;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .view-profile {
      font-size: 10px;
      color: rgb(87, 85, 85);
      font-weight: 400;
      margin-top: 4px;
      display: contents;
    }

    .view-profile:hover {
      color: #1d6ec9;
    }

    h5 {
      font-size: 12px;
      color: #1d6ec9;
      font-weight: 400;
    }

    .my-booking {
      margin-bottom: 5px;
      padding: 6px 6px;

      a {
        font-size: 12px;
        color: #1d6ec9;
        font-weight: 400;
        line-height: 14px;
        padding: 0px;
        letter-spacing: normal;
      }
    }

    .logout-btn {
      text-align: left;
      padding: 6px 6px;
      margin-bottom: 5px;

      .log-out {
        font-size: 12px;
        width: 100%;
        text-align: left;
        background: none;
        border: none;
        color: #1d6ec9;
        padding: 0px 0px;

      }

      button:hover {
        color: gray;
      }
    }

    .my-booking:hover,
    .logout-btn:hover {
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      border-color: #d2dae6;
      border-radius: 6px;
    }
  }
}

// profile-page drop down

.navigation {
  ul {
    margin-bottom: 0;

    a {
      padding: 8px 14px 8px 25px;
    }

    li.dropdownMenu {
      position: relative;
      display: flex;
      flex-direction: column;

      >button {
        padding-left: 56px;
        padding-right: 20px;
        padding-top: 8px;
        padding-bottom: 10px;
        border: none;
        background: transparent;
        color: #1d6ec9;
        text-align: left;

        .fa-angle-down,
        [class^="icon-"] {
          position: absolute;
        }

        [class^="icon-"] {
          left: 28px;
          top: 8px;
        }

        .fa-angle-down {
          right: 3px;
          top: 8px;
          line-height: 17px;
          margin-top: 0px;
        }

      }

      .currency-form {
        position: absolute;
        top: 32px;
        width: 235px;
        display: block;
        flex-direction: column;
        padding: 7px;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        border: solid 1px #d2d9e7;
        background-color: #e6e7ee;
        z-index: 99;

        .currency-form-btn {
          width: 100%;
          height: 30px;
          border: none;
          color: #fff;
          text-align: center;
          border-radius: 10px;
          margin: 10px auto;
          text-align: center;
          background-image: linear-gradient(to right, #1377c4, #00ceda);
          box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 20%), -2px -2px 5px 2px #ffffff;

          &:hover {
            background-image: linear-gradient(to right, #00ceda -12%, #1377c4);
          }
        }

        .currency-form-group {
          margin-bottom: 7px;

          .currency-form-label {
            font-size: 14px;
            color: #1d6ec9;
            display: block;
            line-height: 16px;
            margin-bottom: 8px;
            padding: 0px 5px;
          }

          .css-b62m3t-container {
            .custom-select__control {
              border: none;
              border-radius: 6px;
              cursor: pointer;
              box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
              -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
              background-image: linear-gradient(to right, #ececf3, #e6e7ee);
              min-height: auto;

              .css-qc6sy-singleValue {
                color: #1d6ec9;
                font-size: 12px;
              }

              [class*=indicatorContainer] {
                .fa-angle-down:before {
                  color: #1d6ec9;
                }
              }
            }

            .custom-select__menu-list,
            .css-26l3qy-menu {
              background-color: #e6e7ee;
              padding: 3px;
              border-radius: 6px;


              .custom-select__option {
                border-radius: 6px;
                font-size: 12px;
                padding: 10px;


              }

              .css-9gakcf-option {
                box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                  inset -2px -2px 6px 0 #ffffff;
                border-color: #d2dae6;
                color: #435b75;
                background-color: rgb(230, 231, 238);
              }

              .css-1n7v3ny-option,
              .css-yt9ioa-option .css-9gakcf-option {
                background-color: rgb(230, 231, 238);
                color: #435b75;
                cursor: pointer;
                border-radius: 6px;
                cursor: pointer;
              }

              .css-1n7v3ny-option,
              .css-yt9ioa-option .css-9gakcf-option :hover {
                box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                  inset -2px -2px 6px 0 #ffffff;
                border-color: #d2dae6;
                color: #435b75;
              }
            }

            .custom-select__indicator-separator {
              display: none;
            }
          }
        }
      }

      &.active {

        .profile-dropdown,
        .currency-form {
          display: block;
        }
      }

      @media(hover: hover) and (pointer: fine) {

        &:hover,
        &:focus {

          .profile-dropdown,
          .currency-form {
            display: block;
          }
        }
      }
    }
  }
}

.header {
  padding: 9.71px 0;
  min-height: 55px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;

  .logoWrap {
    img {
      height: 32px;
      display: block;
    }

    .logo-sticky {
      display: none;
    }
  }
}

.header-removed-nav {
  .logoWrap {
    img {
      // margin: 0 auto;
    }
  }
}

.header-sticky {

  .indexPageWrap,
  .networkPageWrap {
    .fixedHeader {
      .header {
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        background-color: #eff0f5;
        padding: 9.71px 15px;
        min-height: 55px;

        .navigation a,
        .header-contact-us-link {
          color: #1d6ec9;
        }

        .signin-btn,
        .btnBorder3 {
          color: #1d6ec9;
          border-color: #1d6ec9;
        }

        .logoWrap {
          .logo1 {
            display: none;
          }

          .logo-sticky {
            display: block;
          }
        }
      }
    }
  }

}

.fixedHeader {
  .navigation {
    .dropdownMenu {
      button {
        color: #1d6ec9;
      }
    }
  }
}


.css-mxnygf,
.css-q9urix {

  .custom-select__menu,
  .css-26l3qy-menu {
    border-radius: 10px;
    padding: 5px;
    z-index: 999;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%)t;
    border: solid 1px #d2d9e7;
    background-color: #e6e7ee;
    font-size: 14px;

    .css-1n7v3ny-option,
    .css-9gakcf-option,
    .css-yt9ioa-option {
      background-color: transparent;
      color: #435b75;
      padding: 8px 8px;
      cursor: pointer;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

    }

    .css-yt9ioa-option {
      padding: 8px;
    }

    .css-9gakcf-option:active {
      background-color: transparent;
    }

    .css-yt9ioa-option:active {
      background-color: transparent;
    }

    .css-1n7v3ny-option:active {
      background-color: transparent;
    }

    .css-9gakcf-option {
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      border-color: #d2dae6;
      border-radius: 6px;
    }

    .css-1n7v3ny-option,
    .css-9gakcf-option :hover {
      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
      border-color: #d2dae6;
      padding: 8px;
      border-radius: 6px;
      background-color: transparent;
    }
  }

}



@media (max-width: 991px) {

  .landingPageWrap,
  .listingPage,
  .dashboard-pages,
  .FaqPageWrap,
  .bookingPageWrap,
  .membersCheckoutPageWrap,
  .siteBg {
    header {
      .navigation {
        z-index: 999;

        .dropdownMenu {
          button {
            color: var(--text-color3);

            i:before {
              color: var(--text-color3);
            }
          }
        }

        .profile {
          display: none;
        }

        ul {

          .mobile-profile,
          .btn-logout-2,
          .mobile-view-profile,
          .currency-form-wrap {
            display: block;
          }

          .select-lang-hide {
            display: none;
          }

          .btn-logout-2 {
            button {
              border: none;
              background-color: transparent;
              padding: 8px 25px;
              width: 100%;
              text-align: left;
              font-size: 14px;
              @media (max-width:480px) {
                padding: 0px 14px;
              }
            }

          }

          .currency-form-wrap {
            .currency-form {
              position: relative;
              width: 100%;
              display: flex;
              padding: 0px 25px;
              flex-direction: column;
              border-radius: 10px;
              background-color: transparent;
              z-index: 99;

              @media (max-width:480px) {
                padding: 0px 14px;
              }

              .currency-form-btn {
                width: 100%;
                height: 35px;
                border: none;
                color: #fff;
                text-align: center;
                border-radius: 10px;
                margin: 15px auto;
                text-align: center;
                background-image: linear-gradient(to right, #1377c4, #00ceda);
                box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 20%), -2px -2px 5px 2px #ffffff;

                &:hover {
                  background-image: linear-gradient(to right, #00ceda -12%, #1377c4);
                }
              }

              .currency-form-group {
                margin-bottom: 7px;

                .currency-form-label {
                  font-size: 14px;
                  color: #1d6ec9;
                  display: block;
                  line-height: 16px;
                  padding: 8px 0px;
                  margin-bottom: 2px;
                }

                .css-b62m3t-container {
                  .custom-select__control {
                    border: none;
                    border-radius: 6px;
                    cursor: pointer;
                    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                    -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                    min-height: auto;

                    .css-qc6sy-singleValue {
                      color: #1d6ec9;
                      font-size: 12px;
                    }

                    .css-319lph-ValueContainer {
                      padding: 6px 8px;
                    }
                  }

                  .css-1w9j89e-menu {
                    border-radius: 6px;
                  }

                  .custom-select__menu-list,
                  .css-26l3qy-menu {
                    background-color: #e6e7ee;
                    padding: 3px;
                    left: 0px;
                    width: 100%;
                    border-radius: 6px;


                    .custom-select__option {
                      border-radius: 6px;
                      font-size: 12px;
                      padding: 10px;

                    }

                    .css-9gakcf-option {
                      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                        inset -2px -2px 6px 0 #ffffff;
                      border-color: #d2dae6;
                      color: #435b75;
                      background-color: rgb(230, 231, 238);
                    }

                    .css-1n7v3ny-option,
                    .css-yt9ioa-option .css-9gakcf-option {
                      background-color: rgb(230, 231, 238);
                      color: #435b75;
                      cursor: pointer;
                      border-radius: 6px;
                      cursor: pointer;
                    }

                    .css-1n7v3ny-option,
                    .css-yt9ioa-option .css-9gakcf-option :hover {
                      box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                        inset -2px -2px 6px 0 #ffffff;
                      border-color: #d2dae6;
                      color: #435b75;
                    }
                  }

                  .custom-select__indicator-separator {
                    display: none;
                  }
                }
              }
            }
          }

          li {
            a {
              line-height: 1.5 !important;

            }

            button {
              color: #1d6ec9;
              padding: 8px 15px;
            }
          }
        }
      }
    }
  }

  .landingPage,
  .siteBg {
    .openMainDropdown {
      .navigation {
        display: block;
        z-index: 999;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        .cancel {
          position: absolute;
          right: 10px;
          display: block;
          top: 20px;
          border: none;
          background: transparent;

          .icon-close:before {
            color: #1d6ec9;
          }
        }

        ul {
          padding: 20px 0px;
          display: block;
          height: 100%;

          li.dropdownMenu>button {
            .fa-angle-down {
              right: 20px;
            }

          }
        }
      }
    }
  }
}

.navigation {
  ul {
    li.dropdownMenu {
      .currency-form {
        width: 99%;
        margin-left: 1px;

        .currency-form-btn {
          width: 100%;
        }
      }
    }

    .mobile-profile,
    .btn-logout-2,
    .mobile-view-profile,
    .currency-form-wrap {
      display: none;
    }


  }


}


@media (max-width:991px) {
  .nav-close {
    display: block;
  }
}

.nav-close {
  display: none;
}

@media (max-width: 991px) {
  .support-page-url {
    img {
      display: none;
    }

    .support-mobile-url {
      display: block;
    }
  }

  .mobile-profile {
    display: block;

    .mobile-profile-info {
      width: 100%;

      .profile-info {
        display: flex;
        padding: 0px 20px;

        .user {
          width: auto;
          height: 80px;
          flex: 0 0 auto;
          justify-content: flex-end;

          .user-img {
            border-radius: 100px;
            padding: 4px;
            width: 70px;
            height: 70px;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);
            overflow: hidden;

            .img {
              background-image: url('../../../img/profileDetault.jpeg');
              background-size: cover;
              background-position: center center;
              height: 100%;
              width: 100%;
              z-index: 99;
              background-repeat: no-repeat;
              border-radius: 100%;
            }

          }
        }

        .user-info {
          flex: 0 0 70%;
          padding: 13px 8px 10px 10px;

          a {
            padding: 8px 0px;
            font-size: 18px
          }

          h4 {
            font-size: 16px;
            font-weight: 400;
            padding: 10px 8px;
          }

          .view-profile {
            font-size: 16px;
          }

          .view-profile:hover {
            color: #435b75;
          }

          .my-booking {
            padding: 10px;

            a {
              font-size: 14px;
            }
          }

          .logout-btn button {
            font-size: 14px;
            padding: 4px;
          }
        }
      }
    }
  }
}






// profile-page dropdown mobile view Start
@media (max-width: 768px) {
  .mobile-profile {

    .mobile-profile-info {
      display: block;
      width: 100%;

      .profile-info {
        .user {
          flex: 0 0 15%;
          height: 75px;
          display: flex;
          justify-content: center;

          .user-img {
            width: 75px;
            height: 75px;
          }
        }

        .user-info {
          flex: 85%;
          padding: 13px 10px 9px 10px;
        }
      }

      h4 {
        font-size: 16px;
        line-height: 20px;
      }

      .view-profile {
        font-size: 12px;
      }

      .my-booking {
        padding: 9px;

        a {
          font-size: 14px;
        }
      }

      .logout-btn {
        button {
          font-size: 14px;
          line-height: 16px;
          padding: 2px;
          border: none;
          background-color: transparent;
        }
      }
    }

  }
}

@media (max-width: 480px) {
  .mobile-profile {
    .mobile-profile-info {
      .profile-info {
        display: flex;
        padding: 0px 15px;
      }
    }
  }

  .nav-popup ul a {
    padding: 8px 14px 8px 15px;
  }

  .landingPageWrap,
  .listingPage,
  .dashboard-pages,
  .FaqPageWrap,
  .profilePage {
    header {
      .navigation ul {
        .btn-logout-2 button {
          padding: 8px 15px;
          color: #1d6ec9;
        }

        .currency-form-wrap {
          .currency-form {
            padding: 0px 15px;
          }
        }
      }
    }

    .openMainDropdown {
      .navigation {
        .cancel {
          .icon-close:before {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {

  .siteBg .header .navigation ul a,
  .listingPage .header .navigation ul a {
    font-size: 12px;
  }

  .landingPageWrap .navigation .dropdownMenu button,
  .listingPage .navigation .dropdownMenu button,
  .dashboard-pages .navigation .dropdownMenu button,
  .FaqPageWrap .navigation .dropdownMenu button {
    font-size: 12px;
  }
}