.FaqPageWrap {
    .container {
        max-width: 1120px;


        .rightContent-bottom {
            padding-bottom: 30px;
        }

    }

    [class*=indicatorSeparator],
    [class*=indicatorContainer],
    [class*=IndicatorSeparator],
    [class*=IndicatorContainer] {
        display: none;
    }

    .leftContent {
        max-width: 23.2%;
        flex: 0 0 23.2%;

        .tabsWrapper {
            ul {
                a {
                    font-size: 17px;
                    font-weight: normal;
                    color: $white;
                    display: flex;
                    align-items: center;
                    padding: 12px 19px;

                    img {
                        margin-right: 10px;
                    }
                }

                li {
                    max-width: 220px;
                    padding-bottom: 4px;

                    &.active {
                        a {
                            color: #1d6ec9;
                            background-color: rgba(255, 255, 255, 0.6);
                            border-radius: 22.5px;
                        }
                    }
                }
            }
        }
    }

    .rightContent {
        .no-gutters {

            .selectWrap {

                [class*=control] {
                    min-width: 100%;
                    border-radius: 10px;
                    -webkit-box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                    -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                    border: solid 1px rgba(210, 218, 230, 0.6);
                    background-image: none;
                    background-color: rgba(255, 255, 255, 0.35);
                    min-height: 40px;
                    z-index: 1;
                    font-size: 17px;
                    color: rgba(67, 91, 117, 0.6);
                    padding-right: 29px;
                    padding-left: 5px;
                    cursor: pointer;

                    .css-1hb7zxy-IndicatorsContainer {
                        display: none;
                    }

                    .css-319lph-ValueContainer {
                        padding: 0px 8px;
                        margin-top: -4px;
                        color: #7d91a9;
                    }

                    .css-14el2xx-placeholder,
                    [class*=placeholder] {
                        color: rgba(26, 36, 46, 0.6);
                    }

                    .css-qc6sy-singleValue,
                    [class*=control] {
                        color: #435b75;
                    }

                }

                :after {
                    right: 12px;
                }
            }
        }
    }

    .title {
        padding-bottom: 24px;

        h1 {
            font-size: 23px;
            font-weight: normal;
            color: $white;
        }
    }

    .carrierInfoWrap {
        margin-bottom: 15px;

        h2 {
            font-size: 23px;
            font-weight: normal;
            color: $white;
        }

        .formControl {
            padding: 7.5px 15px 10px;
            border-radius: 10px;
            background-color: rgba(255, 255, 255, 0.6);
            border: none;
            outline: none;
            box-shadow: none;
            line-height: 1.64;
            font-size: 14px;
            font-weight: normal;
            color: #1d6ec9;
            width: 100%;
            letter-spacing: normal;
            padding-left: 32px;
            @include ubuntu(400);

            @include placeholder-text {
                color: #1d6ec9;
                font-weight: 400;
            }
        }

        .searchWrapper {
            max-width: 45.23%;
            flex: 0 0 45.23%;

            .formGroup {
                position: relative;

                &:before {
                    content: "";
                    @include background("../img/search-blue.png");
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    .carrierInfoWrapper {
        padding: 0 20px;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.8);

        .carrierInfo {
            display: flex;
            justify-content: space-between;
            border-bottom: solid 1px rgba(67, 91, 117, 0.2);
            align-items: center;
            min-height: 58px;

            &:last-child {
                border: none;
            }

            .headingInfo {
                align-items: center;
                padding: 7px 0 10px;

                .icon {
                    margin-right: 12px;
                }
            }

            h3 {
                font-size: 17px;
                font-weight: normal;
                line-height: 1.35;
                letter-spacing: normal;
                color: #1d6ec9;
            }

            h4 {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.64;
                letter-spacing: normal;
                color: #435b75;
            }
        }

        .checkInInfo {
            max-width: 37.2%;
            flex: 0 0 37.2%;

            ul {
                display: flex;

                li {
                    padding: 0 15px;

                    a {
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 1.64;
                        letter-spacing: normal;
                        color: #1d6ec9;
                    }
                }
            }
        }
    }

    .showMoreLink {
        padding-bottom: 31px;

        button {
            border-radius: 10px;
            border: solid 1px #1d6ec9;
            background-color: rgba(255, 255, 255, 0.52);
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1d6ec9;
            padding: 8.5px 15px;
            min-width: 140px;
        }
    }

    .faqWrapper {
        padding: 3px 0px 0;
        border-radius: 8px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.8);
        margin-bottom: 5px;

        .faqContent {
            border-bottom: solid 1px rgba(67, 91, 117, 0.2);


            &:last-child {
                border: none;

                .faqAnswer {
                    padding-bottom: 10px;
                }
            }

            button {
                width: 100%;
                border: none;
                outline: none;
                box-shadow: none;
                background-color: transparent;
                font-size: 17px;
                @include ubuntu(500);
                line-height: 1.35;
                letter-spacing: normal;
                color: #435b75;
                padding: 17px 0 10px;
                position: relative;
                text-align: left;
                cursor: pointer;
                padding: 18px 45px 19px 60px;

                &.active-btn {
                    &::before {
                        content: "\f106";
                    }
                }

                &::before {
                    content: "\f107";
                    display: inline-block;
                    font: normal bold normal 18px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    color: #1d6ec9;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    left: 26px;
                    top: 55%;
                    transform: translateY(-50%);
                }
            }

            .faqAnswer {
                padding: 0px 45px 0px 60px;
            }

            h3 {
                font-size: 17px;
                @include ubuntu(500);
                line-height: 1.35;
                letter-spacing: normal;
                color: #435b75;
                margin-bottom: 10px;
                position: relative;

                // padding-left: 20px;
                span {
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 1.24;
                    letter-spacing: normal;
                    color: #435b75;
                    margin-bottom: 10px;
                    margin-top: 4px;
                    display: block;
                }

                // &:before {
                //   content: "";
                //   display: block;
                //   position: absolute;
                //   left: 0;
                //   top: 12px;
                //   width: 17px;
                //   height: 2px;
                //   background: #435b75;
                // }
            }

            p,
            li {
                font-size: 17px;
                font-weight: 300;
                line-height: 1.24;
                letter-spacing: normal;
                color: #435b75;
                margin-bottom: 10px;
            }

            ul {
                padding-left: 17px;

                ul {
                    padding-left: 20px;
                }
            }
        }
    }

    .pdf-text-wrap {
        max-width: 660px;
        margin: 20px auto;

        h6 {
            color: #ffffff;
            font-size: 17px;
            font-weight: 400;
            line-height: 1.3;

            @media (max-width:767px) {
                font-size: 14px;
            }
        }

        .pdf-image {
            max-width: 90px;
            flex: 0 0 90px;
            padding-left: 10px;

            img {
                max-width: 50px;
            }
        }
    }
}


.flight-faq-dropdown-section {
    padding: 10px 20px;
    margin-bottom: 80px;

    .row {
        padding: 8px 0;
        border-bottom: solid rgba(67, 91, 117, 0.2);
        align-items: center;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border: none;
        }
    }

    .dropdown {
        padding: 10px 0 15px;
        max-width: 45%;
        flex: 0 0 45%;
    }

    .section-title {
        max-width: 24%;
        flex: 0 0 24%;
    }

    h3 {
        font-size: 17px;
        font-weight: 500;
        color: #435b75;
        line-height: 1.3;
    }

    .selectWrap {
        position: relative;
        margin-right: 0;

        &:after {
            content: "";
            background-image: url("../../../img/dropdown-arrow.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: contain;
            height: 14px;
            width: 14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;

        }



        select {
            min-width: 100%;
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%),
                2px 2px 3px 0 rgb(0 0 0 / 15%);
            -webkit-box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%),
                2px 2px 3px 0 rgb(0 0 0 / 15%);
            -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
                2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px rgba(210, 218, 230, 0.6);
            background-image: none;
            background-color: rgba(255, 255, 255, 0.35);
            min-height: 40px;
            font-size: 17px;
            color: rgba(67, 91, 117, 0.6);
            padding-right: 28px;
        }
    }

    .button-wrap {
        text-align: center;

        .btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &::before {
                display: none;
            }
        }
    }
}




@media (min-width: 992px) {
    .FaqPageWrap {
        .mainContent {
            .pageInternalScroll {
                height: calc(100vh - 120px) !important;
                overflow-y: auto;


                >div {
                    overflow-x: hidden !important;
                }
            }


        }
    }
}

@media (max-width: 767px) {
    .flight-faq-dropdown-section {
        .selectWrap {
            min-width: 251px;
            max-width: 251px;
            .css-b62m3t-container {
                padding: 0px 8px;
                margin-top: -4px;
                color: #7d91a9;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}