@-webkit-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-moz-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-o-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-moz-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-o-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-webkit-keyframes textColour {
  0% {
    color: #fff;
  }

  60%,
  100% {
    color: #3BB2D0;
  }
}

@-moz-keyframes textColour {
  0% {
    color: #fff;
  }

  60%,
  100% {
    color: #3BB2D0;
  }
}

@-o-keyframes textColour {
  0% {
    color: #fff;
  }

  60%,
  100% {
    color: #3BB2D0;
  }
}

@keyframes textColour {
  0% {
    color: #fff;
  }

  60%,
  100% {
    color: #3BB2D0;
  }
}

.loader-text-wrap {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media (max-width:767px) {
    top: 30%;
    transform: translate(-50%, -30%);

    .loader-text {
      padding: 0 10px;
    }
  }
}

.loader-text-wrap .loader-text {
  margin-top: 420px;
  color: #2a93ae;
  font-family: 'sans-serif';
  font-size: 17px;
  line-height: 24px;
  font-weight: 300;

  @media (max-width:767px) {
    margin-top: 380px;
  }
}

.e-loadholder {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 240px;
  height: 240px;
  border: 5px solid #00959d;
  border-radius: 120px;
  box-sizing: border-box;
}

.e-loadholder:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: " ";
  display: block;
  background: #fff;
  transform-origin: center;
  z-index: 0;
}

.e-loadholder:after {
  width: 100px;
  height: 130%;
  -webkit-animation: outerRotate2 30s infinite linear;
  -moz-animation: outerRotate2 30s infinite linear;
  -o-animation: outerRotate2 30s infinite linear;
  animation: outerRotate2 30s infinite linear;
}

.e-loadholder .m-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  color: #888;
  text-align: center;
  border: 5px solid #2a93ae;
  border-radius: 100px;
  box-sizing: border-box;
  z-index: 20;
  text-transform: uppercase;
}

.e-loadholder .m-loader:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: " ";
  display: block;
  background: #fff;
  transform-origin: center;
  z-index: -1;
}

.e-loadholder .m-loader:after {
  width: 100px;
  height: 106%;
  -webkit-animation: outerRotate1 20s infinite linear;
  -moz-animation: outerRotate1 20s infinite linear;
  -o-animation: outerRotate1 20s infinite linear;
  animation: outerRotate1 20s infinite linear;
}

.e-loadholder .m-loader .e-text {
  font-size: 12px;
  font-size: 1rem;
  font-weight: normal;
  /* line-height: 130px;   */
  padding-top: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: textColour 5s alternate linear infinite;
  -moz-animation: textColour 5s alternate linear infinite;
  -o-animation: textColour 5s alternate linear infinite;
  animation: textColour 5s alternate linear infinite;
  display: block;
  width: 140px;
  height: 140px;
  text-align: center;
  border: 5px solid #3bb2d0;
  border-radius: 70px;
  box-sizing: border-box;
  z-index: 20;
  font-family: "Ubuntu";
}

.e-loadholder .m-loader .e-text.non-animation {
  padding-top: 60px;
}

.e-loadholder .m-loader .e-text:before,
.e-loadholder .m-loader .e-text:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: " ";
  display: block;
  background: #fff;
  transform-origin: center;
  z-index: -1;
}

.e-loadholder .m-loader .e-text:before {
  width: 110%;
  height: 40px;
  -webkit-animation: outerRotate2 4s infinite linear;
  -moz-animation: outerRotate2 4s infinite linear;
  -o-animation: outerRotate2 4s infinite linear;
  animation: outerRotate2 4s infinite linear;
}

.e-loadholder .m-loader .e-text:after {
  width: 40px;
  height: 110%;
  -webkit-animation: outerRotate1 8s infinite linear;
  -moz-animation: outerRotate1 8s infinite linear;
  -o-animation: outerRotate1 8s infinite linear;
  animation: outerRotate1 8s infinite linear;
}

#particleCanvas-White {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 50%;
  opacity: 0.1;
}

#particleCanvas-Blue {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
}

.w {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.w1 {
  -webkit-animation: w1anim 30s infinite;
  animation: w1anim 30s infinite;
}


.w2 {
  -webkit-animation: w2anim 30s infinite;
  animation: w2anim 30s infinite;
}

.w3 {
  -webkit-animation: w3anim 30s infinite;
  animation: w3anim 30s infinite;
}

.w4 {
  -webkit-animation: w4anim 30s infinite;
  animation: w4anim 30s infinite;
}

.w5 {
  -webkit-animation: w5anim 30s infinite;
  animation: w5anim 30s infinite;
}

.w6 {
  -webkit-animation: w6anim 30s infinite;
  animation: w6anim 30s infinite;
}


@-webkit-keyframes w1anim {
  0% {
    opacity: 0;
  }

  9% {
    opacity: 1;
  }

  18%,
  100% {
    opacity: 0;
  }
}


@keyframes w1anim {
  0% {
    opacity: 0;
  }

  9% {
    opacity: 1;
  }

  18%,
  100% {
    opacity: 0;
  }
}


@-webkit-keyframes w2anim {

  0%,
  18% {
    opacity: 0;
  }

  27% {
    opacity: 1;
  }

  36%,
  100% {
    opacity: 0;
  }
}


@keyframes w2anim {

  18%,
  0% {
    opacity: 0;
  }

  27% {
    opacity: 1;
  }

  36%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes w3anim {

  0%,
  36% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  54%,
  100% {
    opacity: 0;
  }
}

@keyframes w3anim {

  36%,
  0% {
    opacity: 0;
  }

  45% {
    opacity: 1;
  }

  54%,
  100% {
    opacity: 0;
  }
}


@-webkit-keyframes w4anim {

  54%,
  0% {
    opacity: 0;
  }

  63% {
    opacity: 1;
  }

  72%,
  100% {
    opacity: 0;
  }
}


@keyframes w4anim {

  54%,
  0% {
    opacity: 0;
  }

  63% {
    opacity: 1;
  }

  72%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes w5anim {

  72%,
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  88%,
  100% {
    opacity: 0;
  }
}


@keyframes w5anim {

  72%,
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  88%,
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes w6anim {

  88%,
  0% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes w6anim {

  88%,
  0% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.laoder-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  background-color: #ffffff;
  opacity: 1;
  z-index: 999999;
}

#particleCanvas-Blue {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  z-index: 1;
  background-color: #ffffff;
}


@media (max-width:767px) {
  .e-loadholder {
    width: 170px;
    height: 170px;
  }

  .e-loadholder .m-loader {
    width: 145px;
    height: 145px;
  }

  .e-loadholder .m-loader:after {
    width: 80px;
    height: 130%;
  }

  .e-loadholder .m-loader .e-text {
    font-size: 12px;
    /* line-height: 110px; */
    width: 110px;
    height: 110px;
    padding-top: 55px;
  }

}

@media (max-width:767px) {
  .e-loadholder .m-loader .e-text.non-animation {
    padding-top: 40px;
  }
}