.Terms-wrapper {
    .container {
        .rightContent-bottom {
            padding-bottom: 30px;
        }
    }

    .leftContent {
        max-width: 24.2%;
        flex: 0 0 24.2%;

        .tabsWrapper {
            ul {
                li {
                    max-width: 240px;
                    padding-bottom: 4px;
                }

                li.active a {
                    li.active a {
                        color: #1d6ec9;
                        background-color: rgba(255, 255, 255, 0.6);
                        border-radius: 22.5px;
                    }
                }

                a {
                    font-size: 16px;
                    font-weight: normal;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    padding: 12px 15px;

                    img {
                        width: 32px;
                        height: 25px;
                    }
                }
            }
        }
    }

    .welcome-text-wrap {
        text-align: center;
        padding: 150px 0;

        h1 {
            color: #ffffff;
            font-weight: 400;
            font-size: 24px;
        }
    }

    .card2 {
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
        background-color: rgba(255, 255, 255, 0.8);
        margin-bottom: 20px;
    }

    .content-wrapper {

        h1,
        h4 {
            margin-bottom: 12px;
        }

        p {
            margin-bottom: 8px;
            line-height: 17px;
        }

        h2 {
            margin-bottom: 10px;
        }

        h2,
        h3 {
            font-size: 17px;
            line-height: 21px;
        }

        h3 {
            margin: 10px 0 0;

            +h3 {
                margin-top: 6px;
            }
        }

        ul,
        ol {
            padding-left: 17px;
            margin-bottom: 8px;

            li {
                margin-bottom: 8px;
            }
        }
    }

    .pdf-content-wrapper {
        h3 {
            font-size: 19px;
            margin-bottom: 15px;
        }

        p {
            font-size: 14px;
        }

        .content-col {
            padding-right: 10px;
        }

        .image-col {
            max-width: 160px;
            flex: 0 0 160px;

            a {
                color: #435b75;
                display: flex;
                align-items: center;
                font-weight: 500;

                span {
                    margin-right: 20px;
                }
            }

            img {
                max-width: 50px;
            }
        }

        @media(max-width:579px) {
            .row {
                flex-direction: column;

                .content-col {
                    padding: 0;
                    padding-bottom: 30px;
                }

                .image-col {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }
        }
    }


    .heading-address-section {
        .col-heading {
            address {
                display: none;
            }
        }

        address {
            max-width: 210px;
            margin: 0 0 0 auto;
            font-weight: 400;
        }
    }
}


@media (max-width: 991px) {
    .Terms-wrapper {
        .leftContent {
            .tabsWrapper {

                ul {
                    li {
                        max-width: 230px;
                        flex: 0 0 230px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .Terms-wrapper {
        .leftContent {
            .tabsWrapper {
                ul {

                    li {
                        max-width: 190px;
                        flex: 0 0 190px;

                        a {
                            font-size: 14px;
                            padding: 10px 10px;

                            img {
                                width: 28px;
                                height: 21px;
                            }
                        }
                    }
                }
            }
        }

        .faqWrapper {
            padding: 15px;

        }

        .heading-address-section {
            .col-heading {
                max-width: 100%;
                flex: 0 0 100%;

                address {
                    display: block;
                    padding-bottom: 15px;
                }
            }

            .col-address {
                display: none;
            }

            address {
                margin: 0;
            }
        }
    }
}