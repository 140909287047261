.ReactModal__Overlay {
  z-index: 999;
  background: rgba(255, 255, 255, 0.15) !important;
}

// Login-page, forgot-password-page, reset-passoward-page error message style

.boxBg {
  max-width: 480px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;

  .textCnt {
    text-align: center;
    color: #435b75;

    h6 {
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 8px;
    }

    p {
      font-size: 17px;
      margin-bottom: 28px;
    }
  }

  // error-message-style

  .input-text-wrapper {
    position: relative;
    .star-red {
      position: absolute;
      top: 56px;
      left: 45px;
      font-size: 14px;
      color: red;
    }
  }
}

//   End error msg ui
.upgradePremiumModal {
  .popup-content {
    background-color: #FFFFFF;
    border-radius: 12px;
    max-width: 550px;
    width: 100%;
  }
  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    > svg {
      margin-bottom: 25px;
      margin-right: -50px;
      @media (max-width:389px) {
        margin-right: -35px;
      }
    }
    > p {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 25px;
      strong {
        color: #1178c5;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
    .premiumUpgradeBtn{
      background-color: rgba(241, 244,247, 0.6);
      border-radius: 28px;
      max-width: 296px;
      height: 56px;
      width: 100%;
      max-height: 100%;
      color: #000000;
      font-size: 16px;
      letter-spacing: -0.25px;
      line-height: 19px;
      border: none;
      padding: 4px;
      margin-bottom: 26px;
      strong {
        background-image: linear-gradient(266.61deg, #09CEDA 0%, #1278C5 100%);
        border-radius: 4px;
        width: 91px;
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: normal;
        text-transform: uppercase;
        margin-left: 10px;
      }
      .wrapper {
        background-color: #F1F4F7;
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-left: 24px;
        padding-right: 6px;
        span {
          background-color: #FFFFFF;
          border-radius: 100%;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
        }
      }
      
    }
    .type-anchor {
      margin-bottom: 50px;
      color: #D1D4D8;
      font-size: 14px;
      letter-spacing: -0.22px;
      line-height: 17px;
      background: none;
      border: none;
    }
  }
}
@media (max-width: 600px) {
  .upgradePremiumModal {
    .popup-content {
      max-width: 95%;
      padding: 30px 15px 0;
    }
  }
}

@media (max-width: 380px) {
  .upgradePremiumModal {
    .popup-content {
      max-width: 300px;
    }
  }
}