/*ListingPage common css Start*/
* {
  box-sizing: border-box;
}

.listingPageWrap {
  background: url(../../../img/hotellistingbg.jpg) 0 0 repeat-y;
  background-size: cover;

  .mainContent {
    overflow-x: hidden;
    padding-top: 0;
  }

  .DateRangePickerInput {
    .DateRangePicker_picker {
      bottom: 0 !important;
      top: 41px !important;

      .DayPicker {
        box-shadow: 3px 4px 15px 1px rgb(0 0 0 / 10%);
      }

    }
  }

  .mobileViewWrapper {
    display: none;
  }

  .comparePrice {

    &:hover,
    &:focus {

      .popup,
      .comparePriceContent {
        display: block;
      }
    }

    .comparePriceText {
      cursor: pointer;
      padding-left: 3px;
      display: inline-flex;
      align-items: center;
    }
  }

  .leftSideBar {
    margin-right: 0;
    max-width: 21.93%;
    flex: 0 0 21.93%;

    .hotel-name-search-card {
      .cardBody {
        input {
          height: 36px !important;
        }
      }
    }

    .btnBorder {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      line-height: 1.1;
    }

    .disabled {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: rgba(239, 240, 245, 0.8);

      &:hover {
        background-color: #1d6ec9;
        color: #ffff;
      }
    }

    .mapWrap {
      position: relative;
      overflow: hidden;
      border-radius: 10px;

      button {
        border: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

      }

      .inputWrap2 {
        input {
          @include placeholder-text {
            color: #435b75;
          }
        }
      }

      .mapCard {
        margin-bottom: 20px;
        padding: 9px 10px;

      }
    }

    .icon-close {
      &:before {
        color: $white;
      }
    }

    .priceBtn {
      button {
        margin-right: 20px;
      }
    }

    .btnBorder:hover {
      box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1),
        -1px -1px 4px 1px rgba(255, 255, 255, 0.25);
      -webkit-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1),
        -1px -1px 4px 1px rgba(255, 255, 255, 0.25);
    }

    .rate {
      float: none;
      display: flex;
      padding: 14px 0;

      .ratings {
        background-color: #93b1d1;
        height: 35px;
        width: 35px;
        margin: 0 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 14px;
        color: #435b75;
        cursor: pointer;

      }

      img {
        height: auto;
        width: 18px;
      }
    }

    button {
      font-size: 14px;
    }

    .priceRange {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;

      h4 {
        font-size: 17px;
        color: #435b75;
        font-weight: 400;
      }
    }
  }

  .mainWrap {
    .rightInfo {
      margin-bottom: 10px;

      h3 {
        font-size: 17px;
        font-weight: normal;
        padding-right: 10px;
      }

      .mobileSearchFilterButtons {
        margin-bottom: 15px;

        .d-flex {
          margin: 0 -3.5px;
        }

        .mobileButtons {
          border-radius: 17.5px;
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
          -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
          background-color: rgba(255, 255, 255, 0.3);
          font-family: 'Ubuntu';
          font-size: 14px;
          font-weight: normal;
          color: $white;
          min-width: 174px;
          min-height: 35px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          position: relative;
          margin: 0 3.5px;
          cursor: pointer;

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 15px;
            width: 15px;
            left: 30px;
          }



          .fa {
            margin-right: 10px;
          }
        }
      }
    }

    .sortWrapper {
      max-width: 27.7%;
      flex: 0 0 27.7%;
      border-radius: 12px;
      background-color: rgba(153, 215, 255, 04);
      padding: 7px 7px 7px 15px;
      min-width: 235px;

      .content {
        max-width: 100%;
        flex: 0 0 100%;
        font-size: 14px;
        line-height: normal;

        h6 {
          color: #1d6ec9;
          font-size: 14px;
          font-weight: 400;
        }
      }

      img {
        max-width: 12px;
      }

      .dropdown-arrow {
        @include background("../img/dropdown-arrow.png");
        background-position: center right;
        background-size: contain;
        width: 10px;
        height: 6px;
        text-align: right;
        cursor: pointer;
      }
    }

    .card2 {
      .col-width {
        max-width: 27.5%;
        flex: 0 0 27.5%;
      }

      .imgWrap {
        position: relative;
        width: 100%;
        text-align: center;
        padding: 9px;
        max-width: 27.39%;
        flex: 0 0 27.39%;

        .bg-image {
          // @include background("");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 100%;
          min-height: 120px;
          border-radius: 6px;
        }

        img {
          margin: 0 auto;
          height: 100%;
          width: 100%;
        }

        .savings {
          position: absolute;
          top: 17px;
          left: 17px;
          border-radius: 5px;
          background-color: $persian-green;
          font-size: 12px;
          color: $white;
          margin: 0;
          padding: 4px 11px;
        }
      }

      .cardContent {
        padding: 0 20px;

        .cartTitle {
          font-size: 19px;
          font-weight: 400;
        }

        .ratingComment {
          padding-left: 11px;
        }

        .innerCnt {
          height: 100%;
        }

        .compare-price-wrap {
          display: flex;
          justify-content: space-between;

        }

        .bulletList {
          padding: 9px 0 0;
          min-height: 70px;

          li {
            &:nth-child(3) {
              &:before {
                margin-left: 0;
              }
            }
          }

        }

        .bulletList {
          list-style: none;
          color: #435b75;
          font-size: 14px;

          li {
            display: inline;
            padding: 0px 5px;

            &+li {
              &:before {
                content: "";
                width: 4px;
                height: 4px;
                display: inline-block;
                vertical-align: middle;
                background-color: #435b75;
                border-radius: 50%;
                margin: 0 7px 0 5px;
              }
            }
          }
        }

        .bulletList :first-child {
          padding: 0px 5px 0px 0px;
        }

        .comparePrice {
          padding-bottom: 7px;
        }

        .location {
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
            margin-top: 1px;
          }
        }
      }
    }

    .infoBox {
      padding: 11px 0;
      justify-content: flex-start;

      .infoBoxWrapper {
        padding: 0 14px;
      }
    }

    .publicPrice {
      margin-top: 9px;

      span {
        font-size: 14px;

        &:nth-child(2) {
          // color: #435b75;
          color: #8198a7;
          font-weight: 500;
        }
      }
    }

    .fromPrice {
      margin-top: 8px;
      align-items: flex-start;

      &.singlePrice {
        // padding-top: 9px;
        // margin-top: 0;
        // align-items: center;
        // flex-direction: column;
        // justify-content: center;
        // text-align: center;
        min-height: 67px;
        // align-items: flex-end;

        @media (max-width:1010px) {
          min-height: 78px;
        }

        @media (max-width:991px) {
          min-height: 64px;
        }

        @media (max-width:767px) {
          min-height: 58px;
          align-items: center;
        }
      }

      span {
        color: #435b75;
        font-weight: 500;

        &:nth-child(2) {
          color: #435b75;
          font-weight: 500;
        }
      }

      p {
        color: #435b75;
      }
    }

    .priceDetails {
      margin-bottom: 4px;


      .price-heading {
        color: #1d6ec9;
        font-size: 12px;
        font-weight: 400;
      }

      .fa {
        color: #1d6ec9;
        font-size: 18px;
        margin-left: 5px;
      }
    }

    .savings {
      margin-top: 5px;

      span {
        color: #00b7a6;
        font-size: 14px;

        &:nth-child(2) {
          font-weight: 500;
        }
      }
    }

    .not-found {
      display: flex;
      min-height: calc(100vh - 300px);
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
      background-color: rgba(243, 244, 248, 0.8);
      vertical-align: middle;
      align-items: center;
      min-height: 390px;
      justify-content: center;
      margin: 15px 0;
      padding: 0 10px;
      color: #435b75;

      h2 {
        color: #435b75;
      }

    }
  }


  .infoBoxWrapper {
    position: relative;
    height: 100%;

    .buttonAndTextGroup {
      position: relative;

      .savings {
        margin: 10px 0 7px;
      }
    }

    .buttonGroups {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      min-height: 95px;

      @media(max-width:1199px) {
        height: 96px;
      }

      @media(max-width:767px) {
        height: auto;
      }

      .buttonSpace {
        padding-top: 14px;

        .btnBorder2 {
          font-size: 11px;
          padding: 5.5px 5px;
          max-width: 162px;
          background-color: transparent;
          margin: 0px 0px;

          i {
            font-size: 16px;
            width: 18px;
            margin-right: 3px;
          }
        }

        .btn-select-room {
          margin-top: 0;
          top: 0;
        }

        &.upgradeBtn {
          display: flex;
          width: 100%;
          flex: 0 0100%;
          align-items: center;
          justify-content: space-between;

          .btn {
            min-width: 110px;
            padding: 7.5px 0;
            border-radius: 10px;
            top: 0px;
          }

          button {
            color: #1d6ec9;
            font-size: 14px;
            font-weight: normal;
            padding: 0 8px;
            border: none;
            background-color: transparent;
          }
        }
      }
    }

  }
}

/*ListingPage common css End*/

.filterCnt {
  background: rgba(255, 255, 255, 0.3);
  padding: 9.6px px 0;
  min-height: 55px;
}

// listing page custome style Start
.listingPage {
  .leftSideBar {
    .blueBtn {
      border-radius: 15px;
      padding: 7px 10px;
      box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 10%), -1px -1px 4px 1px rgb(255 255 255 / 25%);
      -webkit-box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 10%), -1px -1px 4px 1px rgb(255 255 255 / 25%);
    }

    .cardHead {
      .clear {
        background: transparent;
        border: none;
        color: #fff;
        font-size: 14px;
      }

    }

    .card {
      .cancelBtn {
        background: transparent;
        border: none;
      }

      .showDropdown {
        position: relative;
        display: block;
        padding: 11px 0px 12px;

        .inputDropdown {
          position: initial;
          min-width: 100%;
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 0.4);
          border: 0;
          padding: 10px;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;

          @include placeholder-text {
            color: #435b75 !important;
          }
        }

        .rbt-input-hint {
          color: #435b75 !important;
        }

        .rbt {
          #hotel_search {
            box-sizing: border-box;
            width: 100%;
            padding: 5px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
            border: solid 1px #d2d9e7;
            background-color: #e6e7ee;
            z-index: 1;
            display: none;
            margin-top: 5px;

            a {
              display: block;
              color: #435b75;
              padding: 12px 9px;
              font-size: 12px;
              line-height: 15px;
              cursor: pointer;
            }

            a:hover {
              box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                inset -2px -2px 6px 0 #ffffff !important;
              border-color: #d2dae6 !important;
              color: #435b75 !important;
              padding: 12px 9px;
              border-radius: 6px;
            }


          }
        }
      }

      .cardBody {

        .inputRange {

          .input-range__track--active,
          .input-range__slider {
            background: rgb(63, 117, 255);
            border: 1px solid rgb(63, 117, 255);
          }
        }
      }
    }
  }

  .mainWrap {
    .card2 {

      .cardContent {
        .location {
          display: flex;
          align-items: flex-start;
        }
      }

      .infoBox {
        .title {
          border-bottom: 1px solid rgba(67, 91, 117, 0.2);
          justify-content: center;

          button {
            font-weight: normal;
            color: rgba(67, 91, 117, 0.6);
            font-size: 14px;
            padding: 0 10px 5px;
            border: 2px transparent;
            background: transparent;
            margin-bottom: 1px;

            &.active {
              border-bottom: 2px solid #1d6ec9;
              color: #1d6ec9;
              margin-bottom: -1px;
            }
          }
        }

        .upgradeBtn {
          .btn .icon-border-plain {
            margin-right: 5px;

            :before {
              color: #fff;
              font-size: 1.3rem;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .sortWrapper {
      position: relative;
      //  cursor: pointer;
      margin-left: auto;
      // padding: 8px 7px 9.2px 15px;
      padding: 0;
      min-height: 53px;
      align-items: flex-start;

      .content {
        >div {
          width: 100%;
        }

        >.whiteText {
          padding: 9px 14px 0;

          @media (min-width:768px) and (max-width:1023px) {
            padding: 4px 14px 0;
          }
        }

        [class*=control] {
          >div {
            padding: 0px 0px;
            margin: 10px 0px 0px 0px;
          }

        }

        .sort-by-dropdown,
        .css-1s2u09g-control,
        .css-1pahdxg-control {
          width: 100%;
          background: transparent;
          border: none;
          min-height: 0px;
          box-shadow: none;
          cursor: pointer;

          .css-319lph-ValueContainer,
          .css-qc6sy-singleValue,
          .css-6j8wv5-Input {
            padding: 0px 0px;
            color: #1d6ec9;
            margin: 0px;
          }

          .css-tlfecz-indicatorContainer,
          .css-1gtu0rj-indicatorContainer {
            display: none;
            padding: 0px;
            color: #1d6ec9;
            width: 15px;
          }

          .css-1okebmr-indicatorSeparator {
            display: none;
          }

          [class*=menu] {
            box-sizing: border-box;
            width: 100%;
            min-width: 211px;
            padding: 5px;
            // margin-left: -15px;
            margin-top: 5px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
            border: solid 1px #d2d9e7;
            background-color: #e6e7ee;
            z-index: 1;

            [class*=MenuList] {
              [class*=option] {
                padding: 5px 8px;
                font-size: 15px;
                border-radius: 6px;
                line-height: 17px;
                color: #435b75 !important;
                cursor: pointer;
              }

            }

            [class*=option] {
              border-color: #d2dae6 !important;
              color: #435b75 !important;
              padding: 5px 8px;
              border-radius: 6px;
            }

            .css-1n7v3ny-option,
            .custom-select__option--is-selected,
            .css-9gakcf-option,
            [class*=option] {
              background-color: rgb(230, 231, 238) !important;
              color: #435b75 !important;
            }

            [class*=option]:hover {
              box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                inset -2px -2px 6px 0 #ffffff !important;
              border-color: #d2dae6 !important;
              color: #435b75 !important;
              padding: 5px 8px;
            }
          }
        }

        [class*=fa] {
          position: absolute;
          right: 10px;
          top: 19px;
          pointer-events: none;
        }

        .sort-by-dropdown {
          margin-top: 0px;
          position: absolute;
          top: 0px;
          left: 0;
          height: 100%;
          width: 100%;

          .css-1hb7zxy-IndicatorsContainer {
            position: absolute;
            top: -11px;
            right: 0;
          }

          .css-26l3qy-menu {
            [class*=option] {
              font-size: 12px
            }
          }

          [class*=control] {
            padding: 18px 14px 10px;
            background: transparent;
            border: none;
            min-height: 0px;
            box-shadow: none;
            cursor: pointer;
          }

          [class*=ValueContainer] {
            padding-top: 10px;
          }

          [class*=indicatorSeparator],
          [class*=indicatorContainer] {
            display: none;
          }

          [class*=singleValue] {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            padding: 0px 0px;
            color: #1d6ec9;
            margin: 0px;
          }
        }
      }
    }

    .dropdown-arrow {
      position: absolute;
      top: 13px;
      right: 10px;
    }

    .rightInfo {
      justify-content: flex-start;
    }
  }

  .mainContent {
    margin-top: 55px;

    .filterCnt {
      min-height: 55px;
      margin-bottom: 0px;
      padding: 9.6px 0px;

      .inputStyle {
        width: 100%;
        flex-grow: 1;
        position: relative;
        margin: 0px 0px 0px 0px;



        input {
          border: none;
          background: rgba(255, 255, 255, 0.4);
          font-size: 14px;
          padding: 9.5px 15px 9.5px 40px;
          box-shadow: none;
        }

        .inputDropdown {
          top: 0px;
          border-radius: 10px;
          border: none;
          background-color: transparent;
          background: transparent;
          position: relative;
          left: 0;
          box-sizing: border-box;
          padding: 0px 15px 0px 37px;
          min-width: auto;
          max-height: 0px;
          min-height: 35px;
          overflow: auto;
          z-index: 999;
        }

        .focus {
          border: 1px solid var(--border-color);
        }

        .dropdown-content {
          margin-right: 0px;

          .icon-location-on:before {
            color: #1d6ec9;
          }

        }

        .rbt {
          >div {
            width: auto;
            max-width: 420px;

            .rbt-input-hint {
              position: relative;
              height: 35px !important;
              border: none;
              background: rgba(255, 255, 255, 0.4) !important;
              font-size: 14px;
              padding: 9.5px 15px 9.5px 40px;
              box-shadow: none;
              min-width: 215px;

            }
          }

          .rbt-menu {
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
            border: solid 1px #cfd1e1;
            background-color: #e6e7ee;
            margin-top: 5px;
            box-sizing: border-box;
            padding: 5px;
            min-width: auto;
            z-index: 10;
            cursor: pointer;

            a {
              align-items: flex-start;
              display: flex;
              color: var(--text-color);
              font-size: 14px;
              padding: 12px 8px;
              border-radius: 6px;
              border: solid 1px transparent;


            }

            a:hover {
              box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
              border-color: #d2dae6;
              background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            }


          }


        }
      }

      .dropdownWrap {
        width: 280px;
        box-sizing: border-box;
        position: relative;
      }

      .dateAndBtnWrap {
        .Date-picker-range {
          .DateRangePicker {
            .DateRangePickerInput {
              color: #1d6ec9;
              border-radius: 10px;
              width: 280px;
              min-width: 280px;
              border-color: transparent;
              background: rgba(255, 255, 255, 0.4);
              font-size: 14px;
              box-shadow: none;
              padding: 4px 10px;
              margin: 0;
              margin-left: 10px;

              .DateInput {
                width: 82px;
                background: transparent;
                display: flex;
                min-width: 82px;
                margin: 0;
                padding: 0px;

                .DateInput_input {
                  font-size: 14px;
                  color: #1d6ec9;
                  background-color: transparent;
                }
              }

              .DateRangePickerInput_arrow {
                width: 80px;
                padding-top: 8px;
                padding-left: 0px;
                display: flex;

                .icon-arrow {
                  display: inline-block;
                  margin-right: 21px;
                  margin-left: 11px;
                  padding-top: 2px;
                  vertical-align: text-top;
                }

                .icon-arrow:before {
                  content: "\e965";
                  font-size: 11px;
                  color: #6C9ABA;
                  vertical-align: text-top;

                }
              }

              .DateRangePickerInput_calendarIcon {
                padding: 0px 0px;
                margin: 0px 7px 0px 3px;
              }
            }
          }
        }

        .dropdownInWrap {
          min-width: 330px;

          .add-room-scroll {
            >div {
              margin-right: 0 !important;
            }
          }

          .dropDownSubWrap {
            border: none;
            padding: 10px 10px 15px;

            .cabinClassWrapper {
              display: none;
            }

            .inWrap {
              padding: 10px 0px 6px 0px;

              button {
                background-color: transparent;
              }

            }

            .roomHead {
              padding-right: 0px;
            }
          }

          .addRoom button {
            border: none;
            color: #1d6ec9;
            background: transparent;

            .icon-add {
              margin-right: 5px;
            }

            .icon-add:before {
              color: #1d6ec9;
              font-size: 10px;
            }

          }
        }
      }

    }

    .pageInternalScroll>div {
      overflow-x: hidden !important;
      margin-right: 0px;
      margin-left: 0px;
    }

    .contentWrapper.row {
      margin-top: 10px;
    }

  }

  .filterCnt {
    .inputStyle {
      .dropdown-menu {
        a.dropdown-item {
          &:before {
            content: "\e960";
            font-size: 16px;
            color: var(--text-color2);
            font-family: "icomoon" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            margin-top: 3px;

          }

          &.disabled {
            &:before {
              display: none;
            }
          }

          .dorpdown-content {
            padding-left: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;

            .icon-location-on {
              display: none;
            }

            .locationCode {
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              .autocompleteSubText {
                margin-top: 5px;
              }
            }
          }
        }
      }

      .mobilePopup {
        .dropdown-menu {
          a.dropdown-item {
            &:before {
              display: none;

            }



            .dorpdown-content {
              padding-left: 0px;
            }
          }
        }
      }
    }
  }
}

// listing page custome style Start


// Hotel lsiting left side bar style Start

.leftSideBar {
  width: 220px;
  margin-right: 30px;

  .card {
    padding: 0px 15px 15px 15px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.3);
    margin-bottom: 10px;
    border: none;

    &.mapCard {
      padding: 9px 10px;
      margin-bottom: 20px;
    }
  }

  .cardHead {
    color: #fff;
    cursor: pointer;
    padding-top: 15px;

    h3 {
      font-size: 19px;
      font-weight: normal;
      color: #ffffff;
    }

    h6 {
      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
      margin-bottom: 0;
    }

    a {
      color: #fff;
      font-size: 14px;
    }

    .icon-close {
      font-size: 0.5rem;
      margin-right: 4px;
    }
  }

  >.cardHead {
    padding: 5px 0 15px;
  }

  .cardBody {

    .customCheckbox:first-child,
    .customRadio:first-child {
      margin-top: 15px;
    }
  }

  // .mapWrap {
  //   position: relative;
  //   border-radius: 10px;
  //   overflow: hidden;

  //   .whiteBtn {
  //     position: absolute;
  //     left: 50%;
  //     border: none;
  //     top: 50%;
  //     transform: translate(-50%, -50%);
  //   }
  // }

  .customCheckbox,
  .customRadio {
    display: block;
  }

  .inputWrap2 {
    padding: 11px 0 12px;

    input {
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.4);
      border: 0;
      padding: 10px;
      display: block;
      position: relative;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .inputRange {
    padding: 8px 0 12px;

    input {
      width: 100%;
    }
  }



  .btnBorder {
    border-radius: 15px;
    padding: 6.5px 10px;
  }

  .rate {
    float: left;
    padding: 10px 0;

    &:not(:checked)>input {
      position: absolute;
      opacity: 0;
    }

    &:not(:checked)>label {
      float: right;
      width: 1em;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      font-size: 30px;
      color: #ccc;
    }

    &:not(:checked)>label:before {
      content: "\e94a";
      font-family: "icomoon" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    >input:checked~label {
      color: #ffc700;
    }

    &:not(:checked)>label:hover,
    &:not(:checked)>label:hover~label {
      color: #deb217;
    }

    &>input:checked+label:hover,
    &>input:checked+label:hover~label,
    &>input:checked~label:hover,
    &>input:checked~label:hover~label,
    &>label:hover~input:checked~label {
      color: #c59b08;
    }
  }

  .btnTopMargin {
    margin-top: 15px;
  }
}

// Hotel lsiting left side bar style End



// Map-view-model
.map-view-popup {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
  border: solid 1px #cfd1e1;
  background-color: #eff0f5;
  padding: 20px;
  overflow-y: auto;
  margin-bottom: 30px;

  .box-content {
    margin: 0px;
  }
}

// Price Detail style

.priceDetailsWrapper {
  .pricedetail-dropdown {
    width: 250px;
    padding: 9px 0px 0px 0px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.25);
    background-color: #eff0f5;
    position: absolute;
    top: calc(100%);
    left: -100px;
    display: none;

    h3 {
      @include ubuntu(500);
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      color: #435b75;
      margin-bottom: 14px;
      text-align: left;
    }

    h4 {
      font-size: 12px;
      @include ubuntu(400);
      line-height: normal;
      letter-spacing: normal;
      color: rgba(67, 91, 117, 0.6);
      margin-bottom: 9px;
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 500;
        color: #435b75;
        font-size: 14px;
        max-width: 80px;
        word-break: break-all;
      }
    }

    h5 {
      @include ubuntu(400);
      font-size: 12px;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(67, 91, 117, 0.6);
      margin-bottom: 14px;
      margin-top: -2px;
    }

    .total-amount {
      h4 {
        font-size: 14px;
      }
    }

    .saving-box {
      h4 {
        color: #00b7a6 !important;
      }
    }

    .dropdown-info {
      padding: 0px 15px 0px 15px;

      // border-bottom: 1px solid rgba(67, 91, 117, 0.2);
      // margin-bottom: 3px;
      h4 {
        span {
          min-width: 100px;
        }
      }
    }

    .note {
      font-size: 12px;
      @include ubuntu(400);
      line-height: normal;
      letter-spacing: normal;
      color: #00b7a6;
      max-width: 100%;
      padding: 0px 15px 8px 15px;
      border-bottom: 1px solid rgba(67, 91, 117, 0.2);
      margin-bottom: 12px;
    }


    .resortFeessection {
      background-color: #d4d6e2;
      padding: 10px;
      margin: 20px 0 0;
      border-radius: 0 0 8px 8px;

      h3 {
        margin-bottom: 5px;
        font-size: 12px;
        display: flex;
        align-items: center;
      }

      p {
        font-size: 10px;
        color: rgba(67, 91, 117, 0.6);
        text-align: left;
      }

      .circle {
        padding: 3px;
        border: 1px solid #435b75;
        border-radius: 100px;
        line-height: 0;
        height: 20px;
        width: 20px;
        margin-right: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          margin-top: -2px;
        }
      }
    }
  }
}


.priceDetails {
  margin-bottom: 4px;
  color: #1d6ec9;
  position: relative;
  display: inline-flex;
  cursor: pointer;

  &:hover,
  &:focus {
    .pricedetail-dropdown {
      display: block;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
      max-width: 14px;
    }
  }
}


// Price Detail style End// 

.payWrapper {
  // position: relative;

  .pricedetail-dropdown {
    width: 220px;
    padding: 9px 0px 0px 0px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.25);
    background-color: #eff0f5;
    position: absolute;
    top: calc(100%);
    left: 260px;
    display: none;

    h3 {
      @include ubuntu(500);
      font-size: 14px;
      line-height: normal;
      letter-spacing: normal;
      color: #435b75;
      margin-bottom: 14px;
    }

    h4 {
      font-size: 12px;
      @include ubuntu(400);
      line-height: normal;
      letter-spacing: normal;
      color: rgba(67, 91, 117, 0.6);
      margin-bottom: 9px;
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 500;
        color: #435b75;
        font-size: 14px;
      }
    }

    h5 {
      @include ubuntu(400);
      font-size: 12px;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(67, 91, 117, 0.6);
      margin-bottom: 14px;
      margin-top: -2px;
    }

    .total-amount {
      h4 {
        font-size: 14px;
      }
    }

    .saving-box {
      h4 {
        color: #00b7a6;
      }
    }

    .dropdown-info {
      padding: 0px 15px 0px 15px;
    }

    .note {
      font-size: 12px;
      @include ubuntu(400);
      line-height: normal;
      letter-spacing: normal;
      color: #00b7a6;
      max-width: 100%;
      padding: 0px 15px 8px 15px;
      border-bottom: 1px solid rgba(67, 91, 117, 0.2);
      margin-bottom: 12px;
    }


    .resortFeessection {
      background-color: #d4d6e2;
      padding: 10px;
      margin: 20px 0 0;
      border-radius: 0 0 8px 8px;

      h3 {
        margin-bottom: 5px;
        font-size: 12px;
        display: flex;
        align-items: center;
      }

      p {
        font-size: 10px;
        color: rgba(67, 91, 117, 0.6);
      }

      .circle {
        padding: 3px;
        border: 1px solid #435b75;
        border-radius: 100px;
        line-height: 0;
        height: 22px;
        width: 22px;
        max-width: 22px;
        flex: 0 0 22px;
        margin-right: 5px;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          margin-top: -2px;
          margin-left: 0 !important;
        }
      }
    }
  }

}

.infoIcon:hover {
  .pricedetail-dropdown {
    display: block;
  }
}

//Right side card info style Start
.infoBox {


  .infoTitle {
    color: #1d6ec9;
    font-size: 17px;
    font-weight: normal;
  }

  .lightText {

    &,
    [class^="icon-"]:before {
      color: #8198a7;
    }
  }

  .blueText {
    color: #1d6ec9;
  }

  .btmSpace {
    margin-bottom: 4px;
    font-size: 10px;
  }

  .btmSpace2 {
    margin-bottom: 15px;
  }

  .btnBorder2 {
    border-radius: 10px;
    padding: 6.5px 10px;

    .icon-border-plain {
      font-size: 16px;
      vertical-align: middle;
    }
  }

  .btn {
    display: inline-block;
    min-width: 150px;
    padding: 9px;
    font-size: 14px;
    border-radius: 10px;
    margin-top: 4px;

    &:before {
      display: none;
    }

    .icon-border-plain:before {
      color: #fff;
      font-size: 16px;
      margin-right: 4px;
      vertical-align: middle;
    }
  }

  .topBorder {
    border-top: 1px solid rgba(67, 91, 117, 0.2);
    padding-bottom: 11px;
    padding-top: 11px;
    margin-top: 11px;

    * {
      vertical-align: middle;
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  [class^="icon-"] {
    /*vertical-align: middle;*/
    width: 24px;
    display: inline-block;
  }

  .icon-flight {
    .path1:before {
      color: #8198a7;
      transform: rotate(-90deg) translateY(-3px);
      display: block;
    }
  }

  .icon-room,
  .icon-tick2,
  .icon-icon3 {
    font-weight: bold;
  }

  .icon-tick2 {
    text-align: right;
  }


}

.infoBox {
  padding: 10px;
  width: 230px;
  min-height: 222px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
  border: solid 1px #dae2ee;
  background-color: rgba(255, 255, 255, 0.35);
  color: #435b75;
  font-size: 12px;
  box-sizing: border-box;

  .infoTitle {
    color: #1d6ec9;
    font-size: 17px;
    font-weight: normal;
  }

  .lightText {

    &,
    [class^="icon-"]:before {
      color: #8198a7;
    }
  }

  .blueText {
    color: #1d6ec9;
  }

  .btmSpace {
    margin-bottom: 4px;
  }

  .btmSpace2 {
    margin-bottom: 10px;
  }

  .btnBorder2 {
    border-radius: 10px;
    padding: 6.5px 10px;

    .icon-border-plain {
      font-size: 1.3rem;
      vertical-align: middle;
      width: 26px;
    }
  }

  .btn {
    display: inline-block;
    min-width: 150px;
    padding: 9px;
    font-size: 14px;
    border-radius: 10px;

    &:before {
      display: none;
    }

    .icon-border-plain:before {
      color: #fff;
      font-size: 20px;
      margin-right: 4px;
      vertical-align: middle;
    }
  }

  .topBorder {
    border-top: 1px solid rgba(67, 91, 117, 0.2);
    padding-bottom: 14px;
    padding-top: 9px;
    margin-top: 11px;

    * {
      vertical-align: middle;
    }

    .lightText {
      margin-right: 2px;
    }
  }

  .mb-10 {
    margin-bottom: 15px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  [class^="icon-"] {
    /*vertical-align: middle;*/
    width: 26px;
    display: inline-block;
  }

  .icon-flight {
    .path1:before {
      color: #8198a7;
      transform: rotate(-90deg) translateY(-3px);
      display: block;
    }
  }

  .icon-room,
  .icon-tick2,
  .icon-icon3 {
    font-weight: bold;
  }

  .icon-tick2 {
    text-align: right;
  }
}

//Right side card info style End


// location-mark tooleTip
.location-mark {
  color: white;
  border-radius: 10px;
  background-color: #1d6ec9;
  width: 60px;
  font-size: 12px;
  padding: 6px;
  display: block;
  text-align: center;
}


// Filter-display
.filter-display {
  display: flex;
  width: 100%;
  padding: 20px;
  background: transparent;
  border: #000;
  flex-wrap: wrap;
  padding: 0px 10px 10px 0px;

  .filter-btn {
    .filter-name {
      width: auto;
      display: inline-flex;
      height: auto;
      font-size: 14px;
      padding: 9px 10px 9px 15px;
      border-radius: 100px;
      color: white;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      background-color: rgba(255, 255, 255, 0.3);
      border: none;
      margin: 7px 8px 0px 0px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;

      .icon-close {
        font-size: 8px;
        margin-left: 9px;
        margin-top: 3px;
        background: none;
        border: none;
        left: auto;
        vertical-align: middle;

        &:before {
          color: white;
        }
      }
    }

  }
}



// compare-price popup start
.comparePrice {
  color: #1d6ec9;
  font-size: 14px;
  position: relative;

  .fa-angle-down {
    font-size: 18px;
    margin-left: 5px;
  }

  .graph {
    width: 3px;
    height: 12px;
    background-color: #1d6ec9;
    margin: 0 10px 0 5px;
    display: inline-block;
    position: relative;

    &:after,
    &:before {
      background-color: #1d6ec9;
      width: 3px;
      display: inline-block;
      content: "";
      height: 6px;
      position: absolute;
      left: -5px;
      bottom: 0;
    }

    &:after {
      left: 5px;
      height: 9px;
    }
  }

  .popup,
  .comparePriceContent {
    position: absolute;
    left: -130px;
    bottom: 28px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #cfd1e1;
    background-color: #eff0f5;
    padding: 7px 0;
    border-radius: 5px;
    width: 360px;
    display: none;

    &:before {
      border-top: 10px solid #eff0f5;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -5px;
    }

    .comparePrice-flex {
      display: flex;
      flex-direction: column;

      .col {
        width: 100%;
        display: flex;
        flex: 0 0 100%;
        justify-content: space-between;
        padding: 0 8.5px;

        .dflex {
          margin-bottom: 5px;
          width: 100%;

          +.dflex {
            padding-left: 5px;
          }
        }
      }

      .key {
        width: 85px;
      }
    }

    .key {
      color: #435b75;
    }

    .value {
      color: #1d6ec9;
      font-weight: 500;
    }

    .font12 {
      color: #8198a7;
    }

    .priceTax {
      color: #435b75;
    }
  }
}

.location-mark {
  color: white;
  border-radius: 10px;
  background-color: #1d6ec9;
  width: 60px;
  font-size: 12px;
  padding: 6px;
  display: block;
  text-align: center;
}

// Filter-display
.filter-display {
  display: flex;
  width: 100%;
  padding: 20px;
  background: transparent;
  border: #000;
  flex-wrap: wrap;
  padding: 0px 10px 10px 0px;

  .filter-btn {
    .filter-name {
      width: auto;
      display: inline-flex;
      height: auto;
      font-size: 14px;
      padding: 9px 10px 9px 15px;
      border-radius: 100px;
      color: white;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      background-color: rgba(255, 255, 255, 0.3);
      border: none;
      margin: 7px 8px 0px 0px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;

      .icon-close {
        font-size: 8px;
        margin-left: 9px;
        margin-top: 3px;
        background: none;
        border: none;
        left: auto;
        vertical-align: middle;

        &:before {
          color: white;
        }
      }
    }

  }
}

.listingHotel {
  .rbt {
    .rbt-input {
      padding-right: 30px;
    }

    .rbt-aux {
      .btn-close {
        top: -2px;
        z-index: 999;
        -webkit-appearance: none;
        height: 35px;
      }
    }
  }
}

.hotel-show-more-btn-wrap {
  margin: 20px 0 5px;
}

.amenities-filter-wrap {
  .cardHead {
    padding-bottom: 10px;
  }

  .amenities-filter-data {
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;

    .customCheckbox {
      &:first-child {
        margin-top: 5px;
      }
    }
  }
}

// Responsive start

@media (max-width: 1199px) {
  .priceDetailsWrapper {
    .pricedetail-dropdown {
      right: 0;
      left: auto;
    }
  }

  .listingPageWrap,
  .listingPage {
    .mainContent {
      .filterCnt {
        .inputStyle {
          .dropdown-content {
            .rbt>div {
              width: 100%;
              min-width: auto;
              z-index: 10;
            }
          }
        }
      }
    }
  }
}

// 1199 end
@media (max-width: 1140px) {
  .listingHotel {
    .mainContent {
      .contentWrapper.row {
        margin: 10px -10px;
      }

      .mainWrap {
        padding: 0px 10px 0px 15px;
      }
    }
  }

  .listingPageWrap {
    .leftSideBar {
      margin-left: -5px;
      margin-right: 0;
      max-width: 22%;
      flex: 0 0 22%;
    }

  }
}

// 1140 end
@media (min-width: 992px) {
  .listingPageWrap.listingHotel {
    overflow-x: hidden;

    .mainContent .pageInternalScroll {
      height: calc(100vh - 175px) !important;
    }
  }
}

@media (min-width: 992px) and (max-width: 1120px) {
  .listingPage .mainContent .filterCnt .inputStyle {
    z-index: 1;
  }
}

@media (max-width:1090px) {
  .listingPageWrap {
    .mainWrap {
      .sortWrapper {
        .content>div {
          .sort-by-dropdown {
            .css-26l3qy-menu {
              box-sizing: border-box;
              width: 100%;
              min-width: 209px;
              padding: 5px;
            }
          }

        }
      }
    }

    .mainWrap {

      .sortWrapper {
        max-width: 27.7%;
        flex: 0 0 27.7%;
        border-radius: 12px;
        background-color: rgb(153, 215, 255);
        padding: 7px 7px 7px 15px;
        min-width: 220px;

        .content>.whiteText {
          padding-left: 0;
        }
      }
    }
  }

}

// 1090 end
@media (max-width: 1050px) {
  .listingPageWrap {

    .infoBoxWrapper {
      .buttonSpace.upgradeBtn .btn {
        min-width: 100px;
        padding: 7.5px 0;
        border-radius: 10px;
        margin-right: 12px;
      }
    }

    .mainWrap {
      .card2 {
        .cardContent {
          padding: 0px 13px;
        }
      }

      .sortWrapper {
        max-width: 27.7%;
        flex: 0 0 27.7%;
        border-radius: 12px;
        background-color: rgb(153, 215, 255);
        padding: 7px 7px 7px 15px;
        min-width: 210px;
      }
    }

    .leftSideBar {
      .card {
        .priceBtn {
          button {
            margin-right: 6px;
          }
        }
      }
    }
  }
}

// 1050 end

@media (min-width: 768px) and (max-width: 991px) {
  .listingHotel {
    .mainContent {
      .mainWrap {
        min-height: 550px;
      }
    }

    .priceDetailsWrapper .pricedetail-dropdown {
      top: auto;
      bottom: 25px;
    }
  }
}

@media (max-width: 991px) {
  .listingHotel {
    .mainContent {
      .contentWrapper.row {
        margin: 10px -10px;
      }

      .mainWrap {
        padding: 0px 0px 0px 0px;
      }
    }

    .card2 .title-row-wrap .title-wrap {
      margin-bottom: 20px;
    }
  }

  .listingPageWrap {
    .leftSideBar {
      margin-left: 0px;
    }
  }

  .listingPageWrap {
    .leftSideBar {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 0;
    }

    .contentWrapper {
      margin: 0;
    }

    .sortWrapper {
      display: none;
    }

    .mainWrap {
      padding: 0;
      margin-top: 0px;

      .comparePrice {
        padding-left: 5px;
        font-size: 14px;
        padding-bottom: 5px;

        @media(min-width:768px) and (max-width:991px) {
          font-size: 12px;
        }
      }

      .rightInfo {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 22px 0px 5px 0px;


        h3 {
          font-size: 14px;
        }

        .mobileSearchFilterButtons {
          width: 100%;
          margin-bottom: 15px;
          position: sticky;
          z-index: 1;

          .d-flex {
            width: 100%;
            justify-content: center;
          }

          .mobileButtons {
            &:before {
              left: 30px;
            }
          }

        }
      }

      .card2 {
        margin-bottom: 10px;
        padding: 10px;
        border: none;
        box-shadow: none;

        .noteCard {
          flex-direction: column;
          padding: 10px;
          font-size: 14px;
          position: relative;

          .btnBorder2 {
            margin: 6px auto 0;
            min-width: 90px;
            padding: 6px 6px;
          }

          .closeBtn {
            position: absolute;
            right: 7px;
            top: 7px;
            font-size: 10px;
            margin: 0;
            z-index: 1;
          }
        }

        .cardContent {
          padding: 0 10px;

          .cartTitle {
            font-size: 19px;
          }

          .ratingComment {
            font-size: 12px;
          }

          .dflexColm {
            display: flex;
            justify-content: flex-start;
          }

          .location {
            margin-top: 5px;
          }


        }

        .infoBox {
          .infoBoxWrapper {
            padding: 0 13px;
          }

          .title {
            button {
              padding: 9px 10px 5px;
              position: relative;
              border: 2px solid transparent;


              &.active {
                &:after {
                  content: "";
                  background-color: #1d6ec9;
                  height: 2px;
                  width: 100%;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  min-width: 60px;
                }
              }
            }
          }
        }

        .fromPrice {
          span {
            font-size: 14px;
          }
        }

        .imgWrap {
          border: none;

          .savings {
            padding-bottom: 3px;

            span {
              font-size: 12px;
            }
          }
        }

        .priceDetailsWrapper {
          display: flex;
          justify-content: space-between;

          .priceDetails {
            order: 1;
            margin-bottom: 7px;

            h4 {
              font-size: 14px;

              @media(min-width:768px) and (max-width:991px) {
                font-size: 12px;
              }
            }
          }
        }

        .buttonAndTextGroup {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 0 4px;
          flex-direction: column;

          .savings {
            margin-bottom: 12px;
            margin-top: 2px;

            span {
              font-size: 12px;

              &:nth-child(2) {
                font-weight: normal;
                padding-left: 4px;
              }
            }
          }

          .buttonSpace {
            padding-top: 0;

            .btnBorder2 {
              padding: 4px 5px;
              max-width: 142px;
              font-size: 12px;
            }

            &.upgradeBtn {
              .btn {
                order: 1;
                padding: 5px 0px;
                font-size: 12px;
                min-width: 100px;
                margin-right: 0;
              }

              h4 {
                font-size: 12px;
                padding-right: 16px;
              }
            }
          }

          .buttonGroups {
            position: relative;
            width: 100%;
            bottom: 0;
          }
        }

        .savings-group-wrap {
          display: block;
        }

        .infoBoxWrapper {
          .buttonGroups {
            padding: 0px 0 5px;

            .buttonSpace {
              margin: 0px 0 0;
              padding: 5px 0px;
            }

            .upgradeBtn {
              display: flex;
              justify-content: center;
              flex-direction: column;

              .btn {
                min-width: 150px;
                font-size: 14px;
                max-width: 100%;
                min-height: 34px;
                border-radius: 10px;
                padding: 9px;
                margin: 10px 0px;
                display: inline-flex;
                align-items: center;
                justify-content: center;


                .icon-border-plain {
                  margin-right: 0px;

                  :before {
                    color: #fff;
                    font-size: 16px;
                    vertical-align: middle;
                  }
                }
              }

              button {
                font-size: 12px;
                padding-right: 16px;
              }
            }

            .centerAlign {
              text-align: center;
            }

            .buttonSpace-select-room {
              margin-top: 2px;
            }


            .btnBorder2,
            .btn-select-room {
              min-width: 150px;
              font-size: 14px;
              max-width: 100%;
              min-height: 34px;
              border-radius: 10px;
              margin: 0px 0px;
              display: inline-flex;
              align-items: center;
              justify-content: center;

            }
          }
        }
      }

      .popup-content {
        padding: 10px 10px;
        margin-top: 20px;

        .box-content {
          margin: 0px;
        }
      }
    }

    .mobileViewWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 24px 0 16px;

      img {
        max-width: 22px;
        height: auto;
        margin: 0 5px;
      }

      span {
        color: #7691b0;
        margin: 0 6px;
        font-size: 14px;
      }
    }

    .bulletList {
      margin: 15px 0;
      display: none;
    }

    .cardContent {
      .location {
        display: none;
      }
    }
  }

}

// 991 end
@media (max-width: 991px) {
  .listingPage {
    background-attachment: inherit;

    // .fixedHeader {
    //   z-index: 1;
    // }

    .mainContent {
      overflow-x: unset;

      .filterCnt2.openFilter {
        min-width: 116px;

        .mobileHide {
          display: block;
          padding-top: 10px;
        }
      }

      .filterCnt2 {
        .mobileHide {
          display: none;
          padding: 0px 4px;
        }

        .dateAndBtnWrap {
          .DateRangePicker_picker {
            left: 0px !important;
          }
        }

        .mobileBtnWrap {
          .btnBorder {
            min-width: 116px;
          }
        }
      }
    }
  }

  .listingPageWrap {

    .mainWrap {
      .card2 {
        padding: 10px;
        margin-bottom: 10px;

        .infoBox {
          .title button {
            padding: 9px 10px 5px;
            position: relative;
            border: 2px solid transparent;
          }
        }
      }

      .imgWrap .bg-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        min-height: 120px;

      }

      .rightInfo {

        h3 {
          padding-top: 4px;
        }
      }
    }

    .mainContent {
      overflow-x: unset;

      .rightInfo-filter {
        display: flex;
        justify-content: center;
        position: sticky;
        top: 95px;
        left: 0;
        right: 0;
        z-index: 1;

        &.rightInfo-filter-active {
          z-index: 3;
        }

        .mobileSearchFilterButtons {
          display: block;

          .d-flex {
            width: 100%;
            justify-content: center;
            position: sticky;
            z-index: 2;
            margin: 0px 0px;

            .mobileButtons {
              border-radius: 17.5px;
              border: none;
              box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
              -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
              background: linear-gradient(to right, #4d98d2 24%, #4d98d2 65%);
              font-family: "Ubuntu";
              font-size: 14px;
              font-weight: normal;
              color: #ffffff;
              min-width: 174px;
              min-height: 35px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              position: relative;
              margin: 0 3.5px;
              cursor: pointer;

              .fa {
                margin-right: 10px;
              }

              .icon-location-on {
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 15px;
                width: 15px;
                left: 33px;

                &::before {
                  color: white !important;
                }
              }
            }

          }


        }

      }

      .openFilter {
        top: 270px;

      }
    }

    .mainWrap {
      .card2 {
        .infoBox {
          .title {
            button.active {
              padding: 9px 10px 5px;
              position: relative;
              border: 2px transparent;
            }

            button {
              padding: 9px 10px 5px;
              position: relative;
              border: 2px transparent;

            }

            button.active:after {
              content: "";
              background-color: #1d6ec9;
              height: 2px;
              width: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
              min-width: 60px;
            }
          }
        }
      }
    }

    .mobileSortFilter {
      .showDropdown {
        .rbt {
          .rbt-input {
            width: 100%;
            -webkit-text-fill-color: #1d6ec9 !important;
            padding-left: 15px;

            @include placeholder-text {
              color: #1d6ec9;
              opacity: 1;
            }
          }

          .rbt-input-hint {
            z-index: -1;
          }
        }
      }
    }
  }

  .listingPage.listingPageWrap {

    .mainContent {

      .filterCnt {
        position: sticky;
        top: 45px;
        left: 0;
        right: 0;
        z-index: 2;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        background: linear-gradient(to right, #4d98d2 24%, #4d98d2 65%);
        min-height: auto;
        padding: 4.5px 19px 4.5px 15px;
        margin-bottom: 9px;

        .mobileShow {
          display: block;
          color: #ffff;

        }

        .locationStartEndWrap {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: 0;
        }

        .responsive-input {
          width: 100%;
          margin-bottom: 0px;
          flex-grow: 1;
          position: relative;
          margin-top: 10px;

          .inputStyle {
            border-radius: 10px;
            box-shadow: none;
            border: none;
            border: 1px solid rgba(255, 255, 255, 0.1);
            background: rgba(255, 255, 255, 0.4);
            box-sizing: border-box;
            padding: 0px 15px 0px 28px;
            min-width: 100%;
            max-height: 0px;
            min-height: 35px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .inputDropdown {
            margin: 0px;
            padding: 0px 15px 0px 25px;
          }
        }

        .dateAndBtnWrap {
          display: flex;
          flex-direction: column;
          width: 100%;
          flex: 100%;
          margin-top: 10px;

          .date-picker-type {
            width: 100%;

            .date-picker-style {
              box-shadow: none;
              background: rgba(255, 255, 255, 0.4);
              border: 0;
              border-radius: 10px;
              padding: 0px 14px 0px 8px;
              box-sizing: border-box;
              width: 100%;
              display: flex;
              min-height: 35px;
              justify-content: space-between;
              border: 1px solid rgba(255, 255, 255, 0.1);
              margin-bottom: 10px;

              .icon-invitation {
                font-size: 14px;
                display: flex;
                align-items: center;
                margin-right: 0px;
                display: flex;
                align-items: center;

              }

              button {
                margin: 0px;
                padding-left: 8px;
                font-size: 14px;
                background: none;
                text-align: left;
                border: none;
              }
            }
          }

          .dropdownWrap {
            margin-top: 0px;
            width: 100%;
          }

          .dropdownInWrap {
            width: 100%;
            min-width: 100%;
            z-index: 2;

            .dropDownSubWrap {
              .add-room-scroll {
                max-height: 250px !important;
              }
            }
          }

          .Date-picker-range .DateRangePicker {
            width: 100%;
            margin-bottom: 10px;

            .DateRangePickerInput {
              width: 100%;
              justify-content: flex-start;
              margin-left: 0px;

              .DateRangePickerInput_arrow {
                margin: 0 0;
                width: auto;
                margin-left: 10px;

                .icon-arrow {
                  margin-right: 20px;
                }
              }

              #check_out {
                margin-right: 0px;
              }
            }

          }
        }

        .dropdownWrap {
          width: 100%;
          margin-left: 0px;
          margin-bottom: 10px;

          .btnSml {
            width: 100%;
          }
        }

        .inputStyle .dropdown-content {
          margin-right: 0px;
          padding: 0px 0px;

          .rbt>div {
            width: auto;
            max-width: 100%;
          }
        }

        .inputStyle {
          input {
            padding-left: 22px !important;

          }

          .icon-location-on {
            z-index: 99;
          }
        }

        .mobileSearchBlock {
          color: #fff;
          display: flex;
          align-items: center;
          padding-bottom: 5px;

          .fa-search {
            margin-right: 16px;
          }

          .selectSearchCnt {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            white-space: nowrap;
            height: 34px;
            padding-top: 3px;
            overflow: hidden;

            h4 {
              font-size: 14px;
              font-weight: normal;
              margin-right: 10px;
              margin-bottom: 0;
              color: #ffffff;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            p {
              font-size: 12px;

              .fa-circle {
                font-size: 4px;
                vertical-align: middle;
                opacity: 0.6;
                margin: 0 2px;
              }
            }
          }

          .fa-angle-down {
            margin-left: auto;
            cursor: pointer;
          }
        }



        .dateAndBtnWrap {
          display: flex;
          flex-direction: column;
        }


        .pageInternalScroll>div {
          margin-right: -9px;
          margin-left: -9px;
        }

      }

      .hide-content {
        z-index: 0;
        // display: none;
      }

      .leftSideBar {
        display: none;
      }
    }

    .mobileShow {
      display: block;
    }

    .mobileSortFilter.showPopup {
      display: block;
      padding: 20px 15px 80px;
      z-index: 999;

      .clearFilter {
        display: none;
      }

      .mobilePopTitle {
        padding-bottom: 12px;
      }

      .filterTitle {
        padding-bottom: 13px;
        order: 2;
      }

      .closePopup {
        top: 0px;
      }

      .sortFilter {
        display: block;
        align-items: flex-start;
        padding-top: 8px;
        flex-direction: column;

        .filterDropdownWrap {
          width: 100%;
          position: relative;
          flex-grow: 1;
          max-width: 100%;
          min-width: 100%;

          +.filterDropdownWrap {
            margin-left: 0px;
            margin-bottom: 25px;
          }

          &.sortByOption {
            max-width: 100%;
            width: 100%;
            margin-left: 0px;
            margin-bottom: 25px;

            .content {
              padding: 0px 0px;
            }

            .filterDropdown {
              top: 63px;
              left: auto;
              right: 0;
              padding: 0;
              width: 100%;
              min-width: 100%;
            }

            .flterOption {
              padding: 0px 0px;
              font-size: 17px;
              box-shadow: -2px -2px 4px 0 #ffffff, 2px 2px 3px 0 rgb(0 0 0 / 15%);
              border: solid 1px #cfd1e1;
              background-image: linear-gradient(to right, #ececf3, #e6e7ee);
              width: 100%;

              >div {
                width: 100%;
              }

              [class*=control] {
                background-color: transparent;
                background: none;
                border: none;
                width: 100%;
                box-shadow: none;
                border: none;
                padding: 9px 10px;
                padding-right: 30px;
                cursor: pointer;

                >div {
                  padding: 0px;
                  //display: none;
                }

                [class*=singleValue] {
                  color: #1d6ec9;
                  font-size: 17px;
                  font-weight: 400;
                }

                [class*=ValueContainer] {
                  padding: 0px 3px;
                  display: flex;
                }

                [class*=Input] {
                  margin: 0px;
                }
              }

              [class*=control]:nth-child(2) {
                display: none;

              }



              .css-26l3qy-menu {
                border-radius: 10px;
                box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                background-color: #eff0f5;
                padding: 5px;
                display: block;
                margin-top: 15px;
                height: auto;
                min-height: 250px;
                box-sizing: border-box;
                z-index: 999;

                .css-4ljt47-MenuList div {
                  display: block;
                  color: #435b75;
                  padding: 10px 9px;
                  font-size: 16px;
                  font-weight: normal;
                  border: solid 1px transparent;
                  border-radius: 10px;
                  white-space: nowrap;
                  margin-bottom: 2px;
                }

                [class*=option]:hover {
                  box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                  border-color: #d2dae6;
                  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                }
              }

              [class*=control]:after {
                content: "\f107";
                display: inline-block;
                font: normal bold normal 18px/1 FontAwesome;
                -webkit-font-smoothing: antialiased;
                color: #1d6ec9;
                transform: rotate(0deg);
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                right: 10px;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
              }
            }
          }

          &.rightAuto {
            margin-right: auto;
          }

          [class*=IndicatorsContainer],
          [class*=indicatorContainer],
          [class*=indicatorSeparator] {
            display: none !important;
            padding: 0px;
          }

          .btnsCnt {
            .borderBtn {
              border-radius: 15px;
              box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1),
                -1px -1px 4px 1px rgba(255, 255, 255, 0.25);
              background-color: #1d6ec9;
              font-size: 14px;
              font-weight: normal;
              color: $white;
            }
          }
        }

        .whiteLabel {
          display: none;
        }

        .flterOption {
          border-radius: 10px;
          font-size: 17px;
          box-shadow: none;
          background-color: transparent;
          font-weight: 500;
          color: var(--text-color);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 0px 10px 0px;
          margin-bottom: 0px;
          cursor: pointer;
          white-space: nowrap;

          .col {
            padding: 0px 0px;
          }
        }

        .filterDropdown {
          border-radius: 10px;
          box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.25);
          background-color: #eff0f5;
          padding: 5px 15px 20px;
          box-sizing: border-box;
          position: absolute;
          top: 50px;
          min-width: 200px;
          display: none;
          z-index: 2;

          .multiCol3 {
            @media (min-width: 768px) {

              .customCheckbox {
                width: 33.33%;
                float: left;
                margin-top: 0;
              }

              .customCheckbox {
                width: 33.33%;
                float: left;
                margin-top: 10px;
                line-height: 7px;

                >span:before {
                  top: -6px;
                }
              }
            }
          }

          .multiColBtn {
            clear: both;
            text-align: right;
            display: block;

            .blueBtn {
              margin-right: 5px;
            }
          }

          &.priceFilter {
            width: 280px;
            padding-top: 17px;
          }

          ul {
            list-style: none;
            padding: 6px;

            li {
              margin-bottom: 2px;
            }

            a {
              display: block;
              color: var(--text-color3);
              padding: 10px 9px;
              border: solid 1px transparent;
              border-radius: 6px;
              white-space: nowrap;

              &:hover {
                box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                  inset -2px -2px 6px 0 #ffffff;
                border-color: #d2dae6;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);
              }
            }
          }
        }

        .collayout-3-dropdown {
          @media (min-width: 768px) {
            .multiCol3 .customCheckbox>span:before {
              top: -3px;
            }

            .customCheckbox input:checked+span:after {
              top: -1px;
            }
          }
        }

        .openedOption {
          .flterOption {
            background-color: rgba(255, 255, 255, 0.6);
          }

          .filterDropdown {
            display: block;
          }
        }

        .btnsCnt {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-top: 26px;
        }

        .borderBtn {
          color: #1d6ec9;
          background: var(--white);
          width: 100px;
          border: 1px solid #1d6ec9;
          border-radius: 15px;
          margin-right: 28px;
          padding: 6px;
          cursor: pointer;

          &.checked,
          &:hover {
            background: #fff;
            color: #1d6ec9;
          }
        }

        .filterby-input {
          order: 2;
          margin-bottom: 26px;

          .showDropdown {
            padding: 2px 0px;

            .inputDropdown {
              position: static;
              min-width: 100%;
              min-height: auto;
              min-height: 45px;
              box-sizing: border-box;
              color: #1d6ec9;
              margin: 0px 0px;
              font-size: 17px;
              padding: 12px 15px 12px 15px;
              border-radius: 10px;
              box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
              -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
              background-image: linear-gradient(to right, #ececf3, #e6e7ee);
              border-color: transparent;

              @include placeholder-text {
                color: #1d6ec9;
              }
            }

            .rbt-input-hint {
              height: auto !important;
              padding: 12px 15px 10px 15px !important;
              color: #1d6ec9 !important;
            }

            #hotel_search {
              border-radius: 10px;
              box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
              background-color: #eff0f5;
              padding: 5px;
              box-sizing: border-box;
              min-width: 200px;
              display: none;
              z-index: 2;
              margin-top: 10px;

              a {
                display: block;
                color: var(--text-color3);
                border: solid 1px transparent;
                border-radius: 6px;
                white-space: nowrap;
                margin-bottom: 2px;
                padding: 10px 15px;
              }

              a:hover {
                box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                border-color: #d2dae6;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);
              }
            }

          }
        }

        .filterDropdownWrap {

          .d-none {
            // display: block !important;
          }

          .star-rating {
            display: flex !important;
            max-width: 130px;
          }

          .checkbox-card {
            display: block;
          }

          .priceBtn {
            display: none;
          }

          .priceRange {
            padding: 17px 0px 0px;
            display: flex !important;
            justify-content: space-between;

            +.cardBody {
              display: flex !important;
            }

            .inputRange {
              margin-left: 10px;
            }

            h4 {
              margin-bottom: 10px;
              margin-right: 10px;
              font-weight: normal;
              color: var(--text-color);
              font-size: 17px;
            }
          }

          .cardBody {
            padding-bottom: 20px;

            .inputRange {
              .inputRange {
                left: 10px;
              }

              .input-range__track--active {
                background: #0075ff;
              }

              .input-range__slider {
                background: #0075ff;
                border-color: #0075ff;
              }
            }
          }
        }

        .cancelBtn {
          color: var(--text-color3);

          &:hover {
            color: #000;
          }
        }

        .priceVal {
          color: var(--text-color);
          font-size: 17px;
        }

        .rangeStyle {
          padding: 10px 0 0;

          input {
            width: 100%;
          }
        }

        .customCheckbox {
          display: block;
          white-space: nowrap;

          span {
            font-size: 17px;
            padding-left: 32px;
          }

          +.customCheckbox {
            margin-top: 12px;
          }

          >span:before {
            width: 21px;
            height: 21px;
          }

        }
      }
    }

    .mobileSortFilter {
      .closePopup {
        display: block;
      }
    }
  }

  .siteBg.detailPageWrap {
    .mainContent {
      overflow-x: unset;

      .hide-content {
        margin-bottom: 45px;
        z-index: 2;
        padding: 9.8px 10px;

      }
    }

  }

  .listingHotel {
    .mainContent {
      .contentWrapper.row {
        margin: 0px 0px;
      }
    }
  }

  .listingHotel {
    .mainContent {
      .pageInternalScroll>div {
        margin-right: 0px !important;
        margin-left: 0px !important;
      }

      .contentWrapper.row {
        margin: 0px 0px;
      }
    }
  }
}

// 991 end
// mobileview-style 
@media (max-width: 767px) {

  .listingPage {

    .mainContent {
      .filterCnt {
        padding: 4.5px 19px 4.5px 15px;
        position: sticky;

        .mobileShow .mobileSearchBlock {
          .selectSearchCnt {
            .arrow img {
              max-width: 18px;
              margin: 0 4px;
            }
          }
        }

        .container {
          .dateAndBtnWrap {
            .date-picker-style {
              .icon-invitation {
                padding-top: 0px !important;
              }


            }

            .inputStyle {
              .date-picker-popup {
                padding: 40px 0px;

                .inputPopTitle {
                  display: block;
                  font-size: 28px;
                  font-weight: 300;
                  color: var(--text-color);
                  display: block;
                  padding-right: 45px;
                  padding-left: 20px;
                  margin-bottom: 32px;
                }
              }
            }
          }

        }

        .dropdownWrap {
          margin-bottom: 0px;
        }
      }

      .filterCnt2.openFilter {
        padding-bottom: 0px;

        .mobileHide {
          padding-top: 0px;
        }
      }

      // mobile-sort filter style START

      .mobileSortFilter {
        .mobileShowsortFilter .sortFilter {
          .filterby-input {
            order: 2;
            margin-bottom: 26px;

            .showDropdown {
              padding: 2px 2px;

              .inputDropdown {
                position: static;
                min-width: 100%;
                min-height: auto;
                min-height: 45px;
                box-sizing: border-box;
                color: #1d6ec9;
                margin: 0px 0px;
                font-size: 17px;
                padding: 12px 15px 12px 15px;
                border-radius: 10px;
                box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                border-color: transparent;
              }

              .rbt-input-hint {
                height: auto !important;
                padding: 12px 15px 10px 15px !important;
                color: #1d6ec9 !important;
              }

              #hotel_search {
                border-radius: 10px;
                box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                background-color: #eff0f5;
                padding: 5px;
                box-sizing: border-box;
                min-width: 200px;
                display: none;
                z-index: 2;
                margin-top: 10px;

                a {
                  display: block;
                  color: var(--text-color3);
                  border: solid 1px transparent;
                  border-radius: 6px;
                  white-space: nowrap;
                  margin-bottom: 2px;
                }

                a:hover {
                  box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                  border-color: #d2dae6;
                  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                }
              }

            }
          }

          .filterDropdownWrap {
            .priceBtn {
              display: none;
            }

            .priceRange {
              padding: 17px 0px 0px;
              display: flex;

              h4 {
                margin-bottom: 10px;
                margin-right: 10px;
                font-weight: normal;
                color: var(--text-color);
              }
            }

            .cardBody {
              padding-bottom: 20px;
            }
          }
        }
      }
    }

    .mainWrap {
      .rightInfo {
        margin: 9px 0px !important;
      }

      .card2 {

        padding: 0px;

        .infoBox {
          .title {
            button.active {
              padding: 9px 36px 5px;
              position: relative;
              border: 0px solid transparent;
              margin-bottom: -2px;
            }

            button {
              padding: 9px 36px 5px;
              position: relative;
              border: 0px solid transparent;
              margin-bottom: -2px;

              &.active:after {
                content: "";
                background-color: #1d6ec9;
                height: 2px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: -10px;
                min-width: 155px;
              }
            }
          }


        }

        .cardContent {
          .title-wrap {
            margin-bottom: 20px;
          }
        }

        .buttonAndTextGroup {
          .buttonGroups {
            position: relative;
            width: 100%;
            bottom: 0;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;


          }

        }
      }

      .not-found {
        min-height: calc(100vh - 200px);
        min-height: 290px;
        background: #3bbfff;
      }

    }

  }

  //  mobile-sort filter END
  .listingPage {
    .sortFilter {
      .filterDropdownWrap.sortByOption {
        .content {
          display: block;
          padding: 0px 0px;

          .flterOption {
            padding: 0px 0px;
            min-height: 45px;
            display: block;

            .sort-by-dropdown {

              .css-1s2u09g-control,
              .css-1pahdxg-control {
                background-color: transparent;
                background: none;
                border: none;
                box-shadow: none;
                border: none;
                padding: 2px 10px;

                .css-qc6sy-singleValue {
                  color: #1d6ec9;
                }

                .css-1hb7zxy-IndicatorsContainer {
                  display: none;
                }

                .css-319lph-ValueContainer {
                  padding: 0px 3px;
                }
              }

              .css-1d8n9bt {
                padding: 0;
              }

              .css-26l3qy-menu {
                border-radius: 10px;
                box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                background-color: #eff0f5;
                padding: 5px;
                display: block;
                margin-top: 15px;
                height: auto;
                min-height: 250px;
                box-sizing: border-box;
                z-index: 999;

                .css-4ljt47-MenuList div {
                  display: block;
                  color: #435b75;
                  padding: 10px 9px;
                  border: solid 1px transparent;
                  border-radius: 6px;
                  white-space: nowrap;
                }


                .css-9gakcf-option,
                .css-1n7v3ny-option,
                .css-yt9ioa-option:hover {
                  box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                  border-color: #d2dae6;
                  background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                }
              }
            }

          }

        }
      }
    }
  }

  .listingPageWrap,
  .listingPage {
    .mainContent {
      .filterCnt {
        .locationStartEndWrap {
          .inputStyle .dropdown-content {
            margin-right: 10px;
            position: relative;

            .rbt {
              border-bottom: 2px solid var(--border-color);
              padding-bottom: 1px;
            }

            .rbt>div {
              width: 100%;
              max-width: 100%;
              background-color: transparent;
              box-shadow: none;
              border: none;
              margin-left: 0px;

              input {
                background: transparent;
                background-color: none;
                box-shadow: none;
                border-radius: 0;
                padding-left: 30px !important;
              }

              .inputDropdown {
                margin: 0px 0px;
                background: transparent;
                background-color: none;
                box-shadow: none;
                border: none;
                background: transparent;
                border-radius: 0;
                font-size: 17px;
                padding: 0px 15px 0px 30px;
              }

              input::placeholder {
                opacity: 0.5;
              }

              .rbt-input-hint {
                background: transparent !important;
                border-radius: 0;
              }

              .dorpdown-content:before {
                content: "\e960";
                color: var(--text-color2);
                position: absolute;
                font-size: 16px;
                display: block;
                font-family: "icomoon" !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

              }


              .locationCode {
                padding: 0px 25px;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                display: block;
                text-overflow: ellipsis;

              }

            }

            .rbt-menu a {
              width: 100%;
              overflow: hidden;
              padding-right: 20px;
            }

            .icon-location-on {
              margin-top: -5px;
              left: 4px;
            }

            .icon-location-on:before {
              font-size: 20px;
            }
          }
        }
      }
    }
  }


  //  compare price-popup
  .comparePrice {
    .comparePriceContent {
      left: 0px;
      width: 330px;

      @media (max-width:370px) {
        width: 300px;
        left: -10px;
        font-size: 12px;
      }

      .comparePrice-flex {
        .key {
          width: 65px;
        }
      }
    }



    .comparePriceContent:before {
      left: 40px;
    }
  }

}

// 767 end
@media (max-width: 767px) {
  .priceDetailsWrapper {
    .pricedetail-dropdown {
      right: 0;
      left: auto;
    }
  }
}

// 767 end

// mobileview-calendar popup
@media (max-width: 767px) {
  .listingPage {
    .mainContent .filterCnt {
      .dateAndBtnWrap {
        .inputStyle {
          .date-picker-popup {
            .Date-picker-range {
              width: 320px;
              margin: 0 auto;

              .DateRangePicker {
                border-bottom: solid 2px #1d6ec9;

                .DateRangePickerInput {
                  background: transparent;
                  border-radius: 0px;
                  justify-content: flex-start;

                  .DateInput_input {
                    padding: 0px 0px !important;
                    font-size: 17px;
                    width: 107px;
                  }

                  .DateRangePickerInput_calendarIcon {
                    padding-right: 0px;
                    font-size: 17px;
                    padding-top: 1px;
                    margin-left: 5px !important;
                  }

                  .DateRangePickerInput_arrow {
                    width: 95px;
                    margin: 0px 3px;
                    padding: 0px 0px;
                    justify-content: flex-end;
                    text-align: right;

                    .icon-invitation {
                      font-size: 17px;
                      margin-right: 0px;
                    }

                    .icon-arrow {
                      margin-right: 22px;
                    }

                  }
                }

                .DateRangePicker_picker {
                  .DayPicker_weekHeader {
                    margin-left: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}

// 767 end
// mobileview-calendar popup
@media (max-width: 767px) {
  .listingPage {
    .mainContent .filterCnt {
      .dateAndBtnWrap {
        .inputStyle {
          .date-picker-popup {
            .Date-picker-range {
              width: 320px;
              margin: 0 auto;

              .DateRangePicker {
                border-bottom: solid 2px #1d6ec9;

                .DateRangePickerInput {
                  background: transparent;
                  border-radius: 0px;
                  justify-content: flex-start;

                  .DateInput_input {
                    padding: 0px 0px;
                    font-size: 17px;
                  }

                  .DateRangePickerInput_calendarIcon {
                    padding-right: 0px;
                    font-size: 17px;
                    padding-top: 1px;
                    margin-left: 5px !important;
                  }

                  .DateRangePickerInput_arrow {
                    width: 95px;
                    margin: 0px 3px;
                    padding: 0px 0px;
                    justify-content: flex-end;
                    text-align: right;

                    .icon-invitation {
                      font-size: 17px;
                      margin-right: 0px;
                    }

                    .icon-arrow {
                      margin-right: 40px;
                    }

                  }
                }
              }
            }
          }
        }
      }
    }

  }

  .listingPage.listingPageWrap {
    .mainWrap {
      .card2 {
        .infoBoxWrapper {
          .buttonGroups {
            .centerAlign {
              text-align: left;
            }

            .upgradeBtn {
              display: flex;
              justify-content: right;

              .btn {
                margin-left: 0px;

                .icon-border-plain {
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }
    }
  }


  .listingPage {
    .mainContent {
      .filterCnt {
        .showDropdown {
          .mobilePopup {
            .dropdown-content {

              .inputDropdown {
                padding: 0px 25px 0px 35px !important;
              }

              .rbt>div {
                width: 100%;
                display: block;
                max-width: 100%
              }

              .rbt {
                .rbt-menu {
                  background-color: transparent;
                  border: none;
                  box-shadow: none;
                  margin: 10px -9px;
                  min-width: 103%;

                  a {
                    align-items: center;
                    display: flex;
                    color: var(--text-color);
                    font-size: 14px;
                    padding: 12px 8px;
                    border-radius: 6px;
                    border: solid 1px transparent;
                  }

                  .disabled {
                    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                    border-color: #d2dae6;
                    background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                  }
                }
              }

            }
          }
        }
      }
    }
  }

  .landingPage {
    .boxStyle {
      #apartmentsTab {
        .filterSecondRow {
          .responsive-input {
            .error-messages {
              margin: -10px 0 15px;
            }
          }
        }
      }
    }
  }
}

// 767 end
@media (max-width: 374px) {

  .listingPageWrap {
    .mainContent {
      .rightInfo-filter {
        .mobileSearchFilterButtons {
          .d-flex {

            .mobileButtons {
              min-width: 145px !important;

              .icon-location-on {
                left: 23px;
              }
            }
          }

        }
      }
    }

    .mainWrap {
      .card2 {
        .infoBoxWrapper {
          .buttonGroups {
            .btn-select-room {
              min-width: 100px !important;
              font-size: 12px;

            }

            .btnBorder2 {
              font-size: 12px !important;
            }
          }

        }
      }
    }

  }

  .listingPageWrap.listingHotel {
    .mainContent {
      .filterCnt {
        .mobileSearchBlock {
          .fa-search {
            margin-right: 8px;
          }

          .selectSearchCnt h4 {
            width: 200px;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
          }
        }

        .responsive-input {
          .inputStyle {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;

          }
        }

        .dateAndBtnWrap {

          .inputStyle {
            .date-picker-popup {
              .Date-picker-range {
                .DateRangePicker {
                  .DateRangePickerInput {
                    .DateInput_input {
                      padding: 0px 5px !important;
                      min-width: 100px;
                    }

                    .DateRangePickerInput_arrow {
                      .icon-arrow {
                        margin-right: 35px;
                      }
                    }

                  }


                }


              }

            }
          }
        }


      }
    }
  }

  .listingPage {
    .mainWrap {
      .card2 {
        .infoBox {
          .title {
            button {
              padding: 7px 24px 5px;
            }

            button.active {
              min-width: 100px;
            }

            button.active:after {
              left: 0;
              min-width: 120px;
            }
          }
        }

      }
    }
  }

}

.errorMessage {
  color: red;
  margin-bottom: 0;
  padding-top: 3px;
}