.thank-you-wrapper .logo {
  max-width: 160px;
}

.thank-you-wrapper .footCopy {
  margin-top: auto;
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  padding: 20px 0 12px;
}

.thank-you-wrapper .pageLoginWrap {
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-height: 100vh;
}

.thank-you-wrapper .pageLoginWrap .container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 89px);
  justify-content: space-between;
  max-width: 750px;
}

.thank-you-wrapper .pageLoginWrap .container .btn {
  display: inline-block;
}

@media (max-width: 1400px) {
  .thank-you-wrapper .pageLoginWrap .container {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .thank-you-wrapper .pageLoginWrap .container {
    padding: 15px;
  }

  .thank-you-wrapper .pageLoginWrap .container .logo {
    margin: 0 auto;
  }

  .thank-you-wrapper .pageLoginWrap .container .boxBg {
    padding: 0;
  }

  .thank-you-wrapper .pageLoginWrap .container .boxBg h1 {
    font-size: 28px;
  }
}

.thank-you-wrapper .pageLoginWrap .boxBg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  max-width: 100%;
  color: #fff;
}

.thank-you-wrapper .pageLoginWrap .boxBg span {
  display: block;
}

@media (max-width: 767px) {
  .thank-you-wrapper {
    .pageLoginWrap {

      .container {
        height: 100%;

        .boxBg {
          padding-top: 30px;

          span {
            display: inline;
          }
        }
      }
    }
  }
}

.thank-you-wrapper .pageLoginWrap .boxBg h1 {
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 30px;
  font-size: 32px;
  color: #fff;
}

.thank-you-wrapper .pageLoginWrap .boxBg p {
  font-weight: 300;
  line-height: 1.4;
  font-size: 16px;
}

.thank-you-wrapper .pageLoginWrap .boxBg p:last-child {
  margin-top: 30px;
  margin-bottom: 0;
}

.thank-you-wrapper .pageLoginWrap .boxBg .icon-heart {
  color: #fff;
  max-width: 50px;
}

.thank-you-wrapper .pageLoginWrap .footCopy {
  text-align: center;
}

.min-height-100vh {
  min-height: 100vh;
}

.thank-you-wrapper .pageLoginWrap .boxBg .icon-heart {
  color: #fff;
  max-width: 50px;
}

.thank-you-wrapper .pageLoginWrap .footCopy {
  text-align: center;
}