.flight-tab-main-wrap {
    .flight-tab-wrap {

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .main-row {
            justify-content: space-between;
            border-bottom: 1px solid rgba($color: #BDA523, $alpha: 0.5);
        }

        .left-content {
            max-width: 60%;
            flex: 0 0 60%;
        }

        .react-tabs__tab-list {
            display: flex;
            margin: 0 -26px;

            button {
                color: rgba($color: #000000, $alpha: 0.8);
                background: none;
                font-size: 15px;
                letter-spacing: 0.34px;
                line-height: 1.2;
                position: relative;
                padding: 0 19.5px;

                &:focus,
                &:active {
                    outline: none;
                    box-shadow: none;
                    border: none;
                }

                &.react-tabs__tab--selected {
                    color: #000;
                    border-color: transparent;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: auto;
                        bottom: 0;
                        border-bottom: 2px solid #000;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 0;
                    }

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: auto;
                        bottom: 2px;
                        width: 8px;
                        height: 6px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-bottom: 5px solid #000;
                    }
                }
            }
        }

        .right-content-wrap {
            display: flex;
            justify-content: flex-end;
        }
    }

    .titles {
        color: rgba($color: #3A405F, $alpha: 0.8);
        font-size: 14px;
        letter-spacing: -0.19px;
        line-height: 1.2;
        margin-bottom: 4px;
    }
}

.flying-from-search-dropdown {
    &.ant-select-dropdown .rc-virtual-list {
        border: none;

        .ant-select-item-group {
            margin: 0;
        }
    }

    .flight-name-title {
        display: flex;
        padding: 0;

        .flight-content {
            white-space: normal;
        }

        .flight-icon {
            min-width: 27px;
        }

        &.child-ele {
            padding-left: 0px;
        }

        h5 {
            color: #000;
            font-size: 16px;
            letter-spacing: 0.36px;
            line-height: 1.2;
            margin-bottom: 5px;
        }

        h6 {
            color: rgba($color: #000, $alpha: 0.6);
            font-size: 14px;
            line-height: 1.2;
            margin-bottom: 0;
        }
    }
}

.icon-content-wrap {
    display: flex;

    .icon-col {
        width: 32px;
        max-width: 32px;
        flex: 0 0 32px;
        align-self: center;
    }

    .flying-from-content {
        max-width: calc(100% - 32px);
        flex: 0 0 calc(100% - 32px);
    }

    .ant-select {
        display: block;
        color: #000;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 1.2;
        border: none;

        .ant-input-group {
            display: block;
            color: #000;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 1.2;

            .ant-input-group-addon {
                display: none;
            }
        }

        .ant-input {
            color: #000;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 1.2;
            border: none;
            padding: 0;
            box-shadow: none;
            text-overflow: ellipsis;

            opacity: 1;
            color: #000;

        }
    }
}

.return-flight-tab {
    .return-flight-row {
        display: flex;
        align-items: center;
    }

    .flying-from-col {
        // max-width: 320px;
        // flex: 0 0 320px;
        width: 100%;
    }

    .swipe-to-col {
        padding: 0 10px;
        max-width: 54px;
        flex: 0 0 54px;

        button {
            background-color: #fff;
            border: 1px solid #979797;
            border-radius: 100%;
            width: 28px;
            height: 28px;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &:after {
                content: '';
                display: block;
                top: -11px;
                width: 1px;
                height: 11px;
                background-color: #979797;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &:before {
                content: '';
                display: block;
                bottom: -11px;
                top: auto;
                width: 1px;
                height: 11px;
                background-color: #979797;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 0;
                opacity: 1;
            }
        }
    }

    .flying-to-col {
        // max-width: 275px;
        // flex: 0 0 275px;
    }

    .returning-date-col,
    .departing-date-col {
        padding: 0 30px;
    }

    .search-now-btn-col {
        //padding-right: 14px;

        .btn-primary {
            border-radius: 8px;
            min-width: 216px;
            height: 60px;
            font-size: 22px;
            letter-spacing: 0.5px;
            line-height: 1.2;
            color: #6945FF;

            &:hover {
                color: #fff;
            }

            &:focus {
                color: #fff !important;
                background-color: #3A405F !important;
            }

            svg {
                margin-left: 12px;
                transform: rotate(85deg);
                vertical-align: -2px;

            }
        }
    }
}

.removeRowBtn {
    border: 1px solid #3A405F;
    background-color: transparent;
    color: #3A405F;
    padding: 10px;
    border-radius: 6px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #3A405F;
        color: #fff;
    }
}

#addFLightWrap {
    .search-now-btn-col .btn-primary {
        min-width: auto;

        svg {
            transform: none;
            width: 30px;
            height: 30px;
            margin: 0;
        }
    }
}

.closeMultiCityFlightModal,
.addFlightBtnWrap {
    display: none;
}

.topHeaderbar {
    #multiCityListModal:not(.openModal) {

        .closeMultiCityFlightModal,
        .addFlightBtnWrap {
            display: none;
        }
    }

    .openModal {
        .addFlightBtnWrap {
            padding-top: 15px;
            margin-top: auto;
            display: flex;

            svg {
                align-self: center;
            }

            .closeModalBtn,
            .addFlightBtn {
                border: 1px solid #3A405F;
                border-radius: 2.5px;
                color: #3A405F;
                padding: 4px 10px 7px;
                min-width: 170px;
                display: flex;
                justify-content: center;
                text-align: center;
                background-color: #3A405F;
                color: #fff;

                &:hover {
                    color: #3A405F;
                    background-color: #fff;
                }

            }

            .closeModalBtn {
                background-color: #fff;
                color: #3A405F;
                margin-left: 15px;

                &:hover {
                    color: #fff;
                    background-color: #3A405F;
                }
            }
        }

        .hideInModal {
            display: none;
        }

        #addFLightWrap .search-now-btn-col .btn-primary {
            min-width: 40px;
            border-radius: 8px;
            height: 40px;
            padding: 8px;
        }

        .listModalOverlay,
        .listModallWrap {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1054;
        }

        .listModalOverlay {
            background-color: #3A405F;
            opacity: 0.700000011920929;
        }

        .listModallWrap {
            display: flex;
            justify-content: center;
            align-items: center;

            .modalContent {
                background-color: #fff;
                border-radius: 7px;
                padding: 40px 15px 15px;
                position: relative;
                z-index: 1056;
                width: 720px;
                min-height: 280px;
                max-width: 90vw;
                display: flex;
                flex-direction: column;

                .modalTitle {
                    font-size: 24px;
                    font-family: 'Geomanist Book';
                    margin-bottom: 20px;
                    color: #3A405F;
                }

                .closeMultiCityFlightModal {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: transparent;
                    border: 0;
                    padding: 10px;
                    width: 40px;
                    height: 40px;
                    display: block;

                    svg {
                        color: #C9D3DF;
                    }
                }
            }
        }
    }

    .main-row {
        .col {
            flex-grow: inherit;
            white-space: nowrap;
            padding-bottom: 15px;
        }
    }

    .flight-types {
        position: relative;

        ul {
            border: 0;
            margin: 0;
        }
    }

    #flightDropdownBtn {
        display: block;
        color: #fff;
        background-color: transparent;
        border: 0;
        margin-left: 30px;
        width: 100px;
        text-align: left;
        padding: 0;
        height: auto;

        svg {
            font-size: 20px;
            vertical-align: middle;
        }
    }

    #selectFlightOptions {
        display: none;
        position: absolute;
        background-color: #ffffff;
        border-radius: 12px;
        box-shadow: 0 4px 12px 0 rgb(58 64 95 / 13%);

        &.open {
            display: block;
            z-index: 1061;
        }

        .react-tabs__tab {
            display: block;
            border-color: transparent;
            border-radius: 0;
            width: 200px;
            background-color: transparent;
            box-sizing: revert;
        }

        li.react-tabs__tab {
            padding: 0;
            border: 0;
            bottom: 0;
        }

        button.react-tabs__tab {
            padding: 10px 0;
            border: 0;
            bottom: 0;
            background-color: #fff;

            &:hover {
                background-color: #E6E6E6;
            }
        }

        button.react-tabs__tab--selected:hover,
        button.react-tabs__tab--selected {
            background-color: #F8D200;
            color: #3A405F;
        }

        .react-tabs__tab-list {
            overflow: hidden;
            border-radius: 12px;

            button:focus {
                box-shadow: none;

                &::after {
                    display: none !important;
                }
            }

        }
    }
}

.addFlightBtnWrap {
    font-size: var(--medium-font);

    a {
        color: #3A405F;

        svg {
            font-size: 18px;
            vertical-align: middle;
        }

    }
}

.date-col {
    .ant-space-item {
        .ant-picker {
            padding: 0;
            border: none;

            input {
                font-size: 18px;
                letter-spacing: -0.25px;
                line-height: 1.2;
                color: #6945FF;
            }
        }
    }

    .icon-content-wrap .icon-col {
        align-self: flex-end;

        svg {
            margin-bottom: 3px;
        }
    }
}

body .ant-space-vertical {
    gap: 0 !important;
}

.flight-banner {
    .addFlightBtnWrap {
        display: block;
    }

    .closeModalBtn {
        display: none;
    }

    .react-tabs__tab {
        border: 0 !important;
        padding: 0;
        background-color: transparent !important;

        &:focus {
            box-shadow: none;
        }

        button.react-tabs__tab {
            padding: 15px;
        }
    }

    #selectFlightOptions {
        .react-tabs__tab-list {
            margin: 0;
        }
    }

    .multiCityFlightTab {
        padding-bottom: 20px;
    }

    .addFlightBtn {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-now-btn-col {
        display: flex;
        justify-content: end;
    }

    .react-tabs {
        padding-bottom: 20px;
    }

    .react-tabs__tab:focus:after {
        background-color: transparent;
    }
}

// Responsive css start
@media (min-width: 1200px) and (max-width: 1550px) {
    .topHeaderbar {
        .return-flight-tab {
            .search-now-btn-col .btn-primary {
                font-size: 18px;
                min-width: 190px;
            }

            .returning-date-col,
            .departing-date-col {
                padding: 0 10px;
            }

            .swipe-to-col {
                max-width: 48px;
            }
        }
    }
}

@media (max-width: 1440px) {
    .icon-content-wrap {
        .ant-select {
            .ant-input {
                font-size: 15px;
            }
        }
    }



    .date-col {
        .ant-space-item {
            .ant-picker {
                input {
                    font-size: 16px;
                }
            }
        }
    }

    .return-flight-tab {

        .flying-to-col,
        .flying-from-col {
            max-width: 24%;
            flex: 0 0 24%;
        }

        .search-now-btn-col {
            padding-right: 0;

            .btn-primary {
                font-size: 18px;
                min-width: 190px;
            }
        }
    }
}

// 1440 end
@media (max-width: 1200px) {
    .flight-tab-main-wrap {
        .flight-tab-wrap {
            .flight-types-ul {
                li {
                    a {
                        font-size: 12px;
                    }

                    .active {
                        &:before {
                            bottom: -9px;
                        }

                        &:after {
                            border: 1px solid #6945FF;
                            bottom: -10px;
                        }
                    }
                }
            }
        }

        .titles {
            font-size: 12px;
            letter-spacing: -0.12px;
            margin-bottom: 3px;
        }
    }

    .icon-content-wrap {
        .icon-col {
            width: 22px;
            max-width: 22px;
            flex: 0 0 22px;

            svg {
                max-width: 13px;
                height: auto;
            }
        }

        .ant-select .ant-input {
            font-size: 12px;
        }
    }

    .return-flight-tab {
        .swipe-to-col {
            max-width: 40px;
            flex: 0 0 40px;

            button {
                width: 17px;
                height: 17px;

                svg {
                    max-width: 10px;
                    height: auto;
                }
            }
        }

        .search-now-btn-col {
            .btn-primary {
                min-width: 135px;
                // height: 38px;
                border-radius: 5px;
                font-size: 13.75px;
                letter-spacing: 0.31px;

                svg {
                    max-width: 12px;
                    height: auto;
                    margin-left: 7px;
                }
            }
        }

        .date-col {
            padding: 0 12px;
        }
    }

    .date-col {
        .ant-space-item {
            .ant-picker {
                input {
                    font-size: 12px;
                    letter-spacing: -0.16px;
                }
            }
        }
    }
}

// 1200 end
// @media (min-width: 768px) and (max-width: 1199px) {
//     .multiCityFlightTab,
//     #oneWayFlightTab  {
//         .flying-to-col,
//         .flying-from-col {
//             flex-grow: 1;
//             max-width: fit-content;
//             max-width: 360px;
//             flex: 0 0 360px;
//         }
//     }

// }
@media (min-width: 768px) {

    .returning-date-col,
    .departing-date-col {
        border-left: 1px solid #C9D3DF;
    }

    .topHeaderbar {
        .flight-tab-wrap {
            .tab-content {
                background-color: #fff;
                border-radius: 8px;
                position: relative;

                &#multiCityOpen {
                    z-index: 1061;
                }

                .btn-primary {
                    border-radius: 0 8px 8px 0;
                }

                .titles {
                    color: #3A405F;
                }

                .flying-from-col {
                    padding-left: 15px;
                }
            }
        }

        .openModal {
            .multiCityFlightTab {
                padding-top: 20px;
            }
        }
    }

}

@media (max-width: 767px) {
    .flight-banner {
        .flight-tab-wrap .btn-primary {
            .mobileHide {
                display: block;
            }

            .mobileShow {
                display: none;
            }
        }
    }

    .dropdown-wrap .custom-dropdown {
        left: 0;
    }

    .sort-detail-popup .custom-search-dropdown.ant-select-dropdown .rc-virtual-list {
        padding: 0;
    }

    .custom-search-dropdown.ant-select-dropdown .rc-virtual-list .ant-select-item {
        padding: 10px 0;

        .flight-icon {
            width: 40px;
        }
    }

    .mobile-popup {
        .ant-input {
            color: #000 !important;
            border-bottom: 2px solid #979797 !important;
        }

        .child-ele {
            padding-left: 0px !important;
        }

        .ant-select-item {
            margin-top: 0 !important;
        }

        .custom-search-dropdown.ant-select-dropdown {
            top: 12px !important
        }

        .flight-icon {
            display: flex;
            align-items: center;
            padding-left: 10px;
        }
    }

    .flight-banner {
        .flight-tab-wrap {
            .main-row {
                flex-direction: column-reverse;
                color: #fff;
                margin: 0;

                .left-content {
                    max-width: 100%;
                    flex-basis: 100%;

                    .react-tabs__tab-list {
                        li {
                            padding-left: 0;
                            padding-right: 0;
                        }

                        button {
                            color: #fff;
                        }
                    }
                }
            }

            .removeRowBtn {
                border-color: #fff;
                color: #fff;
                margin-top: -42px;
            }

            .multiCityFlightTab .search-now-btn-col {
                .btn-primary {
                    margin-top: -60px;
                }
            }

            .addFlightBtn {
                background-color: transparent;
                color: #fff;
            }

            .right-content-wrap {
                padding: 0 20px 20px;
            }
        }
    }

    .topHeaderbar {
        .return-flight-tab .openModal {
            .titles {
                color: #000;
            }

            .icon-content-wrap .ant-select .ant-input,
            .date-col .ant-space-item .ant-picker input {
                color: #000;
            }

            .swipe-to-col button {
                border-color: #979797;

                &::before,
                &::after {
                    background-color: #979797;
                }
            }

            .ant-input-wrapper input::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #979797 !important;
                opacity: 1;
                /* Firefox */
            }

            .ant-input-wrapper input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #979797 !important;
            }

            .ant-input-wrapper input::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #979797 !important;
            }
        }
    }

    .topHeaderbar,
    .flight-banner {
        .flight-tab-wrap {
            padding: 15px;
        }

        .flight-tab-wrap .right-content-wrap {
            width: 100%;
            justify-content: space-between;

            .toggle-btn {
                font-size: 14px;
                color: #fff;
            }

            .checkbox-content {
                label {
                    font-size: 14px;
                    color: #fff;

                    .checkmark {
                        border-color: #fff;
                        top: -2px;
                    }
                }
            }
        }

        .ant-input-wrapper input::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #979797 !important;
            opacity: 1;
            /* Firefox */
        }

        .ant-input-wrapper input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #979797 !important;
        }

        .ant-input-wrapper input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #979797 !important;
        }

        .react-tabs .tab-box .react-tabs__tab-panel--selected {
            padding: 0;
        }

        .icon-col {
            display: none;
        }

        .titles {
            color: #fff;
            font-size: 12px;
        }

        .return-flight-tab {
            .icon-content-wrap {
                .ant-select .ant-input {
                    background-color: transparent;
                    color: #fff;
                }

                .flying-from-content {
                    max-width: 100%;
                    flex-basis: 100%;
                }
            }

            .swipe-to-col {
                button {
                    border-color: #fff;
                    top: 0;
                    left: 12px;

                    &:after,
                    &:before {
                        background-color: #fff;
                    }
                }
            }

            .departing-date-col {
                border: 0;
                padding-left: 0;
            }

            .return-flight-row {
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .returning-date-col {
                padding: 0 12px;
            }

            .flying-to-col,
            .flying-from-col,
            .departing-date-col,
            .returning-date-col {
                max-width: calc(50% - 38px);
                flex-basis: calc(50% - 38px);
                padding-top: 20px;
                padding-left: 0;
                padding-right: 0;
            }

            .flying-to-col,
            .flying-from-col {
                .ant-select-selection-search {
                    border-bottom: 1px solid #ffffff;

                    .ant-input {
                        font-size: 18px;
                    }
                }
            }

            .search-now-btn-col {
                max-width: 100%;
                flex-basis: 100%;
                text-align: center;
                padding-top: 15px;
            }

            .swipe-to-col {
                max-width: 60px;
                flex: 0 0 60px;
            }
        }

        .date-col .ant-space-item .ant-picker input {
            color: #fff;
            font-size: 18px;
            border-bottom: 1px solid #ffffff;
            width: calc(50% - 27px);
            border-radius: 0;
        }

        .ant-picker-input,
        .ant-picker,
        .ant-space,
        .date-content {
            width: 100%;
        }

        .flight-tab-wrap {
            .main-row {
                .left-content {
                    .react-tabs__tab-list {
                        margin: 0;
                        justify-content: center;

                        button {
                            &.react-tabs__tab--selected::before {
                                border-bottom-color: #fff;
                            }

                            &.react-tabs__tab--selected::after {
                                border-color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .react-tabs .tab-content {
            padding-top: 0;
        }
    }

    .topHeaderbar {
        .titles {
            display: none;
        }

        .flight-tab-wrap {
            padding-top: 0;
            padding-bottom: 0;
        }

        .return-flight-tab .search-now-btn-col .btn-primary {
            width: 40px;
            min-width: auto;
            padding: 0;
            border-radius: 0;

            svg {
                max-width: 24px;
                font-size: 20px;
            }
        }

        .openModal .listModallWrap .modalContent {
            min-height: 100vh;
            width: 100vw;
            max-width: none;
            border-radius: 0;
        }
    }

    .topHeaderbar {
        .return-flight-tab {
            .return-flight-row {
                flex-wrap: nowrap;

                .search-now-btn-col,
                .flying-to-col,
                .flying-from-col,
                .departing-date-col,
                .returning-date-col {
                    max-width: fit-content;
                    flex-basis: auto;
                    padding-top: 0;

                }

                .flying-to-col,
                .flying-from-col {
                    padding-top: 4px;

                    .ant-select-selection-search {
                        display: flex
                    }
                }

                .flying-to-col,
                .departing-date-col,
                .returning-date-col {
                    padding-right: 10px;
                }

                .btn-primary {
                    height: 40px;

                    svg {
                        margin-left: 0;
                    }
                }

                .search-now-btn-col {
                    margin-right: -15px;
                }

                .swipe-to-col {
                    padding: 0;
                    max-width: 40px;
                    flex-basis: 40px;

                    .ant-btn-button {

                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
            }

        }

        .main-row {

            .col {
                padding-bottom: 8px;
                padding-top: 8px;
            }
        }

        #flightDropdownBtn {
            margin-left: 0;
        }

        .date-col .ant-space-item .ant-picker input {
            padding-bottom: 1px;
        }

        .right-content {
            margin-left: auto;
        }

        .dropdown-wrap {
            padding-right: 0;
        }

        .openModal {

            .ant-select-selection-search,
            .ant-picker input {
                border-bottom: var(--border-style) !important;
            }

            .return-flight-row {
                justify-content: start;
                align-items: end;
                padding-bottom: 10px;
            }

            .search-now-btn-col {
                margin-left: auto;
                padding-right: 10px !important;
            }
        }
    }
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
    display: none;
}

@media (max-width: 600px) {
    .topHeaderbar {
        #flightDropdownBtn {
            font-size: 12px;
        }

        .date-col .ant-space-item .ant-picker input {
            font-size: 12px !important;
        }

        .flight-tab-wrap {

            .dropdown-wrap .toggle-btn,
            .tab-content .ant-select .ant-input {
                font-size: 12px !important;
            }
        }
    }
}

@media (max-width: 480px) {
    .topHeaderbar {
        .flight-tab-wrap .tab-content .ant-select .ant-input {
            width: 64px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .topHeaderbar .openModal {
        .multiCityFlightTab {

            .departing-date-col,
            .flying-from-col,
            .flying-to-col {
                max-width: calc(50% - 38px);
                flex-basis: calc(50% - 38px);
            }

            .departing-date-col {
                border: 0;
                padding-top: 20px;
            }

            .date-content,
            .ant-select .ant-input {
                border-bottom: 2px solid #979797;
                flex-grow: 1;
                padding-bottom: 5px;
            }

            .date-col .icon-content-wrap .icon-col {
                align-self: center;
            }
        }
    }

    .flying-from-search-dropdown {
        .flight-name-title {
            h5 {
                font-size: 13px;
            }

            h6 {
                font-size: 10px;
            }
        }
    }

    .ant-slide-up-leave {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .topHeaderbar .return-flight-tab .openModal {

        .flying-to-col,
        .flying-from-col {
            max-width: 32%;
            flex: 0 0 32%;
        }
    }

    .flying-from-search-dropdown {
        .flight-name-title {
            h5 {
                font-size: 15px;
            }

            h6 {
                font-size: 13px;
            }
        }
    }
}