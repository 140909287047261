.bannerSec .header {
    .navigation {
        display: flex;
        align-items: center;
    }
}

.our-story-link, .header-contact-us-link {
    color: #ffffff;
    font-size: 19px;
    margin-right: 30px;

    &:hover {
        color: #ffffff;
    }
}

.mobile-menu-btn {
    background-color: transparent;
    border: none;
    max-width: 50px;
    display: none;
}

.header-sticky {
    .our-story-page {
        .fixedHeader .header {
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
            background-color: #eff0f5;
            padding: 9.71px 15px;
            min-height: 55px;

            .logoWrap {
                .logo1 {
                    display: none;
                }

                .logo-sticky {
                    display: block;
                }
            }

            .navigation a,
            .btnBorder3 {
                color: #1d6ec9;
                border-color: #1d6ec9;
            }
        }
    }
}

.our-story-page {
    background: url("../img/widget-bg.jpg") 0 0;
    background-size: 100% 100vh;
    background-attachment: fixed;

    .our-story-name {
        padding: 0 0 30px;
        max-width: 300px;
        margin: 0 auto;
    }

    .our-story-wrap {
        padding: 90px 15px 0;
    }

    .header {
        // box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        // background-color: #eff0f5;
        box-shadow: none;
        background-color: transparent;
        padding: 18px 15px;

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }



        .navigation {
            display: flex;
            align-items: center;
            margin-right: 30px;

            ul a {
                padding: 8px 0;
                font-size: 19px;
                color: #ffffff;
                min-width: 120px;
                background-color: transparent;
            }
        }

        .btnBorder3 {
            font-size: 19px;
            padding: 7.5px 10px;
            color: #ffffff;
            min-width: 120px;
            background-color: transparent;
        }
    }

    .footer {
        background: rgba(255, 255, 255, 0.8);
        box-shadow: none;
        bottom: 0;
        width: 100%;
        min-height: 65px;
    }

    .card2 {
        padding: 15px 20px;

        h3 {
            color: #435b75;
            font-weight: 500;
            font-size: 17px;
            margin-bottom: 20px;
        }

        p {
            font-size: 14px;
            line-height: 21px;
            font-weight: 300;

            +p {
                margin-top: 16px;
            }
        }
    }

    .section-full-image {
        padding: 15px 0 30px;

        .container {
            max-width: 600px;
            border-radius: 16px;
            overflow: hidden;
        }
    }

    .section-signature {
        padding: 30px 0 50px;

        img {
            max-width: 250px;
        }

        h4,
        h6 {
            color: #ffffff;
        }

        h4 {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            text-transform: uppercase;
            margin-top: 15px;
        }

        h6 {
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            margin-bottom: 15px;
        }
    }
}

// Responsive Css Start
@media (max-width:991px) {
    .mobile-menu-btn {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top: 10px;
    }

    .sticky-icon {
        display: none;
    }

    .header-toggle {
        .mobile-menu-btn {
            top: 3px;

            .logo1 {
                display: none;
            }
        }

        .sticky-icon {
            display: block;
        }
    }

    .header-sticky {
        .mobile-menu-btn {
            top: 3px;

            .logo1 {
                display: none;
            }
        }

        .sticky-icon {
            display: block;
        }
    }

    .bannerSec .header .container {
        justify-content: space-between;
    }

    .our-story-page,
    .indexPageWrap {
        .mobile-menu-btn {
            display: block;
        }

        .header {
            .navigation {
                display: none;

            }
        }

        .header-toggle {
            .header {
                background-color: #eff0f5;
                height: 100vh;

                .btnBorder3 {
                    color: #1d6ec9;
                    border-color: #1d6ec9;
                }
            }

            .logoWrap {
                .logo1 {
                    display: none;
                }

                .logo-sticky {
                    display: block;
                }
            }

            .navigation {
                display: block;
                left: 0;
                position: fixed;
                top: 100px;
                z-index: 999;
                padding: 0px 14px 8px 25px;
                width: 100%;

                li {
                    margin-bottom: 8px;
                }

                a, .header-contact-us-link {
                    color: #1d6ec9;
                    font-size: 14px !important;
                    line-height: 17px;
                    padding: 12px 0;
                    margin: 0;
                    display: inline-block;
                }
.header-contact-us-link {
    display: block;
}
                .btnBorder3 {
                    border: none;
                    color: #1d6ec9;
                    min-width: auto;
                }
            }
        }

    }
}

// 991 end
@media (max-width:1220px) {
    .our-story-main {
        height: calc(100vh - 58px);
        overflow: auto;
    }
}