// .ReactModal__Overlay.ReactModal__Overlay--after-open {
//     z-index: inherit;
//     background-color: transparent !important;
//     position: static !important;

// }

.pay-deduction {
    margin-top: 10px;
}

.popup {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(67, 91, 117, 0.6);
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.popup-open {
        display: flex;
    }

    .close {
        background: transparent;
        box-shadow: none;
        border: none;
        position: absolute;
        top: 2px;
        right: 5px;
        z-index: 9;
        min-width: auto;
        padding: 5px;

        &::before {
            display: none;
        }
    }

    .popup-content {
        position: relative;
        // max-width: 480px;
        max-width: auto !important;
        margin: 0 auto;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        border: solid 1px #cfd1e1;
        background-color: #eff0f5;
        padding: 30px 25px 0;
        overflow-y: auto;

        .formControl {
            background-color: rgba(255, 255, 255, 0.35);
            border-radius: 10px;
            border: 1px solid #dae2ee;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
            width: 100%;
            padding: 9px 15px 10px;
            display: block;
            overflow: visible;
            box-sizing: border-box;
            height: 40px;
            color: #435b75;
            @include ubuntu(400);
            font-size: 17px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-text-fill-color: #435b75;
            opacity: 1;
            // caret-color: rgba(0, 0, 0, 0);

            @include placeholder-text {
                font-weight: 400;
                color: rgba(67, 91, 117, 0.6);
                -webkit-text-fill-color: rgba(67, 91, 117, 0.6);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

// default popup end
.magic-section-popup {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(67, 91, 117, 0.6);
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.popup-open,
    &.ReactModal__Content--after-open {
        display: flex;
    }

    .popup-title .close {
        background: transparent;
        box-shadow: none;
        border: none;
        position: absolute;
        top: 2px;
        right: 5px;
        z-index: 9;
        min-width: auto;
        padding: 5px;

        &::before {
            display: none;
        }
    }

    .popup-content {
        position: relative;
        max-width: 1000px;
        margin: 0 auto;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        border: solid 1px #cfd1e1;
        background-color: #eff0f5;
        padding: 30px 25px;
        overflow-y: auto;

        .colCard {
            margin: 0 -10px;
            display: flex;
            flex-wrap: nowrap;

            .cardStyle {
                margin-bottom: 30px;
                display: block;
                padding-bottom: 0;
                margin: 0 10px;
                position: relative;

                .link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                }

                .cardImg {

                    margin: 0;
                    padding: 0;
                    height: auto;

                    .cardImg-wrap {
                        // padding: 9px;
                        // padding-bottom: 60%;
                        position: relative;
                    }

                    img {
                        position: static;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        height: auto;
                        width: auto;
                    }
                }
            }

            .cardText {
                padding-bottom: 10px;

                a {
                    color: #435b75;
                }

                button {
                    border: none;
                    background: transparent;
                    display: block;
                    font-size: 21px;
                    color: #435b75;
                    text-align: left;

                }

                h5 {
                    font-size: 21px;

                    button {
                        font-size: inherit;
                        color: inherit;
                        font-weight: inherit;
                        background-color: transparent;
                        border: none;
                        width: 100%;
                        display: block;
                        text-align: left;
                    }
                }
            }
        }
    }

    video {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.magic-home-section-popup {
    .popup-content {
        max-width: 700px;

        .popup-title {
            .close {
                background: transparent;
                box-shadow: none;
                border: none;
                position: absolute;
                top: 3px;
                right: 5px;
                z-index: 9;
                min-width: auto;
                padding: 5px;
            }
        }
    }
}

// magic-section-popup end
.ssn-collection-popup {
    .popup-content {
        max-width: 650px;
        padding-bottom: 20px;
    }

    .icon-close:before {
        color: #1d6ec9;
    }

    .react-tabs__tab-list {
        margin-bottom: 16px;
    }

    .popup-title {
        margin-bottom: 35px;

        .close {
            font-size: 16px;
        }

        h2 {
            font-size: 15px;
            font-weight: 400;
        }
    }

    .submit-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 12px;
            font-weight: normal;
            color: rgba(67, 91, 117, 0.6);
        }

        .btn {
            min-width: 120px;

            &::before {
                display: none;
            }
        }
    }

    .radio-button-wrap {
        display: flex;
        margin: 0 -7px 10px;
    }

    .radio-btn {
        position: relative;
        margin: 0 7px;
        min-height: 30px;

        label {
            color: #1d6ec9;
            font-size: 14px;
            padding: 6px 25px;
            border-radius: 8px;
            box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            border: solid 1px #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            cursor: pointer;
        }

        input[type="radio"] {
            position: absolute;
            top: -5px;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
        }

        input[type="radio"]:checked+label {
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        }
    }

    .formGroup {
        margin-bottom: 25px;
    }

    @media (max-width: 767px) {
        #selectFlightOptions ul.react-tabs__tab-list li {
            flex-grow: 0;
            justify-content: center;
            display: block;
        }

        .react-tabs__tab-panel .BSrow .col {
            padding: 0 !important;
        }
    }

    @media (max-width: 440px) {
        #selectFlightOptions ul.react-tabs__tab-list li {
            margin-right: 10px;
        }

        #selectFlightOptions button.react-tabs__tab {
            width: 100%;
            margin-right: 0px;
        }
    }

    @media (max-width: 374px) {
        .submit-btn {
            flex-direction: column;

            p {
                margin-bottom: 12px;
                padding: 0;
            }
        }
    }
}

// ssn-collection-popup end
.magic-video-section-popup {
    .close {
        top: -22px;
        right: -22px;
        padding: 0;
        // img {
        //     filter: brightness(0);
        // }
    }

    .popup-content {
        padding: 0;
        max-width: 992px;
        overflow: inherit;
        border-radius: 10px;
        box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 80%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
        border: solid 1px #d2dae6;
        background-image: linear-gradient(to right, #ececf3, #e6e7ee);
        padding: 10px;

        .content-video-box {
            padding-bottom: 56.25%;
            border-radius: 8px;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            // border: solid 1px #d2dae6;
            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
            overflow: hidden;
            position: relative;
        }

        iframe,
        video,
        .video-js {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 100% !important;
            width: 101% !important;
            // object-fit: cover;
            max-width: 101%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }

        .vjs-poster {
            background-size: cover;
        }

        .vjs-fullscreen-control,
        .vjs-picture-in-picture-control {
            display: none;
        }

        .first-video-play-btn {
            background-color: transparent;
            border: none;
            cursor: pointer;
            left: 50%;
            outline: none;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            z-index: 1;

            @media (max-width:767px) {
                img {
                    max-width: 70px;
                }
            }
        }
    }

    .popup-magic-video {
        @include background('');
        background-image: url('../img/network/video-section-bg.png');
    }

    .video-background-controls {
        top: auto !important;
        right: auto !important;
        left: 10px;
        bottom: 10px;

        button {
            background-color: transparent;
            border: none;
        }
    }
}

// magic video section end
.save-traveller-popup {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;

    .popup-content {
        max-width: 700px;

    }

    .popup-footer {
        padding: 20px 0;
        border-top: 1px solid #dae2ee;
    }

    .information-content-wrap {
        max-height: 325px;
        overflow: hidden;
        overflow-y: auto;

        @media(max-width:374px) {
            padding: 0 10px;
        }
    }

    .delete-btn {
        background: none;
        min-width: auto;
        color: #eb2026;
        box-shadow: none;
        padding: 0;
        display: inline-flex;
        align-items: center;
        font-size: 14px;

        img {
            margin-right: 5px;
            max-width: 17px;
        }


        &:before {
            display: none;
        }
    }

    .popup-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        h3 {
            font-size: 21px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }
    }

    .toggle-links-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #dae2ee;
    }

    .toggle-links {

        ul {
            list-style: none;
            display: flex;
            margin: 0 -10px;

            li {
                padding: 0 10px;

                a {
                    color: #435b75;
                    position: relative;
                }

                .active {
                    color: #1d6ec9;

                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        bottom: -10px;
                        background-color: #1d6ec9;
                    }
                }
            }
        }
    }

    .button-wrap {
        display: flex;
        justify-content: flex-end;

        .btn {
            margin: 0 5px;
            font-size: 14px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 10px 12px 10px 48px;
            background-position: left center;
            min-width: 130px;
        }

        .blue-btn {
            background-color: transparent;
            border-radius: 22.5px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
            background-image: url("../img/profile/blue-btn-bg.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;

            &:before {
                display: none;
            }
        }
    }

    .information-content-wrap-inner {
        padding-top: 20px;

        .info-card {
            &:last-child {
                border-bottom: none;
            }
        }
    }

    .info-card {
        padding: 15px 0;
        // border-radius: 10px;
        // background-color: #c2e4f3;
        padding: 10px;
        padding-left: 0;
        margin-bottom: 15px;
        min-height: 325px;
        border-bottom: 1px solid #dae2ee;

        &:last-child {
            border-bottom: none;
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }

        h5 {
            color: rgba(67, 91, 117, 0.6);
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 6px;
        }

        .info-heading {
            padding-bottom: 25px;
        }

        .formGroup {
            margin-bottom: 13px;
        }

        select {
            min-width: 100%;
            border-radius: 10px;
            box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            -webkit-box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
            -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px rgba(210, 218, 230, 0.6);
            background-image: none;
            background-color: rgba(255, 255, 255, 0.35);
            min-height: 40px;
            font-size: 17px;
            color: rgba(67, 91, 117, 0.6);
            padding-right: 28px;
        }

        .selectWrap {
            position: relative;
            margin-right: 0;


            &:after {
                content: "";
                background-image: url(../img/dropdown-arrow.png);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                background-size: contain;
                height: 14px;
                width: 14px;
                position: absolute;
                top: 50%;
                z-index: 0;
                transform: translateY(-50%);
                right: 10px;

            }
        }


        .selectWrap {
            .css-26l3qy-menu {
                z-index: 999;
            }

            [class*=control] {
                min-width: 100%;
                border-radius: 10px;
                -webkit-box-shadow: -2px -2px 4px 0 rgb(255 255 255 / 85%), 2px 2px 3px 0 rgb(0 0 0 / 15%);
                -ms-box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85), 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px rgba(210, 218, 230, 0.6);
                background-image: none;
                background-color: rgba(255, 255, 255, 0.35);
                min-height: 38px;
                font-size: 17px;
                color: rgba(67, 91, 117, 0.6);
                padding-right: 28px;
                cursor: pointer;

                .css-1hb7zxy-IndicatorsContainer {
                    display: none;
                }

                .css-319lph-ValueContainer {
                    padding: 0px 8px;
                    margin-top: -4px;
                    color: #7d91a9;
                }

                [class*="placeholder"] {
                    color: #7d91a9;
                }

                [class*="singleValue"] {
                    color: #435b75;
                }

            }
        }

        .col-content {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px;

            .formGroup {
                max-width: 50%;
                flex: 0 0 50%;
                padding: 0 8px;

                .react-tel-input {
                    .form-control {
                        min-height: 40px;
                        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                        border: 1px solid #dae2ee;
                    }
                }

                .SingleDatePicker {
                    width: 100%;

                    .SingleDatePickerInput {
                        background-color: rgba(255, 255, 255, 0.35);
                        border-radius: 10px;
                        border: 1px solid #dae2ee;
                        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
                        width: 100%;
                        padding: 7px 15px 6px;
                        display: block;
                        overflow: visible;
                        box-sizing: border-box;
                        color: rgba(67, 91, 117, 0.6);
                        font-family: "Ubuntu", sans-serif;
                        font-weight: 400;
                        font-size: 17px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        min-height: 40px;

                        .DateInput_input {
                            background-color: transparent;
                            padding: 0px 0px 0px 10px;
                            border-bottom: none;
                            line-height: normal;
                            color: rgba(67, 91, 117, 0.6);
                            -webkit-text-fill-color: rgba(67, 91, 117, 0.6);
                            opacity: 1;
                        }

                        .DateInput_input__focused {
                            border-bottom: none;
                        }
                    }


                    .SingleDatePicker_picker {
                        top: 50px !important;
                        left: 0px !important;

                        .DayPicker_weekHeader {
                            margin-left: 17px;

                            .DayPicker_weekHeader_li {
                                width: 43px !important;
                            }
                        }
                    }
                }
            }

            .title-field {
                max-width: 130px;
                flex: 0 0 130px;

            }
        }

        .first-row-content {
            .col-content {
                .formGroup {
                    max-width: 37%;
                    flex: 0 0 37%;
                    padding: 0 10px;
                }

                .title-field {
                    max-width: 26%;
                    flex: 0 0 26%;

                    .selectWrap [class*=control] [class*=css]+[class*=css] {
                        // display: none;
                    }
                }
            }
        }
    }
}

// save traveller popup profile end
.profile-info-popup {
    .attention-title {
        border-bottom: solid 1px #dae2ee;
        padding: 10px 0;
    }

    .text-icon {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px;

        .text-style {
            padding: 10px 15px 0px 0px;
        }

        .img-style {
            padding: 5px 5px;
            border: 2px solid #435b75;
            border-radius: 100px;
            line-height: 0;
            height: 40px;
            width: 40px;

            img {
                width: 25px;
            }
        }

        h3 {
            font-size: 21px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }
    }
}


.add-save-traveller-popup {
    .popup-content {

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 !important;
    }

    .SingleDatePicker {
        select {
            box-shadow: none;
            -webkit-box-shadow: none;
            background-color: transparent;
            border: none;
            min-height: auto;
        }
    }

}

.attention-popup {
    .popup-content {
        max-width: 580px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 !important;
    }

    .popup-title {
        padding-bottom: 20px;

        h3 {
            font-size: 21px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }

        .text-icon {
            display: flex;
            justify-content: flex-start;

            .text-style {
                padding: 10px 15px 0px 0px;
            }

            .img-style {
                padding: 5px 5px;
                border: 2px solid #435b75;
                border-radius: 100px;
                line-height: 0;

                img {
                    width: 25px;
                }
            }
        }
    }

    .attention-info {
        padding: 20px 0px 0px 0px;
        color: #435b75;
        border-top: solid 1px #dae2ee;
        border-bottom: solid 1px #dae2ee;
        margin-bottom: 30px;

        h3 {
            font-size: 21px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 20px;
        }

        strong {
            font-weight: bold;
        }

    }

    .button-wrap {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        .btn {
            margin: 0 5px;
            font-size: 16px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 7px 12px 7px 50px;
            background-position: left center;
            min-width: 130px;
        }

        .btn:before {
            background-image: none;
        }

        .blue-btn {
            background-color: transparent;
            border-radius: 22.5px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
            background-image: url('../img/profile/blue-btn-bg.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;
        }
    }
}

// attention-popup end
.affiliate-profile-cancellation-popup {
    &.open-modal {
        display: flex;
    }

    .popup-title {
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #dae2ee;

        h3 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 0;
            color: #435b75;
            display: flex;
            align-items: center;
        }
    }

    .popup-content {
        max-width: 580px;
    }

    .content-sub-title {
        padding: 25px 0;
        color: #435b75;
        border-bottom: solid 1px #dae2ee;

        h4 {
            font-size: 16px;
            color: #435b75;
            font-weight: 400;
        }
    }

    p {
        font-size: 16px;
        color: #435b75;
        font-weight: 400;
    }

    .attention-title {
        border-bottom: solid 1px #dae2ee;
        padding: 10px 0;
    }

    .text-icon {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px;

        .text-style {
            padding: 10px 15px 0px 0px;
        }

        .img-style {
            padding: 5px 5px;
            border: 2px solid #435b75;
            border-radius: 100px;
            line-height: 0;
            height: 40px;
            width: 40px;

            img {
                width: 25px;
            }
        }

        h3 {
            font-size: 21px;
            font-weight: 500;
            color: #435b75;
            line-height: normal;
            margin-bottom: 5px;
        }
    }

    .button-wrap {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;

        .btn {
            margin: 0 5px;
            font-size: 16px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 7px 12px 7px 42px;
            background-position: left center;
            min-width: 130px;
        }

        .btn:before {
            background-image: none;
        }

        .blue-btn {
            background-color: transparent;
            border-radius: 22.5px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
            background-image: url('../img/profile/blue-btn-bg.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;
        }
    }
}

.qrcode-popup {
    &.open-popup {
        display: flex;
    }

    .content-box {
        img {
            width: 100%;
            border-radius: 4px;
        }
    }

    .popup-content {
        padding: 30px 25px;

        @media (max-width: 600px) {
            max-width: 90%;
        }
    }
}

.room-details-popup {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background-color: rgba(67, 91, 117, 0.5);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: none;

    .popup-content-wrap {
        max-width: 652px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width:650px) {
            max-width: 95%;
        }
    }

    .popup-content {
        max-width: 100%
    }

    .popup-title {
        margin-bottom: 0px;
        padding-bottom: 0px;
        padding-left: 20px;

        h3 {
            @include ubuntu(500);
            font-size: 17px;
            line-height: normal;
            letter-spacing: normal;
            color: #435b75;
            margin-bottom: 18px;
        }
    }

    .popup-content {
        position: relative;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        border: solid 1px #cfd1e1;
        background-color: #eff0f5;
        padding: 13px 0px 13px 0px;
        max-height: 95vh;
        overflow: hidden;


        .box-content {
            margin-top: 0px;
            overflow: hidden;
            overflow-y: auto;
            padding: 0px 20px 10px 20px;
        }
    }

    .close {
        margin-top: -4px;
        padding: 4px;
        font-weight: 400;
        font-size: 18px;
        position: absolute;
        top: 10px;
        right: 8px;
        line-height: 1;
        min-width: auto;
        box-shadow: none;
        background: none;
        border-radius: 0;

        &:before {
            display: none;
        }
    }

    .slick-list {
        border-radius: 10px;
    }

    .slider-image {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 15px;
        text-align: center;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .room-details-content {
        h3 {
            @include ubuntu(400);
            font-size: 23px;
            line-height: normal;
            letter-spacing: normal;
            color: #1d6ec9;
            margin-bottom: 13px;
        }

        p {
            @include ubuntu(400);
            font-size: 14px;
            line-height: normal;
            letter-spacing: normal;
            color: #435b75;
        }

        .progress-wrap {
            max-width: 235px;
            margin-top: 20px;

            h4 {
                font-size: 14px;
                @include ubuntu(400);
                line-height: normal;
                letter-spacing: normal;
                color: #435b75;
            }

            h3 {
                font-size: 17px;
                @include ubuntu(400);
                line-height: normal;
                letter-spacing: normal;
                color: #00b7a6;
                margin-bottom: 0;
            }

            .progress {
                display: flex;
                height: 4px;
                overflow: hidden;
                line-height: 0;
                font-size: 0.75rem;
                background-color: rgba(126, 148, 168, 0.4);
                border-radius: 5px;
                box-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
                margin-top: 4px;

                .progress-bar {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -ms-flex-pack: center;
                    justify-content: center;
                    overflow: hidden;
                    color: #fff;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #00b7a6;
                    transition: width 0.6s ease;
                }
            }

        }

    }

    .room-facilities-wrap {
        margin-top: 23px;

        h3 {
            @include ubuntu(500);
            font-size: 17px;
            line-height: normal;
            letter-spacing: normal;
            color: #435b75;
            margin-bottom: 11px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;

            li {
                max-width: 25%;
                flex: 0 0 25%;
                padding: 0 15px;
                font-size: 14px;
                @include ubuntu(400);
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #435b75;

                @media(max-width:767px) {
                    max-width: 50%;
                    flex: 0 0 50%;
                }
            }
        }
    }

    .slider-image-slider {
        .slider-image {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 325px;

            @media (max-width:767px) {
                min-height: 250px;
            }

            @media (max-width:480px) {
                min-height: 200px;
            }
        }
    }
}

.saver-popup {
    display: none;

    .popup-content {
        position: relative;
        max-width: 530px;
        margin: 0 auto;
        width: 100%;
        padding: 10px 15px;
        overflow-y: auto;
        background-color: rgb(239, 240, 245);

        .icon-close {
            position: absolute;
            top: 7px;
            right: 10px;
            border: none;
            background-color: transparent;

            :before {

                color: #1d6ec9;
            }
        }

        .heading {

            h1 {
                font-size: 25px;
                font-weight: 400;
                line-height: 30px;
                display: flex;
                align-items: center;
                padding-bottom: 7px;
                border-bottom: 1px solid gainsboro;


                &:before {
                    content: "";
                    background-image: url(../img/icon-super.png);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 66px;
                    width: 50px;
                    margin-right: 3px;

                }
            }
        }

        .popup-text {
            border-bottom: 1px solid gainsboro;
            padding-top: 15px;

            h3 {
                font-size: 20px;
                font-weight: 300;
                line-height: 23px;
                margin-bottom: 10px;
            }

            p {
                font-size: 18px;
                line-height: 21px;
                font-weight: 300;
                margin-bottom: 25px;
            }

        }

        .popup-footer {
            padding: 15px 0px;
            display: flex;
            justify-content: flex-end;

            button {
                min-width: 110px;
                padding: 8px;
                margin: 0px 10px;
                font-size: 13px;
                overflow: hidden;
            }

            .btn:before {
                height: 26px;
                width: 35px;
                background-size: 26px;
                position: absolute;
                left: -4px;
                top: 9px;
            }
        }

    }
}

.hotelDetailPopup {
    .popup-title {
        font-size: 17px;
        color: var(--text-color);
        font-weight: 500;
        margin-bottom: 5px;
        padding-left: 20px;
    }

    .popup-content .infoBox {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
}

.hotel-confirmation-popup {
    .popup-content {
        position: relative;
        max-width: 580px;
        margin: 0 auto;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
        border: solid 1px #cfd1e1;
        background-color: #eff0f5;
        padding: 15px 21px;

        @media(max-width:600px) {
            max-width: 90%;
        }
    }

    .formGroup {
        h5 {
            font-size: 14px;
            color: #7d92a9;
            margin-bottom: 5px;
        }
    }

    .popup-content-title {
        padding: 20px 0;
        border-bottom: solid 1px #dae2ee;
        color: #435b75;
    }

    .popup-title {
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #dae2ee;

        h3 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 0;
            color: #435b75;
            display: flex;
            align-items: center;
        }
    }

    h4 {
        font-size: 16px;
        color: #435b75;
        font-weight: 400;
        margin-bottom: 12px;
    }

    ul,
    ol {
        padding-left: 15px;

        li {
            margin-bottom: 10px;
        }
    }

    .popup-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 15px;
        margin: 0 -10px;

        .button-wrap {
            display: flex;
        }

        .btn {
            margin: 0 10px;
            min-width: 130px;
            font-size: 16px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 10px 12px 10px 25px;
            background-position: left center;
            justify-content: center;
            align-items: center;

            &:before {
                display: none;
            }

            @media(max-width:374px) {
                min-width: 110px;
                margin: 0 7px;
                font-size: 14px;
            }
        }

        .blue-btn {
            background-color: transparent;
            border-radius: 22.5px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
            background-image: url(../img/profile/blue-btn-bg.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;
        }
    }
}

.cancellation-policy-popup {
    color: #435b75;
    line-height: 1.3;

    .popup-content-wrap {
        .popup-content {
            margin: 0;
            padding: 30px 25px 0;
            min-height: 150px;

            @media(max-width:767px) {
                margin: 0 auto;
                padding: 30px 15px 0;
            }
        }

        .popup-title {
            margin-bottom: 15px;

        }

        h5 {
            color: #435b75;
            margin-bottom: 20px;
            font-size: 15px;
            font-weight: 400;
        }

        p {
            color: #435b75;
            margin-bottom: 10px;
        }
    }

    @media(max-width:640px) {
        .popup-content {
            max-width: 90%;
        }
    }
}

.hotelDetailCheckoutPopup {
    .icon-close {
        &::before {
            color: #1d6ec9;
        }
    }

    .tooltip-wrap {
        position: relative;

        img {
            cursor: pointer;
        }

        &:hover {
            .pricedetail-dropdown {
                display: block;
                left: -50px;
            }
        }

        @media (max-width:767px) {
            .pricedetail-dropdown {
                top: auto;
                bottom: 20px;
                width: 250px;

                .dropdown-info {
                    h4 {
                        span {
                            min-width: 100px;
                        }
                    }
                }
            }
        }
    }

    .amountCalculation .pricedetail-dropdown {
        .note {
            p {
                color: inherit;
                margin: 0;
            }
        }

        .dropdown-info {
            h4 {
                font-size: 12px;
                font-family: "Ubuntu", sans-serif;
                font-weight: 400;
                line-height: normal;
                letter-spacing: normal;
                color: rgba(67, 91, 117, 0.6);
                margin-bottom: 9px;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .popUpBox {
        max-width: 90%;
        margin: 0px auto;
        border-radius: 10px 10px 0 0;
    }

    .contentGroup h2 {
        font-size: 18px;
    }

    .popup-title {
        font-size: 23px;
        padding-left: 5px;
    }

    .popup-content {
        padding: 25px 10px 15px;

        .box-content {
            margin-top: 10px;
        }
    }

    .roomsInfo {
        .sectionTitle {
            p {
                min-width: 80px;
            }
        }
    }

    .resortFeessection {
        background-color: #d4d6e2;
        border-radius: 8px;
        padding: 6px 10px;
        margin: 6px 0;

        h3 {
            font-size: 14px;
            margin-bottom: 2px;
            display: flex;
            align-items: center;

            strong {
                margin-left: 8px;
            }

            .circle {
                padding: 3px;
                border: 1px solid #435b75;
                border-radius: 100px;
                line-height: 0;
                height: 16px;
                width: 16px;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 12px;
                }
            }
        }

        p {
            font-size: 12px;
            margin-bottom: 0;
            color: #435b75;
        }
    }

    .amountCalculation {
        padding: 12px 14px 5px 15px;
        border-radius: 8px;
        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        -webkit-box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
        -ms-box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
        border: solid 1px #dae2ee;
        background-color: rgba(255, 255, 255, 0.35);
        margin: 0;
    }

    @media(max-width:991px) {
        .resortFeessection {
            p {
                margin-left: 20px;
            }
        }
    }

    @media(max-width:680px) {
        .popup-content {
            max-width: 95%;
        }
    }

}

.modal-policy {
    z-index: 99;

    .icon-close {
        &::before {
            color: #1d6ec9;
        }
    }
}

.first-video-popup {
    background-color: rgba(67, 91, 117, 0.4);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
}

.popup-email-success-sent {
    .popup-content {
        padding: 10px 30px 30px;
        max-width: 440px;
        text-align: center;

        .check-icon {
            max-width: 50px;
            margin: 0 auto 15px;
        }

        h3 {
            color: #435b75;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.35;
        }
    }
}

.popup-privacy-confirm-text {
    .btn-understand-wrap {
        text-align: right;
        padding: 15px 0 25px;

    }

    .btn-understand {
        font-size: 14px;
        padding: 12px;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        max-height: 40px;
        border-radius: 4px;
        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
        background-image: linear-gradient(to right, #326ed1, #06c4d4);

        &:before {
            display: none;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.2;
        color: #435b75;
        font-weight: 400;
    }

    h5 {
        color: #435b75;
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 8px;

        +p {
            margin-bottom: 20px;
        }
    }

    @media (max-width:767px) {
        .btn-understand-wrap {
            text-align: center;
        }

        p {
            font-size: 14px;
        }

        h5 {
            font-size: 17px;
        }
    }
}

.popup-price-check {
    .popup-content {
        max-width: 580px;

        @media (max-width:600px) {
            max-width: 90%;
        }
    }

    .check-icon {
        margin-bottom: 20px;

        img {
            max-width: 70px;
            margin: 0 auto;
        }
    }

    .check-icon-down {

        svg,
        img {
            transform: rotate(90deg);
        }
    }

    h2 {
        color: #435b75;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: normal;
        margin-bottom: 16px;
    }

    h3 {
        color: #435b75;
        font-size: 17px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: normal;
        margin-bottom: 16px;
    }

    .button-group {
        padding: 20px 0;
        display: flex;
        justify-content: flex-end;

        .btn {
            margin: 0 8px;
            font-size: 14px;
            line-height: 1.2;
            display: inline-flex;
            justify-content: flex-start;
            min-height: 35px;
            height: auto;
            padding: 10px 12px 10px 48px;
            background-position: left center;
            min-width: 130px;
        }

        .btn-blue {
            background-color: transparent;
            border-radius: 22.5px;
            box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);
            background-image: url("../img/profile/blue-btn-bg.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 100%;

            &:before {
                display: none;
            }
        }
    }
}

.home-contact-popup {
    top: 100px;
    transform: none;
    left: auto;
    background-color: transparent;
    backdrop-filter: blur(0);
    height: auto;

    .close {
        img {
            max-width: 20px;
        }
    }

    .popup-title {
        padding-bottom: 20px;
    }

    .popup-content {
        max-width: 370px;
        padding: 30px 20px 20px;
    }

    p,
    a {
        color: #7d92a9;
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
    }

    .support-icon {
        text-align: right;
        margin-top: -20px;
        margin-right: -8px;

        img {
            max-width: 40px;
        }
    }
}

.modal-hotel-sold-out .sold-out-wrap {
    .infoBox {
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        border: none;

        h2 {
            background: rgba(255, 86, 48, 0.05);
            border: 1px dashed #FF5630;
            border-radius: 4px;
            width: 130px;
            height: 46px;
            margin: 0 auto 12px;
            color: #FF5630;
            font-weight: 500;
            font-size: 19px;
            line-height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #091E42;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            max-width: 236px;
            margin: 0 auto;
            text-align: center;
        }
    }
}

.flight-confirmation-cancel-popup {
    .popup-content {
        max-width: 700px;
        padding-top: 23px;
    }

    .popup-title h3 {
        font-size: 21px;
        line-height: 24px;
    }

    .text-right {
        text-align: right;
    }

    textarea {
        background-color: rgba(255, 255, 255, 0.35);
        border-radius: 10px;
        border: 1px solid #dae2ee;
        box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2), inset -2px -2px 6px 0 #ffffff;
        width: 100%;
        padding: 9px 15px 10px;
        display: block;
        overflow: visible;
        box-sizing: border-box;
        color: #435b75;
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-size: 17px;
        resize: none;
    }

    .popup-content-title {
        margin: 22px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            font-size: 16px;
            line-height: 20px;
            color: #435b75;
        }

        .status {
            background: #36B37E;
            padding: 5px 8px;
            border-radius: 4px;
            color: #FFFFFF;
            font-size: 12px;
            line-height: 20px;
        }
    }

    .resons-wrap {
        margin: 0 -25px;
        padding: 12px 25px;
        border-top: 1px solid #DFE1E6;
        border-bottom: 1px solid #DFE1E6;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0 0 0 8px;
            display: flex;
            justify-content: space-between;

            li {
                display: flex;
                max-width: 33.33%;
                flex: 0 0 33.33%;
                align-items: center;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #A5A6A8;
            }

            .active {
                color: #435b75;

                .number {
                    background-color: #298DEB;
                    border-color: #298DEB;
                    color: #ffffff;
                }
            }
        }

        .dash-line {
            display: block;
            height: 1px;
            width: 80px;
            background: #B3BAC5;
            margin-left: 8px;
        }

        .number {
            height: 24px;
            width: 24px;
            border: 1px solid #B3BAC5;
            border-radius: 100%;
            margin: 0 6px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content-title {
        font-size: 16px;
        line-height: 20px;
        color: #435b75;
        margin: 20px 0;
    }

    .ant-radio-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .ant-radio-wrapper {
            max-width: calc(50% - 5px);
            flex: 0 0 calc(50% - 5px);
            margin: 0;
            border-radius: 4px;
            border: 1px solid #D6D9DE;
            padding: 8px 5px;
            margin-bottom: 10px;
            color: #6A6A6A;
            font-size: 11px;
            line-height: 16px;
            font-weight: 400;

            .ant-radio-inner {
                border: 1px solid #B3BAC5;
                height: 12px;
                width: 12px;
                background-color: transparent;
                box-shadow: none !important;

                &::after {
                    height: 12px;
                    width: 12px;
                    margin-top: -6px;
                    margin-left: -6px;
                }
            }
        }
    }

    .step-2 {
        .box {
            border: 1px solid #D6D9DE;
            border-radius: 4px;
            margin-bottom: 16px;
        }

        .content {
            padding: 10px 20px;
        }

        .row+.row {
            border-top: 1px solid #D6D9DE;
        }

        h4 {
            color: #435b75;
            font-size: 14px;
            line-height: 20px;
        }

        h5 {
            color: #435b75;
            font-size: 11px;
            line-height: 16px;
            font-weight: 400;
        }

        .highlighted-box {
            padding: 23px 20px;
            background: #EEF7DC;
            border-radius: 4px;

            p {
                font-size: 12px;
                line-height: 14px;
                color: #74879D;
            }
        }
    }

    .step-3 {
        .box {
            border: 1px solid #D6D9DE;
            border-radius: 4px;
            margin-bottom: 16px;
        }

        .row {
            padding: 2px 0;
            margin-bottom: 10px;
        }

        .ant-radio-group .ant-radio-wrapper {
            max-width: 100%;
            flex: 0 0 100%;
            display: flex;
            padding-left: 20px;
            padding-right: 20px;
            border: none;
            justify-content: space-between;

            +.ant-radio-wrapper {
                border-top: 1px solid #D6D9DE;
            }

            .ant-radio {
                +span {
                    display: block;
                    max-width: calc(100% - 55px);
                    flex: 0 0 calc(100% - 55px);
                    padding: 0;
                }
            }
        }

        h4 {
            font-size: 14px;
            line-height: 20px;
            color: #435b75;
        }

        h5 {
            color: #3C3C3C;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
        }

        ul,
        ol {
            padding-left: 15px;
            font-size: 11px;
            line-height: 16px;
            color: #6A6A6A;
        }

        p {
            font-size: 11px;
            line-height: 16px;
            color: #6A6A6A;
        }
    }

    .button-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;

        .btn {
            min-width: 150px;
            padding: 9px;
            font-size: 14px;
            border-radius: 10px;

            &::before {
                display: none;
            }
        }

        .btn-back {
            background: transparent;
            border-radius: 0;
            color: #1d6ec9;
            font-size: 16px;
            line-height: 20px;
            box-shadow: none;
            padding: 0;

            img {
                margin-right: 6px;
            }
        }
    }
}

// Responsive popup start
.popup-review-and-verify {
    color: #435b75;
    font-size: 17px;
    line-height: 21px;

    .popup-content {
        padding: 20px;
        max-width: 720px;
        background-color: #c5dae7;
        border: none;
        box-shadow: none;
    }

    .popup-title {
        border-bottom: 1px solid #435b75;
        padding-bottom: 10px;
        margin-bottom: 15px;

        h3 {
            font-size: 21px;
            line-height: 24px;
        }
    }

    p {
        line-height: 30px;
        font-weight: 300;
    }

    .button-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 5px;

        .btn {
            font-size: 14px;
            padding: 12px;
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            max-height: 40px;
            border-radius: 4px;
            box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px 2px #fff;
            background-image: linear-gradient(to right, #326ed1, #06c4d4);

            &::before {
                display: none;
            }

            img {
                width: 15px;
                height: 15px;
                margin-right: 7px;
                vertical-align: text-top;
            }

            +.btn {
                margin-left: 15px;
            }
        }
    }

    @media (max-width:767px) {
        .popup-content {
            max-width: calc(100% - 25px);
        }

        .btn {
            min-width: 150px;
        }
    }

    @media (max-width:375px) {
        .btn {
            min-width: 125px;
        }
    }
}
.popup-additional-policy {
    .content {
        padding-bottom: 15px;
        ul,ol {
            padding-left: 17px;
            li {
                margin-bottom: 5px;
            }
        }
    }
}
@media (max-width: 1024px) {

    .magic-section-popup,
    .magic-video-section-popup {
        .popup-content {
            max-width: 93%;
        }
    }
}

// 1024 end
@media (max-width: 991px) {
    .magic-video-section-popup {
        .popup-content {
            max-width: 600px;
        }
    }

    .magic-section-popup .popup-content .colCard .cardText h5 {
        font-size: 17px;
    }
}

@media (max-width: 767px) {
    .magic-section-popup {
        .popup-content {
            max-width: 420px;
            padding-bottom: 0;

            .colCard {
                flex-direction: column;
                margin: 0;

                .cardStyle {
                    margin: 0 0 30px;
                }

                .cardText {
                    h5 {
                        font-size: 19px;
                    }
                }
            }
        }
    }

    .ssn-collection-popup {
        .popup-content {
            max-width: 80%;
        }
    }

    .magic-video-section-popup {
        .popup-content {
            max-width: 500px;
        }
    }

    .attention-popup {
        .attention-info {
            h3 {
                font-size: 18px;
                line-height: 1.3;
            }
        }
    }

    .hotelDetailCheckoutPopup {
        .roomInfo {
            h3 {
                span {
                    display: block;
                }

                button {
                    margin-left: 0;
                }
            }
        }
    }

    .save-traveller-popup {
        .information-content-wrap-inner {
            .first-row-content .col-content {
                .formGroup {
                    max-width: 50%;
                    flex: 0 0 50%;
                }

                .title-field {
                    max-width: 100%;
                    flex: 0 0 100%;

                    .inputStyle {
                        max-width: 150px;
                    }
                }

            }
        }
    }

    .ipad-portrait-popup h6 {
        font-size: 14px;
    }

    .flight-confirmation-cancel-popup {
        .popup-content {
            padding: 20px 15px 0;
        }

        .resons-wrap {
            margin: 0 -15px;
            padding: 10px;

            ul {
                padding: 0;

                li {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .dash-line {
                width: 60px;
            }

            .number {
                height: 20px;
                width: 20px;
                max-width: 20px;
                flex: 0 0 20px;
            }
        }

        .button-wrap {
            .btn-back {
                font-size: 14px;
                line-height: 18px;
            }
        }

        .step-2 {
            .content {
                padding: 10px 15px;
            }

            .highlighted-box {
                padding: 15px;
            }
        }

        .step-3 .ant-radio-group .ant-radio-wrapper {
            padding-left: 15px;
            padding-right: 0;

            .ant-radio+span {
                max-width: calc(100% - 40px);
                flex: 0 0 calc(100% - 40px);
            }
        }
    }
}

// 767 end
@media (max-width: 640px) {
    .affiliate-profile-cancellation-popup {
        .popup-content {
            max-width: 90%;
            padding: 30px 15px 0;
        }

        .button-wrap .btn {
            padding: 7px 12px 7px 30px;
            min-width: 110px;
        }
    }

    .flight-confirmation-cancel-popup {
        .popup-content {
            max-width: calc(100% - 20px);
        }

        .resons-wrap .dash-line {
            width: 30px;
        }
    }
}

//640 end
@media (max-width: 580px) {
    .magic-section-popup {
        .popup-content {
            .colCard {
                .cardText {
                    padding: 5px 0;
                }
            }
        }
    }

    .ssn-collection-popup {
        .popup-content {
            max-width: 90%;
            padding: 30px 15px 20px;
        }

        .submit-btn {
            p {
                padding-right: 10px;
            }
        }
    }

    .magic-video-section-popup {
        .popup-content {
            max-width: 90%;
        }
    }

    .attention-popup,
    .save-traveller-popup {
        .popup-content {
            max-width: 90%;
        }
    }

    .save-traveller-popup .info-card .first-row-content .col-content {
        .title-field {
            select {
                padding-right: 12px;
            }
        }
    }
}

// 580 end
@media (max-width: 480px) {
    .magic-section-popup {
        .popup-content {

            .colCard {
                .cardText {
                    h5 {
                        font-size: 17px;
                    }
                }
            }

        }
    }

    .save-traveller-popup {
        .popup-content {
            padding: 30px 15px 0;
        }

        .information-content-wrap {

            form,
            .info-card {
                .col {
                    max-width: 100%;
                    flex: 0 0 100%;

                    .col {
                        padding: 0;
                    }
                }
            }
        }

        .button-wrap {
            .btn {
                min-width: 120px;

                &::before {
                    display: none;
                }
            }
        }

        .info-card {
            .first-row-content .col-content {

                .title-field,
                .formGroup {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

            .title-field {

                .inputStyle {
                    max-width: 100% !important;
                }
            }

            .col-content {
                .formGroup {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

        }
    }

    .popup-email-success-sent {
        .popup-content {
            padding: 10px 20px 30px;
            max-width: 90%;
        }
    }
}

// 580 end

@media only screen and (min-device-width : 768px) and (max-device-width : 1366px) and (orientation : portrait) {
    .ipad-portrait-popup {
        display: flex !important;

        &.ipad-portrait-popup-fadeout {
            display: none !important;
        }

        .content-box {
            text-align: center;

            img {
                max-width: 200px;
            }
        }

        .popup-content {
            max-width: 550px;
            padding: 30px 20px 22px;
            margin-top: 12px;
        }

        h6 {
            font-family: "Ubuntu", sans-serif;
            font-weight: 400;
            font-size: 21px;
            line-height: normal;
            letter-spacing: normal;
            color: #435b75;
            margin-top: 12px;
        }
    }
}