.listingFlight {
    .mainContent {
        .filterCnt {
            #flightsTab.flight-tab-wrap {
                .travelerNclass {
                    .custom-dropdown {
                        box-sizing: border-box;
                        width: 320px;
                        padding: 5px 15px;
                        border-radius: 10px;
                        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
                        border: solid 1px #d2d9e7;
                        background-color: #e6e7ee;
                        position: absolute;
                        top: 0;
                        z-index: 3;
                        right: 0;
                        bottom: auto;
                    }

                    .travler-popup-wrap {
                        .btn {
                            min-width: 100px;
                            font-size: 13px;
                            line-height: 22px;
                            height: 35px;
                            padding: 0;
                            margin: 10px 0 10px auto;
                            display: block;

                            &:before {
                                display: none;
                            }
                        }
                    }

                    @media(max-width:991px) {
                        .custom-dropdown {
                            padding: 0;
                            width: auto;
                            position: static;
                            background-color: transparent;
                            border: none;
                            box-shadow: none;
                        }

                        .travler-popup-wrap {
                            box-sizing: border-box;
                            width: 320px;
                            padding: 5px 15px;
                            border-radius: 10px;
                            box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
                            border: solid 1px #d2d9e7;
                            background-color: #e6e7ee;
                            position: absolute;
                            top: 25px;
                            z-index: 3;
                            right: 0;
                            bottom: auto;
                        }
                    }
                }
            }
        }
    }

    .filter-display {
        +p {
            color: #ffffff;
        }
    }

    .sortFilter {
        .sortByOption {
            margin-top: 10px;
        }

        .input-range {
            border-radius: 4px;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #fff;
            border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);
            padding-right: 10px;

            .input-range__slider {
                box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #3f51b5;
                background: #3f51b5;
                width: 16px;
                height: 16px;
                transform: translateY(-50%);
                margin-top: -4px;

                @media(max-width:991px) {
                    width: 24px;
                    height: 24px;
                    box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 15%);
                    border: solid 1px #d2dae6 !important;
                    background-color: #f0f1f6 !important;
                }
            }

            .input-range__track {
                height: 0.5rem;

            }

            .input-range__track--active {
                background-color: #1d6ec9 !important;
            }
        }

        .filterDropdownWrap {
            width: 150px;

            @media(max-width:1130px) {
                width: 120px;
            }

            @media(max-width:1030px) {
                width: 100px;
            }
        }

        .airlines-wrap,
        .filter-emissions-wrap {
            width: 130px;
        }

        .airlines-wrap {

            h4,
            h5 {
                color: #8198a7;
                font-size: 16px;
                margin-bottom: 5px;
                font-weight: normal;
            }

            h5 {
                font-size: 14px;
            }

            .filterDropdown {
                min-width: 350px;

                @media (max-width: 767px) {
                    min-width: 100%;
                }

                .btnsCnt-wrap {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 26px;

                    .btnsCnt {
                        padding: 0;
                    }
                }
            }

            .connecting-airport-content {
                margin-top: 20px;

                .checkboxes-wrapper {
                    margin-top: 10px;
                    max-height: 250px;
                    overflow: hidden;
                    overflow-y: auto;

                    h4 {
                        font-size: 13px;
                    }

                    @media (max-width:991px) {
                        max-height: inherit;
                        overflow: initial;
                    }
                }

                .custom-switcher {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .customCheckbox {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 14px 0;

                    &:hover {
                        .btn-only {
                            display: block;
                        }
                    }

                    >span {
                        margin-top: 0;
                    }

                    .btn-only {
                        color: #1d6ec9;
                        border: none;
                        background-color: transparent;
                        font-size: 14px;
                        font-weight: 500;
                        display: none;
                    }
                }
            }

            .btnsCnt {
                text-align: right;
                justify-content: flex-end;
            }

            .btn-clear {
                border: none;
                color: #8198a7;
                background: none;
            }
        }

        .connecting-airlines-wrap {
            width: 170px;
        }

        .times-wrap {
            padding: 5px 0 10px;
            min-width: 300px;
            right: 0;

            @media (max-width: 767px) {
                min-width: 100%;
            }

            .times-toggle-ul {
                display: flex;
                border-bottom: 1px solid rgba(67, 91, 117, 0.2);
                padding: 0;
                justify-content: center;
                margin-bottom: 5px;

                li {
                    margin-bottom: 0;

                    a {
                        padding: 0;
                        box-shadow: none;
                        background: none;
                        border: none;
                        font-weight: normal;
                        color: rgba(67, 91, 117, 0.6);
                        font-size: 14px;
                        padding: 5px 20px 10px;
                        border-radius: 0;

                        &.active {
                            border-bottom: 2px solid #1d6ec9;
                            color: #1d6ec9;
                            margin-bottom: -1px;
                        }
                    }
                }
            }

            .icon-flight {
                &:before {
                    color: #7e94a8;
                }
            }

            .dot {
                display: block;
                height: 3px;
                width: 3px;
                border-radius: 100%;
                background: #8198a7;
                margin: 0 8px;
            }

            .flight-detail-info {
                display: flex;
                align-items: center;
                color: #8198a7;
                font-size: 14px;
                padding: 15px 0 10px;

                img {
                    margin-right: 7px;
                }
            }

            .flight-name {
                h4 {
                    color: #8198a7;
                    font-size: 16px;
                    font-weight: normal;
                }
            }

            .flight-info-group {
                padding: 13px 8px 0;
                // display: none;

                &.activeTab {
                    display: block;
                }

                .inputRange {
                    padding: 10px 15px;

                    input {
                        width: 100%;
                    }
                }
            }

            .clear-btn {
                border: none;
                color: #8198a7;
                background: none;
            }

            .clear-btn-wrap {
                padding: 8px;
                text-align: right;
            }

            #return {
                img {
                    transform: scaleX(-1);
                }
            }

            .flight-detail-info-wrap {
                padding-bottom: 7px;
            }
        }
    }

    .not-found-content {
        margin-top: 0;
    }

    .priceFilter {
        .priceVal {
            margin-bottom: 13px;
        }


        .btnsCnt {
            justify-content: flex-start;
        }
    }

    .flightResult {
        .flightInfoBox {
            position: relative;
            padding: 12px 20px 10px 16px;

            .flightDetailsbtn {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 0;
                font-size: 14px;

                @media (min-width: 992px) {
                    position: absolute;
                    bottom: 10px;
                    left: 0px;
                    width: 100%;
                    padding: 0 8px;
                }

                .green-text {
                    color: #00b7a6;
                    margin-right: 4px;
                    margin-left: 2px;
                }

                h4 {
                    font-size: 12px;
                    color: #435b75;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                }
            }

            @media (max-width: 767px) {
                display: block;
                padding: 0px;
            }

            .detailsLink {
                border: none;
                background-color: transparent;
                color: var(--text-color3);
            }
        }
    }

    .mobilePlanTitle.mobileShow {
        display: none;

        @media (max-width: 767px) {
            display: block;
        }
    }

    .mobileSortFilter {
        .sortFilter {
            display: flex;
            flex-direction: row;
            flex: 0 0 100%;
            justify-content: space-between;
            flex-direction: column;

            .checkbox-filter {
                display: flex;
                width: 100%;
            }
        }

        .sortByOption {
            width: 220px;
            margin-left: 25px;
            position: relative;

            .flterOption {
                padding: 8px 15px 22px 15px !important;
                flex-direction: column;


                .white-text {
                    width: 100%;
                    padding: 0px 0px;

                }

                [class*=fa] {
                    position: absolute;
                    right: 10px;
                    top: 19px;
                    pointer-events: none;
                }

                .sort-by-dropdown {
                    position: absolute;
                    height: 100%;
                    min-width: 100%;
                    background: transparent;
                    padding-top: 0px;
                    top: 0;
                    left: 0;
                    cursor: pointer;

                    .css-1s2u09g-control {
                        background: transparent;
                        border: none;
                        padding-top: 20px;
                    }

                    [class*=menu] {
                        box-sizing: border-box;
                        width: 100%;
                        min-width: auto;
                        padding: 5px;
                        // margin-left: -15px;
                        margin-top: 5px;
                        border-radius: 10px;
                        box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
                        border: solid 1px #d2d9e7;
                        background-color: #e6e7ee;
                        z-index: 1;

                        [class*=MenuList],
                        [role="listbox"] {
                            div {
                                font-size: 12px !important;
                                padding: 10px 10px;
                                font-size: 14px;
                                border-radius: 6px;
                                line-height: 17px;
                                color: #435b75 !important;
                                border-color: #d2dae6 !important;
                                box-shadow: none !important;
                                cursor: pointer;
                                background-color: transparent;

                                &:hover {
                                    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                                        inset -2px -2px 6px 0 #ffffff !important;
                                    border-color: #d2dae6 !important;
                                    color: #435b75 !important;
                                    padding: 10px 10px;
                                }
                            }

                            [class*=singleValue] {
                                div {
                                    margin: 0px !important;
                                    padding: 0px !important;
                                }
                            }
                        }

                    }

                    [class*=control] {
                        padding: 13px 0px 0px 15px;
                        background: transparent;
                        background-color: transparent;
                        cursor: pointer;
                        border: none;
                        border-color: none;
                        box-shadow: none;

                        :hover {
                            border-color: none;
                            border: none;
                        }
                    }

                    [class*=control]:hover {
                        border-color: none;
                        border: none;
                    }

                    [class*=control] {
                        div {
                            padding: 0px;
                            margin: 0px;
                        }

                        div {}

                        >div {
                            padding: 10px 0px 0px 0px;
                            margin: 5px 0px 0px 0px;
                        }



                    }

                    [class*=ValueContainer] {
                        padding-top: 10px;
                        padding-left: 0px;
                    }

                    [class*=indicatorSeparator],
                    [class*=indicatorContainer] {
                        display: none;
                    }

                    [class*=Input] {
                        margin: 0px;
                        padding: 0px;
                    }

                    [class*=singleValue] {
                        position: absolute;
                        top: 0;
                        left: 0;
                        color: var(--text-color3);
                        height: 100%;
                        margin: 0px 0px;
                        width: 100%;

                    }

                    .css-ackcql {
                        margin: 0px;
                        padding: 0px;
                    }
                }

            }
        }

    }


    .flightResult {
        margin-top: 17px;
        display: flex;
    }

    .airLinesLogo {
        width: 60px;
        margin-right: 10px;

        img {
            max-width: 50px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            width: 50px;
            margin-right: 8px;
        }
    }

    .flightInfoBox {
        margin-bottom: 0;
        min-width: 446px;
        flex-grow: 1;
    }

    .flightPlansRow {
        display: flex;
        min-width: 230px;
    }

    .flightPlanBox {
        margin-left: 10px;
        width: 23%;
        flex-grow: 1;
        text-align: center;
        background: rgba(243, 244, 248, 0.8);
        border-radius: 10px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: -10px;
            width: 10px;
            top: 26px;
            bottom: 12px;
            background: rgba(255, 255, 255, 0.3);
            display: block;
        }

        .flightPlanName {
            font-size: 14px;
            color: #fff;
            background: #00b7a6;
            border-radius: 10px 10px 0 0;
            padding: 5px 0 4px;
        }

        &.premiumEconomyPlan .flightPlanName {
            background: #008f82;
        }

        &.businessClassPlan .flightPlanName {
            background: #0076b7;
        }

        &.firstClassPlan .flightPlanName {
            background: #8a62d3;
        }

        .btn {
            min-width: 110px;
            padding: 7px;
            display: inline-block;

            &:before {
                display: none;
            }
        }

        // .btn:hover {
        //   color: #fff;
        // }

        .flightPlanInfo {
            padding: 10px;
            font-size: 12px;
            color: var(--text-color2);

            .flightPlanInfoInWrap {
                border-radius: 6px;
                box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                    inset -2px -2px 6px 0 #ffffff;
                border: solid 1px #dae2ee;
                background-color: rgba(255, 255, 255, 0.35);
                padding: 9px 11px 16px;
                min-height: 215px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media (max-width:767px) {
                    min-height: auto;
                    flex-direction: row;
                }

                .publicPrice {
                    margin-bottom: 5px;
                }

                .lineThrText {
                    text-decoration: line-through;
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }

            .totalText {
                white-space: normal;
                margin-bottom: 3px;
            }

            .taxNote {
                margin-bottom: 11px;
            }

            .savingText {
                display: inline-block;
                border-radius: 6px;
                background-color: #00b7a6;
                color: #fff;
                padding: 3px 11px 4px;
                margin-bottom: 6px;
            }

            .planPrice {
                font-size: 17px;
                font-weight: 500;
                color: var(--text-color);
                margin-bottom: 6px;

                .icon-info {
                    opacity: 0.5;
                    margin-left: 4px;
                    display: none;
                }
            }
        }
    }

    .flightInfoRow {
        display: flex;
        align-items: flex-start;
        padding: 20px 0px 17px 0;
    }

    .flightDurNstop {
        flex-grow: 1;
        text-align: center;
        margin-right: 19px;
        position: relative;

        .flightBar {
            height: 8px;
            border-radius: 4px;
            box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.2),
                inset -2px -2px 6px 0 #ffffff;
            border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);
            margin-bottom: 5px;
            position: relative;
            text-align: center;

            .icon-flight {
                position: absolute;
                right: -30px;
                font-size: 26px;
                top: -9px;
                text-shadow: -2px -2px 1px rgba(255, 255, 255, 0.5);

                *:before {
                    color: #7e94a8;
                }
            }

            .fa-circle {
                position: relative;
                top: -4px;
                color: var(--text-color2);
                font-size: 15px;
                margin-right: 5px;
                margin-left: 5px;
            }
        }
    }

    .flightStartEnd {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        max-width: 75%;
    }

    .flightNumbers {
        margin-left: auto;
        text-align: right;
        padding-left: 20px;
        font-size: 12px;
        width: 135px;

        @media(max-width:767px) {
            width: 100%;
        }
    }

    .flightEndInfo {
        text-align: right;
        position: relative;

        .supText {
            font-size: 12px;
            left: 103%;
            top: -3px;
            position: absolute;
            color: var(--text-color3);
        }
    }

    .flightLocation,
    .flgithDate {
        font-size: 14px;
        color: var(--text-color4);

        @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 13px;
        }
    }

    .flightTime {
        font-size: 20px;
        font-weight: normal;
        color: var(--text-color);
        margin-bottom: 2px;
        margin-top: 2px;

        @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 17px;
        }
    }

    .flightDuration {
        border-radius: 15px;
        box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.85),
            2px 2px 3px 0 rgba(0, 0, 0, 0.15);
        border: solid 1px rgba(210, 218, 230, 0.6);
        background-color: rgba(255, 255, 255, 0.35);
        color: var(--text-color3);
        padding: 5px 10px;
        display: inline-flex;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        top: -11px;

        .icon-timer {
            margin-right: 5px;
        }
    }

    .flightDetailsbtn {
        padding-left: 8px;
        margin-top: 4px;
        font-size: 14px;

        a.detailsLink {
            color: var(--text-color3);

            .fa {
                margin-left: 7px;
            }
        }
    }

    .flgithStops {
        text-align: center;
        color: var(--text-color3);
        font-size: 14px;

        &.nonStopFlight {
            color: var(--text-green);
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 12px;
        }
    }

    .noFlightAvailable {
        .flightPlanInfo .flightPlanInfoInWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            height: 215px;
            padding: 48px 23px 44px;
            box-sizing: border-box;

            img {
                width: 48px;
            }

            .not-available-content {
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
            }
        }
    }

    .popupStyle {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        z-index: 99;
        display: flex !important;

        .popUpBox {
            width: 640px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #cfd1e1;
            background-color: #eff0f5;
            box-sizing: border-box;
            position: relative;
            padding: 15px 0 0;
            max-width: 900px;
            width: 100%;
            margin-top: 0px;
        }



        .popupCloseBtn {
            position: absolute;
            right: 0;
            top: 0;
            padding: 9px;

            .icon-close {
                &:before {
                    color: var(--text-color3);
                }
            }
        }

        .flightStartEnd {
            padding: 0 2px 0 7px;
        }

        .flightDurNstop {
            margin-left: 8px;
            margin-right: 30px;

            .flightBar .fa-circle {
                margin: 0 22px;
            }
        }

        .popupTitle {
            font-size: 17px;
            color: var(--text-color);
            font-weight: 500;
            margin-bottom: 5px;
            padding-left: 20px;
        }
    }

    .flightSubDetails {
        padding: 6px 0 9px 101px;
        color: var(--text-color);
        font-size: 14px;

        .flightLogo2 {
            img {
                max-width: 70px;
            }
        }

        .flightID {
            color: var(--text-color4);
        }

        .innerRow {
            display: flex;

            .colLeft {
                width: 84px;
            }
        }

        .leftSpace {
            padding-left: 30px;
        }

        .flightTime2,
        .flightDuration2 {
            font-weight: 500;
            display: flex;
            align-items: center;

            .icon-timer {
                margin-right: 5px;
            }

            .icon-timer:before {
                color: var(--text-color);
            }
        }

        .flightTime2 {
            padding-right: 5px;
        }

        .blueText {
            color: var(--text-color3);
        }

        .layOverInfo {
            padding: 6px 5px 6px 9px;
            border-radius: 15px;
            box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff;
            border: solid 1px #dae2ee;
            background-color: rgba(255, 255, 255, 0.35);
            max-width: 100%;
            font-size: 14px;
        }

        .row1 {
            padding-bottom: 12px;
            padding-left: 10px;
        }

        .row2 {
            align-items: center;
            padding-bottom: 7px;
            padding-left: 0px;

            .flightStartPoint {
                padding-bottom: 10px;
            }
        }

        .row3 {
            .colLeft {
                width: 88px;
            }
        }

        .flightStartEndPoints {
            position: relative;

            .flightStartPoint:after {
                width: 1px;
                content: "";
                position: absolute;
                left: -22px;
                top: 10px;
                bottom: -6px;
                background: var(--text-color4);
                height: 100%;
            }

            .flightStartPoint,
            .flightEndPoint {
                position: relative;
                display: flex;

                &:before {
                    position: absolute;
                    left: -25px;
                    top: 3px;
                    content: "";
                    width: 8px;
                    height: 8px;
                    border: 1px solid var(--text-color4);
                    border-radius: 50%;
                    background: #eff0f5;
                }
            }
        }

        .flightKeyValue {
            color: var(--text-color4);
            padding: 16px 0 0 12px;
            position: relative;

            .flightVal {
                font-size: inherit;
                font-weight: 500;
                color: var(--text-color);
                padding-left: 5px;
            }

            .refundable {
                color: green;
                border: none;
            }

            .non-refundable {
                color: red;
                border: none;
            }

            .keyValWrap {
                display: inline-block;
                padding: 0 17px 9px 0;

                .baggage-dropdown {
                    width: auto;
                    padding: 9px 10px 10px 10px;
                    border-radius: 10px;
                    box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                    background-color: #eff0f5;
                    position: absolute;
                    display: block;
                    bottom: 28px;

                    table {
                        width: 100%;

                        th {
                            text-align: left;
                        }
                    }

                    .piece-col,
                    .wt-col {
                        text-align: center;
                        padding: 7px;
                    }

                    tbody {
                        tr {
                            .value-col {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

    }

    .flightStartInfo {
        width: 84px;

        @media (max-width:600px) {
            width: 90px;
        }
    }

    .flightEndInfo {
        width: 95px;
    }

    .popupFlightBox {
        padding-bottom: 18px;

        .no-fare-rule-found {
            font-size: 17px;
            color: var(--text-color);
            font-weight: 500;
            margin: 20px 0;
        }

        .flightInfoRow {
            padding-top: 50px;

            .airLinesLogo {
                width: 95px;
                cursor: pointer;
                position: relative;

                .logo-tip {
                    width: auto;
                    padding: 9px 10px 10px 10px;
                    border-radius: 6px;
                    box-shadow: 0 2px 10px 2px rgb(0 0 0 / 25%);
                    background-color: #eff0f5;
                    position: absolute;
                    display: none;
                    bottom: 55px;
                }
            }

            .airLinesLogo:hover .logo-tip {
                display: block;
            }
        }

        .flightSubDetails {
            padding: 6px 0 9px 107px;

            .row1 {

                padding-left: 0px;
            }

            .flightrow {
                padding: 20px 0px 15px 19px;
                flex-wrap: wrap;

            }

            .flightRowWrap {
                max-width: 100%;
                display: flex;
                flex: 0 0 100%;
                align-items: flex-start;

                .innerRow {
                    flex: 0 0 65%;
                    max-width: 65%;
                }
            }

            .flightFacilities {
                ul {
                    li {
                        color: #8198a7;
                        font-size: 14px;
                        margin-bottom: 8px;
                        font-weight: normal;
                        display: flex;

                        align-items: flex-start;

                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    .mobileSectionTitle {
        display: none;
    }

    .flightDetailsModal {
        .layOverInfo {
            margin-top: 25px;
            margin-bottom: 25px;

            .innerRow {
                justify-content: center;
            }
        }

        .popupCloseBtn {
            background-color: transparent;
            border: none;
        }
    }


    .filterCnt {
        #flightsTab.flight-tab-wrap {
            .startEndDate {
                .icon-invitation {
                    margin-top: 7px;
                    margin-right: 8px;
                }

                .icon-arrow {
                    margin: 9.5px 0 0 0;
                    position: relative;
                    left: -12.5px;
                    padding-right: 10px;
                    font-size: 11px;
                }
            }

        }
    }

    @media (max-width: 1199px) {
        .flightDuration {
            font-size: 12px;
        }

        .flightStartEnd {
            width: 100%;
            padding: 0px 0px 20px 0px;
            max-width: 100%;
        }

        .flightInfoBox {
            min-width: 370px;
        }

        .pageWrap .flightInfoRow {
            // flex-direction: column;
            // align-items: flex-start;
            // padding-top: 0;
            // padding-bottom: 0px;

            .airLinesLogo {
                padding-bottom: 20px;
            }
        }


        .flightPlanBox {
            .btn {
                min-width: 100px;
                padding: 7px;
                display: inline-block;
            }

        }
    }



    @media (max-width: 991px) {
        .flightResult {
            background: rgba(243, 244, 248, 0.8);
            border-radius: 10px;
            box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
            padding: 10px 10px 12px;
            flex-direction: column;
            margin-top: 7px;
        }

        .flightInfoRow {
            flex-direction: row;
            padding-top: 20px;
        }

        .flightInfoBox {
            margin-bottom: 10px;
        }

        .flightPlansRow {
            .flightPlanBox:first-child {
                margin-left: 0;

                &:before {
                    display: none;
                }
            }
        }

        .filterCnt {
            .dropdownInWrap {
                left: auto;
                right: 0;
            }
        }

    }

    @media (max-width: 991px) {

        .mobileFilterBtn {
            position: sticky;
            transition: all 0.3s;
            top: 0px;
            width: 100%;
            left: 0;
            border: none;
            right: 0;
            z-index: 0;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
            background: linear-gradient(to right, #4d98d2 24%, #4d98d2 65%);
            text-align: center;
            display: block;
            font-size: 14px;
            color: #fff;
            padding: 9.5px;
            border-radius: 17.5px;

        }


        .show-less-btn,
        .show-more-btn {
            display: none !important;
            background-color: transparent;
            border: none;
            max-width: 100px;
        }

        .not-available-content {
            .mobileHide {
                display: block;
            }
        }

        .mobileSortFilter {
            padding: 20px 15px 80px;

            .mobilePopTitle {
                padding-bottom: 12px;
            }

            .clearBtnMobile {
                .btn {
                    font-size: 17px;
                }
            }

            .sortFilter {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .btnsCnt {
                    display: none;
                }

                .checkbox-filter {
                    display: flex;
                    width: 100%;
                    flex: 0 0 100%;
                    flex-direction: column;
                    order: 2;
                }

                .filterDropdownWrap {
                    width: 100%;
                    max-width: 100%;
                    margin: 0px 0px 25px 0px;

                    .flterOption {
                        box-shadow: none;
                        background: transparent;
                        padding: 0px;
                        font-weight: 500;
                        font-size: 17px;
                        padding: 0px 0px 10px 0px;
                        color: var(--text-color);

                        .fa {
                            display: none;
                        }
                    }

                    .filterDropdown {
                        background: transparent;
                        box-shadow: none;
                        position: relative;
                        top: 0;
                        display: block;
                        padding: 0;

                        .customCheckbox {
                            span {
                                font-size: 17px;
                            }

                            span:before {
                                width: 21px;
                                height: 21px;
                            }
                        }
                    }

                    .filterDropdown.priceFilter {
                        width: 100%;
                        padding-top: 10px;
                    }

                    .showMore {
                        background-color: transparent;
                        border: none;
                        display: flex;
                    }
                }

                .mobilePopTitle {
                    padding-bottom: 13px;
                }

                .sort-filter-wrap {
                    display: flex;
                    width: 100%;
                    flex: 0 0 100%;
                    padding-bottom: 0px;

                    .filterDropdownWrap.sortByOption {

                        margin-left: 0px;

                        .fa {
                            position: absolute;
                            right: 16px;
                            display: block;
                            top: 12px;
                            color: var(--text-color3);
                        }


                        .css-b62m3t-container {
                            width: 100%;
                            cursor: pointer;

                            [class*=control] {
                                padding: 9px 10px 10px 15px;
                                cursor: pointer;
                                font-weight: normal;
                                min-height: auto;
                            }
                        }

                        .flterOption {
                            width: 100%;
                            padding: 0px;
                            font-size: 17px;
                            box-shadow: -2px -2px 4px 0 #ffffff, 2px 2px 3px 0 rgb(0 0 0 / 15%);
                            border: solid 1px #cfd1e1;
                            background-image: linear-gradient(to right, #ececf3, #e6e7ee);
                            min-height: 45px;
                        }

                        .whiteLabel {
                            display: none;
                        }

                        .css-26l3qy-menu {
                            left: 0px;

                            .css-4ljt47-MenuList {


                                .css-9gakcf-option {
                                    color: #435b75 !important;
                                    padding: 10px 10px;
                                }

                                .css-1n7v3ny-option,
                                .custom-select__option--is-selected,
                                .css-9gakcf-option {
                                    color: #435b75 !important;
                                }

                                .css-1n7v3ny-option,
                                .css-9gakcf-option:hover {
                                    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                                        inset -2px -2px 6px 0 #ffffff !important;
                                    border-color: #d2dae6 !important;
                                    color: #435b75 !important;
                                    padding: 10px 10px;
                                }

                            }
                        }
                    }

                }
            }

            .sortByOption {
                .flterOption {
                    .sort-by-dropdown {
                        [class*=control]>div {
                            padding: 0px 0px 0px 0px;
                            margin: 0px 0px 0px 0px;
                        }
                    }
                }
            }
        }

        #flightsTab.flight-tab-wrap {
            .travelerNclass {
                .custom-dropdown.travler-popup {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    padding: 20px 15px 80px;
                    border-radius: 0px;
                    position: fixed;
                    top: 0px;
                    z-index: 3;
                    right: 0;
                    left: 0;

                    .dropdown-title {
                        padding-bottom: 12px;

                        h3 {
                            font-size: 23px;
                            color: var(--text-color);
                            font-weight: normal;
                        }
                    }

                    h5 {
                        font-size: 17px;
                        line-height: 20px;
                        font-weight: 500;
                    }

                    h6 {
                        font-size: 14px;
                    }

                    h3 {
                        font-size: 20px;
                        padding-top: 10px;
                    }

                    .popupCloseBtn {
                        display: block;
                        background: transparent;
                        border: none;
                        padding: 15px;

                        .icon-close {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }


    @media (max-width: 767px) {

        .show-less-btn,
        .show-more-btn {
            display: block !important;
        }

        .not-available-content {
            .mobileHide {
                display: none;
            }
        }
    }

    @media (max-width: 767px) {
        .flightResult {
            margin-top: 10px;

            .flightInfoRow {
                padding: 7px 10px 9px 11px;
                flex-direction: column;

                @media (max-width: 374px) {
                    padding: 7px 5px 9px;
                }
            }

            .flightInfoRow .airLinesLogo {
                padding-bottom: 16px;
            }

            .flightEndInfo {
                width: 85px;
            }

            .flightStartInfo {
                width: 72px;
            }

            .flightTime {
                font-size: 17px;

                @media (max-width: 374px) {
                    font-size: 15px;
                }
            }

            .flightDetailsbtn {
                padding-left: 3px;
                font-size: 16px;

                a.detailsLink {
                    .fa-angle-down {
                        transform: rotate(-90deg);
                        margin-left: 4px;
                    }
                }
            }

            .flightInfoBox {
                margin-bottom: 20px;
                min-width: 100%;
            }

            .flightPlanBox {
                position: relative;
                margin-left: 2px;

                .mobilePlanTitle {
                    margin-bottom: 13px;
                }

                &:after {
                    content: "";
                    width: 10px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    display: block;
                    border-radius: 6px 0 0 6px;
                }

                .btn {
                    min-width: 140px;
                    padding: 9.5px 7px;
                }

                .flightPlanInfo .taxNote {
                    margin-bottom: 8px;
                }

                .icon-info {
                    font-size: 14px;
                }
            }

            .flightPlanBox.economyPlan {
                margin-left: 2px;

                &:after {
                    background: #00b7a6;
                }

                .mobilePlanTitle {
                    color: #00b7a6;
                }
            }

            .flightPlanBox.businessClassPlan {
                &:after {
                    background: #0076b7;
                }

                .mobilePlanTitle {
                    color: #0076b7;
                }
            }

            .flightPlanBox.firstClassPlan {
                &:after {
                    background: #8a62d3;
                }

                .mobilePlanTitle {
                    color: #8a62d3;
                }
            }

            .flightPlanBox.premiumEconomyPlan {
                &:after {
                    background: #008f82;
                }

                .mobilePlanTitle {
                    color: #008f82;
                }
            }

            .flightPlanInfoInWrap {
                margin-left: 7px;

                .publicPrice {
                    margin-bottom: 2px !important;
                }
            }

            .noFlightAvailable .flightPlanInfo .flightPlanInfoInWrap {
                min-height: auto;
                align-items: flex-start;
                padding: 9px 11px 12px;
            }

            .mobileShowMoreLink {
                font-size: 14px;
                color: var(--text-color3);

                .fa {
                    padding: 0 5px;
                }

                &.active {
                    .fa {
                        transform: rotate(180deg);
                    }
                }
            }

            .flightPlansRow {
                .flightPlanBox {
                    display: none;

                    &:first-child {
                        display: block;
                    }
                }

                &.showAllPlans {
                    .flightPlanBox {
                        display: block;
                    }
                }
            }
        }

        .flightInfoBox {
            padding: 0;
            border: 0;
            background: transparent;
            box-shadow: none;
        }

        .flightPlanName {
            display: none;
        }

        .flightPlansRow {
            flex-direction: column;
        }

        .flightPlanBox {
            width: auto;
            margin-left: 0;
            margin-bottom: 10px;

            &.card2 {
                background: transparent;
                border: 0;
                box-shadow: none;
                padding: 0;
            }

            .flightPlanInfo {
                padding: 0;
            }

            &:before {
                display: none;
            }

            .flightInfoRow {
                flex-direction: column;
                padding-top: 7px;

                .airLinesLogo {
                    padding-bottom: 16px;
                }
            }
        }

        .mobileLeft {
            text-align: left;
            display: flex;
            flex-direction: column;

            .savingText {
                order: 2;
            }
        }

        .flightPlanInfoInWrap {
            display: flex;
            justify-content: space-between;
        }

        &.popupOpned {
            .popupStyle {
                display: block;
            }
        }

        .popupStyle {

            .popUpBox {
                border-radius: 0;
                width: 100%;
                height: 100%;
            }
        }

        .mobileFilterBtn {
            border-radius: 17.5px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.3);
            text-align: center;
            display: block;
            font-size: 14px;
            color: #fff;
            padding: 9.5px;

            .fa {
                margin-right: 10px;
            }
        }

        .mobileSortFilter.showPopup {
            display: block;
            padding: 20px 15px 80px;
            z-index: 999;

            .clearFilter {
                display: none;
            }

            .mobilePopTitle {
                padding-bottom: 12px;
            }

            .filterTitle {
                padding-bottom: 13px;
                order: 2;
            }

            .closePopup {
                top: 0px;
            }
        }

        .filterDropdownWrap {
            order: 2;
        }


        .clearFilter {
            position: absolute;
            right: 62px;
            top: 14px;
            color: var(--text-color3);
        }

        .applyBtnMobile {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            text-align: center;
            background-image: linear-gradient(to bottom,
                    rgba(230, 231, 238, 0),
                    #e6e7ee);
            padding: 35px;
            padding-bottom: 20px;

            .btn {
                box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.2),
                    -3px -3px 6px 2px #ffffff;
            }
        }

        .showMoreWrap {
            .showMoreInWrap {
                transition: max-height 0.3s;
                max-height: 200px;
                overflow: hidden;
                margin-bottom: 7px;
            }

            &.openMore {
                .showMoreInWrap {
                    max-height: 1000px;
                }
            }

            .showMore {
                color: var(--text-color3);
                display: flex;
                align-items: center;

                .fa {
                    font-size: 23px;
                    line-height: 23px;
                    margin-left: 9px;
                }
            }
        }

        .filterCnt {
            padding: 4.5px 19px 4.5px 15px;
            margin-bottom: 9px;
        }

        .mobileSearchBlock {
            color: #fff;
            display: flex;
            align-items: center;
            padding-bottom: 5px;

            h4 {
                font-size: 14px;
                font-weight: normal;
            }

            p {
                font-size: 12px;
            }

            .fa-search {
                margin-right: 16px;
            }

            .fa-angle-down {
                margin-left: auto;
            }

            .arrow {
                opacity: 0.4;
                font-size: 17px;
                line-height: 16px;
            }

            .fa-circle {
                font-size: 4px;
                vertical-align: middle;
                opacity: 0.6;
                margin: 0 2px;
            }

            .selectSearchCnt {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                white-space: nowrap;
                height: 31px;
                padding-top: 3px;

                h4 {
                    margin-right: 10px;
                }
            }
        }



        @media (max-width: 374px) {
            .filterCnt {
                padding: 1px 5px 4px 5px;
            }

            .mobileSearchBlock .fa-search {
                margin-right: 5px;
            }
        }


    }

    @media (max-width: 579px) {
        .flightDuration {
            font-size: 14px;
            padding: 2px 5px;
            top: -1px;
            margin-bottom: 6px;
        }

        .flightDurNstop {
            .flightBar {
                margin-bottom: 9px;
                height: 2px;
                border-radius: 3px;
                box-shadow: -1px -1px 2px 0 #ffffff, 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
                background-color: #7e94a8;
                border: 0;

                .icon-flight {
                    right: -20px;
                    font-size: 19px;
                    top: -8px;
                }

                .fa-circle {
                    top: -9px;
                    color: #1d6ec9;
                    font-size: 10px;
                }
            }
        }

        .flightInfoRow {
            padding-right: 0;
            padding-left: 4px;
        }

        .flightTime {
            margin-top: 10px;
            font-size: 21px;
        }

        .flgithDate {
            display: none;
        }

        .flgithStops,
        .flightLocation {
            font-size: 12px;
        }

        .flightSubDetails .row2 .flightStartPoint {
            margin-bottom: 9px;
            padding-bottom: 0;
        }

        .popupStyle {
            .flightDurNstop {
                margin-left: 0;
                margin-right: 11px;
            }

            .flightStartEnd {
                padding-left: 0;
            }

            .popupScrollBox {
                padding: 0 10px 15px;
            }

            .mobileSectionTitle {
                display: block;
                font-weight: 500;
                color: var(--text-color);
                font-size: 17px;
                margin-top: 14px;
                padding-left: 4px;
            }

            .popupCloseBtn {
                padding: 14px;
            }

            .popUpBox {
                padding-top: 25px;
            }

            .popupTitle {
                font-size: 23px;
                padding-left: 15px;
                margin-bottom: 9px;
                font-weight: normal;
            }

            .airLinesLogo {
                display: none;
            }

            .flightSubDetails {
                padding-left: 0;

                .flightKeyValue {
                    padding-left: 6px;

                    .keyValWrap:last-child {
                        padding-bottom: 0;
                    }
                }

                .row2 {
                    padding-left: 9px;
                }

                .innerRow .colLeft {
                    width: 82px;
                }

                .leftSpace {
                    padding-left: 17px;
                }

                .row3 {
                    .colLeft {
                        width: 78px;
                    }
                }

                .flightStartPoint:after {
                    left: -16px;
                }

                .flightStartPoint,
                .flightEndPoint {
                    &:before {
                        left: -20px;
                    }
                }
            }

            .popupFlightBox {
                padding-bottom: 0;
            }

            .flightEndInfo {
                .supText {
                    left: 77%;
                }
            }
        }
    }


}

.listingFlight {
    .sortFilter {
        .sortByOption {
            position: relative;

            .flterOption {
                flex-direction: column;
                align-items: flex-start;
            }

            .fa {
                position: absolute;
                right: 12px;
                top: 20px;
            }

            .css-b62m3t-container {
                position: static;

                [class*=singleValue] {
                    margin: 0;
                    color: #1d6ec9;
                }

                [class*=indicatorSeparator],
                [class*=indicatorContainer] {
                    display: none;
                }
            }

            .css-1s2u09g-control,
            .css-1pahdxg-control {
                background: none;
                border: none;
                min-height: auto;
                box-shadow: none;

                >div {
                    padding: 0;
                }

                .css-1hb7zxy-IndicatorsContainer,
                .css-1gtu0rj-IndicatorsContainer {
                    display: none;
                }

                .css-319lph-ValueContainer {
                    padding: 0;

                    .css-6j8wv5-Input {
                        padding: 0;
                        margin: 0px;
                        display: block;
                    }

                    .css-qc6sy-singleValue {
                        margin: 0;
                        color: #1d6ec9;
                    }
                }
            }

            .css-26l3qy-menu {
                left: 0px;

                .css-4ljt47-MenuList {
                    div {
                        padding: 5px 8px;
                        font-size: 14px;
                        border-radius: 6px;
                        line-height: 17px;
                        color: #435b75 !important;
                        cursor: pointer;
                    }

                    .css-9gakcf-option {
                        color: #435b75 !important;
                        padding: 5px 8px;
                    }

                    .css-1n7v3ny-option,
                    .custom-select__option--is-selected,
                    .css-9gakcf-option {

                        color: #435b75 !important;
                    }

                    .css-9gakcf-option:hover {
                        box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%),
                            inset -2px -2px 6px 0 #ffffff;
                        border-color: #d2dae6 !important;
                        color: #435b75 !important;
                        padding: 5px 8px;
                    }

                }

                .css-9gakcf-option {
                    box-shadow: inset 2px 2px 3px 0 rgb(0 0 0 / 20%), inset -2px -2px 6px 0 #ffffff
                }
            }
        }
    }
}


.listingFlight {
    .sortFilter {
        .priceFilter {
            .priceVal {
                display: flex;
                justify-content: space-between;
            }
        }

        .btnsCnt {
            .btnBorder {
                // background: #1d6ec9;
                // border: 1px solid #1d6ec9;
                color: var(--white);
                width: 100px;
                border-radius: 15px;
                margin-right: 28px;
                padding: 6px;
                cursor: pointer;
                background-image: linear-gradient(90deg, #1377c4, #00ceda);
                border: none;
                box-shadow: 2px 2px 4px 2px rgb(0 0 0 / 25%), -1px -1px 4px 2px hsl(0deg 0% 100% / 35%);

                &:hover {
                    background-image: linear-gradient(90deg, #00ceda -12%, #1377c4);
                }
            }

            .cancelBtn {
                border: none;
                background: transparent;
            }
        }

        .filterDropdownWrap .btnsCnt .borderBtn {
            background-image: linear-gradient(90deg, #1377c4, #00ceda);
            border: none;
            box-shadow: 2px 2px 4px 2px rgb(0 0 0 / 25%), -1px -1px 4px 2px hsl(0deg 0% 100% / 35%);

            &:hover {
                background-image: linear-gradient(90deg, #00ceda -12%, #1377c4);
            }
        }
    }

    .popupStyle {

        .popUpBox {
            max-width: 900px;
            width: 100%;
            max-height: calc(100vh - 120px);
            margin-top: 0px;
            overflow-y: auto;
            border-radius: 10px;

            .popupCloseBtn {

                font-size: 16px;
            }

        }



        .popupCloseBtn {
            position: absolute;
            right: 0;
            top: 0;
            padding: 9px;

            .icon-close {
                &:before {
                    color: var(--text-color3);
                }
            }
        }

        .flightStartEnd {
            padding: 0 2px 0 7px;
        }

        .flightDurNstop {
            margin-left: 8px;
            margin-right: 25px;

            .flightBar .fa-circle {
                margin: 0 22px;
            }
        }


    }
}

// Responsive css start
.listingFlight {
    @media (min-width: 768px) and (max-width: 1180px) {
        .flightDetailsModal .popupStyle .popUpBox {
            max-height: 85vh;
            max-width: calc(100% - 30px);
        }
    }

}

.featuresGlobalDetails .infoToolTip {
    display: flex;
}

@media (min-width: 768px) and (max-width: 1180px) {
    .featuresGlobalDetails {
        top: 50%;
        transform: translateY(-50%);

        .flightFeaturesContent {
            height: auto;
        }

        .wrapperRow:first-child {
            height: 66vh;
            overflow: hidden;
            overflow-y: auto;
            max-height: 480px;
        }

        .classLabel {
            height: 58px;

            span {
                margin-right: 10px;
            }
        }

        .slick-list .classLabel .classLabelInner {
            height: 42px;
            line-height: 42px;
        }

        .slick-slider {
            .slick-arrow {
                top: 10px;
                transform: none;
                background-color: transparent;

                &:before {
                    background-color: transparent;
                    font-size: 22px;
                }
            }

            .slick-prev {
                left: 15px;

                &::before {
                    left: 0;
                }
            }

            .slick-next {
                right: 15px;
            }

            .slick-next {

                &::before {
                    position: relative;
                    right: 0;
                    top: 0;
                    transform: none;
                }
            }
        }
    }

    .listingFlight {
        .flightNumbers {
            min-width: 100px;
            max-width: 100px;
            width: 100%;
        }
    }
}

@media(max-width:992px) {

    .listingFlight {
        .popupFlightBox {
            .flightSubDetails {
                .flightRowWrap {
                    .innerRow {
                        flex: 0 0 60%;
                        max-width: 60%;
                    }
                }
            }
        }

        .sortFilter {
            .input-range {
                height: 6px;
                width: calc(100% - 20px);

                .input-range__track {
                    height: 6px;
                }

                .input-range__slider {
                    margin-top: -5px;
                }
            }
        }

        .mobileSortFilter {
            .applyBtnMobile {
                z-index: 999;
            }
        }
    }
}

@media(max-width: 768px) {

    .listingFlight {
        .popupFlightBox {
            .flightInfoRow {
                padding-top: 30px;

                .airLinesLogo {
                    display: none;
                }
            }

            .flightSubDetails {
                padding: 5px;

                .flightRowWrap {
                    .innerRow {
                        flex: 0 0 60%;
                        max-width: 60%;
                    }
                }

                .flightrow {
                    padding: 0px 0px 15px 17px;
                    flex-wrap: wrap;
                }
            }
        }

        .flightDetailsModal .popupStyle .popUpBox {
            max-width: calc(100% - 20px);
        }
    }
}


@media (max-width: 579px) {
    .listingFlight {
        .popupStyle {
            .flightStartEnd {
                padding-left: 0;
            }
        }
    }

    .listingFlight {
        .popupFlightBox {


            .flightSubDetails {
                .row2 {
                    padding-left: 0px;
                }

                .flightRowWrap {
                    flex-direction: column;

                    .innerRow {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }

                .flightrow {
                    padding: 0px 0px 15px 17px;
                    flex-wrap: wrap;
                    flex-direction: column;
                }

                .flightFacilities {
                    padding-left: 0px;
                    padding-top: 10px;
                }
            }
        }
    }
}

.input-range__track--background {
    margin-top: -0.25rem;
}