.slick-list {
    height: 100%;
    object-fit: cover;
    position: relative;
    // margin: 0 1px;
}

.slick-slider {
    height: 100%;
    max-height: 400px;
    object-fit: cover;
    position: relative;
}

.slick-slide {
    padding: 0px;
}

.slick-slider {
    z-index: 0;



    .slick-prev {
        left: 7px;
        z-index: 1;
    }

    .slick-arrow {
        top: calc(50% + 9px);
        transform: translateY(calc(-50%));
        height: 36px;
        width: 36px;

        &:before {
            height: 36px;
            width: 36px;
            padding: 9px 12px;
            border-radius: 100px;
            display: inline-block;
            position: absolute;
            font: normal bold normal 18px/1 FontAwesome;
            -webkit-font-smoothing: antialiased;
            color: #1d6ec9;
            background-color: white;
            opacity: 0.5;
            transform: translateY(-50%);
            top: 50%;
        }
    }

    .slick-next {
        right: 7px;
        z-index: 0;
    }

    .slick-next:before {
        content: "\f105";
        right: 7px;
    }

    .slick-prev:before {
        content: "\f104";
    }

    .w-100 {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        border-radius: 10px;
        overflow: hidden;
    }
}

.thumbnail {
    .slick-list {
        margin: 0 -3.5px;
    }

    .slick-slide {
        padding: 0 2.5px;
    }
}

@media(max-width:991px) {
    .slick-slider {
        height: 100%;
        max-height: 340px;
        object-fit: cover;
        position: relative;

        .slick-arrow:before {
            font-size: 48px;
        }

        .slick-next {
            right: -3px;
            z-index: 0;
            //transform: rotate(90deg) translatex(28px);
        }

        .w-100 {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
            border-radius: 10px;
            overflow: hidden;

        }
    }

    .thumbnail {
        .slick-arrow {
            top: 40%;
        }
    }
}

@media(max-width:767px) {
    .slick-slider {
        .slick-arrow {
            height: 25px;
            width: 25px;
            top: calc(50% + 4.5px);
            &:before {
                height: 25px;
                width: 25px;
            }
        }

        .slick-prev:before {
            padding: 2px 5px;
            font-size: 18px;
        }

        .slick-next:before {
            padding: 2px 5px;
            right: 50%;
        }
    }
}